import "./EmailAutoComplete.scss";

import React, {FC, useEffect, useState} from "react";
import AutoCompleteDto from "../compose/AutoCompleteDto";
import {performFetch} from "../../util/HttpHelper";
import {AutocompleteGetTagProps, AutocompleteRenderInputParams, Chip, Paper, TextField} from "@mui/material";
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {DomainValidationStatus} from "../compose/DomainValidationStatus";
import {extractAddressDomain, extractEmailAddresses} from "../../util/Formatters";
import {MailHelperService} from "../../services/common/MailHelperService";
import {useAppStore} from "../../hooks/ReduxHooks";
import styled from "@emotion/styled";
import LockIcon from '@mui/icons-material/Lock';
import Autocomplete from '@mui/material/Autocomplete';
import {getStore_DoNotCall} from "../../BootStrap";

const AutoCompleteOptionSpan = styled.span`
  font-size: 10pt;
  display: flex;
  align-items: center;

  span.name {
    display: block;
    font-weight: bold;
  }

  .key {
    margin-right: 5px;
    margin-left: -5px;
  }
`;

interface EmailAutoCompleteProps {
    label: string;
    value: AutoCompleteDto[];
    autoFocus?: boolean;
    setValue: (newValue: AutoCompleteDto[]) => void;
}

const AutoCompleteTag: FC<{ option: AutoCompleteDto, tagProps: {} }> = ({option, tagProps}) => {
    const [status, setStatus] = useState<DomainValidationStatus>("none");

    useEffect(() => {
        if (!option.Address || !option.Address.includes("@")) {
            setStatus("invalid");
            return;
        }
        const domain = extractAddressDomain(extractEmailAddresses(option.Address)[0]) || "";
        MailHelperService.validateEmailDomain(domain)
            .then(setStatus)
            .catch(console.error);
    }, [option.Address]);

    return (
        <Chip
            {...tagProps}
            label={getAutoCompleteLabel(option)}
            title={(status === "invalid" ? "The email address might be invalid: " : "") + option.Address}
            className={status}
        />
    );
}

const SUGGESTIONS_DELAY = 200;

type AutoCompleteOption = AutoCompleteDto | string;

function getAutoCompleteLabel(option: AutoCompleteDto | string) {
    if (typeof option === "string") {
        return option;
    }
    if (option.HasKey === undefined) {
        const pgpKeyAddresses = getStore_DoNotCall().getState().accountsState.pgpKeyAddresses;
        if (pgpKeyAddresses.length > 0) {
            option.HasKey = pgpKeyAddresses.includes(option.Address);
        }
    }
    const prefix = option.HasKey ? "🔒 " : "";
    return prefix + (option.Name && option.Name.length > 0 ? option.Name : option.Address);
}

function isString(value: AutoCompleteOption): value is string {
    return (value as AutoCompleteDto).Address === undefined;
}

const RenderEmailAutoComplete = (props: EmailAutoCompleteProps) => {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = useState<AutoCompleteDto[]>([]);
    const [currentText, setCurrentText] = useState<string>();

    const [textSubject] = React.useState(new Subject<string>());

    const store = useAppStore();

    useEffect(() => {
        if (!open || !currentText || currentText.length < 1) return;

        try {
            textSubject.next(currentText);
        } catch (e) {
        }
    }, [open, currentText, textSubject]);

    useEffect(() => {
        let subscription: Subscription;
        try {
            subscription = textSubject.pipe(debounceTime(SUGGESTIONS_DELAY))
                .subscribe({
                    next: text_ => {
                        (async () => {
                            try {
                                const result = await performFetch(`/Mail3/LookupPotentialContacts/${text_}`) as any[];

                                const pgpKeyAddresses = store.getState().accountsState.pgpKeyAddresses;

                                const newOptions = [
                                    {Address: text_, Name: ""},
                                    ...result.map(r => ({
                                        Address: r.A,
                                        Name: r.N.replace(/\s+/g, " "),
                                        HasCertificate: r.C,
                                        HasKey: pgpKeyAddresses.includes(r.A?.toLowerCase()),
                                    }))
                                ];

                                setOptions(newOptions);
                            } catch (ex) {
                                setOptions([{Address: "Unable to lookup contacts", Name: "", IsError: true}]);
                            }
                        })();
                    }
                });
        } catch (e) {
            console.log("Error subscribing to subject", e);
        }

        return () => {
            subscription.unsubscribe();
        }
    }, [textSubject, store]);

    function handleChange(event: React.ChangeEvent<{}>, newValue: AutoCompleteOption[]) {
        props.setValue(newValue
            .map(v => isString(v)
                ? {Address: v, Name: ""}
                : v as AutoCompleteDto)
            .filter(v => !v.IsError));
    }

    function renderOption(props: any, option: AutoCompleteDto) {
        return (
            <AutoCompleteOptionSpan {...props} key={option.Name + option.Address}>
                {option.HasKey && <span className="key"><LockIcon/></span>}
                <div>
                    <span className="name">{option.Name}</span>
                    <span style={{color: option.IsError ? "red" : ""}}>{option.Address}</span>
                </div>
            </AutoCompleteOptionSpan>
        );
    }

    function renderTags(tagOptions: AutoCompleteDto[], getTagProps: AutocompleteGetTagProps) {
        return tagOptions.map((option, index) =>
            <AutoCompleteTag key={option.Address} option={option} tagProps={getTagProps({index})}/>
        );
    }

    function renderInput(params: AutocompleteRenderInputParams) {
        return (
            <TextField
                {...params}
                label={props.label}
                margin="dense"
                fullWidth
                autoFocus={props.autoFocus}
                size="small"
                onChange={(e) => setCurrentText(e.target.value)}
                InputProps={{...params.InputProps, type: 'email'}}
            />
        )
    }

    return (
        <Autocomplete
            className={"auto-complete"}
            freeSolo
            multiple
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onChange={handleChange}
            options={options}
            filterOptions={(options) => options}
            value={props.value}
            getOptionLabel={getAutoCompleteLabel}
            PaperComponent={(params: any) => (<Paper {...params} elevation={8}/>)}

            renderTags={renderTags}

            renderOption={renderOption}

            renderInput={renderInput}
        />
    );
};

export default RenderEmailAutoComplete;
