import {CalendarState} from '../../state/CalendarState';
import {CalendarViewData, DummyCalendarViewData} from './CalendarViewData';
import {Calendar} from '../../domain/CalendarDto';
import {DateTime, Duration, Info, Interval, Zone} from 'luxon';
import {CalendarRowData} from './CalendarRowData';
import {CalendarDayData} from './CalendarDayData';
import {Recurrence} from '../../domain/RecurrenceDto';
import {RecurrenceTypeEnum} from '../../domain/RecurrenceTypeEnum';
import {CalendarEntryData, NonNullInterval} from './CalendarEntryData';
import {
    DayOfWeekFlagValues,
    DaysOfWeekFlags,
    EOMFlag,
    FridayFlag,
    MondayFlag,
    SaturdayFlag,
    SundayFlag,
    ThursdayFlag,
    TuesdayFlag,
    WednesdayFlag
} from '../../domain/DayOfWeekName';
import {getDayOfWeekFromDateTime} from "../../util/DateHelper";

const dummyDateTime = DateTime.local();

const MONDAY = 1;

export function calculateCalendarView(state: CalendarState, calendars: Calendar[]): CalendarViewData {
    if (state.mode === "none") {
        return DummyCalendarViewData;
    }

    const perfStart = DateTime.utc();

    const [startDate, endDate] = calculateDateRange(state);

    const interval = Interval.fromDateTimes(startDate, endDate.plus({days: 1})) as NonNullInterval;
    const rows: CalendarRowData[] = [];

    // TODO: refactor into view mode specific logic...
    const entries = findCalendarEntriesInRange(calendars, interval);

    const today = DateTime.utc();
    console.debug("Entries: ", entries);

    let currentDate: DateTime = startDate;
    for (let i = 0; i < interval.length("weeks"); i++) {
        const nextWeek = currentDate.plus({days: 7});

        const startInLocal = currentDate;
        const endInLocal = nextWeek;

        const filteredEntries = entries.filter(e => e.intervalLocal.overlaps(Interval.fromDateTimes(startInLocal, endInLocal)));

        const row: CalendarRowData = {
            entries: layoutCalendarEntries(startInLocal, filteredEntries),
            days: calculateWeekDays(currentDate, today),
        };

        rows.push(row);

        currentDate = nextWeek;
    }

    // TODO: make this depend on the view...
    const headers = Info.weekdays();
    const shortHeaders = Info.weekdays("short");

    console.log("Time taken: ", DateTime.utc().diff(perfStart).milliseconds, "ms");

    return {headers, shortHeaders, rows};
}

export function layoutCalendarEntries(layoutStartDate: DateTime, entries: CalendarEntryData[]): CalendarEntryData[] {

    const clonedEntries = entries.map(entry => {
        const diffStart = Math.max(entry.intervalUtc.start.diff(layoutStartDate, "days").days, 0);
        const diffEnd = entry.intervalUtc.end.minus(1).diff(layoutStartDate, "days").days;

        const colStart = Math.max(Math.floor(diffStart), 0);

        const colEnd = Math.min(colStart + Math.floor(diffEnd - diffStart), 6);

        const colSpan = 1 + colEnd - colStart;

        const newEntry: CalendarEntryData = {
            ...entry,
            colStart,
            colSpan,
        };
        return newEntry;
    });

    // TODO: this algorithm can be improved quite a bit!
    // This is trying to ensure that no entries overlap with one another
    const rowEntries: boolean[][] = [[], [], [], [], [], [], []];
    for (const entry of clonedEntries.sort(compareEntries)) {
        let bestRow = 0;
        for (let col = entry.colStart; col < entry.colStart + entry.colSpan; col++) {
            const rowPopulation = rowEntries[col] || [];
            if (rowPopulation[bestRow]) {
                col = entry.colStart - 1;
                bestRow++;
            }
        }
        for (let col = entry.colStart; col < entry.colStart + entry.colSpan; col++) {
            const rowEntry = rowEntries[col] || [];
            rowEntry[bestRow] = true;
        }
        entry.row = bestRow;
    }
    return clonedEntries;
}

function compareEntries(a: CalendarEntryData, b: CalendarEntryData): number {
    const diff = b.colSpan - a.colSpan;
    if (diff === 0) {
        return a.colStart - b.colStart;
    }
    return diff;
}

export function calculateDateRange(state: CalendarState): [DateTime, DateTime] {
    switch (state.mode) {
        case "month":
            return calculateMonthViewDateRange(state.year, state.month);
        case "week":
            return calculateWeekViewDateRange(state.year, state.week);
    }
    console.error("View mode not supported: ", state.mode);
    return calculateMonthViewDateRange(state.year, state.month);
}

export function calculateMonthViewDateRange(year: number, month: number): [DateTime, DateTime] {
    // This method assumes Monday is the start of the week

    const monthStartDate = DateTime.local(year, month, 1);
    const startDate = monthStartDate.minus({days: monthStartDate.weekday - 1});

    const monthEndDate = monthStartDate.set({day: monthStartDate.daysInMonth});
    const endDate = monthEndDate.plus({days: 7 - monthEndDate.weekday});

    return [startDate, endDate];
}

export function calculateWeekViewDateRange(year: number, week: number): [DateTime, DateTime] {
    // This method assumes Monday is the start of the week

    const yearStartDate = DateTime.utc(year);
    const weekStartDate = yearStartDate.plus({weeks: week});

    const startDate = weekStartDate.minus({days: weekStartDate.weekday - 1});

    const endDate = startDate.plus({days: 6});

    return [startDate, endDate];
}

export function calculateWeekDays(startDate: DateTime, today: DateTime): CalendarDayData[] {
    let currentDate = startDate;
    const days: CalendarDayData[] = [];
    for (let i = 0; i < 7; i++) {
        days.push({date: currentDate, isWeekend: isWeekend(currentDate), isToday: currentDate.hasSame(today, "day")});
        currentDate = currentDate.plus({days: 1});
    }
    return days;
}

export function isWeekend(date: DateTime): boolean {
    return date.weekday >= 6;
}

export function findCalendarEntriesInRange(calendars: Calendar[], {start, end}: NonNullInterval) {

    const potentials = calendars
        .filter(cal => cal.StartDateTime && cal.StartDateTime <= end);

    const singleInstances = potentials
        .filter(cal => cal.StartDateTime && (!cal.Recurrences || cal.Recurrences.length === 0) &&
            ((!cal.EndDateTime && cal.StartDateTime >= start) ||
                (cal.EndDateTime && cal.EndDateTime >= start)));

    const results: CalendarEntryData[] = [
        ...singleInstances.map(calendar => ({
            calendar,
            recurrence: null,
            instanceId: 0,
            intervalLocal: Interval.fromDateTimes((calendar.StartDateTime ?? dummyDateTime).toLocal(), (calendar.EndDateTime ?? dummyDateTime).toLocal()) as NonNullInterval,
            intervalUtc: Interval.fromDateTimes((calendar.StartDateTime ?? dummyDateTime).toUTC(), (calendar.EndDateTime ?? dummyDateTime).toUTC()) as NonNullInterval,
            colStart: 0,
            colSpan: 0,
            row: 0,
        })),
    ];

    for (const potential of potentials) {
        for (const recurrence of potential.Recurrences) {
            calculateCalendarRecurrencesInDateRange(potential, recurrence, start, end, results);
        }
    }

    return results;
}

export function calculateCalendarRecurrencesInDateRange(calendar: Calendar, recurrence: Recurrence, start: DateTime, end: DateTime, results: CalendarEntryData[]): CalendarEntryData[] {

    if (recurrence.Until && recurrence.Until < start) return results;

    const calStart = calendar.StartDateTime;
    const calEnd = calendar.EndDateTime;
    if (!calStart || !calEnd) return results;

    switch (recurrence.Type) {
        case RecurrenceTypeEnum.Yearly:
            const tempYear = DateTime.utc(start.year, recurrence.MonthOfYear, recurrence.DayOfMonth);
            if (tempYear < start || tempYear > end) {
                // This recurrence can never be within the bounds of the view
                return results;
            }
            break;
    }

    for (const instance of calculateCalendarRecurrenceInstances(calendar, recurrence, start)) {

        if (instance.intervalUtc.start < end && instance.intervalUtc.end > start) {
            results.push(instance);
        }
        if (instance.intervalUtc.start > end) {
            break;
        }
    }

    return results;
}

export function calculateCalendarRecurrenceInstances(calendar: Calendar, recurrence: Recurrence, viewStartDate: DateTime) {
    const startDateTime = calendar.StartDateTime;
    if (!startDateTime) {
        return [];
    }

    // TODO: This algorithm needs improving as it's not very fast atm!
    // E.g. for yearly, we can just take the year number as the instanceId
    const eventDuration = !calendar.EndDateTime
        ? Duration.fromObject({hours: 24})
        : calendar.EndDateTime.diff(startDateTime);

    const interval = recurrence.Interval;
    const dayOfMonth = recurrence.DayOfMonth;
    const weekOfMonth = recurrence.WeekOfMonth;
    const monthOfYear = recurrence.MonthOfYear;

    const daysOfWeek = recurrence.DaysOfWeek ?? getDayOfWeekFromDateTime(startDateTime);

    const timezone = calendar.Timezone;

    switch (recurrence.Type) {
        case RecurrenceTypeEnum.Daily:
            return calculateRecurrences(calendar, recurrence, eventDuration, viewStartDate, timezone,
                date => date.plus({days: interval}));

        case RecurrenceTypeEnum.Weekly:
            return calculateRecurrences(calendar, recurrence, eventDuration, viewStartDate, timezone,
                date => calculateNextWeeklyRecurrence(date, daysOfWeek, interval));

        case RecurrenceTypeEnum.Monthly:
            return calculateRecurrences(calendar, recurrence, eventDuration, viewStartDate, timezone,
                date => calculateNextMonthlyRecurrence(date, dayOfMonth, interval));

        case RecurrenceTypeEnum.MonthlyOnDay:
            return calculateRecurrences(calendar, recurrence, eventDuration, viewStartDate, timezone,
                date => calculateNextMonthlyOnDayRecurrence(date, weekOfMonth, daysOfWeek, interval));

        case RecurrenceTypeEnum.Yearly:
            // var timezone = pair_.Calendar.Timezone != null ? TimezoneConverter.FromActiveSync(pair_.Calendar.Timezone) : TimeZoneInfo.Local;
            return calculateRecurrences(calendar, recurrence, eventDuration, viewStartDate, timezone,
                date => calculateNextYearlyRecurrence(startDateTime, date, dayOfMonth, monthOfYear, interval));

        case RecurrenceTypeEnum.YearlyOnDay:
            return calculateRecurrences(calendar, recurrence, eventDuration, viewStartDate, timezone,
                date => calculateNextYearlyOnDayRecurrence(date, weekOfMonth, monthOfYear, daysOfWeek, interval));

    }

    console.warn("Unsupported recurrence type: " + recurrence.Type);
    return [];
}

function* calculateRecurrences(calendar: Calendar, recurrence: Recurrence, eventDuration: Duration, viewStartDate: DateTime, eventTimezone: Zone, calcNextDate: (date: DateTime) => DateTime): Generator<CalendarEntryData> {
    if (!calendar.StartDateTime) return;

    let currentTime = calendar.StartDateTime;

    const recurrenceType = recurrence.Type;

    const daysOfWeek = recurrence.DaysOfWeek ?? getDayOfWeekFromDateTime(calendar.StartDateTime);
    if (!recurrence.DaysOfWeek) {
        recurrence.DaysOfWeek = daysOfWeek;
    }

    switch (recurrenceType) {
        case RecurrenceTypeEnum.Weekly:
            if (currentTime < viewStartDate) {
                // Optimisation so we only need to iterate over the visible entries - find the nearest week
                currentTime = currentTime.set({year: viewStartDate.year, month: viewStartDate.month, day: 1});
                currentTime = currentTime.set({weekday: getDayOfWeekFromFlags(daysOfWeek)});
            }
            break;
        case RecurrenceTypeEnum.Monthly:
            if (currentTime < viewStartDate) {
                // Optimisation so we only need to iterate over the visible entries
                currentTime = currentTime.set({year: viewStartDate.year, month: viewStartDate.month, day: recurrence.DayOfMonth});
                if (currentTime.month !== recurrence.DayOfMonth) {
                    currentTime = currentTime.minus({days: currentTime.day});
                }
            }
            break;
        // case RecurrenceTypeEnum.Yearly:
        //     if (currentUtcTime < viewStartDate) {
        //         // Optimisation so we only need to iterate over the visible entries
        //         currentUtcTime = currentUtcTime.set({year: viewStartDate.year, month: recurrence.MonthOfYear, day: recurrence.DayOfMonth});
        //     }
        //     break;
        case RecurrenceTypeEnum.MonthlyOnDay:
            currentTime = currentTime.plus({months: (-1 * (recurrence.Interval ?? 1))});
            currentTime = calcNextDate(currentTime);
            break;
        case RecurrenceTypeEnum.YearlyOnDay:
            currentTime = currentTime.plus({years: (-1 * (recurrence.Interval ?? 1))});
            currentTime = calcNextDate(currentTime);
            break;
    }

    let maxIterations: number = 0;
    let instanceId: number = 0;
    const maxOccurrences: number = recurrence.Occurrences;
    const until: DateTime = recurrence.Until ?? DateTime.local(3000);
    do {
        if (recurrenceType !== RecurrenceTypeEnum.Weekly || isDayOfWeek(currentTime, daysOfWeek)) {
            // TODO: at the moment we're just using the event timezone since it will always be GMT, but we should convert to the local zone here
            // Convert from event time to UTC
            const actualUtc = currentTime.toUTC();

            const endLocal = currentTime.plus(eventDuration);
            const adjustedEventDuration = endLocal.offset === currentTime.offset
                ? eventDuration
                : eventDuration.plus({minutes: currentTime.offset - endLocal.offset});

            const entry: CalendarEntryData = {
                calendar,
                recurrence,
                instanceId: instanceId++,
                intervalLocal: Interval.fromDateTimes(currentTime, currentTime.plus(adjustedEventDuration)) as NonNullInterval,
                intervalUtc: Interval.fromDateTimes(actualUtc, actualUtc.plus(adjustedEventDuration)) as NonNullInterval,
                colStart: 0,
                colSpan: 0,
                row: 0,
            };
            yield entry;
        }

        currentTime = calcNextDate(currentTime);
    } while (instanceId < maxOccurrences && currentTime <= until && ++maxIterations < 100000);

    if (maxIterations >= 100000) {
        console.warn("Exceeded max iterations: ", maxIterations);
    }
}

function calculateNextYearlyOnDayRecurrence(currentDate: DateTime, weekOfMonth: number, monthOfYear: number, dayOfWeek: DaysOfWeekFlags, interval: number): DateTime {
    let nextYear = currentDate.plus({years: interval});
    if (monthOfYear !== nextYear.month) {
        nextYear = nextYear.plus({months: nextYear.month - monthOfYear});
    }

    return calculateWeekAndDayInMonth(weekOfMonth, dayOfWeek, nextYear);
}

function calculateNextMonthlyOnDayRecurrence(currentDate: DateTime, weekOfMonth: number, daysOfWeek: DaysOfWeekFlags, interval: number): DateTime {
    const nextMonth = currentDate.plus({months: interval});

    return calculateWeekAndDayInMonth(weekOfMonth, daysOfWeek, nextMonth);
}

function calculateWeekAndDayInMonth(weekOfMonth: number, daysOfWeek: DaysOfWeekFlags, startDate: DateTime): DateTime {
    if (daysOfWeek === EOMFlag) {
        return startDate.set({day: startDate.daysInMonth});
    }

    let nextDate = startDate.set({day: 1});
    const targetMonth = nextDate.month;
    while (!isDayOfWeek(nextDate, daysOfWeek)) {
        nextDate = nextDate.plus({days: 1});
    }
    nextDate = nextDate.plus({weeks: (weekOfMonth - 1)});
    if (targetMonth !== nextDate.month) {
        nextDate = nextDate.minus({weeks: 1});
    }
    return nextDate;
}

function calculateNextMonthlyRecurrence(currentDate: DateTime, dayOfMonth: number, interval: number): DateTime {
    let nextDate = currentDate.plus({months: interval});
    if (nextDate.day !== dayOfMonth) {
        if (!isLastDayInMonth(nextDate)) {
            nextDate = nextDate.set({day: dayOfMonth});
        }
    }
    return nextDate;
}

function isLastDayInMonth(dateTime: DateTime): boolean {
    return dateTime.day === dateTime.daysInMonth;
}

function calculateNextWeeklyRecurrence(currentDate: DateTime, daysOfWeek: DaysOfWeekFlags, interval: number): DateTime {
    let nextDate: DateTime = currentDate;
    let nextWeek: DateTime = currentDate.plus({days: 7 * interval});
    do {
        nextDate = nextDate.plus({days: 1});

        if (nextDate.weekday === MONDAY) {
            nextDate = nextDate.plus({days: 7 * (interval - 1)});
        }
        if (isDayOfWeek(nextDate, daysOfWeek)) {
            break;
        }
    } while (nextDate < nextWeek);
    return nextDate;
}

function calculateNextYearlyRecurrence(originalDateTime: DateTime, currentDateUtc: DateTime, dayOfMonth: number, monthOfYear: number, interval: number): DateTime {

    const year: number = currentDateUtc.year + interval;

    const daysInMonth: number = DateTime.utc(year, monthOfYear).daysInMonth ?? 30;
    const dayToUse: number = Math.min(dayOfMonth, daysInMonth);

    return currentDateUtc.set({year, month: monthOfYear, day: dayToUse})
}

export function isDayOfWeek(date: DateTime, dayOfWeek: DaysOfWeekFlags): boolean {
    switch (date.weekday) {
        case 1:
            return (dayOfWeek & MondayFlag) === MondayFlag;
        case 2:
            return (dayOfWeek & TuesdayFlag) === TuesdayFlag;
        case 3:
            return (dayOfWeek & WednesdayFlag) === WednesdayFlag;
        case 4:
            return (dayOfWeek & ThursdayFlag) === ThursdayFlag;
        case 5:
            return (dayOfWeek & FridayFlag) === FridayFlag;
        case 6:
            return (dayOfWeek & SaturdayFlag) === SaturdayFlag;
        case 7:
            return (dayOfWeek & SundayFlag) === SundayFlag;
    }
    return false;
}

export function validateCalendar(calendar: Calendar): string | null {
    if (!calendar.StartDateTime) {
        return "You must specify a start date/time";
    }
    if (!calendar.EndDateTime) {
        return "You must specify an end date/time";
    }
    if (calendar.EndDateTime.diff(calendar.StartDateTime).milliseconds < 0) {
        return "The start date/time must be before then end date/time";
    }
    if (!calendar.Subject?.trim()) {
        return "You must provide a title for the event";
    }
    return null;
}

export function getDayOfWeekFromFlags(daysOfWeek: DaysOfWeekFlags | null): number {
    if (!daysOfWeek) return 1;

    let i = 1;
    for (const flag of DayOfWeekFlagValues) {
        if ((daysOfWeek & flag) === flag) {
            return i;
        }
        i++;
    }
    return 1;
}
