import {AppDispatch} from "../../AppStore";
import {SystemActions} from "../../actions/SystemActions";
import {performMail3RestCall} from "../../util/HttpHelper";
import {LoginAuditDto} from "../../domain/LoginAuditDto";
import {UsersActions} from "../../redux/UsersSlice";

interface LoginAuditResponseDto {
    Logins: LoginAuditDto[];
}

export class LoginAuditService {
    static async updateLoginAudits(dispatch: AppDispatch) {
        try {
            const response = await performMail3RestCall<LoginAuditResponseDto>("LoginAudit/List", {});
            response.Logins.sort((a, b) => b.LastLoginTime - a.LastLoginTime);
            dispatch(UsersActions.setLoginAudits(response.Logins));
        } catch (e: any) {
            dispatch(SystemActions.showFormattedError("Unable to get your login audits", e));
        }
    }

    static async deleteLoginAudits(loginAuditIds: number[], dispatch: AppDispatch) {
        try {
            await performMail3RestCall<LoginAuditResponseDto>("LoginAudit/Delete", {Ids: loginAuditIds});

            await LoginAuditService.updateLoginAudits(dispatch);
        } catch (e: any) {
            dispatch(SystemActions.showFormattedError("Unable to remove the login audit record", e));
        }
    }
}
