import React, {useEffect, useState} from "react";

import {AppState} from "../../AppState";
import {shallowEqual, useSelector} from "react-redux";
import {Centre} from "../Centre";

import "./ItemView.scss";
import {LoadingIndicator} from "../common/LoadingIndicator";
import {AccountItemView} from '../accounts/AccountItemView';
import {
    isAccountContent,
    isCalendarEntryContent,
    isContactContent,
    isDummyContent,
    isEmailAnalysisContent,
    isEmailContent,
    isEmailRuleContent,
    isEmailSourceContent,
    isLoginRequiredContent,
    isPgpKeyContent,
    isUnknownPgpKeyContent
} from '../../domain/ItemContent';
import AnalyseEmailView from '../analysis/AnalyseEmailView';
import EmailSourceView from '../source/EmailSourceView';
import {ContactContentView} from "../contacts/ContactContentView";
import {CalendarContentView} from "../calendar/CalendarContentView";
import {PgpKeyItemView} from "../pgpkeys/PgpKeyItemView";
import {UnknownPgpKeyItemView} from "../pgpkeys/UnknownPgpKeyItemView";
import {RuleItemView} from "../rules/RuleItemView";
import LoginView from "../login/LoginView";
import {EmailThreadView} from "../email/EmailThreadView";

const ItemView: React.FC = () => {

    const [loadingTimedOut, setLoadingTimedOut] = useState<boolean>(false);

    const itemContent = useSelector(({itemState}: AppState) => itemState.itemContent, shallowEqual);
    const isLoadingContent = isDummyContent(itemContent) && itemContent.dummyType === "loading";//useSelector(({itemState}: AppState) => itemState.isLoadingContent);

    useEffect(
        () => {
            setLoadingTimedOut(false);

            // TODO: Find a better way of doing this since this seems to cause the email to render twice 
            const handle = setTimeout(() => setLoadingTimedOut(true), 500);

            return () => clearTimeout(handle);
        }
        , [isLoadingContent]
    );

    if (isLoadingContent && loadingTimedOut && !isLoginRequiredContent(itemContent)) {
        return ((<div><Centre><LoadingIndicator/></Centre></div>));
    }

    if (isDummyContent(itemContent)) {
        if (itemContent.dummyType === "none") {
            return (<Centre>No item selected</Centre>);
        }
        if (itemContent.dummyType === "loading") {
            return (<Centre/>);
        }
        return <div>Unsupported dummy type {itemContent.dummyType}</div>;
    }

    if (isEmailAnalysisContent(itemContent)) {
        return <AnalyseEmailView/>;
    }
    if (isEmailSourceContent(itemContent)) {
        return <EmailSourceView/>;
    }
    if (isEmailContent(itemContent)) {
        return <EmailThreadView itemContent={itemContent}/>;
    }
    if (isAccountContent(itemContent)) {
        return <AccountItemView account={itemContent}/>;
    }
    if (isContactContent(itemContent)) {
        return <ContactContentView contact={itemContent}/>;
    }
    if (isCalendarEntryContent(itemContent)) {
        return <CalendarContentView calendarEntry={itemContent}/>;
    }
    if (isPgpKeyContent(itemContent)) {
        return <PgpKeyItemView pgpKey={itemContent}/>;
    }
    if (isUnknownPgpKeyContent(itemContent)) {
        return <UnknownPgpKeyItemView pgpKey={itemContent}/>;
    }
    if (isEmailRuleContent(itemContent)) {
        return <RuleItemView rule={itemContent}/>;
    }
    if (isLoginRequiredContent(itemContent)) {
        return <LoginView/>;
    }
    return <Centre><>Unsupported item type: {typeof itemContent}</>
    </Centre>;
}

export default ItemView;
