import {performRawRestCall} from "../util/HttpHelper";
import {DB} from "../db/DbManager";
import {ThemePref} from "../theme/Themes";
import {
    AllowedThemes,
    CHANGE_CONNECTION_STATE,
    CHANGE_THEME,
    ChangeConnectionStateAction,
    ChangeThemeAction,
    CONNECTION_ERROR,
    ConnectionErrorAction,
    ConnectionStatus,
    HIDE_ERROR,
    HIDE_NOTIFICATION,
    HIDE_NOTIFICATION_MESSAGE,
    HideErrorAction,
    HideNotificationAction,
    HideNotificationMessageAction,
    LOGIN_ASYNC,
    LoginAsyncAction,
    NOTIFICATION_PERMISSION,
    NotificationPermissionAction,
    PERFORM_HANDSHAKE,
    PerformHandshakeAction,
    RESIZE_FOLDER_TREE,
    RESIZE_WINDOW,
    ResizeFolderTreeAction,
    ResizeWindowAction,
    SET_APP,
    SET_NOTIFICATION_MESSAGES,
    SetAppAction,
    SetNotificationMessagesAction,
    SHOW_ERROR,
    SHOW_FOLDER_MENU,
    SHOW_NOTIFICATION,
    SHOW_NOTIFICATION_MESSAGES,
    SHOW_TOOLBAR_MENU,
    SHOW_WARNING,
    ShowErrorAction,
    ShowFolderMenuAction,
    ShowNotificationAction,
    ShowNotificationMessagesAction,
    ShowToolbarMenuAction,
    ShowWarningAction,
} from "./SystemActionTypes";
import {FolderTreeSizePref, HandshakePref} from "../util/Preferences";
import HandshakeDto from "../services/messages/HandshakeDto";
import {formatError} from "../util/Formatters";
import {NotificationMessage} from "../domain/NotificationMessage";
import {LocationAppType} from "../apps/AppConfig";
import {AppDispatch} from "../AppStore";
import {ItemActions} from "./ItemActions";
import {androidApp} from "../util/DeviceUtil";
import {clearUsernameCookie} from "../util/CommonCookies";

export interface Notifier {
    showNotification?: (message: any) => void;
    showWarning?: (message: any) => void;
    showError?: (message: any) => void;
}

export class SystemActions {
    static setApp(newApp: LocationAppType): SetAppAction {
        return {type: SET_APP, newApp};
    }

    static resizeWindow(newWidth: number): ResizeWindowAction {
        return {type: RESIZE_WINDOW, newWidth};
    }

    static resizeFolderTree(newWidth: number): ResizeFolderTreeAction {
        FolderTreeSizePref.set(newWidth);
        return {type: RESIZE_FOLDER_TREE, newWidth};
    }

    static changeTheme(newTheme: AllowedThemes): ChangeThemeAction {
        ThemePref.set(newTheme);
        return {type: CHANGE_THEME, newTheme};
    }

    static setNotificationMessages(messages: NotificationMessage[]): SetNotificationMessagesAction {
        return {type: SET_NOTIFICATION_MESSAGES, messages};
    }

    static hideNotificationMessage(id: string): HideNotificationMessageAction {
        return {type: HIDE_NOTIFICATION_MESSAGE, id};
    }

    static showNotificationMessages(): ShowNotificationMessagesAction {
        return {type: SHOW_NOTIFICATION_MESSAGES};
    }

    static changeConnectionState(status: ConnectionStatus): ChangeConnectionStateAction {
        return {type: CHANGE_CONNECTION_STATE, status};
    }

    static connectionError(error: any): ConnectionErrorAction {
        return {type: CONNECTION_ERROR, error};
    }

    static logout(dispatch: AppDispatch): void {
        dispatch({type: LOGIN_ASYNC, username: ""});
        dispatch(ItemActions.showItemContent({loginRequired: true}));
    }

    static async performFullLogout(): Promise<void> {
        try {
            clearUsernameCookie();
            await performRawRestCall("/api/logout", {});
            window.location.reload();
        } catch (e) {
            console.log(e);
        }
    }

    private static loginBegin(): LoginAsyncAction {
        return {type: LOGIN_ASYNC};
    }

    private static loginSuccess(username: string): LoginAsyncAction {
        console.log("Logged in successfully");
        DB.init(username)
            .catch(e => console.error("Error initialising DB: ", e));
        androidApp.handleLogin();
        return {type: LOGIN_ASYNC, username};
    }

    private static loginError(error: Error): LoginAsyncAction {
        return {type: LOGIN_ASYNC, error};
    }

    static showFormattedError(message: string, error: any): ShowErrorAction {
        return {type: SHOW_ERROR, message: formatError(message, error)};
    }

    static showError(message: any): ShowErrorAction {
        return {type: SHOW_ERROR, message};
    }

    static hideError(): HideErrorAction {
        return {type: HIDE_ERROR};
    }

    static showFormattedWarning(message: string, error: any): ShowWarningAction {
        return {type: SHOW_WARNING, message: formatError(message, error)};
    }

    static showWarning(message: any): ShowWarningAction {
        return {type: SHOW_WARNING, message};
    }

    static hideWarning(): ShowWarningAction {
        return {type: SHOW_WARNING, message: undefined};
    }

    static showNotification(message: any): ShowNotificationAction {
        return {type: SHOW_NOTIFICATION, message};
    }

    static hideNotification(): HideNotificationAction {
        return {type: HIDE_NOTIFICATION};
    }

    static showFolderMenu(visible: boolean): ShowFolderMenuAction {
        return {type: SHOW_FOLDER_MENU, visible};
    }

    static showToolbarMenu(visible: boolean): ShowToolbarMenuAction {
        return {type: SHOW_TOOLBAR_MENU, visible};
    }

    static async performLogin(dispatch: AppDispatch, username: string, password: string) {
        const request = {username, password};
        try {
            dispatch(SystemActions.loginBegin());
            await performRawRestCall("/api/login", request);
            dispatch(ItemActions.showItemContent({dummyType: "none"}));
            dispatch(SystemActions.loginSuccess(username));
        } catch (e) {
            console.log("Login Error", e);
            dispatch(SystemActions.loginError(e as Error));
        }
    }

    static performHandshake(data: HandshakeDto): PerformHandshakeAction {
        HandshakePref.set(data);

        return {type: PERFORM_HANDSHAKE, data};
    }

    static updateNotificationPermission(): NotificationPermissionAction {
        return {
            type: NOTIFICATION_PERMISSION,
            newPermission: Notification.permission,
        };
    }
}

