import "./AnalyseEmailView.scss";
import {AppState} from '../../AppState';
import {EmailAnalysisDto} from '../../services/messages/responses/EmailAnalysisDto';
import {connect, ConnectedProps} from 'react-redux';
import React, {FC, Fragment, PropsWithChildren} from 'react';
import {SpamFilterResultDto} from '../../domain/SpamFilterResultDto';
import {HideContentButton} from '../common/HideContentButton';
import {DomainReputationDto} from '../../domain/DomainReputationDto';
import {MessageIdLinkDto} from '../../domain/MessageIdLinkDto';
import {EmailRawHeaderDto} from '../../domain/EmailRawHeaderDto';
import {ContentLocation} from "../../locations/ContentLocation";

interface AnalysisSectionProps {
    title: string;
}

function formatPositiveAndNegativeNumber(value: number) {
    if (value > 0) {
        return "+" + value;
    }
    return value.toString();
}

const AnalysisSection: FC<AnalysisSectionProps & PropsWithChildren> = ({children, title}) => {
    return (
        <div className="analysis-section">
            <div className="title">{title}</div>
            {children}
        </div>
    );
};

const RenderAnalyseEmailView: FC<AnalyseEmailViewProps> = ({analysis}) => {
    function renderSpamFilterResult(dto: SpamFilterResultDto, index: number) {
        const weightType = dto.Weight === 0 ? "not-applicable" : (dto.Weight > 0 ? "spam" : "ham");
        return (
            <div className="spam-filter-result" key={index}>
                <span className={"weight " + weightType}>{dto.Weight === 0 ? "N/A" : formatPositiveAndNegativeNumber(dto.Weight)}</span>

                <span className="type">{dto.Type}</span>

                {dto.SubType !== "None" ? <span className="sub-type">{dto.SubType}</span> : null}

                {dto.Cause ? <span className="cause">{dto.Cause}</span> : null}

                {dto.Description ? <span className="description">{dto.Description}</span> : null}
            </div>
        );
    }

    function renderReputation(dto: DomainReputationDto, index: number) {
        return (
            <div className="domain-reputation" key={index}>
                <div className="name">{dto.Domain}</div>
                <div className="field"><span>Counts:</span><span className="ham">{dto.HamCount}</span>/<span className="spam">{dto.SpamCount}</span></div>
                <div className="field"><span>Reputation:</span><span className="reputation">{dto.Reputation}</span></div>
            </div>
        );
    }

    function renderEmailIdLink(id: number, index: number) {
        if (id === analysis.EmailId) {
            return (
                <Fragment key={index}>
                    {index > 0 ? ", " : ""}
                    {id}
                </Fragment>
            );
        }
        return (
            <Fragment key={index}>
                {index > 0 ? ", " : ""}
                <button
                    className="link"
                    onClick={() => ContentLocation.showEmailAnalysis(id)}>
                    {id}
                </button>
            </Fragment>
        );
    }

    function renderMessageLink(dto: MessageIdLinkDto, index: number) {
        return (
            <div className="message-links" key={index}>
                <span className="message-id">{dto.MessageId}</span>
                <span className="type">({dto.LinkType})</span>
                <span className="email-ids">[
                    {dto.LinkedEmailIds.map(renderEmailIdLink)}
                    ]</span>
            </div>
        );
    }

    function renderEmailHeader(header: EmailRawHeaderDto, index: number) {
        return (
            <div className="header" key={index}>
                <span className="name">{header.Name}</span>
                {header.Lines.map((line, index) => (<span key={index} className={index === 0 ? "" : "hanging"}>{line}<br/></span>))}
            </div>
        );
    }

    function renderHeaderData(name: string, value: string | number) {
        return (
            <div className="header-data">
                <span className="name">{name}</span>
                <span className="value">{value}</span>
            </div>
        );
    }

    return (
        <div className="email-analysis">
            <HideContentButton/>

            <AnalysisSection title="Spam Filter Results">
                {analysis.SpamFilterResults.map(renderSpamFilterResult)}
            </AnalysisSection>

            <AnalysisSection title="Reputation Information">
                {analysis.Reputations.map(renderReputation)}
            </AnalysisSection>

            <AnalysisSection title="Message Links">
                <div><b>Thread Id:</b> {analysis.ThreadId}</div>
                {analysis.Links.map(renderMessageLink)}
            </AnalysisSection>

            <AnalysisSection title="Email Headers">
                {renderHeaderData("Source IP", analysis.SourceIP)}
                {renderHeaderData("Smtp Origin", analysis.SmtpOrigin)}
                {renderHeaderData("Sender", analysis.Sender)}
                {renderHeaderData("Security", analysis.PendingSecureCount === 0 ? "sent securely" : "sent insecurely")}
                {renderHeaderData("Recipient", analysis.AccountId)}

                <div>&nbsp;</div>

                {analysis.Headers.map(renderEmailHeader)}
            </AnalysisSection>
        </div>
    );
};

const mapState = ({itemState}: AppState) => ({
    analysis: itemState.itemContent as EmailAnalysisDto,
});

const connector = connect(mapState);

type AnalyseEmailViewProps = ConnectedProps<typeof connector>;

const AnalyseEmailView = connector(RenderAnalyseEmailView);

export default AnalyseEmailView;
