import {PhysicalAddressDto} from "./PhysicalAddressDto";
import {ClientEntityDto} from "./ClientEntityDto";

export interface ContactDto extends ClientEntityDto {

    ChangeId: number | undefined;
    
    FirstName: string | null;
    MiddleName: string | null;
    LastName: string | null;

    Emails: string[];

    HasPicture: boolean;
    PictureDataUrl?: string;

    Category: string | null;

    HomePhone: string | null;
    Home2Phone: string | null;
    HomeFax: string | null;
    MobilePhone: string | null;
    BusinessPhone: string | null;
    CompanyPhone: string | null;
    Business2Phone: string | null;
    BusinessFax: string | null;
    Pager: string | null;
    RadioPhone: string | null;
    CarPhone: string | null;
    MMS: string | null;

    Addresses: PhysicalAddressDto[];
    IMAddresses: string[];

    Title: string | null;
    NickName: string | null;
    Suffix: string | null;
    Spouse: string | null;
    Children: string | null;
    WebPage: string | null;

    JobTitle: string | null;
    OfficeLocation: string | null;
    Company: string | null;
    Department: string | null;
    ManagerName: string | null;
    AssistantName: string | null;
    AssistantPhone: string | null;

    Notes: string | null;
    Birthday: string | null;
    Anniversary: string | null;

    Alias: string | null;

    CustomerId: string | null;

    GovernmentId: string | null;
}

export const blankContact: ContactDto = {
    Id: -1,
    ClientId: undefined,
    ChangeId: undefined,
    Alias: null,
    Anniversary: null,
    AssistantName: null,
    AssistantPhone: null,
    Birthday: null,
    Business2Phone: null,
    BusinessFax: null,
    BusinessPhone: null,
    Category: null,
    Children: null,
    Company: null,
    CompanyPhone: null,
    CustomerId: null,
    FirstName: null,
    GovernmentId: null,
    HasPicture: false,
    Home2Phone: null,
    HomeFax: null,
    HomePhone: null,
    JobTitle: null,
    LastName: null,
    MiddleName: null,
    MobilePhone: null,
    NickName: null,
    Notes: null,
    Pager: null,
    Spouse: null,
    Suffix: null,
    Title: null,
    WebPage: null,
    Emails: [],
    IMAddresses: [],
    Addresses: [],
    MMS: null,
    CarPhone: null,
    RadioPhone: null,
    ManagerName: null,
    Department: null,
    OfficeLocation: null
};
