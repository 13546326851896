import {FC} from "react";
import {TextField} from "@mui/material";
import {
    ASSIGN_CATEGORY_ACTION,
    AUTO_ARCHIVE_ACTION,
    EmailRuleActionTypes,
    FLAG_EMAIL_ACTION,
    FORWARD_EMAIL_ACTION,
    MARK_AS_READ_ACTION,
    MOVE_TO_FOLDER_ACTION,
    SET_EMAIL_IMPORTANCE_ACTION
} from "../../services/rules/dto/EmailRuleActionDto";
import {SimpleNumberTextField} from "../common/SimpleNumberTextField";
import {SimpleSelect} from "../common/SimpleSelect";
import {useAppSelector} from "../../hooks/ReduxHooks";
import {UIMap} from "../../util/UIMap";
import {ImportanceEnum} from "../../domain/ImportanceEnum";

export type EmailRuleActionChange = Exclude<EmailRuleActionTypes, "Type">;

function uiOption<T extends string | number>(option: T): UIMap<T> {
    return ({title: option.toString(), value: option});
}

const importanceOptions: UIMap<ImportanceEnum>[] = [uiOption("Low"), uiOption("Medium"), uiOption("High")];

export const RuleItemActionView: FC<{
    action: EmailRuleActionTypes,
    onChange: (change: EmailRuleActionChange) => void,
}> = ({
          action,
          onChange,
      }) => {

    const folders = useAppSelector(({folderState}) => folderState.folderList);

    switch (action.Type) {
        case AUTO_ARCHIVE_ACTION:
            return <>
                <span>automatically archive the email after </span>
                <SimpleNumberTextField value={action.AutoArchiveDays}
                                       onChange={newValue => onChange({...action, AutoArchiveDays: newValue || 1})}/>
                <span>&nbsp;days</span>
            </>;

        case ASSIGN_CATEGORY_ACTION:
            return <>
                <span>set the category to </span>
                <TextField fullWidth
                           value={action.Category}
                           onChange={e => onChange({...action, Category: e.target.value})}/>
            </>;

        case FLAG_EMAIL_ACTION:
            return <>
                <span>flag the email</span>
            </>;

        case FORWARD_EMAIL_ACTION:
            return <>
                <span>forward the email to </span>
                <TextField fullWidth
                           type="email"
                           value={action.ForwardingAddress}
                           onChange={e => onChange({...action, ForwardingAddress: e.target.value})}/>
            </>;

        case MARK_AS_READ_ACTION:
            return <>
                <span>mark the email as read</span>
            </>;

        case MOVE_TO_FOLDER_ACTION:
            const folderOptions: UIMap<number>[] = folders.filter(f => !f.hidden).map(f => ({value: f.folderId, title: f.name}));

            return <>
                <span>move the email to </span>
                <SimpleSelect options={folderOptions}
                              value={action.FolderId}
                              onChange={newFolderId => onChange({...action, FolderId: newFolderId})}/>
            </>;

        case SET_EMAIL_IMPORTANCE_ACTION:
            return <>
                <span>set as </span>
                <SimpleSelect options={importanceOptions}
                              value={action.Importance}
                              onChange={newImportance => onChange({...action, Importance: newImportance})}/>
                <span> importance</span>
            </>;
    }
}
