import {ClientEntityDto} from "../services/messages/ClientEntityDto";

export interface NotePageDto extends ClientEntityDto {
    ClientSectionId: string | undefined;

    Name: string;

    ModifiedTime: number;

    HtmlContent: string | undefined;
}

export function isNotePage(test: any): test is NotePageDto {
    return test && (test as NotePageDto).ClientSectionId !== undefined;
}
