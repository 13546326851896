import {DayOfWeekName, DaysOfWeekFlags} from './DayOfWeekName';
import {RecurrenceTypeEnum} from './RecurrenceTypeEnum';
import {CalendarTypeEnum} from './CalendarTypeEnum';
import {DateTime, Zone} from 'luxon';
import {dateTimeFromNullableTicks, ticksFromNullableDateTime} from '../util/DateHelper';

export const MAX_OCCURRENCES = 10000;

interface RecurrenceCommon {
    DaysOfWeek: DaysOfWeekFlags | null;
}

export interface RecurrenceDto extends RecurrenceCommon {
    Type: RecurrenceTypeEnum | null;

    Occurrences: number | null;

    Interval: number | null;

    WeekOfMonth: number | null;

    MonthOfYear: number | null;

    Until: number | null;

    DayOfMonth: number | null;

    CalendarType: CalendarTypeEnum | null;

    IsLeapMonth: boolean | null;

    FirstDayOfWeek: DayOfWeekName | null;
}

export interface Recurrence extends RecurrenceCommon {
    Type: RecurrenceTypeEnum;

    Occurrences: number;

    Interval: number;

    WeekOfMonth: number;

    MonthOfYear: number;

    Until: DateTime | null;

    DayOfMonth: number;

    CalendarType: CalendarTypeEnum | null;

    IsLeapMonth: boolean;

    FirstDayOfWeek: DayOfWeekName | null;
}

export function recurrenceFromDto(dto: RecurrenceDto, zone: Zone): Recurrence {
    return {
        ...dto,
        Type: dto.Type ?? RecurrenceTypeEnum.Monthly,

        Interval: dto.Interval ?? 1,
        DayOfMonth: dto.DayOfMonth ?? 1,
        WeekOfMonth: dto.WeekOfMonth ?? 1,
        MonthOfYear: dto.MonthOfYear ?? 1,
        Occurrences: dto.Occurrences ?? MAX_OCCURRENCES,

        IsLeapMonth: dto.IsLeapMonth ?? false,

        Until: dateTimeFromNullableTicks(dto.Until, zone),
    };
}

export function dtoFromRecurrence(entity: Recurrence, zone: Zone): RecurrenceDto {
    return {
        ...entity,
        
        Occurrences: entity.Occurrences === MAX_OCCURRENCES ? null : entity.Occurrences,

        Until: ticksFromNullableDateTime(entity.Until, zone),
    };
}
