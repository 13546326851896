import "./UserAvatar.scss";

import React, {useState} from "react";
import {Avatar, Badge, Button, Divider, Menu, MenuItem} from "@mui/material";
import {SystemActions} from "../../actions/SystemActions";
import {locationManager} from "../../BootStrap";
import {pluralise} from "../../util/DateHelper";
import {Permission} from "../common/Permission";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import PeopleIcon from '@mui/icons-material/People';
import AccountTreeIcon from "@mui/icons-material/AccountTree";

const UserAvatar: React.FC = () => {

    const dispatch = useAppDispatch();

    const [isMenuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const userName = useAppSelector(({system}) => system.userName);

    const notificationCount = useAppSelector(({system}) => system.notificationMessages.length);

    const theme = useAppSelector(({system}) => system.theme);

    function onShowNotifications() {
        dispatch(SystemActions.showNotificationMessages());
        setMenuOpen(false);
    }

    const title = `Logged in as ${userName}${notificationCount > 0 ? "\r\n" + pluralise(notificationCount, "notification", true) : ""}`;

    return (
        <>
            <Menu open={isMenuOpen}
                  onClose={() => setMenuOpen(false)}
                  anchorEl={anchorEl}
                  BackdropProps={{className: "menu-backdrop"}}
                  className="user-avatar"
                  transitionDuration={100}
            >
                <MenuItem onClick={() => dispatch(SystemActions.changeTheme(theme === "dark" ? "light" : "dark"))}>
                    <InvertColorsIcon/>Switch Theme
                </MenuItem>

                <MenuItem onClick={() => onShowNotifications()} disabled={!notificationCount}>
                    <NotificationsIcon/>{notificationCount ? "Show Notifications" : "No Notifications"}
                </MenuItem>

                <MenuItem onClick={() => locationManager.switchApp("prefs")}><SettingsApplicationsIcon/>Preferences</MenuItem>

                <MenuItem onClick={() => locationManager.switchApp("rules")}><AccountTreeIcon/>Email Rules</MenuItem>

                <MenuItem onClick={() => locationManager.switchApp("pgpkeys")}><VpnKeyIcon/>PGP Keys</MenuItem>

                <MenuItem onClick={() => locationManager.switchApp("login-audit")}><VerifiedUserIcon/>Login Audit</MenuItem>

                <Permission requiredRole="admin">
                    <MenuItem onClick={() => locationManager.switchApp("users")}><PeopleIcon/>User Management</MenuItem>
                </Permission>

                <Divider/>

                <MenuItem onClick={() => SystemActions.performFullLogout()}><ExitToAppIcon/>Logout</MenuItem>
            </Menu>

            <Button className="button-avatar"
                    onClick={event => {
                        setAnchorEl(event.currentTarget);
                        setMenuOpen(true);
                        event.stopPropagation();
                    }}>
                <Badge color="secondary"
                       badgeContent={notificationCount}
                       overlap="circular"
                       anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
                    <Avatar className="user-avatar"
                            title={title}
                    >
                        {userName.toUpperCase()[0]}
                    </Avatar>
                    <span className="mobile-only">Logged in as {userName}</span>
                </Badge>
            </Button>
        </>
    );
};

export default UserAvatar;
