import React from "react";

import "./EmailHeaderView.scss";
import {EmailActions} from '../../actions/EmailActions';
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {formatFromAddressesString} from "../../util/Formatters";
import {OutgoingEmailDto} from "../../services/messages/OutgoingEmailDto";

import {AppState} from "../../AppState";
import {SimpleGrid} from "../common/grid/SimpleGrid";
import {RelativeDateTime} from "../common/RelativeDateTime";
import {ContentLocation} from "../../locations/ContentLocation";

const OutboxGridRow = (item: OutgoingEmailDto) => (
    <>
        <div className="header-address">{formatFromAddressesString(item.Address, true)}</div>

        <div className="header-retry-count">{item.RetryCount}</div>

        <div className="header-next-retry"><RelativeDateTime dateTime={item.NextRetry}/></div>
    </>
);

const OutboxHeaderView = () => {

    console.log("Rendering outbox grid...");

    const dispatch = useAppDispatch();

    const items = useAppSelector(({folderState}) => folderState.outgoingEmails);
    const selectedItemSelector = ({itemState}: AppState) => itemState.selectedOutgoingEmails;

    function onUpdateSelection(newSelection: OutgoingEmailDto[]) {
        dispatch(EmailActions.selectOutgoingEmails(newSelection));
        if (newSelection.length > 0) {
            ContentLocation.showOutboxEmail(newSelection[0].Uid);
        }
    }

    return (
        <div className="content-item-list email-view">
            <SimpleGrid
                items={items}
                selectedItemSelector={selectedItemSelector}
                rowIdExtractor={item => item.Uid}
                onUpdateSelection={onUpdateSelection}
                rowRenderer={OutboxGridRow}
                rowClassRules={item => ({"error": item.RetryCount})}
            >
                <div className="header-address">Address</div>
                <div className="header-retry-count">Retry Count</div>
                <div className="header-next-retry">Next Retry</div>
            </SimpleGrid>
        </div>
    );
};

export default OutboxHeaderView;
