import "./RulesView.scss";

import {ContentViewContainer} from "../ContentView";
import {AppContainer} from "../common/AppContainer";
import React, {useEffect} from "react";
import {RulesListView} from "./RulesListView";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {RulesService} from "../../services/rules/RulesService";
import ItemView from "../item/ItemView";
import {isEmailRuleContent} from "../../domain/ItemContent";

export const RulesView = () => {
    const dispatch = useAppDispatch();

    const hasContent = useAppSelector(({itemState}) => isEmailRuleContent(itemState.itemContent));

    useEffect(() => {
        RulesService.updateRules(dispatch)
            .catch(console.error);
    });

    return (
        <AppContainer>
            <ContentViewContainer hasContent={hasContent}>
                <RulesListView/>
                <ItemView/>
            </ContentViewContainer>
        </AppContainer>
    );
}
