import {SystemStatusViewType} from "../locations/SystemStatusLocation";
import {
    SET_SYSTEM_STATUS_VIEW,
    SetSystemStatusViewAction,
    UPDATE_SYSTEM_STATUS,
    UpdateSystemStatusAction
} from "./SystemStatusActionTypes";
import {SystemStatusResponseDto} from "../services/system/SystemStatusResponseDto";

export class SystemStatusActions {
    static setView(view: SystemStatusViewType): SetSystemStatusViewAction {
        return {type: SET_SYSTEM_STATUS_VIEW, view};
    }

    static updateSystemStatus(newStatus: SystemStatusResponseDto): UpdateSystemStatusAction {
        return {type: UPDATE_SYSTEM_STATUS, newStatus};
    }
}
