import "./CalendarContentView.scss";

import React, {FC, useState} from "react";
import {CalendarEntryData} from "../../services/calendar/CalendarEntryData";
import {HideContentButton} from "../common/HideContentButton";
import {StackPanel} from "../panels/StackPanel";
import {SimpleField} from "../common/SimpleField";
import {DateTime} from "luxon";
import {RecurrenceTypeEnum} from "../../domain/RecurrenceTypeEnum";
import {dateTimeToLocaleString, formatDayOfMonth, formatDaysOfWeek, getMonthName, pluralise} from "../../util/DateHelper";
import {MAX_OCCURRENCES, Recurrence} from "../../domain/RecurrenceDto";
import LoopIcon from '@mui/icons-material/Loop';
import DateRangeIcon from '@mui/icons-material/DateRange';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EmailIcon from '@mui/icons-material/Email';
import ExploreIcon from '@mui/icons-material/Explore';
import PersonIcon from '@mui/icons-material/Person';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {SystemActions} from "../../actions/SystemActions";
import {AppState} from "../../AppState";
import {ContentLocation} from "../../locations/ContentLocation";
import {CalendarEditView} from "./CalendarEditView";
import {EmailActions} from "../../actions/EmailActions";
import {getCalendarIcon} from "./CalendarView";
import {applyEmailContentSafetySettings} from "../../util/SafetyTransformer";
import {useAppSelector} from "../../hooks/ReduxHooks";
import {EditButtons} from "../common/EditButtons";

type DeleteCalendarEntryType = "all" | "occurrence" | "none";

export interface CalendarContentViewProps {
    calendarEntry: CalendarEntryData;
}

function formatReminderMins(reminderMins: number | null) {
    if (reminderMins === null || reminderMins === undefined) {
        return undefined;
    }
    if (reminderMins <= 0) {
        return "At time of event";
    }
    if (reminderMins > 60 * 24) {
        return (reminderMins / 60 / 24) + " days";
    }
    if (reminderMins > 60) {
        return (reminderMins / 60) + " hours";
    }
    return reminderMins + " mins";
}

function formatDateTime(dateTime: DateTime | null, allDay: boolean | null) {
    if (!dateTime) return null;
    if (allDay) {
        // return dateTime?.toFormat("d MMM yyyy");
        return dateTime.toLocaleString();
    }
    return dateTimeToLocaleString(dateTime);
    // return dateTime?.toFormat("d MMM yyyy HH:mm");
}

function formatTimeOnly(dateTime: DateTime | null) {
    return dateTime?.toFormat("HH:mm");
}

export function formatRecurrence(recurrence: Recurrence | null) {
    if (!recurrence) return null;

    switch (recurrence.Type) {
        case RecurrenceTypeEnum.Daily:
            return `Every ${pluralise(recurrence.Interval, "day")}`;

        case RecurrenceTypeEnum.Weekly:
            return `Every ${pluralise(recurrence.Interval, "week")} on ${formatDaysOfWeek(recurrence.DaysOfWeek)}`;

        case RecurrenceTypeEnum.Monthly:
            return `Every ${pluralise(recurrence.Interval, "month")} on ${formatDayOfMonth(recurrence.DayOfMonth)} of the month`;

        case RecurrenceTypeEnum.MonthlyOnDay:
            return `Every ${pluralise(recurrence.Interval, "month")} on the ${formatDayOfMonth(recurrence.WeekOfMonth)} ${formatDaysOfWeek(recurrence.DaysOfWeek)}`;

        case RecurrenceTypeEnum.Yearly:
            return `Every ${pluralise(recurrence.Interval, "year")} on ${formatDayOfMonth(recurrence.DayOfMonth)} ${getMonthName(recurrence.MonthOfYear)}`;

        case RecurrenceTypeEnum.YearlyOnDay:
            return `Every ${pluralise(recurrence.Interval, "year")} on the ${formatDayOfMonth(recurrence.WeekOfMonth)} ${formatDaysOfWeek(recurrence.DaysOfWeek)} of ${getMonthName(recurrence.MonthOfYear)}`;
    }
    return null;
}

function formatRecurrenceOccurence(recurrence: Recurrence | null, instanceId: number) {
    if (recurrence?.Until) {
        return "until " + formatDateTime(recurrence.Until, true);
    }
    if (recurrence?.Occurrences && recurrence.Occurrences < MAX_OCCURRENCES) {
        return `(recurrence ${instanceId} of ${recurrence.Occurrences})`;
    }
    return null;
}

const CalendarContentReadOnlyView: FC<CalendarContentViewProps> = ({calendarEntry}) => {
    const {calendar, intervalLocal} = calendarEntry;

    const {recurrence} = calendarEntry;

    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

    const whitelistedImages = useAppSelector(s => s.preferenceState.imageWhitelist);

    const dispatch = useDispatch();

    const utcNow = DateTime.utc();

    const daysUntilEvent = Math.ceil(
        (intervalLocal.start?.startOf("day") ?? intervalLocal.end?.startOf("day") ?? utcNow.startOf("day"))
            .diffNow("days").days// + 0.999
    );

    function renderDateTimeInfo() {
        const {start, end} = intervalLocal;

        if (intervalLocal.end.diff(start, "days").days <= 1) {
            if (calendar.AllDayEvent) {
                return <><DateRangeIcon/><span>{formatDateTime(start, calendar.AllDayEvent)}</span></>;
            }
            return (
                <>
                    <DateRangeIcon/>
                    <span>{formatDateTime(start, true)}</span>

                    <span className="connection-text">&nbsp;at&nbsp;</span>

                    <span>{formatTimeOnly(start)}</span>
                    <span className="connection-text">-</span>
                    <span>{formatTimeOnly(end)}</span>
                </>
            );
        }

        return (
            <>
                <DateRangeIcon/>
                <span>{formatDateTime(intervalLocal.start, calendar.AllDayEvent)}</span>
                <span className="connection-text">until</span>
                <span>{formatDateTime(intervalLocal.end, calendar.AllDayEvent)}</span>
            </>
        );
    }

    function renderRecurrenceInfo() {
        if (!recurrence) return "";

        const recurrenceString = formatRecurrence(recurrence);
        const recurrenceOccurence = formatRecurrenceOccurence(recurrence, calendarEntry.instanceId);

        return (
            <>
                <div className={recurrenceOccurence ? "date-time-info" : "date-time-info event-section"}>
                    <span className="field">{recurrenceString ? <>
                        <LoopIcon/>{recurrenceString} {recurrenceOccurence}</> : null}</span>
                </div>
                {/*{recurrenceOccurence ?*/}
                {/*    <div className="event-section">*/}
                {/*        <span className="field icon-indent">{recurrenceOccurence}</span>*/}
                {/*    </div> : null}*/}
            </>
        );
    }

    async function onConfirmDelete(type: DeleteCalendarEntryType) {
        setConfirmDeleteOpen(false);
        if (type === "all" || !calendarEntry.recurrence) {
            dispatch(await EmailActions.addRemoteOperation({
                ManageCalendar: {
                    DeleteId: calendar.Id,
                    DeleteClientId: calendar.ClientId
                }
            }));
            dispatch(EmailActions.clearItemContent());
        } else if (type === "occurrence") {
            // TODO:
            dispatch(SystemActions.showWarning("Not implemented yet"));
        }
    }

    function onEditCalendarEntry() {
        ContentLocation.editCalendar(calendar.Uid, calendarEntry.instanceId);
    }

    return (
        <>
            <StackPanel orientation="vertical" className="calendar-content-view">
                <HideContentButton/>

                <div className="event-section subject">
                    {getCalendarIcon(calendar)}
                    {calendar.Subject}
                </div>

                <SimpleField fieldName="" detail={calendar.Category}/>

                <div>
                    {intervalLocal.start.day === utcNow.day
                        ? (intervalLocal.start < utcNow ? <div className="primary">This event was today</div> :
                            <div className="event-today">This event is today</div>)
                        : (daysUntilEvent <= 0
                            ? <div className="error">This event occurs in the past</div>
                            : <div>This event occurs in {pluralise(daysUntilEvent, "day", true)}</div>)}
                </div>

                <div className="event-section date-time-info">{renderDateTimeInfo()}</div>
                <div className="event-section timezone">{calendar.Timezone.name}</div>

                {renderRecurrenceInfo()}

                <SimpleField fieldName="Reminder" detail={formatReminderMins(calendar.ReminderMins)}
                             icon={<NotificationsIcon/>}/>

                <SimpleField fieldName="Location" detail={calendar.Location} icon={<ExploreIcon/>}/>

                <SimpleField fieldName="Organizer" detail={calendar.OrganizerName} icon={<PersonIcon/>}/>
                <SimpleField fieldName="Organizer Email" detail={calendar.OrganizerEmail} icon={<EmailIcon/>}/>

                <SimpleField fieldName="Attendees" detail={calendar.Attendees.map(a => a.Name).join("; ")}/>

                {/* TODO: format in html if it's HTML content */}
                {calendar.Body ? <div>
                    <span className="field-name">Details</span>
                    <span className="field field-preformatted" dangerouslySetInnerHTML={{
                        __html: applyEmailContentSafetySettings(0, calendar.Body, true, {
                            applySafety: true,
                            applyJunkSafety: false,
                            darkTheme: true,
                            mobile: false,
                            containerWidth: 600,    // TODO: for non-mobile, find the width of the container
                            whitelistedImages,
                        }).safeContent
                    }}/>
                </div> : null}

            </StackPanel>

            <EditButtons onSetEditing={onEditCalendarEntry}
                         onDelete={() => setConfirmDeleteOpen(true)}/>

            <Dialog open={confirmDeleteOpen} onClose={() => onConfirmDelete("none")}>
                <DialogTitle id="alert-dialog-title">Delete Calendar Entry</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Are you sure you want to delete this calendar
                        entry?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    {calendarEntry.recurrence ?
                        <Button onClick={() => onConfirmDelete("all")} color="secondary">Delete Series</Button> : null}
                    <Button onClick={() => onConfirmDelete("occurrence")} color="secondary">Delete</Button>
                    <Button onClick={() => onConfirmDelete("none")} color="primary" autoFocus>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export const CalendarContentView: FC<CalendarContentViewProps> = ({calendarEntry}) => {

    const isEditMode = useSelector(({itemState}: AppState) => itemState.itemType === "editcalendar");

    return (
        <div className="calendar-content-container edit-buttons-container">
            {isEditMode
                ? <CalendarEditView calendarEntry={calendarEntry}/>
                : <CalendarContentReadOnlyView calendarEntry={calendarEntry}/>}
        </div>
    );
}
