import {createAppProperties} from "./LocationUtil";
import {AppDispatch} from "../AppStore";
import {AppState} from "../AppState";
import {LocationProperties} from "../util/LocationParser";
import {locationManager} from "../BootStrap";
import {ensurePgpKeysLoaded} from "../util/PGPUtil";
import {isPgpKeyContent} from "../domain/ItemContent";
import {ItemActions} from "../actions/ItemActions";
import {NoItemContent} from "../state/ItemState";

export interface PgpKeysLocationProperties {
    kH?: string;
}

export const PgpKeysLocationPropertyNames = createAppProperties<PgpKeysLocationProperties>("kH");

export class PgpKeysLocation {

    static handleLocationChange(dispatch: AppDispatch, getState: () => AppState, newLocation: LocationProperties): void {

        console.log("PGP key view");

        const {accountsState, itemState} = getState();

        ensurePgpKeysLoaded(dispatch, accountsState)
            .catch(e => console.error("Unable to load all PGP keys", e));

        if (!isPgpKeyContent(itemState.itemContent) || itemState.itemContent.key.getKeyId().toHex() !== newLocation.kH) {
            const keyIdString = newLocation.kH;

            const pgpKey = accountsState.secretKeys.find(k => k.keyHexId === keyIdString)
                || accountsState.pgpKeys.find(k => k.keyHexId === keyIdString)
                || (keyIdString ? {unknownKeyHexId: keyIdString} : NoItemContent);

            dispatch(ItemActions.showItemContent(pgpKey));
        }
    }

    static selectPgpKey(keyIdString: string | undefined) {
        locationManager.updateWindowLocation("pgpkeys", {kH: keyIdString});
    }
}
