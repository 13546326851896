import "./CalendarView.scss";
import React, {CSSProperties, FC, ReactNode} from "react";
import {AppState} from '../../AppState';
import {shallowEqual, useSelector} from 'react-redux';
import classNames from 'classnames';
import {CalendarRowData} from '../../services/calendar/CalendarRowData';
import {CalendarDayData} from '../../services/calendar/CalendarDayData';
import {CalendarEntryData} from '../../services/calendar/CalendarEntryData';
import {Button, ButtonGroup} from '@mui/material';
import {dateTimeToLocaleString, MonthDisplayNames} from '../../util/DateHelper';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import TodayIcon from '@mui/icons-material/Today';
import {CalendarLocation} from '../../locations/CalendarLocation';
import {ContentLocation} from "../../locations/ContentLocation";
import {SimpleSelect} from "../common/SimpleSelect";
import {createDefaultCalendarEntry} from "../../services/calendar/CalendarFactory";

import {Calendar} from "../../domain/CalendarDto";
import {ItemActions} from "../../actions/ItemActions";
import {useAppDispatch} from "../../hooks/ReduxHooks";
import {SimpleNumberTextField} from "../common/SimpleNumberTextField";

export function getCalendarIcon(calendar: Calendar) {
    const lowerSubject = calendar.Subject.toLowerCase();
    const lowerCategory = calendar.Category?.trim().toLowerCase();

    let icon = null;
    switch (lowerCategory) {
        case "birthday":
            icon = "🎂";
            break;
        case "anniversary":
            icon = "🎈";
            break;
        case "holiday":
            icon = "🏖";
            break;
        case "reminder":
            icon = "⏰";
            break;
        case "wedding":
            icon = "💒";
            break;
    }
    if (!icon) {
        if (calendar.AllDayEvent && (lowerSubject.includes("birthday"))) {
            icon = "🎂";
        } else if (calendar.AllDayEvent && (lowerSubject.includes("anniversary"))) {
            icon = "🎈";
        } else if (calendar.AllDayEvent && (lowerSubject.includes("holiday"))) {
            icon = "🏖";
        } else if (lowerSubject.includes("remind")) {
            icon = "⏰";
        } else if (lowerSubject.includes("wedding")) {
            icon = "💒";
        }
    }
    return icon ? <span className="cal-icon">{icon}</span> : "";
}

export const CalendarView: FC = () => {

    const isMobile = useSelector(({system}: AppState) => system.isMobile);

    const view = useSelector(({calendarState}: AppState) => calendarState.viewData, shallowEqual);
    const month = useSelector(({calendarState}: AppState) => calendarState.month, shallowEqual);
    const year = useSelector(({calendarState}: AppState) => calendarState.year, shallowEqual);
    const mode = useSelector(({calendarState}: AppState) => calendarState.mode, shallowEqual);

    const dispatch = useAppDispatch();

    console.log("Rendering calendar");

    function onSelectCalendarEntry(entry: CalendarEntryData) {
        ContentLocation.showCalendar(entry.calendar.Uid, entry.instanceId);
    }

    function onCreateNewCalendarEntry(dayData: CalendarDayData) {
        const entry = createDefaultCalendarEntry(dayData.date);
        console.log("Creating entry", entry);
        dispatch(ItemActions.createCalendarEntry(entry));
        ContentLocation.editCalendar(entry.calendar.Uid, entry.instanceId);
    }

    function renderEntry(entry: CalendarEntryData, index: number): ReactNode {

        const style: CSSProperties = mode === "month"
            ? ({top: (30 + entry.row * 20) + "px"})
            : ({top: (30 + entry.row * 50) + "px"});

        const {start, end} = entry.intervalLocal;
        return (
            <div
                key={index}
                className={classNames("entry", "selectable", "pointer", "col-" + entry.colStart, "cspan-" + entry.colSpan)}
                style={style}
                title={dateTimeToLocaleString(start) + " until " + dateTimeToLocaleString(end)}
                onClick={() => onSelectCalendarEntry(entry)}>

                {!entry.calendar.AllDayEvent ? <span className="time">{start?.toFormat("HH:mm")}</span> : null}

                {getCalendarIcon(entry.calendar)}
                {entry.calendar.Subject}
            </div>
        );
    }

    function renderDay(day: CalendarDayData, index: number): ReactNode {
        return (
            <div key={index}
                 className={classNames("day", "selectable", "pointer", {"weekend": day.isWeekend, "today": day.isToday})}
                 onClick={() => onCreateNewCalendarEntry(day)}>
                <div className="date">
                    {day.date.day}
                    {day.date.month !== month ? <span className="different-month">{isMobile ? day.date.monthShort : day.date.monthLong}</span> : null}
                </div>
            </div>
        );
    }

    function renderRow(row: CalendarRowData, index: number): ReactNode {
        return (
            <div key={index} className="row">
                {row.days.map(renderDay)}
                {row.entries.map(renderEntry)}
            </div>
        );
    }

    const headers = isMobile ? view.shortHeaders : view.headers;

    function handleYearChange(newYear: number | undefined) {
        if (newYear) {// && newYear > 1000) {
            CalendarLocation.setMonthAndYear(newYear, month);
        }
    }

    return (
        <div className={classNames("content-item-list", "calendar-view", "mode-" + mode)}>
            <div className="navigation">

                <ButtonGroup>
                    <Button className="large-button"
                            title="Go to today"
                            onClick={() => CalendarLocation.setCurrentViewToToday()}>
                        <TodayIcon/>
                    </Button>

                    <Button className="large-button"
                            title={"Previous " + mode}
                            onClick={() => CalendarLocation.addToCurrentViewDate(-1)}>
                        <ArrowLeftIcon/>
                    </Button>
                    <Button className="large-button"
                            title={"Next " + mode}
                            onClick={() => CalendarLocation.addToCurrentViewDate(1)}>
                        <ArrowRightIcon/>
                    </Button>
                </ButtonGroup>

                <SimpleSelect className="month-select selectable"
                              value={month}
                              options={MonthDisplayNames}
                              onChange={month => CalendarLocation.setMonthAndYear(year, month)}
                              displayEmpty
                              disableUnderline/>
                <div className="year-select">
                    <SimpleNumberTextField value={year} type="year" onChange={handleYearChange} InputProps={{style: {width: "40px"}}}/>
                </div>
            </div>
            <div className="row header-row">
                {headers.map((header, index) => (<div key={index} className="day header">{header}</div>))}
            </div>
            {view.rows.map(renderRow)}
        </div>
    );
};
