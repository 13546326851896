import {UIMap} from "../util/UIMap";

export enum RecurrenceTypeEnum {
    Daily = 0,
    Weekly = 1,
    Monthly = 2,
    MonthlyOnDay = 3,
    Yearly = 5,
    YearlyOnDay = 6,
}

export const RecurrenceTypeDisplayNames: UIMap<RecurrenceTypeEnum>[] = [
    {value: RecurrenceTypeEnum.Daily, title: "Daily "},
    {value: RecurrenceTypeEnum.Weekly, title: "Weekly "},
    {value: RecurrenceTypeEnum.Monthly, title: "Monthly on the same day "},
    {value: RecurrenceTypeEnum.MonthlyOnDay, title: "Monthly on the same week "},
    {value: RecurrenceTypeEnum.Yearly, title: "Annually on the same day "},
    {value: RecurrenceTypeEnum.YearlyOnDay, title: "Annually on the same week "},
];
