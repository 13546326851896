import {FileDto} from "../services/protected/dto/FileDto";

export interface ProtectedFilesState {
    files: FileDto[];

    selectedFile?: FileDto;

    filterText: string;

    showConfirmDeleteSelectedFile: boolean;
}

export const initialProtectedFilesState: ProtectedFilesState = {
    files: [],

    filterText: "",

    showConfirmDeleteSelectedFile: false,
};
