import {FC, useState} from "react";
import {DbAttachment} from "../../db/DbSchema";
import {uuid} from "../../util/Uuid";
import {AttachmentDto} from "../../domain/AttachmentDto";
import {AttachmentList} from "../email/AttachmentList";
import ConfirmDialog from "../common/ConfirmDialog";
import {isImageFile} from "../../util/FileUtil";
import {AttachmentActions} from "../../actions/AttachmentActions";
import {connect, ConnectedProps} from "react-redux";
import {createImageThumbnail} from "../../util/ImageUtil";
import {AppState} from "../../AppState";
import {FileUploadControl, FileUploadControlRef} from "../common/FileUploadControl";
import {DropDownMenuItem} from "../toolbar/DropDownMenu";
import GetApp from "@mui/icons-material/GetApp";
import {useAppStore} from "../../hooks/ReduxHooks";

interface AttachmentManagerViewProps {
    emailUid: string;
    fileControlRef: FileUploadControlRef;
}

export async function convertFileToAttachment(emailUid: string, file: File): Promise<DbAttachment> {
    const attachment: DbAttachment = {
        emailUid,
        attachmentUid: uuid(),
        isUploaded: false,
        name: file.name,
        size: file.size,
        contentId: null, // TODO: support adding attachments with content ids
        contentType: file.type,
    };
    if (isImageFile(file.name)) {
        attachment.thumbnail = await createImageThumbnail(file);
    }
    return attachment;
}

const RenderAttachmentManagerView: FC<AttachmentManagerViewProps & ConnectedAttachmentManagerViewProps> =
    ({emailUid, fileControlRef, draftAttachments, onAddAttachment, onRemoveAttachment}) => {

        const [isRemovingAttachment, setIsRemovingAttachment] = useState(false);
        const [attachmentToRemove, setAttachmentToRemove] = useState<AttachmentDto | null>(null);

        const store = useAppStore();

        async function attachmentFilesSelected(files: FileList) {
            if (!draftAttachments) {
                return;
            }

            for (let i = 0; i < files.length; i++) {
                const file: File = files[i];

                const newAttachment = await convertFileToAttachment(emailUid, file);

                onAddAttachment(emailUid, file, newAttachment);
            }
        }

        function removeAttachment() {
            if (attachmentToRemove && draftAttachments) {
                onRemoveAttachment(emailUid, attachmentToRemove.Uid);
            }
        }

        const attachmentDtos: AttachmentDto[] | undefined = draftAttachments ? draftAttachments
            .map(a => ({
                Id: undefined,
                Name: a.name,
                ContentType: a.thumbnail ? "Thumbnail" : "",
                Image: a.thumbnail || "pdf.png",    // TODO: use the correct image for the attachment type!
                Uid: a.attachmentUid,
                ContentId: a.contentId,
                DataSize: a.size,
                IsPendingUpload: !a.isUploaded,
                IsInline: false,
            })) : undefined;

        const contextMenuItems: (DropDownMenuItem | string)[] = [
            {
                icon: <GetApp/>,
                name: "Remove Attachment",
                onClick: () => {
                    setAttachmentToRemove(store.getState().itemState.contextAttachment || null);
                    setIsRemovingAttachment(true);
                },
            }];

        return (
            <>
                <FileUploadControl multiple fileControlRef={fileControlRef} onFilesSelected={attachmentFilesSelected}/>

                {
                    !!draftAttachments?.length && !!attachmentDtos
                    && (
                        <AttachmentList
                            attachments={attachmentDtos}
                            emailId={-1}
                            contextMenuItems={contextMenuItems}
                            onSelectAttachment={(attachment) => {
                                setAttachmentToRemove(attachment);
                                setIsRemovingAttachment(true);
                            }}/>
                    )
                }

                <ConfirmDialog message={`Are you sure you want to remove the attachment ${attachmentToRemove?.Name}?`}
                               title="Remove attachment"
                               open={isRemovingAttachment}
                               onConfirm={confirmed => {
                                   if (confirmed) {
                                       removeAttachment();
                                   }
                                   setIsRemovingAttachment(false);
                               }}
                               confirmColour="error"/>
            </>
        );
    };

const mapState = ({compose}: AppState) => ({
    draftAttachments: compose.draftAttachments
});

const mapDispatch = {
    onAddAttachment: AttachmentActions.addAttachment,
    onRemoveAttachment: AttachmentActions.removeAttachment,
};

const connector = connect(mapState, mapDispatch);

type ConnectedAttachmentManagerViewProps = ConnectedProps<typeof connector>;

const AttachmentManagerView = connector(RenderAttachmentManagerView);

export default AttachmentManagerView;
