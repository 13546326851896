import {CalendarRowData} from './CalendarRowData';

export interface CalendarViewData {
    headers: string[];
    shortHeaders: string[];
    rows: CalendarRowData[];
}

export const DummyCalendarViewData: CalendarViewData = {
    headers: [],
    shortHeaders: [],
    rows: [],
};
