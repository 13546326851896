import {Badge, Drawer, IconButton} from "@mui/material";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import MenuIcon from "@mui/icons-material/Menu";
import {AppSpecificToolbar} from "./AppSpecificToolbar";
import {AppNavToolbar} from "./AppNavToolbar";
import React, {FC} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../AppState";
import {SystemActions} from "../../actions/SystemActions";
import UserAvatar from "../profile/UserAvatar";
import {SpecialFolderList} from "../folder/SpecialFolderList";
import {SidebarView} from "../folder/SidebarView";
import {useAppSelector} from "../../hooks/ReduxHooks";
import {isEmailContent} from "../../domain/ItemContent";
import {EmailToolbar} from "./EmailToolbar";

export const MobileToolbar: FC = () => {

    const showFolderMenu = useAppSelector(({
                                               system,
                                               folderState
                                           }: AppState) => system.showFolderMenu || folderState.selectedFolder === undefined);
    const showToolbarMenu = useAppSelector(({system}: AppState) => system.showToolbarMenu);

    const hasServerError = useAppSelector(({system}: AppState) => system.systemMessages.length > 0);

    const notificationCount = useAppSelector(s => s.system.notificationMessages.length);

    const dispatch = useDispatch();

    const onShowFolderMenu = (show: boolean) => dispatch(SystemActions.showFolderMenu(show));
    const onShowToolbarMenu = (show: boolean, e?: any) => {
        dispatch(SystemActions.showToolbarMenu(show));
        e?.stopPropagation();
    };

    const folderMenuAvailable = useAppSelector((state: AppState) => state.system.appConfig.showFolderMenuFn(state));

    const showAppControlsInToolbar = useAppSelector(({system}: AppState) => system.appConfig.showAppControlsInMobileToolbar);

    const showMobileSearch = useSelector(({system}: AppState) => system.appConfig.showMobileSearch);
    const hasContent = useAppSelector(s => isEmailContent(s.itemState.itemContent));

    return (
        <>
            {folderMenuAvailable &&
                <IconButton className="flip"
                            onClick={() => onShowFolderMenu(!showFolderMenu)}><ArrowDropDownCircleIcon/></IconButton>}

            {showMobileSearch && !hasContent && <SpecialFolderList/>}
            {showMobileSearch && hasContent && <EmailToolbar reducedMenu/>}

            <IconButton color="primary"
                        onClick={e => onShowToolbarMenu(!showToolbarMenu, e)}
                        className="email-toolbar-menu">
                <Badge color="secondary"
                       badgeContent={notificationCount}
                       overlap="circular"
                       anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
                    <MenuIcon color={hasServerError ? "secondary" : "action"}/>
                </Badge>
            </IconButton>

            {folderMenuAvailable &&
                <Drawer open={showFolderMenu} onClose={() => onShowFolderMenu(false)} transitionDuration={100}>
                    <SidebarView/>
                </Drawer>}

            {showAppControlsInToolbar && <AppSpecificToolbar/>}

            <Drawer open={showToolbarMenu} onClose={() => onShowToolbarMenu(false)} anchor="right"
                    transitionDuration={100}>
                <div className="main-toolbar main-toolbar-vertical">
                    {!showAppControlsInToolbar && <AppSpecificToolbar/>}
                    <AppNavToolbar/>
                    <UserAvatar/>
                </div>
            </Drawer>
        </>);
}
