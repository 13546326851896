import {PhotoDto} from "../services/photos/dto/PhotoDto";
import {PhotoFolderDto} from "../services/photos/dto/PhotoFolderDto";

export interface PhotosState {
    breadcrumbs: string[];
    photos: PhotoDto[];
    folders: PhotoFolderDto[];

    selectedPhoto?: string;
    selectedPhotoName?: string;
    
    contextFolder?: string;
    contextPhoto?: PhotoDto;

    searchText: string;

    loadingPhotos: boolean;
    
    scrollX: number;
}

export const initialPhotosState: PhotosState = {
    breadcrumbs: [],
    photos: [],
    folders: [],

    contextFolder: undefined,
    contextPhoto: undefined,

    searchText: "",

    loadingPhotos: false,
    
    scrollX: 0,
};
