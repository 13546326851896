import {createAction, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {initialPreferenceState, PreferenceState} from "../state/PreferenceState";
import {PERFORM_HANDSHAKE, PerformHandshakeAction} from "../actions/SystemActionTypes";
import {ChangeAliasesResponseDto} from "../services/messages/responses/ChangeAliasesResponseDto";
import {extractEmailUsername} from "../util/Formatters";
import {ADD_ALIAS, AliasAsyncPayload, ImageWhitelistDto, REMOVE_ALIAS} from "./PreferenceActions";
import {FileViewModeType} from "../domain/FileViewModeType";
import {FileFolderTypePref, FileViewModePref} from "../util/Preferences";
import {FileFolderType} from "../domain/FileFolderType";

export namespace PreferenceActions {
    export const addAlias = createAction<AliasAsyncPayload>(ADD_ALIAS);
    export const removeAlias = createAction<AliasAsyncPayload>(REMOVE_ALIAS);

    export const preferencesSlice = createSlice({
        name: "preferences",
        initialState: initialPreferenceState,
        reducers: {
            changeViewMode(state, {payload}: PayloadAction<FileViewModeType>) {
                state.viewMode = payload;
                FileViewModePref.set(payload);
            },
            changeFileFolder(state, {payload}: PayloadAction<FileFolderType>) {
                state.fileFolderType = payload;
                FileFolderTypePref.set(payload);
            },
            changeShowingSearch(state, {payload}: PayloadAction<boolean>) {
                state.showingSearch = payload;
            },
            setSavingWhitelist(state, {payload}: PayloadAction<boolean>) {
                state.isSavingWhitelist = payload;
            },
            updateWhitelist(state, {payload}: PayloadAction<ImageWhitelistDto>) {
                state.isSavingWhitelist = false;
                state.imageWhitelist = payload;
            },
            setFetchingAvailableWords(state, {payload}: PayloadAction<boolean>) {
                state.fetchingAvailableWords = payload;
            },
            setAvailableWords(state, {payload}: PayloadAction<string[]>) {
                state.fetchingAvailableWords = false;
                state.availableWords = payload;
            },
        },
        extraReducers: builder =>
            builder
                .addCase(PreferenceActions.addAlias, handleAddAlias)
                .addCase(PreferenceActions.removeAlias, handleRemoveAlias)
                .addMatcher(action => action.type === PERFORM_HANDSHAKE, handlePerformHandshake)
    });

    export const {
        changeViewMode,
        changeFileFolder,
        changeShowingSearch,
        setSavingWhitelist,
        updateWhitelist,
        setFetchingAvailableWords,
        setAvailableWords,
    } = preferencesSlice.actions;
}

function handlePerformHandshake(state: PreferenceState, action: PerformHandshakeAction) {
    state.aliases = parseResponse(action.data);
    state.imageWhitelist = action.data.ImageWhitelist;
}

function parseResponse(response: ChangeAliasesResponseDto) {
    return [...new Set<string>(response.AvailableAddresses.map(extractEmailUsername))];
}

function handleRemoveAlias(state: PreferenceState, {payload}: PayloadAction<AliasAsyncPayload>): PreferenceState {
    if (payload.error) {
        state.isSavingAliases = false
        state.aliases.push(payload.data);
        return state;
    }
    if (payload.response) {
        state.isSavingAliases = false;
        state.aliases = parseResponse(payload.response);
        return state;
    }
    state.isSavingAliases = true
    state.aliases = state.aliases.filter(a => a !== payload.data);
    return state;
}

function handleAddAlias(state: PreferenceState, {payload}: PayloadAction<AliasAsyncPayload>) {
    if (payload.error) {
        state.isSavingAliases = false
        state.aliases = state.aliases.filter(a => a !== payload.data);
        return;
    }
    if (payload.response) {
        state.isSavingAliases = false;
        state.aliases = parseResponse(payload.response);
        return;
    }
    state.isSavingAliases = true
    state.aliases.push(payload.data);
}
