import "./Editor.scss";

import React, {useEffect, useRef, useState} from "react";

import tinymce, {Editor} from 'tinymce';
import 'tinymce/themes/silver';

// import 'tinymce/skins/ui/oxide/content.min.css';
// import 'tinymce/skins/content/default/content.min.css';
import 'tinymce/icons/default/index.js';
import 'tinymce/plugins/autolink/index.js';
import 'tinymce/plugins/image/index.js';
import 'tinymce/plugins/media/index.js';
import 'tinymce/plugins/paste/index.js';
import 'tinymce/plugins/imagetools/index.js';
import 'tinymce/plugins/table/index.js';
import 'tinymce/plugins/searchreplace/index.js';
import 'tinymce/plugins/autoresize/index.js';
import 'tinymce/plugins/lists/index.js';
import 'tinymce/plugins/save/index.js';
import 'tinymce/plugins/code/index.js';
import 'tinymce/plugins/codesample/index.js';
import 'tinymce/plugins/emoticons/index.js';
import 'tinymce/plugins/emoticons/js/emojis.js';
import {FileUploadControl, FileUploadControlCallback} from "../common/FileUploadControl";
import {useSelector} from "react-redux";
import {AppState} from "../../AppState";
import {ErrorBoundary} from "../common/ErrorBoundary";
//import 'tinymce/plugins/formatpainter/index.js';

export type EditorFileCallback = (filename: string, metadata: any) => void;

interface RenderEditorProps {
    plugins?: ("autolink" | "image" | "paste" | "imagetools" | "table" | "media" | "searchreplace" | "autoresize" | "lists" | "emoticons" | "code" | "codesample" | "save")[];//| "powerpaste"
    statusbar?: boolean;
    toolbar?: boolean | string;
    //toolbar_drawer?: "floating" | "sliding";
    menubar?: boolean | string;
    blockFormats?: string;

    height?: string;

    initialValue?: string;
    instanceId: string | undefined;
    onChange: (html: string) => void;

    onAddInlineImage?: (callback: EditorFileCallback, file: File) => Promise<void>;

    onSave?: () => void;
}

let nextEditorId = 1;

let imageCallback: EditorFileCallback = () => {
};

let loadedTheme = false;

const RenderEditor = ({
                          plugins, statusbar, toolbar, menubar, blockFormats,
                          initialValue, instanceId, onChange,
                          onAddInlineImage, onSave, height,
                      }: RenderEditorProps) => {

    const editorRef = useRef<HTMLDivElement>(null);
    const imageFileControl = useRef<FileUploadControlCallback | null>();

    const [editorId] = useState<string>("editor-" + nextEditorId++);

    const theme = useSelector(({system}: AppState) => system.theme);

    useEffect(() => {
        if (loadedTheme) return;
        // TODO: this is a bit suboptimal, but seems to be the only way :(
        if (theme === "light") {
            require('tinymce/skins/ui/oxide/skin.min.css');
        } else {
            require('tinymce/skins/ui/oxide-dark/skin.min.css');
        }
        loadedTheme = true;
    }, [theme]);

    const setupEditor = (editor: Editor) => {
        editor.on('init', () => {
            if (initialValue) {
                editor.setContent(initialValue);
            }
        });

        editor.on('Change', () => {
            onChange(editor.getContent())
        });
    }

    function handleFilePicker(callback: Function, value: any, meta: Record<string, any>) {
        if (meta.filetype === "image" && onAddInlineImage && imageFileControl.current) {
            imageCallback = callback as EditorFileCallback;
            imageFileControl.current.openFileDialog();
        }
    }

    async function onImagesSelected(files: FileList) {
        if (!onAddInlineImage || !imageCallback) return;

        for (let i = 0; i < files.length; i++) {
            const file: File = files[i];

            await onAddInlineImage(imageCallback, file);
        }
    }

    useEffect(() => {
        if (editorRef.current) {
            console.debug("Initialising editor...");

            tinymce.init({
                selector: "#" + editorRef.current.id,
                plugins: plugins,
                statusbar: statusbar ?? true,
                toolbar: toolbar ?? true,
                //toolbar_drawer: props.toolbar_drawer,
                resize: false,
                menubar: menubar,
                branding: false,
                height: height ?? "100%",
                skin: theme === "light" ? "oxide" : "oxide-dark",
                //autoresize_bottom_margin: 50,
                forced_root_block: "div",
                setup: setupEditor,
                // images_upload_handler: onSetImageUploadParams ? imageUploadHandler as any : undefined,
                file_picker_types: onAddInlineImage ? "image" : undefined,
                file_picker_callback: onAddInlineImage ? handleFilePicker : undefined,
                block_formats: blockFormats ?? "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3; Header 4=h4; Preformatted=pre; Code=code",
                content_css: "/react-mail/static/blank.css",

                save_enablewhendirty: true,
                save_onsavecallback: onSave,
            }).catch(console.error);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const editor = tinymce.get(editorId);
        if (editor && initialValue !== undefined) {
            // console.debug("Setting editor content...");
            editor.setContent(initialValue);
        }
    }, [initialValue, editorId, instanceId]);

    return (
        <ErrorBoundary>
            <FileUploadControl fileControlRef={imageFileControl} onFilesSelected={onImagesSelected}/>

            <div id={editorId} className="rich-text-editor" ref={editorRef}>

            </div>
        </ErrorBoundary>
    )
};

export default React.memo(RenderEditor);
