import {FC, useEffect, useState} from "react";
import {dateTimeFromTicks, formatDateTimeAsFriendlyString} from "../../util/DateHelper";

export interface RelativeDateTimeProps {
    dateTime: number | null | undefined;
    fallbackText?: string;
}

// Renders a date time that is relative to the current time, updating it as required
export const RelativeDateTime: FC<RelativeDateTimeProps> = ({dateTime, fallbackText}) => {

    const dateTimeValue = dateTime ? dateTimeFromTicks(dateTime) : null;

    const [dateTimeString, setDateTimeString] = useState<string>(formatDateTimeAsFriendlyString(dateTimeValue));

    useEffect(() => {
        const interval = setInterval(() => setDateTimeString(formatDateTimeAsFriendlyString(dateTimeValue)), 10000);
        return () => clearInterval(interval);
    }, [dateTimeValue]);

    return <>{dateTimeString || fallbackText}</>;
}
