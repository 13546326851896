import {useAppDispatch, useAppSelector, useAppStore} from "../../hooks/ReduxHooks";
import styled from "@emotion/styled";
import {PhotosList} from "./PhotosList";
import {Button} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect, useState} from "react";
import {ContentLocation} from "../../locations/ContentLocation";
import {PhotosActions, PictureSize} from "../../redux/PhotosSlice";
import {LoadingIndicator} from "../common/LoadingIndicator";
import {css} from "@emotion/css";
import {Centre} from "../Centre";
import {SystemActions} from "../../actions/SystemActions";
import {PhotoRatingControls} from "./PhotoRatingControls";

const PhotoContentDiv = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid transparent;

  > div {
    flex: 0 0 auto;
    height: calc(100% - ${PictureSize}px);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }

  :focus {
    border: 1px dashed gray;
  }

  .rating-control {
    margin: 10px;
    position: absolute;
  }
`;

const PhotoPreviewListDiv = styled.div`
  height: ${PictureSize}px;
  overflow: hidden;

  flex-wrap: nowrap;

  > div {
    display: inline-flex;
  }
`;

const fullPageLoadingIndicatorStyle = css`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: ${PictureSize}px;
  height: calc(100% - ${PictureSize}px);
  background: rgba(0, 0, 0, 0.2);
`;

const PhotoItemView = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const selectedPhoto = useAppSelector(s => s.photosState.selectedPhoto);

    const dispatch = useAppDispatch();

    useEffect(() => {
        setLoading(true);
    }, [selectedPhoto]);

    if (!selectedPhoto) return null;

    return (<>
            <img src={`/Mail3/Photos/GetB64/${btoa(selectedPhoto)}`}
                 alt={selectedPhoto}
                 onLoad={() => setLoading(false)}
                 onError={() => dispatch(SystemActions.showError("Unable to load photo"))}
            />
            {loading && <Centre className={fullPageLoadingIndicatorStyle}><LoadingIndicator/></Centre>}
        </>
    );
}

const PhotoSelectionView = () => {
    const isMobile = useAppSelector(s => s.system.isMobile);
    const scrollX = useAppSelector(s => s.photosState.scrollX);
    const [x, setX] = useState<number>(0);
    const dispatch = useAppDispatch();

    function handleMouseWheel(e: React.WheelEvent) {
        dispatch(PhotosActions.scrollX(-e.deltaY));
    }

    function handleTouchStart(e: React.TouchEvent) {
        setX(e.touches[0].clientX);
    }

    function handleTouchMove(e: React.TouchEvent) {
        const {clientX} = e.touches[0];
        dispatch(PhotosActions.scrollX(x - clientX));
        setX(clientX);
    }

    return (
        <PhotoPreviewListDiv className="MuiBackdrop-root"
                             onWheel={e => handleMouseWheel(e)}
                             onTouchMove={e => handleTouchMove(e)}
                             onTouchStart={e => handleTouchStart(e)}>
            <div style={{
                transform: `translate(${-scrollX}px, 0)`,
                transition: isMobile ? "none" : "transform ease 0.2s"
            }}>
                <PhotosList/>
            </div>
        </PhotoPreviewListDiv>
    );
}

export const PhotoContentViewer = () => {
    const store = useAppStore();

    function onClearItemContent() {
        ContentLocation.showPhotoFolder(store.getState().photosState.breadcrumbs, undefined);
    }

    function handleKeyPress(e: React.KeyboardEvent) {
        if (e.key === "ArrowRight") {
            ContentLocation.showIncrementalPhoto(store.getState(), 1);
        } else if (e.key === "ArrowLeft") {
            ContentLocation.showIncrementalPhoto(store.getState(), -1);
        } else if (e.key === "Escape") {
            onClearItemContent();
        }
    }

    return (
        <PhotoContentDiv tabIndex={1} onKeyDown={e => handleKeyPress(e)}>
            <Button onClick={onClearItemContent} size="small" className="hide-content-button" title="Close"
                    style={{display: "block"}}>
                <CloseIcon/>
            </Button>
            <div>
                <PhotoItemView/>
            </div>
            <PhotoRatingControls/>
            <PhotoSelectionView/>
        </PhotoContentDiv>
    );
}
