import {Button, TextField} from "@mui/material";
import {EmailLocation} from "../../locations/EmailLocation";
import EmailAutoComplete from "../common/EmailAutoComplete";
import {
    convertRecipientAddressesToString,
    extractEmailAddresses,
    extractRecipientAddresses
} from "../../util/Formatters";
import React, {FC, useRef, useState} from "react";
import DbDraftEmail from "../../domain/DbDraftEmail";
import AttachmentManagerView from "./AttachmentManagerView";
import {FileUploadControlCallback} from "../common/FileUploadControl";
import {useAppDispatch, useAppSelector, useAppStore} from "../../hooks/ReduxHooks";
import {ComposeEmailActions} from "../../actions/ComposeEmailActions";
import {SenderSelectControl} from "./SenderSelectControl";
import {validateEmailToSend} from "../../validations/EmailValidator";
import ErrorDialog from "../common/ErrorDialog";
import ConfirmDialog from "../common/ConfirmDialog";
import {ComposeEmailControls} from "./ComposeEmailControls";
import CloseIcon from "@mui/icons-material/Close";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import {popOutContent} from "../common/HideContentButton";
import {isTearOffWindow} from "../../App";
import SettingsIcon from '@mui/icons-material/Settings';
import {ComposeEmailSettingsDialog} from "./ComposeEmailSettingsDialog";

interface ComposeEmailHeaderProps {
    draftEmail: DbDraftEmail;
}

export const ComposeEmailHeader: FC<ComposeEmailHeaderProps> = ({draftEmail}) => {
    const dispatch = useAppDispatch();
    const store = useAppStore();

    const isMobile = useAppSelector(({system}) => system.isMobile);

    const {showCC, showBCC, showSender} = useAppSelector(s => s.compose.composeOptions);

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [validationDialogOpen, setValidationDialogOpen] = useState(false);

    const defaultAddress = useAppSelector(({system}) => system.defaultAddress);
    const isOffline = useAppSelector(({system}) => system.isOffline);

    const draftEmailId = draftEmail?.Uid;

    const senderAddress = extractEmailAddresses(draftEmail?.From)[0] || defaultAddress;
    const toAddresses = extractRecipientAddresses(draftEmail?.To);
    const ccAddresses = extractRecipientAddresses(draftEmail?.Cc);
    const bccAddresses = extractRecipientAddresses(draftEmail?.Bcc);

    const fileControlRef = useRef<FileUploadControlCallback | null>();

    function openAttachFileDialog() {
        fileControlRef.current?.openFileDialog();
    }

    function onChangeDraftEmail(changes: Partial<DbDraftEmail>) {
        dispatch(ComposeEmailActions.changeDraftEmail(changes));
    }

    let moreFieldsCCElt: React.ReactNode = null;
    let moreFieldsBCCElt: React.ReactNode | null = null;
    let moreFieldsSenderElt: React.ReactNode | null = null;

    const hasCC = ccAddresses.length > 0;
    const hasBCC = bccAddresses.length > 0;
    const hasSender = senderAddress !== defaultAddress;
    if (hasCC || showCC) {
        moreFieldsCCElt = (<>
            <EmailAutoComplete
                label="CC"
                value={ccAddresses}
                setValue={newValue => onChangeDraftEmail({Cc: convertRecipientAddressesToString(newValue)})}
            />
        </>);
    }
    if (hasBCC || showBCC) {
        moreFieldsBCCElt = (<>
            <EmailAutoComplete
                label="BCC"
                value={bccAddresses}
                setValue={newValue => onChangeDraftEmail({Bcc: convertRecipientAddressesToString(newValue)})}
            />
        </>);
    }

    if (hasSender || showSender) {
        moreFieldsSenderElt = <SenderSelectControl senderAddress={senderAddress}/>;
    }

    async function validateAndSendEmail(allowSendingWithoutSubject: boolean) {
        if (!draftEmail) {
            return;
        }

        const validationResult = validateEmailToSend(draftEmail);
        if (validationResult.errors.length > 0) {
            setValidationDialogOpen(true);   // TODO: make the error text
            return;
        }

        if (!allowSendingWithoutSubject && !draftEmailHasSubject()) {
            setConfirmDialogOpen(true);
        } else {
            await ComposeEmailActions.sendEmail(draftEmail, isOffline, store);
            // TODO: fix this - we need the email to actually be sent!
            // if (isTearOffWindow) {
            //     window.close();
            // }
        }
    }

    function draftEmailHasSubject() {
        return draftEmail && draftEmail.Subject.trim().length > 0;
    }

    async function handleSendWithoutSubjectConfirmation(confirmed: boolean) {
        setConfirmDialogOpen(false);
        if (confirmed) {
            await validateAndSendEmail(true);
        }
    }

    function popOutComposer() {
        popOutContent();
        EmailLocation.stopComposingEmail();
    }

    function closeComposer() {
        if (isTearOffWindow) {
            window.close();
        } else {
            EmailLocation.stopComposingEmail();
        }
    }

    return (
        <>
            <div className="editor-column">
                <div className="button-container">
                    <Button variant="contained" color="primary"
                            onClick={() => validateAndSendEmail(false)}>Send</Button>

                    {isMobile &&
                        <Button variant="contained" color="secondary" onClick={openAttachFileDialog}>Attach</Button>}

                    {isTearOffWindow && <div style={{height: "32px"}}>&nbsp;</div>}
                    {!isTearOffWindow && !isMobile && (
                        <Button variant="contained"
                                color="inherit"
                                title="Pop out"
                                onClick={() => popOutComposer()}>
                            <AspectRatioIcon/>
                        </Button>)
                    }
                    {!isTearOffWindow && (
                        <Button variant="contained"
                                color="inherit"
                                title="Close"
                                onClick={() => closeComposer()}>
                            <CloseIcon/>
                        </Button>)
                    }

                    <ComposeEmailControls isSigned={!!draftEmail.IsSigned} isEncrypted={!!draftEmail.IsEncrypted}/>
                </div>

                <div className="editor-subject">
                    {moreFieldsSenderElt}

                    <EmailAutoComplete
                        label="To"
                        autoFocus
                        value={toAddresses}
                        setValue={newValue => onChangeDraftEmail({To: convertRecipientAddressesToString(newValue)})}
                    />

                    {moreFieldsCCElt}

                    {moreFieldsBCCElt}

                    <TextField
                        label="Subject"
                        fullWidth
                        value={draftEmail?.Subject || ""}
                        onChange={e => onChangeDraftEmail({Subject: e.target.value})}
                    />

                    {!isMobile && <div className="editor-more-fields">
                        <Button variant="contained" color="secondary" onClick={openAttachFileDialog}>Attach</Button>
                        <span className="spacer">&nbsp;</span>
                        <Button onClick={() => dispatch(ComposeEmailActions.setComposeEmailOptionsDialogOpen(true))}
                                title="Compose Email Options">
                            <SettingsIcon/>
                        </Button>
                    </div>}
                </div>

            </div>
            {draftEmailId ? <AttachmentManagerView emailUid={draftEmailId} fileControlRef={fileControlRef}/> : null}

            <ErrorDialog
                open={validationDialogOpen}
                onClose={() => setValidationDialogOpen(false)}
                title="Recipients Missing"
                message="You must add at least one recipient to be able to send the email"
            />

            <ConfirmDialog
                open={confirmDialogOpen}
                onConfirm={handleSendWithoutSubjectConfirmation}
                title="Send Without Subject?"
                message="Are you sure you want to send this email without a subject?"
                confirmColour="warning"
            />

            <ComposeEmailSettingsDialog hasCC={hasCC} hasBCC={hasBCC} hasSender={hasSender}/>
        </>
    );
};
