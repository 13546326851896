import {FileUploadControl, FileUploadControlCallback} from "../common/FileUploadControl";
import React, {useRef} from "react";
import {Button} from "@mui/material";
import {StackPanel} from "../panels/StackPanel";
import {pgpReadKey} from "../../util/PGPUtil";
import {useAppDispatch} from "../../hooks/ReduxHooks";
import {SystemActions} from "../../actions/SystemActions";
import {ItemActions} from "../../actions/ItemActions";
import {pgpKeyFromRawKey} from "../../domain/PgpKey";

export const PgpKeyListControlsView = () => {
    const dispatch = useAppDispatch();

    async function attachmentFilesSelected(files: FileList) {

        console.log("File: ", files);

        for (let i = 0; i < files.length; i++) {
            const file: File = files[i];

            const reader = new FileReader();
            reader.addEventListener('load', (e) => {
                const text = e.target?.result?.toString() || "";
                pgpReadKey({armoredKey: text})
                    .then(key => dispatch(ItemActions.showItemContent(pgpKeyFromRawKey(key))),
                        error => dispatch(SystemActions.showError("Unable to import key: " + error.message)));
            });
            reader.readAsText(file);
        }
    }
    
    const fileControlRef = useRef<FileUploadControlCallback>();

    const showImportKeyDialog = () => fileControlRef.current?.openFileDialog();
    
    return (
        <StackPanel orientation="horizontal"
                    className="panel">
            <FileUploadControl fileControlRef={fileControlRef} onFilesSelected={attachmentFilesSelected}/>
            
            <Button variant="contained" 
                    onClick={showImportKeyDialog}>
                Load Key
            </Button>
        </StackPanel>
    );    
}