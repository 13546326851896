import React, {useState} from "react";
import {ContactAddress, extractFriendlyNamesFromEmailAddresses} from "../../util/Formatters";
import "./EmailRecipientList.scss";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {AppState} from "../../AppState";
import {ContentLocation} from "../../locations/ContentLocation";
import {setTemporaryContactData} from "../../locations/ContactLocation";
import {Tooltip} from "@mui/material";
import {ContactDto} from "../../services/messages/ContactDto";
import {ContactContentLiteView} from "../contacts/ContactContentView";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

interface EmailRecipientListProps {
    prefix: string;
    recipients: string | null | undefined;
    unverifiedSender?: boolean;
    className?: string;
    isSignedBySender?: boolean;
    mismatchedSignature?: boolean;
}

export const EmailRecipientList: React.FC<EmailRecipientListProps> = (props) => {
    const [expanded, setExpanded] = useState(false);

    const contactLookupMap = useSelector(({contactState}: AppState) => contactState.contactLookupMap);

    if (!props.recipients) {
        return null;
    }

    const addresses = extractFriendlyNamesFromEmailAddresses(props.recipients, contactLookupMap);

    const limit = expanded ? 100000 : 4;

    function onSelectKnownContact(contact: ContactDto) {
        ContentLocation.showContact(contact.Id, contact.ClientId);
    }

    function onSelectUnknownAddress(address: ContactAddress) {
        setTemporaryContactData(address);
        ContentLocation.createContact();
    }

    function renderSignature() {
        if (props.isSignedBySender) {
            return <span title="This email was digitally signed by this person"><CheckCircleIcon/></span>;
        }
        if (props.mismatchedSignature) {
            return <span title="The digital signature is invalid and the email might be a forgery"><ErrorIcon className="error"/></span>;
        }
        return null;
    }

    function renderContactAddress(address: ContactAddress, index: number) {
        const verified = !props.unverifiedSender || props.isSignedBySender;
        const contact = address.Contact;

        const addressElt = (
            <span>
                {renderSignature()}
                {!verified ? address.Address : (address.Name || address.Address)}
            </span>
        );

        if (contact) {
            return (
                <Tooltip key={index}
                    // leaveDelay={100000}
                         classes={{tooltip: "contact-tooltip"}}
                         title={<ContactContentLiteView contact={contact} small highlightEmail={address.Address}/>}>
                        <span className="known-contact">
                            <button className="link"
                                    onClick={() => onSelectKnownContact(contact)}>
                                {addressElt}
                            </button>; 
                        </span>
                </Tooltip>
            );
        }

        return (
            <span key={index} title={address.Address}>
                <button className="link"
                        onClick={() => onSelectUnknownAddress(address)}>
                    {addressElt}
                </button>; 
            </span>
        );
    }

    const addressElements = addresses
        .slice(0, limit)
        .map(renderContactAddress);

    if (addresses.length > 4) {
        if (!expanded) {
            addressElements.push(<span key="e"><button className="link" onClick={() => setExpanded(true)}>+{addresses.length - limit} more</button></span>);
        } else {
            addressElements.push(<span key="c"><button className="link" onClick={() => setExpanded(false)}>collapse</button></span>);
        }
    }

    return (
        <div className={classNames("email-recipient-list", props.className)}>
            <span className="prefix">{props.prefix}</span>
            {addressElements}
        </div>
    );
};
