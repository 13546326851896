import {AppState} from "../AppState";
import {LocationProperties} from "../util/LocationParser";
import {SystemStatusActions} from "../actions/SystemStatusActions";
import {locationManager} from "../BootStrap";
import {createAppProperties} from "./LocationUtil";
import {AppDispatch} from "../AppStore";

export type SystemStatusViewType = "status" | "logs";

export interface SystemStatusLocationProperties {
    sview?: SystemStatusViewType;
}
export const SystemStatusLocationPropertyNames = createAppProperties<SystemStatusLocationProperties>("sview");

export class SystemStatusLocation {

    static handleLocationChange(dispatch: AppDispatch, getState: () => AppState, newLocation: LocationProperties): void {

        const newView = newLocation.sview || "status";

        const {sysStatus} = getState();

        if (newView !== sysStatus.view) {
            dispatch(SystemStatusActions.setView(newView));
        }
    }

    static switchView(sview: SystemStatusViewType) {
        locationManager.updateWindowLocation("system", {sview});
    }
}
