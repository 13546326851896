import React, {FC, useEffect} from "react";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import CreateIcon from '@mui/icons-material/Create';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import DbDraftEmail from "../../domain/DbDraftEmail";
import {ComposeEmailActions} from "../../actions/ComposeEmailActions";
import {shallowEqual} from "react-redux";
import {ensurePgpKeysLoadedBoolean} from "../../util/PGPUtil";
import {checkDraftEmailEncryptionRecipients} from "../../security/EncryptedEmailHelper";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';

interface ComposeEmailControlsProps {
    isSigned: boolean;
    isEncrypted: boolean;
}

type ComposePgpOptions = "sign" | "encrypt";

export const ComposeEmailControls: FC<ComposeEmailControlsProps> = ({isSigned, isEncrypted}) => {
    const dispatch = useAppDispatch();

    const pgpKeysLoaded = useAppSelector(({accountsState}) => accountsState.pgpKeysLoaded);
    const isMobile = useAppSelector(s => s.system.isMobile);

    useEffect(() => {
        ensurePgpKeysLoadedBoolean(dispatch, pgpKeysLoaded).catch(console.error);
    });

    const hasSecretKeys = useAppSelector(({accountsState}) => accountsState.secretKeys.length > 0);

    const encryptionErrors = useAppSelector(({
                                                 accountsState,
                                                 compose
                                             }) => checkDraftEmailEncryptionRecipients(compose.draftEmail, accountsState.pgpKeys), shallowEqual);

    const value: ComposePgpOptions[] = [];
    if (isSigned && hasSecretKeys) value.push("sign");
    if (isEncrypted) value.push("encrypt");

    function onChangeDraftEmail(changes: Partial<DbDraftEmail>) {
        dispatch(ComposeEmailActions.changeDraftEmail(changes));
    }

    function onChangePgpOptions(e: any, newValue: ComposePgpOptions[]) {
        onChangeDraftEmail({IsEncrypted: newValue.includes("encrypt"), IsSigned: newValue.includes("sign")});
    }

    function onOpenSettingsDialog() {
        dispatch(ComposeEmailActions.setComposeEmailOptionsDialogOpen(true));
    }

    return (
        <ToggleButtonGroup size="small" className="pgp-options" value={value} onChange={onChangePgpOptions}>

            <ToggleButton value="sign"
                          title={hasSecretKeys ? "Sign this email" : "You can't sign this email because you have no secret keys"}
                          className={hasSecretKeys ? "" : "error"}>
                {isSigned ? <BorderColorIcon/> : <CreateIcon/>}
            </ToggleButton>
            <ToggleButton value="encrypt"
                          title={encryptionErrors.length === 0 ? "Encrypt this email" : "You can't encrypt this email because you don't have keys for: " + encryptionErrors.join(", ")}
                          className={encryptionErrors.length === 0 ? "" : "error"}>
                {isEncrypted ? <LockIcon/> : <LockOpenIcon/>}
            </ToggleButton>

            {isMobile && <ToggleButton value="settings" onClick={() => onOpenSettingsDialog()}>
                <SettingsIcon/>
            </ToggleButton>}
        </ToggleButtonGroup>
    );
};
