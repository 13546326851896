import {ToolbarButton} from "../toolbar/ToolbarTypes";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {LoginAuditService} from "../../services/login-audit/LoginAuditService";
import {shallowEqual} from "react-redux";

export const LoginAuditToolbar = () => {
    const dispatch = useAppDispatch();

    const selectedAuditIds = useAppSelector(({usersState}) => usersState.selectedLogins.map(la => la.Id), shallowEqual);

    return (
        <>
            <ToolbarButton title="Refresh"
                           label="Refresh Tokens"
                           onClick={() => LoginAuditService.updateLoginAudits(dispatch)}
                           icon={<RefreshIcon/>}/>

            <ToolbarButton title="Delete"
                           label="Delete Login Tokens"
                           onClick={() => LoginAuditService.deleteLoginAudits(selectedAuditIds, dispatch)}
                           icon={<DeleteIcon/>}
                           disabled={selectedAuditIds.length === 0}/>
        </>
    );
};
