import {Dispatch} from 'redux';
import {AppState} from '../AppState';
import {ContactActions} from "../actions/ContactActions";
import {blankContact, ContactDto} from "../services/messages/ContactDto";
import {ContactAddress} from "../util/Formatters";
import {setContactName} from "../components/contacts/ContactEditView";

let tempContactAddress: ContactAddress | null = null;

export function setTemporaryContactData(contactAddress: ContactAddress) {
    tempContactAddress = contactAddress;
}

export function handleViewContact(state: AppState, newContactId: number, newContactIdStr: string | undefined, dispatch: Dispatch) {
    const {contactState} = state;

    const newContact: ContactDto =
        contactState.contacts.find(c =>
            (c.Id === newContactId && !newContactIdStr && newContactId !== -1)
            || (c.ClientId && newContactIdStr && c.ClientId === newContactIdStr))
        || {...blankContact, ClientId: newContactIdStr, FirstName: "New Contact"};

    if (newContact.Id === -1 && !newContactIdStr) {
        return;
    }
    
    if (newContact.Id === -1 && tempContactAddress) {
        if (tempContactAddress.Name) {
            setContactName(newContact, tempContactAddress.Name);
        }
        if (tempContactAddress.Address) {
            newContact.Emails = [];
            newContact.Emails.push(tempContactAddress.Address);
        }
    }
    dispatch(ContactActions.showContact(newContact));

    tempContactAddress = null;
}
