import React, {FC} from "react";
import {Checkbox, FormControlLabel} from "@mui/material";

export interface SimpleCheckboxProps {
    label: React.ReactNode;
    checked: boolean | undefined;
    onChange: (checked: boolean) => void;

    name?: string;
    colour?: 'primary' | 'secondary' | 'default' | undefined;
    disabled?: boolean;
}

export const SimpleCheckbox: FC<SimpleCheckboxProps> = (props) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={props.checked}
                    onChange={e => props.onChange(e.target.checked)}
                    name={props.name}
                    color={props.colour || "primary"}
                    disabled={props.disabled}
                />
            }
            label={props.label}
        />
    );
}