import { CssRule } from './CssRule';
import { CssRuleType } from './CssRuleType';
import { CssProperty } from './CssProperty';
import { CssSelector } from './CssSelector';

export class CssParser {
    private tokens: string[] = [];
    private rules: CssRule[] = [];

    private index: number = 0;
    private currentToken: string | null = null;
    private currentRule: CssRule = new CssRule();
    private inMediaSection: boolean = false;

    ParseCss(cssText: string): CssRule[] {
        if (cssText.startsWith("<!--")) {
            this.tokens = tokenizeCssText(cssText.substring(4, cssText.length - 4));
        } else {
            this.tokens = tokenizeCssText(cssText);
        }
        this.rules = [];

        this.inMediaSection = false;
        this.index = 0;
        this.currentToken = null;
        this.currentRule = new CssRule();

        this.parseTokens();

        return this.rules;
    }

    ParseCssProperties(cssText: string): CssProperty[] {
        this.tokens = tokenizeCssText(cssText);

        this.inMediaSection = false;
        this.index = 0;
        this.currentToken = "dummy";
        this.currentRule = new CssRule();

        this.parseProperties();

        return this.currentRule.properties;
    }

    private parseTokens(): void {
        this.currentRule = new CssRule();

        while (this.index < this.tokens.length) {
            this.parseSelectors();

            if (this.isMediaSelector()) {
                // We're ignoring media selectors for now
                this.currentRule.type = CssRuleType.MediaStart;
                this.rules.push(this.currentRule);
                this.currentRule = new CssRule();
                this.inMediaSection = true;
                continue;
            }

            this.parseProperties();

            if (this.currentRule.selectors.length > 0) {
                this.rules.push(this.currentRule);
                this.currentRule = new CssRule();
            }
        }
    }

    private isMediaSelector(): boolean {
        const { selectors } = this.currentRule;
        return selectors.length > 0 && selectors[0].value.toLowerCase().startsWith("@media");
    }

    private parseProperties(): void {
        while (this.currentToken && this.currentToken !== "}") {
            this.parseProperty();
        }
    }

    private parseProperty() {
        let builder = "";
        while (this.nextToken()) {
            if (this.currentToken === ":") break;
            if (this.currentToken === "}" || this.currentToken === "{") return;
            builder += this.currentToken;
        }
        var name = builder.trim();

        builder = "";
        while (this.nextToken()) {
            if (this.currentToken === ";" || this.currentToken === "}") break;
            if (this.currentToken === "{") return;
            builder += this.currentToken;
        }
        var value = builder.trim();

        if (name.length > 0 || value.length > 0) {
            this.currentRule.properties.push(new CssProperty(name, value));
        }
    }

    private nextToken(): string | null {
        const count = this.tokens.length;
        let inComment = 0;
        do {
            if (this.index >= count) {
                return this.currentToken = null;
            }

            if (inComment === 1) inComment--;

            this.currentToken = this.tokens[this.index++];
            if (this.index < count - 1) {
                var nextToken = this.tokens[this.index];
                if (inComment === 0 && this.currentToken === "/" && nextToken === "*") {
                    inComment = 2;
                    this.index++;
                } else
                    if (inComment === 2 && this.currentToken === "*" && nextToken === "/") {
                        inComment = 1;
                        this.index++;
                    }
            }
        } while (this.currentToken && inComment > 0);
        return this.currentToken;
    }

    private parseSelectors(): void {
        let builder = "";

        while (this.nextToken() && this.currentToken !== "{") {
            if (this.currentToken === "}") {
                if (this.inMediaSection) {
                    const newRule = new CssRule();
                    newRule.type = CssRuleType.MediaEnd;
                    this.rules.push(newRule);
                }
                return;
            }
            if (this.currentToken === ",") {
                this.addSelector(builder);
                builder = "";
            } else {
                builder += this.currentToken;
            }
        }
        this.addSelector(builder);
    }

    private addSelector(builder: string) {
        var value = builder.trim();
        if (value.length > 0) {
            this.currentRule.selectors.push(new CssSelector(value));
        }
    }
}

export function tokenizeCssText(cssText: string): string[] {
    const matches = (cssText || "").matchAll(/([{}:;,/*])|([^{}*:;,/]+)/g);
    if (!matches) {
        return [];
    }
    const result: string[] = [];
    for (const match of matches) {
        result.push(match[0]);
    }
    return result;
}
