import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import React, {FC, ReactNode, useEffect, useState} from "react";

interface TextFieldDialogProps {
    title: ReactNode;
    message: ReactNode;
    initialValue: string;
    open: boolean;
    onClose: (value: string | null) => void;
}

export const TextFieldDialog: FC<TextFieldDialogProps> = ({
                                                              title,
                                                              message,
                                                              initialValue,
                                                              open,
                                                              onClose,
                                                          }) => {
    const [value, setValue] = useState<string>(initialValue);

    useEffect(() => {
        setValue(initialValue)
    }, [open, initialValue]);

    return (
        <Dialog open={open}
                onClose={() => onClose(null)}>

            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

            <DialogContent className="edit-email-category-dialog">
                <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
                <TextField value={value ?? ""}
                           onChange={e => setValue(e.target.value)}
                           onKeyPress={e => {
                               if (e.key === "Enter") {
                                   onClose(value);
                               }
                           }}
                           autoFocus fullWidth
                           onFocus={e => e.target.select()}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(value)} color="primary" variant="contained">OK</Button>
                <Button onClick={() => onClose(null)} color="secondary" variant="contained">Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}
