import React from "react";
import {SearchControl} from './SearchControl';

import './FolderControls.scss';

export const FolderControls = () => {
    return (
        <div className="folder-controls">
            <SearchControl showWhenMobile={false}/>
        </div>
    );
};
