import {Button} from "@mui/material";
import {FileUploadControl, FileUploadControlCallback} from "../common/FileUploadControl";
import {QuickSearchText} from "../common/QuickFilterOption";
import React, {useRef} from "react";
import {ProtectedFilesActions} from "../../redux/ProtectedFilesSlice";
import {ProtectedFilesService} from "../../services/protected/ProtectedFilesService";
import {useAppDispatch} from "../../hooks/ReduxHooks";
import {useStore} from "react-redux";
import {AppStore} from "../../AppStore";

export const ProtectedFileControls = () => {
    const fileControlRef = useRef<FileUploadControlCallback | null>();

    const dispatch = useAppDispatch();

    const store = useStore() as AppStore;

    function onFilterTextChange(text: string) {
        dispatch(ProtectedFilesActions.setFilterText(text));
    }

    async function onFilesSelectedForUpload(files: FileList) {
        console.log(files);
        const {accountsState, system} = store.getState();
        for (const file of files) {
            await ProtectedFilesService.uploadFile(file, accountsState.secretKeys, system.defaultAddress, dispatch);
        }
        await ProtectedFilesService.listProtectedFiles(dispatch);
    }

    function onUploadFiles() {
        fileControlRef.current?.openFileDialog();
    }
    
    return (
        <>
            <QuickSearchText onQuickSearchTextChange={onFilterTextChange}
                             searchPrompt="Filter Files"
                             autoFocus
                             fullWidth={false}/>

            <Button variant="contained" color="primary" onClick={onUploadFiles}>Upload</Button>
            <FileUploadControl multiple fileControlRef={fileControlRef} onFilesSelected={onFilesSelectedForUpload}/>
        </>
    );
}
