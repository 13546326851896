import {AppState} from '../../AppState';
import {connect, ConnectedProps} from 'react-redux';
import {EmailActions} from '../../actions/EmailActions';
import React, {FC} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {EmailThreadContentDto} from '../../domain/EmailContentDto';
import "./ArchiveSettingsDialog.scss";
import {DateField} from "../common/DateField";
import {dateTimeFromNullableTicks, ticksFromNullableDateTime, UtcZone} from "../../util/DateHelper";
import {DateTime} from "luxon";

const RenderArchiveSettingsDialog: FC<ArchiveSettingsDialogProps> = ({ emailContent, autoArchiveDate, open, onClose, onSetArchiveDate, onSetAutoArchiveDateOnEmails }) => {

    function handleDateChange() {
        onSetAutoArchiveDateOnEmails([emailContent.Id], autoArchiveDate);
        onClose();
    }

    function setSelectedDate(date: DateTime | null) {
        onSetArchiveDate(ticksFromNullableDateTime(date, UtcZone));
    }

    return (
        <Dialog open={open} onClose={onClose}>

            <DialogTitle id="alert-dialog-title">Auto Archive Settings</DialogTitle>
            <DialogContent className="edit-email-category-dialog">
                
                <div>Select the automatic archival date below:</div>

                <DateField label=" "
                           fullWidth
                           value={dateTimeFromNullableTicks(autoArchiveDate, UtcZone)} 
                           onChange={newDate => setSelectedDate(newDate)}/>
                
                <div>&nbsp;</div>
            </DialogContent>

            <DialogActions className="archive-dialog-buttons">
                <Button onClick={handleDateChange} color="primary" variant="contained">OK</Button>
                <Button onClick={onClose} color="secondary" variant="contained">Cancel</Button>
            </DialogActions>

        </Dialog>
    );
}

const mapState = ({ itemState }: AppState) => ({
    emailContent: itemState.itemContent as EmailThreadContentDto,
    open: itemState.showArchiveSettingsDialog,
    autoArchiveDate: itemState.autoArchiveDate,
});

const mapDispatch = {
    onClose: EmailActions.hideArchiveSettingsDialog,

    onSetArchiveDate: EmailActions.setAutoArchiveDate,

    onSetAutoArchiveDateOnEmails: EmailActions.setAutoArchiveDateOnEmails,
};

const connector = connect(mapState, mapDispatch);

type ArchiveSettingsDialogProps = ConnectedProps<typeof connector>;

const ArchiveSettingsDialog = connector(RenderArchiveSettingsDialog);

export default ArchiveSettingsDialog;
