import Folder, {FolderTypeEnum} from "../domain/Folder";
import {locationManager} from "../BootStrap";
import {LocationProperties} from "../util/LocationParser";
import {Dispatch} from "redux";
import {EmailActions} from "../actions/EmailActions";
import {AppState} from "../AppState";
import {GET_GREYLISTED_EMAILS_ASYNC, SELECT_FOLDER, SelectFolderAction, SET_SEARCH_TEXT, SetSearchTextAction} from "../actions/FolderActionTypes";
import {PhotosFolderId, ProtectedFilesFolderId, SearchFolderId} from '../reducers/FolderReducer';
import {performFetch, performRestCallDirectly} from '../util/HttpHelper';
import {GetGreylistedEmailsRequestDto} from '../services/messages/requests/GetGreylistedEmailsRequestDto';
import {GreylistedEmailResponseDto} from '../services/messages/responses/GreylistedEmailResponseDto';
import {CalendarState} from '../state/CalendarState';
import {CalendarLocation, ensureCalendarsLoaded} from './CalendarLocation';
import {EmailDataSource, EmailDataSourceResult} from "../domain/EmailDataSource";
import {FolderActions} from "../actions/FolderActions";
import {convertEmailHeader} from "../services/messages/EmailHeaderDto";
import NextFolderPageDto from "../services/messages/NextFolderPageDto";
import NextFolderPageResultDto from "../domain/NextFolderPageResultDto";
import {AllActionTypes} from "../actions/AllActionTypes";
import {ContentLocation} from "./ContentLocation";
import {createAppProperties} from "./LocationUtil";
import {DB} from "../db/DbManager";
import {RxSubjects} from "../state/RxSubjectsState";
import {AppDispatch, AppStore} from "../AppStore";
import {AccountsActions} from "../redux/AccountsSlice";
import {NotesState} from "../state/NotesState";
import {NotesLocation} from "./NotesLocation";
import {ProtectedFilesService} from "../services/protected/ProtectedFilesService";
import {PreferenceActions} from "../redux/PreferencesSlice";
import {ensurePgpKeysLoaded} from "../util/PGPUtil";
import {AccountsState} from "../state/AccountState";

export interface FolderLocationProperties {
    folderId?: number;
    searchText?: string;
}

export const FolderLocationPropertyNames = createAppProperties<FolderLocationProperties>("folderId", "searchText");

async function displayAllCalendars(dispatch: Dispatch, calendarState: CalendarState) {
    await ensureCalendarsLoaded(dispatch, calendarState);
    CalendarLocation.setMonthWeekAndYear(calendarState.year, calendarState.month, calendarState.week);
}

async function displayAccounts(dispatch: Dispatch, accountsState: AccountsState) {

    ensurePgpKeysLoaded(dispatch, accountsState)
        .catch(e => console.error("Unable to load all PGP keys", e));

    const activeAccounts = await DB.getAllActiveGateKeeperAccounts();

    dispatch(AccountsActions.accountsLoaded(activeAccounts));
}

function handleError(error: Error, message: string) {
    console.error(message, error);// TODO: handle the errors better
}

function createSetSearchTextAction(searchText: string): SetSearchTextAction {
    return {type: SET_SEARCH_TEXT, searchText};
}

function createSelectFolderAction(folderId: number, virtualDataSource?: EmailDataSource): SelectFolderAction {
    return {type: SELECT_FOLDER, folderId, virtualDataSource};
}

async function displayGreylistedEmailsFolder(dispatch: Dispatch<AllActionTypes>) {
    // TODO: allow paging the greylisted emails
    const request: GetGreylistedEmailsRequestDto = {PageSize: 200, Offset: 0};

    dispatch({type: GET_GREYLISTED_EMAILS_ASYNC, request});

    try {
        const response = await performFetch(`/Mail3/GetGreylistedEmails/${request.Offset}/${request.PageSize}`) as GreylistedEmailResponseDto;
        dispatch({type: GET_GREYLISTED_EMAILS_ASYNC, response});
    } catch (error) {
        dispatch({type: GET_GREYLISTED_EMAILS_ASYNC, error: error as Error});
    }
}

export function selectInboxFolderByDefault(store: AppStore) {
    const {folderState} = store.getState();
    if (!folderState.selectedFolderId) {
        const inboxFolderId = folderState.folderList.find(f => f.type === FolderTypeEnum.Inbox)?.folderId;
        if (inboxFolderId) {
            FolderLocation.selectFolderById(inboxFolderId);
        }
    }
}

let nextPageRequestId = 5000;

async function getNextVirtualFolderResultsPage(folderId: number, offset: number, pageSize: number, dispatch: Dispatch): Promise<void> {
    try {
        nextPageRequestId++;

        const request: NextFolderPageDto = {
            RequestId: nextPageRequestId,
            FolderId: folderId,
            Offset: offset,
            PageSize: pageSize,
        };

        console.debug("Loading next folder page: ", request);
        const newResults = await performRestCallDirectly<NextFolderPageResultDto>("NextFolderPage", request);

        if (newResults.Emails) {
            const pageResult: EmailDataSourceResult = {
                emails: newResults.Emails.map(convertEmailHeader),
                lastEmail: newResults.Emails.length < pageSize,
                offset,
                pageSize,
            };
            dispatch(FolderActions.searchResultsLoaded(pageResult));
            return;
        }

    } catch (e) {
        console.error("Failed to load the next set of rows", e);
        dispatch(FolderActions.performSearchAsync(undefined, undefined, new Error("A problem occurred while fetching the next set of emails")));
    }
}

function displayDefaultNotesSectionAndPage(dispatch: AppDispatch, notesState: NotesState) {
    if (!notesState.selectedPage && notesState.sections && notesState.sections.length > 0) {
        NotesLocation.selectSectionByClientId(notesState.sections[0].ClientId || "");
    }
}

export class FolderLocation {
    static selectFolderById(folderId: number) {
        locationManager.updateWindowLocation("email", {folderId});
    }

    static selectFolder(folder: Folder) {
        locationManager.modifyWindowLocation("email", location => {
            location.folderId = -1;
            delete location.itemId;
            delete location.itemIdStr;
            delete location.itemType;
        });
        locationManager.updateWindowLocation("email", {folderId: folder.folderId});
    }

    static searchForText(searchText: string) {
        if (searchText && searchText.length > 0) {
            locationManager.updateWindowLocation("email", {folderId: SearchFolderId, searchText});
        } else {
            locationManager.modifyWindowLocation("email", location => delete location.searchText);
        }
    }

    static handleLocationChange(dispatch: AppDispatch, getState: () => AppState, newLocation: LocationProperties, oldLocation: LocationProperties) {

        const newFolderId = newLocation.folderId || -999;
        const newSearchText = newLocation.searchText || "";

        if (newLocation.searchText !== oldLocation.searchText) {

            dispatch(createSetSearchTextAction(newSearchText));

            RxSubjects.searchText$.next(newSearchText);

        } else if ((newLocation.folderId !== oldLocation.folderId && newFolderId) || (getState().folderState.selectedFolder === undefined && newFolderId)) {

            dispatch(createSelectFolderAction(newFolderId));

            const {folderState, calendarState, notesState, accountsState} = getState();
            const {selectedFolderId, selectedFolder} = folderState;

            if (!selectedFolderId || !selectedFolder) {
                return;
            }

            const isVirtualSource = DB.isPrivateBrowserMode()
                && selectedFolder.type !== FolderTypeEnum.Calendar
                && selectedFolder.type !== FolderTypeEnum.ProtectedFiles;

            if (isVirtualSource) {
                const dataSource: EmailDataSource = {
                    getNextResultsPage: (offset: number, pageSize: number) => getNextVirtualFolderResultsPage(selectedFolderId, offset, pageSize, dispatch),
                };

                dispatch(createSelectFolderAction(selectedFolderId, dataSource));

                dataSource.getNextResultsPage(0, 30)
                    .catch(e => handleError(e, "Online Folder"));

            } else {
                switch (selectedFolder.type) {
                    case FolderTypeEnum.Notes:
                        displayDefaultNotesSectionAndPage(dispatch, notesState);
                        break;
                    case FolderTypeEnum.Outbox:
                        break;
                    case FolderTypeEnum.Drafts:
                        break;
                    case FolderTypeEnum.Contacts:
                        break;
                    case FolderTypeEnum.Calendar:
                        ContentLocation.clearItemContent();
                        displayAllCalendars(dispatch, calendarState)
                            .catch(e => handleError(e, "Calendars"));
                        break;
                    case FolderTypeEnum.Accounts:
                        displayAccounts(dispatch, accountsState)
                            .catch(e => handleError(e, "Accounts"));
                        break;
                    case FolderTypeEnum.Greylist:
                        displayGreylistedEmailsFolder(dispatch)
                            .catch(e => handleError(e, "Greylisted Emails"));
                        break;
                    case FolderTypeEnum.ProtectedFiles:
                    case FolderTypeEnum.Attachments:
                    case FolderTypeEnum.Files:
                    case FolderTypeEnum.Photos:
                        if (selectedFolderId === ProtectedFilesFolderId) {
                            dispatch(PreferenceActions.changeFileFolder("protected"));
                            ProtectedFilesService.listProtectedFiles(dispatch)
                                .catch(e => handleError(e, "Protected Files"));
                        } else if (selectedFolderId === PhotosFolderId) {
                            dispatch(PreferenceActions.changeFileFolder("photos"));
                        } else {
                            dispatch(PreferenceActions.changeFileFolder("attachments"));
                        }
                        break;
                    default:
                        DB.getEmailHeadersInFolder(selectedFolderId)
                            .then(headers => dispatch(EmailActions.showEmailHeaders(headers)), e => handleError(e, "Email Folder"));
                        break;
                }
            }
        }
    }
}
