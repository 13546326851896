import React, {FC, PropsWithChildren, ReactNode} from "react";
import {ToolbarView} from "../toolbar/ToolbarView";

export interface AppContainerProps {
    contentClassName?: string;
    view?: ReactNode;
}

export const AppContainer: FC<AppContainerProps & PropsWithChildren> = ({
                                                        contentClassName,
                                                        view,
                                                        children,
                                                    }) => (
    <div className="main-container">
        <ToolbarView/>

        {view || (
            <div className={contentClassName || "main-content"}>
                {children}
            </div>
        )}
    </div>
);
