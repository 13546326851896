import "./AccountHistoryView.scss";

import React, {FC} from "react";
import {connect, ConnectedProps} from "react-redux";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {Button, IconButton} from '@mui/material';
import {AppState} from '../../AppState';
import {SmallLoadingIndicator} from '../common/SmallLoadingIndicator';
import {AccountActionHelper} from '../../actions/AccountActionHelper';
import {copyTextToClipboard} from '../../util/ClipboardUtil';
import {Notifier, SystemActions} from '../../actions/SystemActions';
import {dateTimeFromTicks, dateTimeToLocaleString} from "../../util/DateHelper";

const RenderAccountHistoryView: FC<AccountHistoryViewProps> = ({
    accountHistory,
    onHideAccountsHistory, onShowNotification, onShowError,
}) => {

    if (!accountHistory) {
        return <div/>;
    }

    const notifier: Notifier = { showNotification: onShowNotification, showError: onShowError };

    const accountElts = accountHistory.map((account, i) => (
        <div key={i} className="account-version">
            <div className="category">{account.category}</div>

            <div className="account-name">{account.name}</div>

            {account.fields.map((field, j) => (
                <div key={j} className="field">
                    <span className="name">{field.name}</span>
                    <IconButton onClick={() => copyTextToClipboard(field.value, notifier)}><FileCopyIcon /></IconButton>
                    <span className="value">{field.value}</span>
                </div>
            ))}
            <div className="field">
                <span className="name">Modified Time</span>
                &nbsp;
                <span className="value timestamp">{dateTimeToLocaleString(dateTimeFromTicks(account.createTime))}</span>
            </div>
            
            {!account.decrypted ? <div className="decrypting-indicator"><SmallLoadingIndicator /></div> : null}
            {/* <div className="decrypting-indicator"><SmallLoadingIndicator /></div> */}
        </div>
    ));
    return (<>
        <div className="account-item">
            {accountElts}
        </div>
        <div className="edit-buttons">
            <Button onClick={onHideAccountsHistory} variant="contained">Close</Button>
        </div>
    </>);
}

const mapState = ({ accountsState }: AppState) => ({
    accountHistory: accountsState.accountHistory,
});

const mapDispatch = {
    onShowNotification: SystemActions.showNotification,
    onShowError: SystemActions.showError,

    onHideAccountsHistory: AccountActionHelper.hideAccountHistory,
};

const connector = connect(mapState, mapDispatch);

type AccountHistoryViewProps = ConnectedProps<typeof connector>;

const AccountHistoryView = connector(RenderAccountHistoryView);

export default AccountHistoryView;
