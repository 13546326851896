import {
    CATEGORY_CONDITION,
    CategoryCondition,
    EMAIL_BODY_CONDITION,
    EMAIL_FROM_CONDITION,
    EMAIL_FROM_DOMAIN_CONDITION,
    EMAIL_SUBJECT_CONDITION,
    EMAIL_TO_CONDITION,
    EmailBodyCondition,
    EmailRuleConditionTypes,
    EmailSubjectCondition,
    isOrContains
} from "../../services/rules/dto/EmailRuleConditionDto";
import {FC} from "react";
import {TextField} from "@mui/material";
import {SimpleCheckbox} from "../common/SimpleCheckbox";

export type EmailRuleConditionChange = Exclude<EmailRuleConditionTypes, "Type">;

export const RuleItemConditionView: FC<{
    condition: EmailRuleConditionTypes,
    onChange: (change: EmailRuleConditionChange) => void,
}> = ({
          condition,
          onChange,
      }) => {

    function textControls<T extends CategoryCondition | EmailBodyCondition | EmailSubjectCondition>(condition: T, label: string) {
        return <>
            <span>{label}</span>
            <TextField value={condition.Text}
                       onChange={e => onChange({...condition, Text: e.target.value})}/>

            <SimpleCheckbox label={"case sensitive"}
                            checked={condition.MatchCase}
                            onChange={(checked) => onChange({...condition, MatchCase: checked})}/>

            <SimpleCheckbox label={"partial match"}
                            checked={condition.MatchPartial}
                            onChange={(checked) => onChange({...condition, MatchPartial: checked})}/>
        </>
    }

    switch (condition.Type) {
        case EMAIL_TO_CONDITION:
            return <>
                <span>the email is to</span>
                <TextField type="email"
                           value={condition.Address}
                           onChange={e => onChange({...condition, Address: e.target.value})}/>

                <SimpleCheckbox label={"include CC"}
                                checked={!!condition.IncludeCC}
                                onChange={(checked) => onChange({...condition, IncludeCC: checked})}/>
            </>;
        case EMAIL_FROM_CONDITION:
            return <>
                <span>the email is from</span>
                <TextField fullWidth
                           type="email"
                           value={condition.Address}
                           onChange={e => onChange({...condition, Address: e.target.value})}/>
            </>;
        case EMAIL_FROM_DOMAIN_CONDITION:
            return <>
                <span>the email is from the domain</span>
                <TextField fullWidth
                           value={condition.Address}
                           onChange={e => onChange({...condition, Address: e.target.value})}/>
            </>;
        case CATEGORY_CONDITION:
            return <>
                {textControls(condition, "the category " + isOrContains(condition))}
            </>;
        case EMAIL_BODY_CONDITION:
            return <>
                {textControls(condition, "the email body " + isOrContains(condition))}
            </>;
        case EMAIL_SUBJECT_CONDITION:
            return <>
                {textControls(condition, "the subject " + isOrContains(condition))}
            </>;
    }
}
