export function isAndroidApp() {
    return navigator.userAgent === "AndroidApp";
}

interface JavaScriptInterface {
    onLoad(): void;

    handleLogin(): void;

    downloadFile(fileName: string, desc: string, url: string): void;

    emailMarkedAsRead(emailId: number): void;
    emailDeleted(emailId: number): void;
}

declare const Android: JavaScriptInterface;

function runAndroidFunction(func: () => void) {
    if (isAndroidApp()) {
        try {
            func();
        } catch (e) {
            console.log(e);
        }
    }
}

class AndroidApp implements JavaScriptInterface {
    onLoad(): void {
        runAndroidFunction(() => Android.onLoad());
    }

    handleLogin(): void {
        runAndroidFunction(() => Android.handleLogin());
    }

    downloadFile(fileName: string, desc: string, url: string): void {
        runAndroidFunction(() => Android.downloadFile(fileName, desc, url));
    }

    emailMarkedAsRead(emailId: number): void {
        runAndroidFunction(() => Android.emailMarkedAsRead(emailId));
    }

    emailDeleted(emailId: number): void {
        runAndroidFunction(() => Android.emailDeleted(emailId));
    }
}

export const androidApp = new AndroidApp();
