import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {QuickSearchText} from "../common/QuickFilterOption";
import React from "react";
import {PhotosActions} from "../../redux/PhotosSlice";

export const PhotosQuickSearch = () => {
    const dispatch = useAppDispatch();

    const searchText = useAppSelector(s => s.photosState.searchText);

    function onFilterTextChange(text: string) {
        dispatch(PhotosActions.setPhotosSearchText(text));
    }

    return <QuickSearchText key="photos"
                            onQuickSearchTextChange={onFilterTextChange}
                            searchPrompt="Search Photos"
                            fullWidth={false}
                            autoFocus={true}
                            initialSearchText={searchText}/>;
}
