import React, {FC, ReactNode} from "react";

export interface SimpleFieldProps {
    fieldName: string;
    detail: string | null | undefined;
    icon?: ReactNode;
}

export const SimpleField: FC<SimpleFieldProps> = ({fieldName, detail, icon}) => {
    if (!detail || detail.trim().length === 0) return null;
    return (
        <div>
            <span className="field-name">{fieldName}</span>
            <span className="field">{icon}{detail}</span>
        </div>
    );
}