import {AppDispatch} from "../../AppStore";
import {performMail3RestCall} from "../../util/HttpHelper";
import {SystemActions} from "../../actions/SystemActions";
import {AttachmentFileListDto} from "./dto/AttachmentFileListDto";
import {AttachmentFilesActions} from "../../redux/AttachmentFilesSlice";

export class AttachmentFilesService {

    constructor(private dispatch: AppDispatch) {
    }

    async searchAttachmentFiles(offset: number, count: number, searchText: string) {
        try {
            const response = await performMail3RestCall<AttachmentFileListDto>(`AttachmentFiles/List?offset=${offset}&count=${count}&searchText=${encodeURI(searchText)}`, {});

            this.dispatch(AttachmentFilesActions.addAttachmentFiles(response));
            
            return response;
        } catch (e: any) {
            this.dispatch(SystemActions.showFormattedError("Unable to get your attachments", e));
        }
        return undefined;
    }
}
