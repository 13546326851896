import React, {FC, useRef} from "react";
import classNames from "classnames";
import {downloadAttachmentFileFromMemory, MimeParser, MimePart} from "../../parsers/MimeParser";
import {useAppDispatch} from "../../hooks/ReduxHooks";
import {EmailActions} from "../../actions/EmailActions";
import {SystemActions} from "../../actions/SystemActions";
import {FileTypesPath} from "./AttachmentList";
import MessageIcon from "@mui/icons-material/Message";

export interface DecryptedAttachmentListProps {
    attachments: MimePart[];
}

export const DecryptedAttachmentList: FC<DecryptedAttachmentListProps> = ({
                                                                              attachments,
                                                                          }) => {

    const dispatch = useAppDispatch();
    const menuRef = useRef<HTMLDivElement>(null);

    if (attachments.length === 0) {
        return null;
    }
    const showMessageButton = attachments.length > 0;
    const smallList = !attachments.length;
    console.log("Decrypted attachments", attachments);

    const onSelectAttachmentInternal = ((attachment: MimePart | null) => attachment
        ? dispatch(SystemActions.showWarning("Not implemented yet"))
        : dispatch(EmailActions.showEmailContent()));

    const viewEmailSpan = showMessageButton
        ? (
            <span
                className={classNames("selectable",)}
                onClick={() => onSelectAttachmentInternal(null)}
                title="View Email">
                    <MessageIcon style={{fontSize: "32pt"}}/>
                </span>
        ) : null;

    return (
        <div ref={menuRef}
             className={classNames("attachment-list", {"small": smallList}, )}>
            {viewEmailSpan}

            {attachments.map((attachment, i) => <DecryptedAttachmentItem key={i} attachment={attachment}/>)}
        </div>
    );
};

function getAttachmentImageSource(attachment: MimePart): string {
    const contentType = attachment.contentType;
    let type = "unknown";
    if (contentType.startsWith("text/plain") || contentType.startsWith("text/html")) {
        type = "txt";
    } else if (contentType.startsWith("image/jpeg") || contentType.startsWith("image/png")) {
        type = "image";
    }
    // TODO: derive the type based on the content type
    return FileTypesPath + type + ".png";
}

const DecryptedAttachmentItem: FC<{
    attachment: MimePart,
}> = ({
          attachment,
      }) => {
    const name = MimeParser.getAttachmentName(attachment);

    return (
        <span className={classNames("selectable")}
              onClick={() => downloadAttachmentFileFromMemory(attachment, name)}
              title={name}>

            <img src={getAttachmentImageSource(attachment)}
                 alt={name}/>

            {<span>{name}</span>}

        </span>
    );
}
