import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {FileDto} from "../services/protected/dto/FileDto";
import {initialProtectedFilesState} from "../state/ProtectedFilesState";

export const filesSlice = createSlice({
    name: "files",
    initialState: initialProtectedFilesState,
    reducers: {
        setProtectedFilesList(state, {payload: files}: PayloadAction<FileDto[]>) {
            state.files = files;
        },
        setSelectedFile(state, {payload: file}: PayloadAction<FileDto | undefined>) {
            state.selectedFile = file;
        },
        setSelectedFileIncrementally(state, {payload: direction}: PayloadAction<number>) {
            const {files} = state;
            const {selectedFile} = state;
            if (selectedFile) {
                const index = files.findIndex(f => f.Name === selectedFile.Name);
                const newFile = files[index + direction];
                state.selectedFile = newFile || state.selectedFile;
            } else {
                state.selectedFile = direction > 0 ? files[0] : files[files.length + direction];
            }
        },
        setFilterText(state, {payload: text}: PayloadAction<string>) {
            state.filterText = text;
        },
        showConfirmDeleteSelectedFile(state, {payload: show}: PayloadAction<boolean>) {
            if (state.selectedFile || state.showConfirmDeleteSelectedFile) {
                state.showConfirmDeleteSelectedFile = show;
            }
        },
    }
});

export namespace ProtectedFilesActions {
    export const {
        setProtectedFilesList,
        setSelectedFile,
        setSelectedFileIncrementally,
        setFilterText,
        showConfirmDeleteSelectedFile,
    } = filesSlice.actions;

    export const actions = filesSlice.actions;
}
