import {useAppDispatch, useAppSelector, useAppStore} from "../../hooks/ReduxHooks";
import React, {FC, useRef} from "react";
import {PhotoFolderDto} from "../../services/photos/dto/PhotoFolderDto";
import {WrapPanel} from "../panels/WrapPanel";
import styled from "@emotion/styled";
import {css} from "@emotion/css";
import {ContentLocation} from "../../locations/ContentLocation";
import classNames from "classnames";
import {ContextMenu} from "../common/ContextMenu";
import {DropDownMenuItem} from "../toolbar/DropDownMenu";
import {PhotosActions} from "../../redux/PhotosSlice";
import {PhotoBrowserService} from "../../services/photos/PhotoBrowserService";
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoneIcon from '@mui/icons-material/Done';

export const horizontalWrapPanelStyle = css`
  flex-direction: row !important;
  flex-wrap: wrap !important;
  justify-content: initial !important;
  overflow: visible !important;
`;

export const PhotoFolders = () => {
    const folders = useAppSelector(s => s.photosState.folders);

    const menuRef = useRef<HTMLDivElement>(null);

    const dispatch = useAppDispatch();

    const store = useAppStore();

    async function updateExclusions(exclude: boolean, excludeRecursively: boolean) {
        const state = store.getState().photosState;
        if (!state.contextFolder) return;
        
        const breadcrumbs = [...state.breadcrumbs, state.contextFolder];
        await new PhotoBrowserService(dispatch).updateExclusions(breadcrumbs, exclude, excludeRecursively);

        dispatch(PhotosActions.setContextFolder(undefined));
    }

    const contextMenuItems: (DropDownMenuItem | string)[] = [
        {
            icon: <DoneIcon/>,
            name: "Show",
            onClick: async () => {
                await updateExclusions(false, false);
            },
        }, "sep1", {
            icon: <DeleteIcon/>,
            name: "Exclude",
            onClick: async () => {
                await updateExclusions(true, false);
            },
        }, {
            icon: <DeleteForeverIcon/>,
            name: "Exclude (Recursively)",
            onClick: async () => {
                await updateExclusions(false, true);
            },
        }];

    return (
        <>
            <WrapPanel className={horizontalWrapPanelStyle} elementRef={menuRef}>
                {folders.map((folder, i) => <PhotoFolder key={i} folder={folder}/>)}
            </WrapPanel>
            <ContextMenu menuItems={contextMenuItems}
                         elementRef={menuRef}/>
        </>
    );
};

const FolderDiv = styled.div`
  width: 200px;
  padding-left: 5px;

  &.excluded {
    color: hotpink;
  }

  &.excluded-recursive {
    color: red;
  }
`;

const PhotoFolder: FC<{ folder: PhotoFolderDto }> = ({folder}) => {
    const breadcrumbs = useAppSelector(s => s.photosState.breadcrumbs);

    const isSelected = useAppSelector(s => s.photosState.contextFolder === folder.Name);

    const dispatch = useAppDispatch();

    return (
        <FolderDiv className={classNames("selectable", "pointer", {
            "excluded": folder.Excluded,
            "excluded-recursive": folder.ExcludedRecursive,
            "selected": isSelected,
        })}
                   onClick={() => ContentLocation.showPhotoFolder(folder.Breadcrumbs ?? breadcrumbs, folder)}
                   onContextMenu={() => dispatch(PhotosActions.setContextFolder(folder.Name))}>
            <span className="folder-type UserMail"/>
            {folder.Name + (folder.Breadcrumbs?.length ? ` (${folder.Breadcrumbs.join("/")})` : "")}
        </FolderDiv>
    )
}
