import React, {ReactNode} from "react";
import {EmailView} from "../components/email/EmailView";
import {SystemStatusControllerView} from "../components/system/SystemStatusControllerView";
import {PreferencesView} from "../components/prefs/PreferencesView";
import {PgpKeysView} from "../components/pgpkeys/PgpKeysView";
import {UserManagementView} from "../components/users/UserManagementView";
import {AppContainer} from "../components/common/AppContainer";
import {Centre} from "../components/Centre";
import {convertTextToTitleCase} from "../util/Formatters";
import {LoginAuditView} from "../components/login-audit/LoginAuditView";
import {LocationAppType} from "./AppConfig";
import {RulesView} from "../components/rules/RulesView";

const appRenderFunctionMap = new Map<LocationAppType, ReactNode>();

export function getAppView(app: LocationAppType): ReactNode {
    const renderFn = appRenderFunctionMap.get(app);
    if (renderFn) {
        return renderFn;
    }
    return (
        <AppContainer>
            <Centre>{convertTextToTitleCase(app)} view not implemented yet</Centre>
        </AppContainer>
    );
}

appRenderFunctionMap.set("email", <EmailView/>);

appRenderFunctionMap.set("system", <SystemStatusControllerView/>);

appRenderFunctionMap.set("prefs", <PreferencesView/>);

appRenderFunctionMap.set("pgpkeys", <PgpKeysView/>);

appRenderFunctionMap.set("users", <UserManagementView/>);

appRenderFunctionMap.set("login-audit", <LoginAuditView/>);

appRenderFunctionMap.set("rules", <RulesView/>);
