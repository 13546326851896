import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import {startApplication} from "./BootStrap";
import {registerForPushNotifications} from "./NotificationManager";

const rootElt = document.getElementById('root')!;

const root = ReactDOM.createRoot(rootElt);
root.render(<App/>);//<React.StrictMode><App/></React.StrictMode>);

if (rootElt) rootElt.className = "x-react";

registerForPushNotifications();

startApplication();
