import "./LoginAuditView.scss";

import {AppContainer} from "../common/AppContainer";
import {SimpleGrid} from "../common/grid/SimpleGrid";
import {FC, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {AppState} from "../../AppState";
import {LoginAuditDto} from "../../domain/LoginAuditDto";
import {UsersActions} from "../../redux/UsersSlice";
import {LoginAuditService} from "../../services/login-audit/LoginAuditService";
import {RelativeDateTime} from "../common/RelativeDateTime";

export const LoginAuditRow: FC<{ login: LoginAuditDto }> = ({login}) => (
    <>
        <div className="grid-location">{login.Location}</div>
        <div className="grid-time"><RelativeDateTime dateTime={login.LastLoginTime}/></div>
        <div className="grid-user-agent">{login.UserAgent}</div>
    </>
);

export const LoginAuditGrid = () => {
    const dispatch = useAppDispatch();

    const logins = useAppSelector(({usersState}) => usersState.logins);

    const selectedLoginsSelector = ({usersState}: AppState) => usersState.selectedLogins;

    function onGridSelectionUpdate(newSelectedItems: LoginAuditDto[]) {
        dispatch(UsersActions.selectLoginAudits(newSelectedItems));
    }

    function selectAll() {
        dispatch(UsersActions.toggleSelectAllLoginAudits());
    }

    return (
        <SimpleGrid items={logins}
                    selectedItemSelector={selectedLoginsSelector}
                    onUpdateSelection={onGridSelectionUpdate}
                    onSelectAll={selectAll}
                    rowIdExtractor={l => l.Id}
                    rowRenderer={login => <LoginAuditRow login={login}/>}
                    rowClassRules={() => ({})}>
            <div className="grid-location">Location</div>
            <div className="grid-time">Last Login Time</div>
            <div className="grid-user-agent">User Agent</div>
        </SimpleGrid>
    );
};

export const LoginAuditView = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        LoginAuditService.updateLoginAudits(dispatch).catch(console.error);
    }, [dispatch]);

    return (
        <AppContainer contentClassName="main-content login-audit-view">
            <LoginAuditGrid/>
        </AppContainer>
    );
}
