import {UserDto} from "../domain/UserDto";
import {LoginAuditDto} from "../domain/LoginAuditDto";

export interface UsersState {
    users: UserDto[];
    selectedUser?: UserDto;

    logins: LoginAuditDto[];
    selectedLogins: LoginAuditDto[];
}

export const BlankUser: UserDto = {
    WebId: -1,
    MailId: -1,
    WebUsername: "",
    MailUsername: "",
    DisplayName: "",
    NewPassword: undefined,
};

export const initialUsersState: UsersState = {
    users: [],

    logins: [],
    selectedLogins: [],
};
