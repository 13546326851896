import React, {FC} from "react";
import {shallowEqual, useSelector} from "react-redux";
import {AppState} from "../../AppState";
import PasswordDialog from "../common/PasswordDialog";
import {isEmailContent} from "../../domain/ItemContent";
import {handleViewEncryptedEmailContent} from "../../security/EncryptedEmailProcessor";
import {AccountsActions} from "../../redux/AccountsSlice";
import {ItemActions} from "../../actions/ItemActions";
import {ContentLocation} from "../../locations/ContentLocation";
import {useAppDispatch, useAppStore} from "../../hooks/ReduxHooks";
import {isGateKeeperRecordDto} from "../../services/messages/GateKeeperRecordDto";
import {ProtectedFilesService} from "../../services/protected/ProtectedFilesService";

export const EncryptionPasswordDialog: FC = () => {

    const {keyDescription, passwordError, passwordRequired, passwordRequiredForItem} =
        useSelector(({accountsState}: AppState) => ({
            keyDescription: accountsState.keyDescription,
            passwordError: accountsState.passwordError,
            passwordRequired: accountsState.passwordRequired,
            passwordRequiredForItem: accountsState.passwordRequiredForItem,
        }), shallowEqual);

    const dispatch = useAppDispatch();
    const store = useAppStore();

    const message = isEmailContent(passwordRequiredForItem)
        ? "Please enter the password for the decrypting this email"
        : `Please enter the password for the decrypting "${passwordRequiredForItem.Name}"`;

    async function onEnterPassword(password: string) {
        dispatch(AccountsActions.setAccountsPassword(password));

        if (isEmailContent(passwordRequiredForItem)) {
            await handleViewEncryptedEmailContent(passwordRequiredForItem, dispatch);
        } else if (isGateKeeperRecordDto(passwordRequiredForItem)) {
            ContentLocation.clearItemContent();
            ContentLocation.showAccount(passwordRequiredForItem.Id, passwordRequiredForItem.ClientId);
        } else {
            const {accountsState} = store.getState();
            await ProtectedFilesService.downloadFile(passwordRequiredForItem, accountsState.secretKeys, accountsState.password, dispatch);
        }
    }

    function onCancelPasswordDialog() {
        dispatch(ItemActions.cancelPasswordEntry());
    }

    return (
        <PasswordDialog
            title="Password Required"
            message={message}
            details={`Key: ${keyDescription}`}
            errorMessage={passwordError}
            open={passwordRequired}
            onCancel={onCancelPasswordDialog}
            onEnterPassword={onEnterPassword}
        />
    );
};