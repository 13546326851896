import React from "react";
import {Box, Chip, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {UIMap} from "../../util/UIMap";

export interface SimpleSelectProps<T extends string | number | undefined> {
    options: UIMap<T>[];
    value: T;
    onChange: (newValue: T) => void;

    className?: string;

    disableUnderline?: boolean;
    displayEmpty?: boolean;
}

export const SimpleSelect = <T extends string | number | undefined>({
                                                                        options,
                                                                        value,
                                                                        onChange,

                                                                        className,

                                                                        disableUnderline,
                                                                        displayEmpty,
                                                                    }: SimpleSelectProps<T>) => {
    return (
        <Select className={className ?? "control"}
                value={value}
                onChange={e => onChange(e.target.value as T)}
                disableUnderline={disableUnderline}
                displayEmpty={displayEmpty}>
            {options.map(({value, title}) => <MenuItem key={value} value={value}>{title}</MenuItem>)}
        </Select>
    );
}

export interface SimpleMultiSelectProps<T extends string | number> {
    options: UIMap<T>[];
    label?: string;
    value: T[];
    onChange: (newValue: T[]) => void;
    startAdornment?: React.ReactNode;
}

export const SimpleMultiSelect = <T extends string | number>({
                                                                 options,
                                                                 label,
                                                                 value,
                                                                 onChange,
                                                                 startAdornment,
                                                             }: SimpleMultiSelectProps<T>) => {
    const select = (
        <Select className="control"
                multiple
                value={value}
                startAdornment={startAdornment}
                renderValue={(selected) => (
                    <Box style={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                        {(selected as T[]).map((value: T) => (
                            <Chip key={value} label={options.find(o => o.value === value)?.title ?? value}/>
                        ))}
                    </Box>
                )}
                onChange={e => onChange(e.target.value as T[])}>
            {options.map(({value, title}) => <MenuItem key={value} value={value}>{title}</MenuItem>)}
        </Select>
    );

    if (label) {
        return (
            <FormControl>
                <InputLabel>{label}</InputLabel>
                {select}
            </FormControl>
        );
    }
    return select;
}