export interface PhysicalAddressDto {
    Type: string;
    Street: string | null;
    City: string | null;
    State: string | null;
    Country: string | null;
    Postcode: string | null;
}

export const blankAddress: PhysicalAddressDto = {
    Type: "",
    Street: "",
    City: "",
    State: "",
    Country: "",
    Postcode: "",
}