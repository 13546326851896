import {LogFileDto, LogLineDto} from "../services/system/dto/SystemLogResponseDto";

export interface SystemLogState {
    logs: string[];
    logFiles: LogFileDto[];
    lines: LogLineDto[];

    selectedLog?: string;
    selectedLogFile?: string;
}

export const initialSystemLogState: SystemLogState = {
    logs: [],
    logFiles: [],
    lines: [],
};
