import {DateTime, Info, Zone} from 'luxon';
import {DayOfWeekDisplayNames, DayOfWeekFlagValues, DaysOfWeekFlags,} from "../domain/DayOfWeekName";
import {UIMap} from "./UIMap";
import {createDateTimeFromZone, createDateTimeInZone} from "../domain/TimezoneDto";
import {RelativeDateTimeOption} from "../domain/RelativeDateTimeOption";

const TicksOffset = 62135596800000;

export const LocalZone = DateTime.local().zone;
export const UtcZone = DateTime.utc().zone;

export interface MonthName {
    id: number;
    name: string;
}

export const MonthDisplayNames: UIMap<number>[] = Info.months().map((name, i) => ({value: i + 1, title: name}));

export function getMonthName(month: number): string | undefined {
    return MonthDisplayNames.find(m => m.value === month)?.title;
}

export function dateTimeToLocaleString(dateTime: DateTime | null): string {
    return dateTime?.toLocaleString(DateTime.DATETIME_SHORT).replace(", ", " ") ?? "";
}

export function formatDayOfMonth(dayOfMonth: number): string {
    let suffix = "th";
    if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
        suffix = "st";
    }
    if (dayOfMonth === 2 || dayOfMonth === 22) {
        suffix = "nd";
    }
    if (dayOfMonth === 3 || dayOfMonth === 23) {
        suffix = "rd";
    }
    return dayOfMonth + suffix;
}

export function formatDaysOfWeek(daysOfWeek: DaysOfWeekFlags | null): string {
    if (!daysOfWeek) return "";
    let result = "";
    for (const flag of DayOfWeekFlagValues) {
        if ((daysOfWeek & flag) === flag) {
            if (result.length > 0) {
                result += ", ";
            }
            result += DayOfWeekDisplayNames.find(d => d.value === flag)!.title;
        }
    }
    return result;
}

export function pluralise(value: number, suffix: string, alwaysIncludeNumber?: boolean) {
    if (value === 1) {
        if (alwaysIncludeNumber) {
            return `${value} ${suffix}`;
        }
        return suffix;
    }
    return `${value} ${suffix}s`;
}

export function plural(value: number, suffix: string) {
    return value === 1 ? suffix : suffix + "s";
}

export function formatDateTimeAsFriendlyStringVsDate(dateTime: DateTime, now: DateTime): string {
    const diff = now.diff(dateTime, ["months", "minutes"]);
    const diffMins = diff.minutes;
    if (diff.months < 1) {
        if (diffMins < 0) {
            const aDiffMins = -diffMins;
            if (aDiffMins < 1) return "Shortly";

            if (aDiffMins < 60) return `in ${pluralise(Math.floor(aDiffMins), "min", true)}`;

            if (now.day === dateTime.day) return "Today at " + dateTime.toFormat("HH:mm");

            if (now.weekNumber === dateTime.weekNumber) return `${dateTime.weekdayShort} at ${dateTime.toFormat("HH:mm")}`;  // e.g. Fri 12:30

            return dateTime.toFormat("dd/MM/yyyy");
        }
        if (diffMins < 1) return "Just now";

        if (diffMins < 60) return `${pluralise(Math.floor(diffMins), "min", true)} ago`;

        if (now.day === dateTime.day) return "Today at " + dateTime.toFormat("HH:mm");

        if (now.weekNumber === dateTime.weekNumber) return `${dateTime.weekdayShort} at ${dateTime.toFormat("HH:mm")}`;  // e.g. Fri 12:30
    }
    if (dateTime.year === now.year) {
        return dateTime.toFormat("dd MMM");
    }
    return dateTime.toFormat("dd/MM/yyyy");
}

export function formatDateTimeAsFriendlyString(dateTime: DateTime | null): string {
    if (!dateTime) return "";
    return formatDateTimeAsFriendlyStringVsDate(dateTime, DateTime.local());
}

export function dateTimeFromTicks(ticks: number): DateTime {
    return DateTime.fromMillis(ticks - TicksOffset);
}

export function ticksFromDateTime(dateTime: DateTime): number {
    return dateTime.valueOf() + TicksOffset;
}

export function dateTimeFromNullableTicks(ticks: number | null, zoneOfTicks: Zone): DateTime | null {
    if (!ticks) {
        return null;
    }
    return createDateTimeInZone(dateTimeFromTicks(ticks), zoneOfTicks);
}

export function ticksFromNullableDateTime(dateTime: DateTime | null, zone: Zone): number | null {
    if (!dateTime) {
        return null;
    }
    return createDateTimeFromZone(dateTime, zone).valueOf() + TicksOffset;
}

export function getDayOfWeekFromDateTime(dateTime: DateTime): DaysOfWeekFlags {
    // TODO: add validation here
    return DayOfWeekFlagValues[dateTime.weekday - 1];
}

export function calculateWeekOfMonth(dateTime: DateTime): DaysOfWeekFlags {
    let weekOfMonth = 0;
    let currentDate = dateTime;
    do {
        weekOfMonth++;
        currentDate = currentDate.minus({days: 7});
    } while (currentDate.month === dateTime.month);

    return weekOfMonth;
}

export function calculateDateFromRelativeDateOption(option: RelativeDateTimeOption): number | null {
    if (!option) return null;

    if (option === "Tomorrow") {
        return ticksFromDateTime(DateTime.now().startOf("day").plus({days: 1}));
    }
    if (option === "OneWeek") {
        return ticksFromDateTime(DateTime.now().startOf("day").plus({days: 7}));
    }
    if (option === "OneMonth") {
        return ticksFromDateTime(DateTime.now().startOf("day").plus({months: 1}));
    }
    return option;
}

export interface DateRangeCategory {
    name: string;
    date: DateTime;
}

function generateDateRangeCategories(referenceDate: DateTime): DateRangeCategory[] {
    const results: DateRangeCategory[] = [];

    const today = referenceDate.startOf("day");

    results.push({name: "Today", date: today});
    results.push({name: "This Week", date: referenceDate.startOf("week")});
    results.push({name: "Last Week", date: referenceDate.startOf("week").minus({days: 7})});
    results.push({name: "Last 3 Weeks", date: referenceDate.minus({weeks: 3})});
    results.push({name: "This Year", date: referenceDate.startOf("year")});
    results.push({name: "Last Year", date: referenceDate.startOf("year").minus({years: 1})});

    return results;
}

let dateRangeCategories: DateRangeCategory[] = [];

export function updateDateRangeCategories(referenceDate: DateTime) {
    dateRangeCategories = generateDateRangeCategories(referenceDate);
}

export function calculateDateRangeCategory(dateTicks: number): string {
    const date = dateTimeFromTicks(dateTicks);

    for (const category of dateRangeCategories) {
        if (date >= category.date) {
            return category.name;
        }
    }
    return date.toFormat("yyyy");
}