import React, { FC } from "react"
import "./LoadingIndicator.scss";

export interface SmallLoadingIndicatorProps {
    className?: string;
}

export const SmallLoadingIndicator: FC<SmallLoadingIndicatorProps> = ({ className }) => {
    return (
        <div className={`round-spin round-spin-small ${className}`}>
        </div>);
}
