import {ContactDto} from '../services/messages/ContactDto';

export interface ContactState {
    contacts: ContactDto[];
    contactLookupMap: Map<string, ContactDto>;
}

export const initialContactState: ContactState = {
    contacts: [],
    contactLookupMap: new Map<string, ContactDto>(),
};
