import {FC} from "react";
import React from "react";
import {Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from "@mui/material";
import {css} from "@emotion/css";
import {OverridableStringUnion} from "@mui/types";
import {ButtonPropsColorOverrides} from "@mui/material/Button/Button";

export interface ConfirmDialogProps {
    title: string;
    message: string;
    open: boolean;
    confirmColour?: OverridableStringUnion<
        'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
        ButtonPropsColorOverrides
    >;
    onConfirm: (confirmed: boolean) => void;
}

const ConfirmDialogClass = css`
    .MuiDialogContent-root {
        overflow: visible;
    }
`;

const ConfirmDialog: FC<ConfirmDialogProps> = ({title, message, open, onConfirm, confirmColour}) => {
    return (
        <Dialog
            open={open}
            className={ConfirmDialogClass}
            onClose={(_, reason) => reason === "backdropClick" ? false : onConfirm(false)}
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onConfirm(true)} color={confirmColour} variant="contained">OK</Button>
                <Button onClick={() => onConfirm(false)} color="inherit" autoFocus>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog;
