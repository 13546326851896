import {GateKeeperFieldType, GateKeeperField} from '../../domain/GateKeeperField';
import {TextFieldProps} from '@mui/material';
import {isValidCvv, isValidCardNumber, isValidExpiryDate, formatCreditCardNumber} from '../../util/CreditCardUtil';
import React from 'react';
import styled from "@emotion/styled";

interface FieldOption {
    type: GateKeeperFieldType;
    name: string;
}

export const FieldTypeOptions: FieldOption[] = [
    {type: "Unknown", name: "Field Type"},
    {type: "CardNumber", name: "Card Number"},
    {type: "CardExpiry", name: "Card Expiry Date"},
    {type: "CVV", name: "Card CVV"},
    {type: "Date", name: "Date"},
    {type: "Email", name: "Email Address"},
    {type: "LoginScript", name: "Login Script"},
    {type: "MemorableWord", name: "Memorable Information"},
    {type: "Number", name: "Number"},
    {type: "Password", name: "Password"},
    {type: "PIN", name: "PIN"},
    {type: "PhoneNumber", name: "Phone Number"},
    {type: "Text", name: "Text"},
    {type: "Username", name: "Username"},
    {type: "URL", name: "URL"},
];

const CharSpan = styled.span`
    display: inline-flex;
    height: 40px;
    flex-direction: column;
    span {
        display: inline-block;
        width: 23px;
        text-align: center;
    }
`;

export const InvalidCVVError = {error: true, helperText: "The CVV must be 3 or 4 numbers"};
export const InvalidCardError = {error: true, helperText: "Invalid card number"};
export const InvalidCardExpiryError = {error: true, helperText: "Please enter a valid expiry date in the format DD/YY"};
export const InvalidNumberError = {error: true, helperText: "Please enter a valid number"};
export const InvalidEmailError = {error: true, helperText: "Please enter a valid email address"};

export function validateAccountField(field: GateKeeperField): TextFieldProps {
    switch (field.type) {
        case "CVV":
            if (!isValidCvv(field.value)) {
                return InvalidCVVError;
            }
            break;
        case "CardNumber":
            if (!isValidCardNumber(field.value)) {
                return InvalidCardError;
            }
            break;
        case "CardExpiry":
            if (!isValidExpiryDate(field.value)) {
                return InvalidCardExpiryError;
            }
            break;
        case "Number":
            if (!/^\s*[-0-9.]+\s*$/.test(field.value)) {
                return InvalidNumberError;
            }
            break;
        case "Email":
            if (!field.value.includes("@")) {
                return InvalidEmailError;
            }
            break;
    }
    return {};
}

export function formatAccountField(type: GateKeeperFieldType, value: string) {
    switch (type) {
        case "CardNumber":
            return formatCreditCardNumber(value);
        case "URL":
            return (<button className="link" onClick={() => window.open(value, "_blank", "noopener noreferrer popup")}>{value}</button>);
        case "MemorableWord":
            return (
                <>
                    {[...value].map((c, i) => (<CharSpan key={i}><span>{(i + 1)}</span><span>{c}</span></CharSpan>))}
                </>
            )
    }
    return value;
}

export function isSensitiveFieldType(type: GateKeeperFieldType) {
    return type === "CVV" || type === "Password" || type === "CardNumber" || type === "PIN" || type === "MemorableWord";
}

export function getAccountFieldType(type: GateKeeperFieldType) {
    switch (type) {
        case "Number":
        case "CVV":
        case "CardNumber":
        case "PIN":
            return "number";
        case "PhoneNumber":
            return "tel";
        case "Email":
            return "email";
        case "URL":
            return "url";
    }
    return "text";
}