import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import styled from "@emotion/styled";
import {LazyLoadingImage} from "../common/LazyLoadingImage";
import {PhotoDto} from "../../services/photos/dto/PhotoDto";
import {ContentLocation} from "../../locations/ContentLocation";
import classNames from "classnames";
import React from "react";
import {PhotosActions} from "../../redux/PhotosSlice";

const PhotoDiv = styled.div`
  display: flex;
  height: 104px;
  width: 104px;
  justify-content: center;
  align-items: center;
  padding: 2px;

  img {
    object-fit: scale-down;
    border-radius: 5px;
    //box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  }
`;

export const PhotosList = () => {
    const photos = useAppSelector(s => s.photosState.photos);
    const breadcrumbs = useAppSelector(s => s.photosState.breadcrumbs);
    const selectedPhotoName = useAppSelector(s => s.photosState.selectedPhotoName);
    const contextPhotoName = useAppSelector(s => s.photosState.contextPhoto?.Name);

    const dispatch = useAppDispatch();
    
    function selectPhoto(photo: PhotoDto) {
        ContentLocation.showPhoto(breadcrumbs, photo);
    }

    return (
        <>
            {photos.map((photo, i) => (
                <PhotoDiv key={i}
                          className={classNames("selectable pointer", {"selected": photo.Name === selectedPhotoName || photo.Name === contextPhotoName})} 
                          onClick={() => selectPhoto(photo)}
                          onContextMenu={() => dispatch(PhotosActions.setContextPhoto(photo))}>
                    <LazyLoadingImage src={"/Mail/DownloadPhotoThumbnail?name=" + photo.Thumbnail}
                                      alt={photo.Name}/>
                </PhotoDiv>
            ))}
        </>
    );
}
