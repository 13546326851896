import React, {FC} from "react";
import {AppSpecificToolbar} from "./AppSpecificToolbar";
import {AppNavToolbar} from "./AppNavToolbar";
import UserAvatar from "../profile/UserAvatar";

export const DesktopToolbar: FC = () => {
    return (
        <>
            <AppSpecificToolbar/>
            <AppNavToolbar/>
            <UserAvatar/>
        </>
    );
}