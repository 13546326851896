import {dtoFromRecurrence, Recurrence, RecurrenceDto, recurrenceFromDto} from './RecurrenceDto';
import {BaseCalendarEntry, BaseCalendarEntryDto} from './BaseCalendarEntryDto';
import {dtoFromRecurrenceException, RecurrenceException, RecurrenceExceptionDto, recurrenceExceptionFromDto} from './RecurrenceExceptionDto';
import {dateTimeFromNullableTicks, ticksFromNullableDateTime} from '../util/DateHelper';
import {IANAZone, Info} from "luxon";
import {ClientEntityDto} from "../services/messages/ClientEntityDto";

export interface CalendarDto extends BaseCalendarEntryDto, ClientEntityDto {
    Uid: string;

    TimezoneName: string | null;

    IsHtml: boolean;

    OrganizerName: string | null;
    OrganizerEmail: string | null;

    Recurrences: RecurrenceDto[];

    Exceptions: RecurrenceExceptionDto[];

    ResponseRequested: boolean;

    DisallowNewTimeProposal: boolean;
}

export interface Calendar extends BaseCalendarEntry, ClientEntityDto {
    Uid: string;

    TimezoneName: string;
    Timezone: IANAZone;

    IsHtml: boolean;

    OrganizerName: string | null;
    OrganizerEmail: string | null;

    Recurrences: Recurrence[];

    Exceptions: RecurrenceException[];

    ResponseRequested: boolean;

    DisallowNewTimeProposal: boolean;
}

export function calendarFromDto(dto: CalendarDto): Calendar {
    const originalTimeZoneName = dto.TimezoneName || "Europe/London";
    const validTimeZoneName = Info.isValidIANAZone(originalTimeZoneName)
        ? originalTimeZoneName
        : "Europe/London";

    let zone = IANAZone.create(validTimeZoneName);

    return {
        ...dto,

        CreateDateTime: dateTimeFromNullableTicks(dto.CreateDateTime, zone),
        StartDateTime: dateTimeFromNullableTicks(dto.StartDateTime, zone),
        EndDateTime: dateTimeFromNullableTicks(dto.EndDateTime, zone),
        AppointmentReplyDateTime: dateTimeFromNullableTicks(dto.AppointmentReplyDateTime, zone),

        Timezone: zone,
        TimezoneName: validTimeZoneName,

        Recurrences: dto.Recurrences.filter(r => r.Type !== null && r.Type !== undefined).map(r => recurrenceFromDto(r, zone)),
        Exceptions: dto.Exceptions.map(re => recurrenceExceptionFromDto(re, zone)),
    };
}

export function dtoFromCalendar(entity: Calendar): CalendarDto {
    const StartDateTime = ticksFromNullableDateTime(entity.StartDateTime, entity.Timezone);

    const result: CalendarDto = {
        ...entity,

        CreateDateTime: ticksFromNullableDateTime(entity.CreateDateTime, entity.Timezone),
        StartDateTime,
        EndDateTime: ticksFromNullableDateTime(entity.EndDateTime, entity.Timezone),
        AppointmentReplyDateTime: ticksFromNullableDateTime(entity.AppointmentReplyDateTime, entity.Timezone),

        Recurrences: entity.Recurrences.map(r => dtoFromRecurrence(r, entity.Timezone)),
        Exceptions: entity.Exceptions.map(re => dtoFromRecurrenceException(re, entity.Timezone)),
    };
    delete (result as any).Timezone;
    return result;
}