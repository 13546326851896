import {Base64} from "js-base64";
import {androidApp, isAndroidApp} from "./DeviceUtil";

export function isImageFile(fileName: string) {
    const lowerName = fileName.toLowerCase();
    return lowerName.endsWith(".png")
        || lowerName.endsWith(".jpg")
        || lowerName.endsWith(".jpeg")
        || lowerName.endsWith(".gif")
        || lowerName.endsWith(".bmp")
        || lowerName.endsWith(".tiff");
}

export function isImageContentType(contentType: string | undefined) {
    return contentType?.startsWith("image/");
}

export function downloadFile(url: string, name: string | undefined) {

    if (isAndroidApp()) {
        const fileName = url.substring(url.lastIndexOf('/') + 1);
        androidApp.downloadFile(name ?? fileName, "Download File", url);
        // window.open(url, "_blank");
        // const a: HTMLAnchorElement = document.createElement('A') as HTMLAnchorElement;
        // a.href = url;
        // a.download = url.substring(url.lastIndexOf('/') + 1);
        // document.body.appendChild(a);
        // a.click();
        // document.body.removeChild(a);
    } else {
        const iframe = document.getElementById("downloadFrame") as HTMLIFrameElement;
        
        iframe.src = "about:blank";
        setTimeout(() => {
            iframe.src = url
        }, 0);
    }
}

export function downloadFileFromMemory(fileName: string, data: string) {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
    element.setAttribute('download', fileName);

    element.style.display = 'none';
    document.body.appendChild(element);

    try {
        element.click();
    } finally {
        document.body.removeChild(element);
    }
}

export function downloadBinaryFileFromMemory(fileName: string, data: Uint8Array) {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:application/octet-stream;base64,' + encodeURIComponent(Base64.fromUint8Array(data)));
    element.setAttribute('download', fileName);

    element.style.display = 'none';
    document.body.appendChild(element);

    try {
        element.click();
    } catch (e: any) {
        console.error("Unable to download file", e);
    } finally {
        document.body.removeChild(element);
    }
}