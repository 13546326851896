import {ContextMenu} from "../common/ContextMenu";
import {TextFieldDialog} from "../common/TextFieldDialog";
import React, {FC, RefObject, useState} from "react";
import {ProtectedFilesService} from "../../services/protected/ProtectedFilesService";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import ConfirmDialog from "../common/ConfirmDialog";
import {ProtectedFilesActions} from "../../redux/ProtectedFilesSlice";

export const ProtectedFileContextMenu: FC<{
    menuElementRef: RefObject<HTMLElement>
}> = ({
          menuElementRef,
      }) => {

    const dispatch = useAppDispatch();

    const [showRenameDialog, setShowRenameDialog] = useState<boolean>(false);
    const selectedFile = useAppSelector(({filesState}) => filesState.selectedFile);
    const showConfirmDeleteSelectedFile = useAppSelector(({filesState}) => filesState.showConfirmDeleteSelectedFile);

    async function onRenameFile(newName: string | null) {
        setShowRenameDialog(false);

        if (newName && selectedFile) {
            await ProtectedFilesService.renameFile(selectedFile.Name, newName, dispatch);
        }
    }

    async function onDeleteFile(confirm: boolean) {
        dispatch(ProtectedFilesActions.showConfirmDeleteSelectedFile(false));
        if (confirm && selectedFile) {
            await ProtectedFilesService.deleteFile(selectedFile.Name, dispatch);
        }
    }

    const contextMenuItems = selectedFile ? [
            {icon: <EditIcon/>, name: "Rename", onClick: () => setShowRenameDialog(true)},
            "div1",
            {icon: <DeleteIcon/>, name: "Delete", onClick: () => dispatch(ProtectedFilesActions.showConfirmDeleteSelectedFile(true))},
        ]
        : [];

    return (
        <>
            <ContextMenu menuItems={contextMenuItems} elementRef={menuElementRef}/>

            <TextFieldDialog title="Rename file" message={`Enter the new name for ${selectedFile?.Name}`}
                             initialValue={selectedFile?.Name || ""}
                             open={showRenameDialog}
                             onClose={onRenameFile}/>

            <ConfirmDialog title="Delete File"
                           message={`Are you sure you want to the protected file '${selectedFile?.Name}'?`}
                           open={showConfirmDeleteSelectedFile}
                           onConfirm={onDeleteFile}
                           confirmColour="error"/>
        </>
    );
}
