import React, {FC, useState} from "react";
import {TEXTFIELD_DATE_FORMAT} from "../../util/Formatters";
import {TextField} from "@mui/material";
import {DateTime} from "luxon";
import {StackPanel} from "../panels/StackPanel";
import {isAndroidApp} from "../../util/DeviceUtil";

const TEXTFIELD_TIME_FORMAT = "HH:mm";

interface DateFieldProps {
    label: string;
    value: DateTime | null;
    onChange: (newValue: DateTime | null) => void;

    fullWidth?: boolean;
    showTime?: boolean;
    hideDate?: boolean;
}

export const DateField: FC<DateFieldProps> = ({
                                                  label,
                                                  value,
                                                  onChange,
                                                  fullWidth,
                                                  showTime,
                                                  hideDate,
                                              }) => {
    const [tempDate, setTempDate] = useState<string>(value?.toFormat(TEXTFIELD_DATE_FORMAT) || "");
    const [tempTime, setTempTime] = useState<string>(value?.toFormat(TEXTFIELD_TIME_FORMAT) || "");

    const [validDate, setValidDate] = useState<boolean>(true);
    const [validTime, setValidTime] = useState<boolean>(true);

    function handleDateTextChange(newValue: string | undefined) {
        try {
            const newDate = newValue ? DateTime.fromFormat(newValue, TEXTFIELD_DATE_FORMAT) : null;
            if (!value) {
                onChange(newDate);
            } else if (newDate) {
                onChange(value.set({year: newDate.year, month: newDate.month, day: newDate.day}));
            } else {
                onChange(null);
            }
            setValidDate(true);
        } catch (e) {
            setValidDate(false);
        }
        setTempDate(newValue ?? "");
    }

    function handleTimeTextChange(newValue: string | undefined) {
        try {
            const newTime = newValue ? DateTime.fromFormat(newValue, TEXTFIELD_TIME_FORMAT) : null;

            if (!value) {
                onChange(newTime);
            } else if (newTime) {
                onChange(value.set({hour: newTime.hour, minute: newTime.minute}));
            } else {
                onChange(null);
            }
            setValidTime(true);
        } catch (e) {
            setValidTime(false);
        }
        setTempTime(newValue ?? "");
    }

    const dateElt = hideDate
        ? null
        : (<TextField error={!validDate}
                      label={label}
                      value={tempDate}
                      type={isAndroidApp() ? "text" : "date"}
                      fullWidth={fullWidth && !showTime}
                      onChange={(e) => handleDateTextChange(e.target.value)}
                      InputLabelProps={{shrink: true}}
        />);

    if (showTime) {
        return (
            <StackPanel>
                {dateElt}
                <TextField error={!validTime}
                           label={hideDate ? label : " "}
                           value={tempTime}
                           type={isAndroidApp() ? "text" : "time"}
                           onChange={(e) => handleTimeTextChange(e.target.value)}
                           InputLabelProps={{shrink: true}}
                />
            </StackPanel>
        );
    }
    return dateElt;
}
