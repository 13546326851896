import {createAppProperties} from "./LocationUtil";
import {AppState} from "../AppState";
import {LocationProperties} from "../util/LocationParser";
import {locationManager} from "../BootStrap";
import {SystemActions} from "../actions/SystemActions";
import {DB} from "../db/DbManager";
import {AppDispatch} from "../AppStore";
import {NotesActions} from "../redux/NotesSlice";

export interface NotesLocationProperties {
    nsCId?: string;
    npCId?: string;
}

export const NotesLocationPropertyNames = createAppProperties<NotesLocationProperties>("nsCId", "npCId");

export class NotesLocation {
    static selectSectionByClientId(sectionClientId: string) {
        locationManager.updateWindowLocation("email", {nsCId: sectionClientId});
    }

    static selectPageByClientId(pageClientId: string) {
        locationManager.updateWindowLocation("email", {npCId: pageClientId});
    }

    static handleLocationChange(dispatch: AppDispatch, getState: () => AppState, newLocation: LocationProperties, oldLocation: LocationProperties) {
        const notesState = getState().notesState;

        if (oldLocation.nsCId !== newLocation.nsCId || notesState.selectedSectionClientId !== newLocation.nsCId) {

            const section = notesState.sections.find(s => s.ClientId === newLocation.nsCId);
                        
            if (section) {
                DB.getNoteSectionPages(section.ClientId)
                    .then(
                        pages => dispatch(NotesActions.selectNoteSection({sectionClientId: newLocation.nsCId, pageClientId: newLocation.npCId, pages})),
                        error => dispatch(SystemActions.showFormattedError("Unable to load the note pages for this section", error)),
                    );
            }
        }

        if (oldLocation.npCId !== newLocation.npCId || notesState.selectedPageClientId !== newLocation.npCId) {
            dispatch(NotesActions.selectNotePage(newLocation.npCId));
            dispatch(SystemActions.showFolderMenu(false));
        }
    }
}
