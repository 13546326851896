export interface ConditionWithMatch {
    MatchCase: boolean;
    MatchPartial: boolean;
    Text: string;
}

interface ConditionWithAddress {
    Address: string;
}

export interface ConditionWithRegex {
    IsRegex: boolean;
}

export const CATEGORY_CONDITION = "CategoryCondition";
export const EMAIL_FROM_CONDITION = "EmailFromCondition";
export const EMAIL_FROM_DOMAIN_CONDITION = "EmailFromDomainCondition";
export const EMAIL_TO_CONDITION = "EmailToCondition";
export const EMAIL_SUBJECT_CONDITION = "SubjectCondition";
export const EMAIL_BODY_CONDITION = "EmailBodyCondition";

export interface CategoryCondition extends ConditionWithMatch {
    Type: typeof CATEGORY_CONDITION;
}

export interface EmailFromCondition extends ConditionWithAddress {
    Type: typeof EMAIL_FROM_CONDITION;
}

export interface EmailFromDomainCondition extends ConditionWithAddress {
    Type: typeof EMAIL_FROM_DOMAIN_CONDITION;
}

export interface EmailToCondition extends ConditionWithAddress {
    Type: typeof EMAIL_TO_CONDITION;
    IncludeCC: boolean | undefined;
}

export interface EmailSubjectCondition extends ConditionWithMatch, ConditionWithRegex {
    Type: typeof EMAIL_SUBJECT_CONDITION;
}

export interface EmailBodyCondition extends ConditionWithMatch, ConditionWithRegex {
    Type: typeof EMAIL_BODY_CONDITION;
}

export type EmailRuleConditionTypes =
    CategoryCondition
    | EmailFromCondition
    | EmailFromDomainCondition
    | EmailToCondition
    | EmailSubjectCondition
    | EmailBodyCondition;

export type EmailRuleConditionTypeValues = EmailRuleConditionTypes["Type"];

export const isOrContains = (condition: ConditionWithMatch) => (condition.MatchPartial ? "contains" : "is");
