import React, {ErrorInfo, PropsWithChildren} from "react";
import {Centre} from "../Centre";

interface ErrorBoundaryState {
    error?: any;
}

export class ErrorBoundary extends React.Component<PropsWithChildren, ErrorBoundaryState> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    static getDerivedStateFromError(error: Error) {    // Update state so the next render will show the fallback UI.    
        return {hasError: true, error};
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {    // You can also log the error to an error reporting service    
        // logErrorToMyService(error, errorInfo);
    }

    render() {
        if (this.state.error) {
            const error = this.state.error;

            // TODO: show the error nicely
            return (<Centre className="error">
                Unexpected Application Error<br/>
                {error.message}
                {/*Error Message: <b>{errorMessage}</b>*/}
            </Centre>);
        } else {
            return this.props.children;
        }
    }
}
