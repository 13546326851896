import {AppState} from "../../AppState";
import {FolderTypeEnum} from "../../domain/Folder";

export namespace FolderSelectors {
    export const selectInboxFolderId = ({folderState}: AppState) => folderState.folderList.find(f => f.type === FolderTypeEnum.Inbox)?.folderId;

    export const selectCalendarFolderId = ({folderState}: AppState) => folderState.folderList.find(f => f.type === FolderTypeEnum.Calendar)?.folderId;

    export const selectContactsFolderId = ({folderState}: AppState) => folderState.folderList.find(f => f.type === FolderTypeEnum.Contacts)?.folderId;
}
