import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {AttachmentFilesActions} from "../../redux/AttachmentFilesSlice";
import {QuickSearchText} from "../common/QuickFilterOption";
import React from "react";

export const AttachmentsQuickSearch = () => {
    const dispatch = useAppDispatch();

    const searchText = useAppSelector(s => s.attachmentsState.searchText);

    function onFilterTextChange(text: string) {
        dispatch(AttachmentFilesActions.setAttachmentsSearchText(text));
    }

    return <QuickSearchText key="attachments"
                            onQuickSearchTextChange={onFilterTextChange}
                            searchPrompt="Search Attachments"
                            fullWidth={false}
                            autoFocus={true}
                            initialSearchText={searchText}/>;
}
