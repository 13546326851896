import React, {FC, PropsWithChildren} from 'react';
import {Button} from '@mui/material';

import "./EditButtons.scss";

export interface EditButtonsProps {
    editing?: boolean;
    onSetEditing: (editing: boolean) => void;
    canSave?: boolean;
    disabled?: boolean;
    onDelete?: () => void;
    onSave?: () => void;
}

export const EditButtons: FC<EditButtonsProps & PropsWithChildren> = ({
                                                                          children,
                                                                          editing, canSave, disabled,
                                                                          onSetEditing, onDelete, onSave
                                                                      }) => {

    if ((editing ?? onSave !== undefined) && !disabled && onSave) {
        return (
            <div className="edit-buttons">
                <Button onClick={onSave} disabled={!(canSave ?? true)} variant="contained" color="primary">Save</Button>
                <Button onClick={() => onSetEditing(false)} variant="contained" color="inherit">Cancel</Button>
            </div>
        );
    }

    return (
        <div className="edit-buttons">
            <Button onClick={() => onSetEditing(true)} variant="contained" color="primary" disabled={disabled}>Edit</Button>
            {children}
            <Button onClick={onDelete} variant="contained" color="secondary" disabled={disabled}>Delete</Button>
        </div>
    );
};
