import "./SystemLogView.scss";

import React, {FC} from "react";
import {SystemLogControlView} from "./controls/SystemLogControlView";
import {SystemLogFileView} from "./controls/SystemLogFileView";

export const SystemLogView: FC = () => {
    return (
        <div className="system-logs">
            <SystemLogControlView/>
            <SystemLogFileView/>
        </div>
    );
};