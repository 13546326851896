import {EmailContentDto} from "../../../domain/EmailContentDto";
import React, {FC, ReactNode} from "react";
import classNames from "classnames";
import HttpsIcon from "@mui/icons-material/Https";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import {dateTimeFromTicks, formatDateTimeAsFriendlyString, plural} from "../../../util/DateHelper";
import {EmailActions} from "../../../actions/EmailActions";
import HistoryIcon from "@mui/icons-material/History";
import {VerbIcon} from "../VerbIcon";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import {RuleAppliedInfo} from "./RuleAppliedInfo";
import {Expander} from "../../common/Expander";
import {useAppDispatch} from "../../../hooks/ReduxHooks";
import {formatAsPercent, getCountryCodeEmoji} from "../../../util/Formatters";
import {ContentSafetyResult} from "../../../util/SafetyTransformer";
import FlagIcon from "@mui/icons-material/Flag";
import NotificationsPausedIcon from "@mui/icons-material/NotificationsPaused";
import OrderTrackingIcon from '@mui/icons-material/LocalShipping';
import PushPinIcon from "@mui/icons-material/PushPin";

export const EmailContentInfo: FC<{
    emailContent: EmailContentDto,
    safetyResult: ContentSafetyResult,
    applySafety: boolean,
    applyJunkSafety: boolean,

    isSignedBySender: boolean,
    mismatchedSignature: boolean,

    onSetNoSafety: () => void,
}> = ({
          emailContent,
          safetyResult,
          applySafety,
          applyJunkSafety,

          isSignedBySender,
          mismatchedSignature,

          onSetNoSafety,
      }) => {

    const dispatch = useAppDispatch();

    const warningElements = [];
    let warningIndex = 0;

    function addBannerElement(title: string, icon: ReactNode, className?: string, content?: ReactNode) {
        warningElements.push((
            <div key={warningIndex}
                 className={classNames("banner", `banner-${warningIndex++}`, className)}
                 title={title}>
                {icon}
                <span className="link">{content || title}</span>
            </div>
        ));
    }

    if (emailContent.Flagged) {
        addBannerElement("This email has been flagged", <FlagIcon className="header"/>, "flagged");
    }
    if (emailContent.Pinned) {
        addBannerElement("This email has been pinned", <PushPinIcon className="header"/>, "pinned");
    }
    if (emailContent.IsEncrypted) {
        addBannerElement("This email is encrypted", <HttpsIcon className="header"/>, "encrypted");
    }
    if (isSignedBySender) {
        addBannerElement("This email was digitally signed", <CheckCircleIcon
            className="header"/>, "signature-verified");
    }
    if (mismatchedSignature) {
        addBannerElement("The digital signature couldn't verified", <ErrorIcon
            className="header"/>, "signature-invalid");
    }
    if (emailContent.ThreadId?.startsWith("ORDER-TRACKING-") || emailContent.ThreadId?.startsWith("PARCEL-TRACKING-")) {
        addBannerElement("This order is being tracked", <OrderTrackingIcon className="header"/>);
    }

    if (safetyResult.safetyApplied && applySafety) {
        const contents = <button className="link" onClick={onSetNoSafety}>Click here to show images - they have been
            hidden for enhanced
            security</button>;
        addBannerElement("Images and external content have been hidden",
            <WarningIcon className="header pointer selectable" onClick={onSetNoSafety}/>, "safety-applied", contents);
    }
    if (safetyResult.trackerDetected) {
        addBannerElement("One or more tracking elements were blocked for enhanced privacy",
            <TrackChangesIcon className="header"/>, "tracker-detected");
    }
    if (applyJunkSafety) {
        addBannerElement("This email is in the junk email folder - only plain text is displayed",
            <TrackChangesIcon className="header"/>, "junk-safety-applied");
    }
    if (emailContent.PgpErrors && emailContent.PgpErrors.length > 0) {
        addBannerElement(emailContent.PgpErrors.join("\r\n"),
            <ErrorIcon className="header"/>, "pgp-error");
    }
    if (emailContent.Location && emailContent.Location.Code) {
        const flagEmoji = getCountryCodeEmoji(emailContent.Location.Code);
        if (flagEmoji) {
            addBannerElement(`Sent from ${emailContent.Location.Name}`,
                <div className="header flag"
                     dangerouslySetInnerHTML={{__html: flagEmoji}}></div>, "email-location");
        }
    }
    if (emailContent.Reminder) {
        const reminderDate = dateTimeFromTicks(emailContent.Reminder.ReminderTime);
        const formattedDate = reminderDate.toFormat("dd/MM/yyyy");
        const action = () => dispatch(EmailActions.showRemindMeLaterSettingsDialog(true));
        const contents = (
            <span>
            You'll get a reminder for this email on
            <button className="link" onClick={action}>&nbsp;{formattedDate}</button>
        </span>);
        addBannerElement(`You'll be reminded on ${formattedDate}`,
            <NotificationsPausedIcon className="header pointer selectable"
                                     onClick={action}/>, "auto-archive", contents);
    }
    if (emailContent.AutoArchiveDateTime) {
        const archiveDate = dateTimeFromTicks(emailContent.AutoArchiveDateTime);
        const formattedDate = archiveDate.toFormat("dd/MM/yyyy");
        const action = () => dispatch(EmailActions.openArchiveSettingsDialog());
        const contents = (
            <span>
            The email will be auto-archived on
            <button className="link" onClick={action}>&nbsp;{formattedDate}</button>
        </span>);
        addBannerElement(`The email will be auto-archived on ${formattedDate}`,
            <HistoryIcon className="header pointer selectable" onClick={action}/>, "auto-archive", contents);
    }
    if (emailContent.Responses && emailContent.Responses.length > 0) {
        const responses = emailContent.Responses
            .map(r => `You ${r.Type} this email ${formatDateTimeAsFriendlyString(dateTimeFromTicks(r.Date))}`)
            .join("; ");
        addBannerElement(responses, <VerbIcon verb={emailContent.Responses[0].Type}
                                              className="header"/>, "auto-archive");
    }
    if (emailContent.ShowingTextVersion) {
        addBannerElement("Plain text version displayed", <TextFieldsIcon className="header"/>);
    }
    const rules = emailContent.RulesApplied?.Rules;
    if (rules && rules.length > 0) {
        addBannerElement(plural(rules.length, "Rule") + " applied: " + rules.map(r => r.Name).join(", "),
            <AccountTreeIcon className="header"/>, undefined, <RuleAppliedInfo rules={rules}/>);
    }

    function onChangeCategory() {
        dispatch(EmailActions.showEmailCategoryDialog(emailContent.Category || ""));
    }

    warningElements.push((
        <div key={warningIndex}
             className={classNames("banner", `banner-${warningIndex++}`)}
        >
            <div className={classNames("email-category", "category-" + emailContent.Category, "selectable", "header")}
                 onClick={onChangeCategory}
            >
                {emailContent.Category}
            </div>
        </div>
    ));

    // This must come last as it has a variable size!
    if (typeof emailContent.AISpamProbability !== "undefined") {
        let aiLegitProbability = 1 - emailContent.AISpamProbability;
        if (aiLegitProbability >= 0.99) aiLegitProbability = 0.99;

        const probability = formatAsPercent(aiLegitProbability);
        warningElements.push((
            <div key={warningIndex}
                 className={classNames("banner", `banner-${warningIndex++}`, "ai-probability", {"ai-agrees": emailContent.AIAgrees})}
                 title={`${probability} chance of being legitimate `}>
                <span className="header">{probability}</span>
                <span className="link">&nbsp;chance of being legitimate</span>
            </div>
        ));
    }

    return warningElements.length === 1
        ? (<>{warningElements[0]}<div style={{height: "5px"}}></div></>)
        : (
            warningIndex > 0
                ? (<Expander>{warningElements}</Expander>)
                : <div style={{height: "5px"}}></div>
        );
}
