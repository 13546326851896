import {Checkbox} from "@mui/material";
import React from "react";
import {useAppSelector} from "../../../hooks/ReduxHooks";
import {AppState} from "../../../AppState";

interface SimpleGridTitleProps<TItem> {
    itemCount: number;

    selectedItemSelector: (state: AppState) => TItem[];

    onSelectAll?: (selected: boolean) => void;

    children: React.ReactNode[];
}

export function SimpleGridTitle<TItem>({
                                           itemCount,
                                           selectedItemSelector,
                                           onSelectAll,
                                           children
                                       }: SimpleGridTitleProps<TItem>) {

    const selectedItemsCount = useAppSelector(state => selectedItemSelector(state).length);

    return (
        <div className="email-grid-header">
            <div className="header-checkbox">
                {onSelectAll ?
                    <Checkbox
                        checked={selectedItemsCount === itemCount && selectedItemsCount > 0}
                        indeterminate={selectedItemsCount > 0 && selectedItemsCount !== itemCount}
                        onChange={e => onSelectAll && onSelectAll(e.target.checked)}
                        color="primary"
                        title={selectedItemsCount !== itemCount ? "Select All" : "Deselect All"}
                    />
                    : <span>&nbsp;</span>
                }
            </div>
            {children}
        </div>
    );
}
