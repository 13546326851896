import {ContactDto} from "../services/messages/ContactDto";

export const SHOW_CONTACT = "SHOW_CONTACT";

export interface ShowContactAction {
    type: typeof SHOW_CONTACT;
    contact?: ContactDto;
}

export type ContactActionTypes =
    ShowContactAction
    ;
