import React, {FC} from "react";
import {useSelector} from "react-redux";
import {AppState} from "../../AppState";
import FolderTreeView from "./FolderTreeView";
import {NoteSectionSidebar} from "../notes/NoteSectionSidebar";
import {NotesFolderId} from "../../reducers/FolderReducer";

export const SidebarView: FC = () => {
    const selectedFolderId = useSelector(({folderState}: AppState) => folderState.selectedFolderId);

    if (selectedFolderId === NotesFolderId) {
        return <NoteSectionSidebar/>;
    }
    
    return <FolderTreeView/>;
};