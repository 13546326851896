import { FC } from "react";
import React from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";

interface ErrorDialogProps {
    title: string;
    message: string;
    open: boolean;
    onClose: () => void;
}

const ErrorDialog: FC<ErrorDialogProps> = ({ title, message, open, onClose }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" autoFocus>OK</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ErrorDialog;
