import {DateTime} from "luxon";
import {SystemStatusViewType} from "../locations/SystemStatusLocation";
import {SystemStatusResponseDto} from "../services/system/SystemStatusResponseDto";

export interface SystemStatusState {
    view: SystemStatusViewType;
    lastUpdateTime?: DateTime;
    status: SystemStatusResponseDto;
}

const initialSystemStatus: SystemStatusResponseDto = {
    Services: [],
    Certificates: [],
    Properties: {},
    Drives: [],
};

export const initialSystemStatusState: SystemStatusState = {
    view: "status",
    status: initialSystemStatus,
}
