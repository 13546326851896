import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {WrapPanel} from "../panels/WrapPanel";
import {ProtectedFileItemView} from "./ProtectedFileItemView";
import {Centre} from "../Centre";
import {useRef} from "react";
import {ProtectedFileContextMenu} from "./ProtectedFileContextMenu";
import {HotKeys} from "react-hotkeys";
import {ProtectedFilesActions} from "../../redux/ProtectedFilesSlice";

export const ProtectedFileListView = () => {

    const dispatch = useAppDispatch();

    const filterText = useAppSelector(({filesState}) => filesState.filterText);
    const files = useAppSelector(({filesState}) => filesState.files);

    const menuElementRef = useRef<HTMLDivElement>(null);

    const lowerFilterText = filterText.toLowerCase();

    const filteredFiles = filterText
        ? files.filter(f => f.Name.toLowerCase().includes(lowerFilterText))
        : files;

    const keyHandlers = {
        DELETE: () => dispatch(ProtectedFilesActions.showConfirmDeleteSelectedFile(true)),
        UP: () => dispatch(ProtectedFilesActions.setSelectedFileIncrementally(-1)),
        DOWN: () => dispatch(ProtectedFilesActions.setSelectedFileIncrementally(1)),
    };
    return (
        <>
            <HotKeys handlers={keyHandlers} className="files-list" innerRef={menuElementRef}>
                <WrapPanel>
                    {filteredFiles.map((file, i) => (
                        <ProtectedFileItemView key={i + "/" + file.Name}
                                               file={file}/>
                    ))}
                    {filteredFiles.length === 0 ? <Centre>{files.length === 0 ? "No files here yet" : "No files match your filter"}</Centre> : null}

                </WrapPanel>
            </HotKeys>
            <ProtectedFileContextMenu menuElementRef={menuElementRef}/>
        </>
    );
};
