import React from "react";
import {useSelector} from "react-redux";
import "./ToolbarView.scss";
import {AppState} from "../../AppState";
import {systemStatusService} from "../../BootStrap";
import {ToolbarButton} from "./ToolbarTypes";
import {SystemStatusLocation} from "../../locations/SystemStatusLocation";

import RefreshIcon from '@mui/icons-material/Refresh';
import BuildIcon from '@mui/icons-material/Build';
import DescriptionIcon from '@mui/icons-material/Description';

export const SystemStatusToolbar = () => {

    const view = useSelector(({sysStatus}: AppState) => sysStatus.view);

    return (
        <>
            <ToolbarButton title="System Status" onClick={() => SystemStatusLocation.switchView("status")}
                           icon={<BuildIcon/>} toggled={view === "status"}/>

            <ToolbarButton title="Logs" onClick={() => SystemStatusLocation.switchView("logs")}
                           icon={<DescriptionIcon/>} toggled={view === "logs"}/>

            <ToolbarButton title="Refresh" onClick={async () => await systemStatusService.refreshState()}
                           icon={<RefreshIcon/>}/>
        </>
    );
};
