import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {initialSystemLogState, SystemLogState} from "../state/SystemLogState";
import {SystemLogResponseDto} from "../services/system/dto/SystemLogResponseDto";

export const systemLogSlice = createSlice({
    name: "systemLog",
    initialState: initialSystemLogState,
    reducers: {
        response: handleSystemLogResponse,

        selectLog(state, {payload}: PayloadAction<string>) {
            state.selectedLog = payload;
        },
        selectLogFile(state, {payload}: PayloadAction<string>) {
            state.selectedLogFile = payload;
        },
    }
});

function handleSystemLogResponse(state: SystemLogState, {payload: response}: PayloadAction<SystemLogResponseDto>) {
    if (response.Lines) {
        if (response.ClearLines) {
            state.lines = response.Lines;
        } else {
            state.lines.push(...response.Lines);
        }
    }
    if (response.LogFiles) {
        state.logFiles = response.LogFiles;
    }
    if (response.Logs) {
        state.logs = response.Logs;
    }
}

export namespace SystemLogActions {
    export const {
        response, selectLog, selectLogFile
    } = systemLogSlice.actions;

    export const actions = systemLogSlice.actions;
}

// For backwards compatibility...

export const SYSTEM_LOG_RESPONSE = "systemLog/response";
export interface SystemLogResponseAction {
    type: typeof SYSTEM_LOG_RESPONSE;
    payload: SystemLogResponseDto;
}
