import React, {CSSProperties, FC, PropsWithChildren} from "react";
import "./Centre.css";
import classNames from "classnames";

interface CentreProps {
    style?: CSSProperties;
    className?: string;
}

export const Centre: FC<CentreProps & PropsWithChildren> = (props) => (
    <div className={classNames("centre", props.className)} style={props.style}>
        {props.children}
    </div >
);
