import {AppStore} from "../../AppStore";
import {performFetch} from "../../util/HttpHelper";
import {SystemStatusResponseDto} from "./SystemStatusResponseDto";
import {SystemStatusActions} from "../../actions/SystemStatusActions";
import {SystemActions} from "../../actions/SystemActions";
import {registerVisibilityChangeHandler} from "../../BootStrap";
import {LocationAppType} from "../../apps/AppConfig";

const RootUrl: string = "/SystemStatus/";

export class SystemStatusService {
    private store: AppStore;

    private lastApp: LocationAppType | null = null;

    constructor(store: AppStore) {
        this.store = store;

        store.subscribe(() => this.handleStateChanges().catch(console.error));

        registerVisibilityChangeHandler(visible => visible ? this.refreshState() : null);
    }

    private async handleStateChanges() {
        try {
            const lastApp = this.lastApp;
            const {app} = this.store.getState().system;

            this.lastApp = app;

            if (app !== lastApp && app === "system") {
                await this.refreshState();
            }

        } catch (e) {
            console.error("Error in System Status Service: ", e);
        }
    }

    async refreshState() {
        try {
            if (this.lastApp !== "system") {
                return;
            }
            const newStatus = await performFetch(RootUrl + "Status") as SystemStatusResponseDto;

            this.store.dispatch(SystemStatusActions.updateSystemStatus(newStatus));

        } catch (e) {
            console.error("Unable to get system status", e);
            this.store.dispatch(SystemActions.showError("Unable to get system status"));
        }
    }
}
