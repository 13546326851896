import RemoteOperationDto from "../services/messages/RemoteOperationDto";

export interface SyncState {
    serverId: number;
    startSyncId: number;
    endSyncId: number;
    operations: RemoteOperationDto[];
    syncInProgress: boolean;
}

export const initialSyncState: SyncState = {
    serverId: 1,
    startSyncId: -1,
    endSyncId: 0,
    operations: [],
    syncInProgress: false,
};
