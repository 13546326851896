import {EmailToolbar} from "./EmailToolbar";
import React from "react";
import {SystemStatusToolbar} from "./SystemStatusToolbar";
import {LoginAuditToolbar} from "../login-audit/LoginAuditToolbar";
import {EmailRuleToolbar} from "./EmailRuleToolbar";
import {useAppSelector} from "../../hooks/ReduxHooks";

export const AppSpecificToolbar = () => {
    const app = useAppSelector(({system}) => system.app);

    switch (app) {
        case "email":
            return <EmailToolbar/>;
        case "system":
            return <SystemStatusToolbar/>;
        case "login-audit":
            return <LoginAuditToolbar/>;
        case "rules":
            return <EmailRuleToolbar/>;
        default:
            return null;
    }
};
