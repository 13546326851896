import "./PreferencesView.scss";

import React, {FC, useState} from "react";
import {Button, Card, CardContent, CardHeader} from "@mui/material";
import {SimpleCheckbox} from "../common/SimpleCheckbox";
import {PreferPlainTextEmailsPref} from "../../util/Preferences";
import {EmailAliasPreferences} from "./EmailAliasPreferences";
import {SecurityPreferences} from "./SecurityPreferences";
import {AppContainer} from "../common/AppContainer";
import {Centre} from "../Centre";
import {useAppDispatch} from "../../hooks/ReduxHooks";
import {SyncActions} from "../../redux/SyncSlice";
import {SystemActions} from "../../actions/SystemActions";
import {locationManager} from "../../BootStrap";
import {ImageWhitelistPreferences} from "./ImageWhitelistPreferences";

export const PreferencesView: FC = () => {
    const [plainText, setPlainText] = useState(PreferPlainTextEmailsPref.get());

    const dispatch = useAppDispatch();

    function setPlainTextPref(checked: boolean) {
        PreferPlainTextEmailsPref.set(checked);
        setPlainText(checked);
    }

    function handleForceResync() {
        dispatch(SyncActions.setSyncState({startSyncId: -1, endSyncId: 0}));
        dispatch(SystemActions.showNotification("Full resync has started..."));
        locationManager.switchApp("email");
    }

    return (
        <AppContainer contentClassName="preferences-container">
            <Card className="preferences-section">
                <CardHeader title="Email Viewing Options"/>
                <CardContent>
                    <SimpleCheckbox
                        label="Show plain text email content when available"
                        checked={plainText}
                        onChange={setPlainTextPref}/>
                </CardContent>
            </Card>

            <Card className="preferences-section">
                <CardHeader title="Sync Options"/>
                <CardContent>
                    <Centre>
                        <div>If content seems out of date, force a resync of all data by clicking below</div>
                        <div>This might take some time to complete</div>
                        <div>&nbsp;</div>
                        <Button variant="contained" color="secondary" onClick={() => handleForceResync()}>Force Resync</Button>
                    </Centre>
                </CardContent>
            </Card>

            <SecurityPreferences/>

            <EmailAliasPreferences/>

            <ImageWhitelistPreferences/>
        </AppContainer>
    );
}
