import {AppDispatch} from "../../AppStore";
import {SystemActions} from "../../actions/SystemActions";
import {performFetch, performMail3RestCall, sendFormData} from "../../util/HttpHelper";
import {FileListDto} from "./dto/FileListDto";
import {ProtectedFilesActions} from "../../redux/ProtectedFilesSlice";
import {
    decryptSecretKeys,
    findDecryptionKeys,
    getKeyDescriptions,
    pgpDecrypt,
    pgpEncryptBinary,
    pgpReadBinaryMessage,
    SecretKey
} from "../../util/PGPUtil";
import {AccountsActions} from "../../redux/AccountsSlice";
import {FileDto} from "./dto/FileDto";
import {PgpKey} from "../../domain/PgpKey";
import {downloadBinaryFileFromMemory} from "../../util/FileUtil";
import {findGatekeeperSecretKey} from "../../util/GateKeeperXmlParser";
import {Message} from "openpgp";

function showPasswordDialog(file: FileDto, message: string, secretKeys: SecretKey[], dispatch: AppDispatch) {
    dispatch(AccountsActions.itemNeedsPassword({
        record: file,
        errorMessage: message,
        keyName: getKeyDescriptions(secretKeys),
    }));
}

export class ProtectedFilesService {
    static async listProtectedFiles(dispatch: AppDispatch) {
        try {
            const fileList = await performMail3RestCall<FileListDto>("ProtectedFiles/List", {});

            dispatch(ProtectedFilesActions.setProtectedFilesList(fileList.Files));
        } catch (e: any) {
            dispatch(SystemActions.showFormattedError("Unable to get your protected files", e));
        }
    }

    static async renameFile(oldName: string, newName: string, dispatch: AppDispatch) {
        try {
            await performMail3RestCall<FileDto>(`ProtectedFiles/Rename/${encodeURIComponent(oldName)}/${encodeURIComponent(newName)}`, {});

            dispatch(SystemActions.showNotification(`File renamed to ${newName}`));

            await ProtectedFilesService.listProtectedFiles(dispatch);
        } catch (e: any) {
            dispatch(SystemActions.showFormattedError("Unable to rename your file", e));
        }
    }

    static async deleteFile(fileName: string, dispatch: AppDispatch) {
        try {
            await performMail3RestCall<boolean>(`ProtectedFiles/Delete/${encodeURIComponent(fileName)}`, {});

            dispatch(SystemActions.showNotification(`File deleted: ${fileName}`));

            await ProtectedFilesService.listProtectedFiles(dispatch);
        } catch (e: any) {
            dispatch(SystemActions.showFormattedError("Unable to delete file", e));
        }
    }

    static async uploadFile(decryptedFile: File, secretKeys: PgpKey[], defaultAddress: string, dispatch: AppDispatch) {
        try {
            const secretKey = findGatekeeperSecretKey(secretKeys, [defaultAddress])[0];

            const message = Message.fromBinary(new Uint8Array(await decryptedFile.arrayBuffer()));
            const encryptedFileData = await pgpEncryptBinary(message, secretKey);
            const encryptedFile = new File([new DataView(encryptedFileData.buffer)], decryptedFile.name, {type: decryptedFile.type});

            const formData = new FormData();
            formData.append("file", encryptedFile);

            await sendFormData("/Mail3/ProtectedFiles/Upload", formData);

            dispatch(SystemActions.showNotification(`File encrypted and uploaded: ${decryptedFile.name}`));

        } catch (e: any) {
            dispatch(SystemActions.showFormattedError("Unable to encrypt and upload file", e));
        }
    }

    static async downloadFile(file: FileDto, secretKeys: PgpKey[], password: string, dispatch: AppDispatch) {
        if (password.length === 0) {
            showPasswordDialog(file, "", secretKeys.map(k => k.key), dispatch);
            return;
        }

        try {
            const encryptedData = await performFetch(`/Mail3/ProtectedFiles/Download/${encodeURIComponent(file.Name)}`);

            const result = await ProtectedFilesService.decryptFile(file, encryptedData, secretKeys, password, dispatch);

            if (result) {
                downloadBinaryFileFromMemory(file.Name, result);
            }
        } catch (e: any) {
            dispatch(SystemActions.showFormattedError("Unable to download file", e));
        }
    }

    static async decryptFile(file: FileDto, encryptedData: Blob, secretKeys: PgpKey[], password: string, dispatch: AppDispatch) {
        let selectedSecretKeys = secretKeys.map(k => k.key);
        try {
            const message = await pgpReadBinaryMessage(new Uint8Array(await encryptedData.arrayBuffer()));

            selectedSecretKeys = findDecryptionKeys(message, secretKeys);

            console.log("Keys:", selectedSecretKeys);

            const privateKeys = await decryptSecretKeys(selectedSecretKeys, password);

            const {data} = await pgpDecrypt(message, privateKeys, undefined, "binary");

            return data as Uint8Array;
        } catch (e: any) {
            // TODO: cache the result if the password validation fails
            showPasswordDialog(file, e.message, selectedSecretKeys, dispatch);
        }
    }
}
