import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {EmailRuleDto} from "../../services/rules/dto/EmailRuleDto";
import {StackPanel} from "../panels/StackPanel";
import {RelativeDateTime} from "../common/RelativeDateTime";
import {
    CATEGORY_CONDITION,
    EMAIL_BODY_CONDITION,
    EMAIL_FROM_CONDITION,
    EMAIL_FROM_DOMAIN_CONDITION,
    EMAIL_SUBJECT_CONDITION,
    EMAIL_TO_CONDITION,
    EmailRuleConditionTypes,
    isOrContains
} from "../../services/rules/dto/EmailRuleConditionDto";
import {ContentLocation} from "../../locations/ContentLocation";
import classNames from "classnames";
import {isEmailRuleContent} from "../../domain/ItemContent";
import {
    ASSIGN_CATEGORY_ACTION,
    AUTO_ARCHIVE_ACTION,
    EmailRuleActionTypes,
    FLAG_EMAIL_ACTION,
    FORWARD_EMAIL_ACTION,
    MARK_AS_READ_ACTION,
    MOVE_TO_FOLDER_ACTION,
    SET_EMAIL_IMPORTANCE_ACTION
} from "../../services/rules/dto/EmailRuleActionDto";
import {FC, Fragment, ReactNode} from "react";
import {Button} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {RulesService} from "../../services/rules/RulesService";

function getConditionDescription(condition: EmailRuleConditionTypes, key: number): ReactNode {
    switch (condition.Type) {
        case CATEGORY_CONDITION:
            return <span key={key}> category {isOrContains(condition)} <span
                className="param">{condition.Text}</span></span>;

        case EMAIL_FROM_CONDITION:
            return <span key={key}> from <span className="param">{condition.Address}</span></span>;

        case EMAIL_FROM_DOMAIN_CONDITION:
            return <span key={key}> from <span className="param">{condition.Address}</span></span>;

        case EMAIL_TO_CONDITION:
            return <span key={key}> to <span className="param">{condition.Address}</span></span>;

        case EMAIL_BODY_CONDITION:
            return <span key={key}> the body {isOrContains(condition)} <span
                className="param">{condition.Text}</span></span>

        case EMAIL_SUBJECT_CONDITION:
            return <span key={key}> subject {isOrContains(condition)} <span
                className="param">{condition.Text}</span></span>
    }
}

const MoveToFolderActionDescription: FC<{ folderId: number }> = ({folderId}) => {
    const folderName = useAppSelector(({folderState}) => folderState.folderList.find(f => f.folderId === folderId)?.name);

    return <span>move to <span className="param">{folderName}</span></span>;
}

function getActionDescription(action: EmailRuleActionTypes): ReactNode {
    switch (action.Type) {
        case MOVE_TO_FOLDER_ACTION:
            return <MoveToFolderActionDescription folderId={action.FolderId}/>;

        case MARK_AS_READ_ACTION:
            return <span>mark as read</span>;

        case FORWARD_EMAIL_ACTION:
            return <span>forward to <span className="param">{action.ForwardingAddress}</span></span>;

        case FLAG_EMAIL_ACTION:
            return <span>flag the email</span>;

        case ASSIGN_CATEGORY_ACTION:
            return <span>categorise as <span className="param">{action.Category}</span></span>

        case SET_EMAIL_IMPORTANCE_ACTION:
            return <span>set as <span className="param">{action.Importance}</span> importance</span>

        case AUTO_ARCHIVE_ACTION:
            return <span>archive after <span className="param">{action.AutoArchiveDays}</span> days</span>
    }
}

function getRuleConditionsDescription(rule: EmailRuleDto): ReactNode[] {
    const orAnd = rule.OrConditions ? " or " : " and ";
    let results: ReactNode[] = [<span key={"a"}>If</span>];
    let i = 0;
    for (const condition of rule.Conditions) {
        if (i++ > 0) {
            results.push(<span key={"j" + i}>{orAnd}</span>);
        }

        results.push(getConditionDescription(condition, i));
    }
    return results;
}

function getRuleActionsDescription(rule: EmailRuleDto): ReactNode {
    return <>
        <span>Then </span>
        {rule.Actions.map((action, i) => (
            <Fragment key={i}>
                {i > 0 ? <span> and </span> : null}
                {getActionDescription(action)}
            </Fragment>
        ))}
    </>;
}

export const RulesListView = () => {
    const dispatch = useAppDispatch();

    const rules = useAppSelector(({rulesState}) => rulesState.rules);

    const selectedRuleId = useAppSelector(({itemState}) => isEmailRuleContent(itemState.itemContent) && itemState.itemContent.Id);

    function renderRule(rule: EmailRuleDto, index: number) {
        function onSelectRule() {
            ContentLocation.showEmailRule(rule.Id);
        }

        async function changeRuleRank(delta: number) {
            if (index + delta < 0 || index + delta > rules.length - 1) return;

            const rule1 = {...rules[index + delta]};
            const rule2 = {...rule};
            rule2.Rank = rule1.Rank;
            rule1.Rank = rule.Rank;

            await RulesService.saveRuleRanks(rule1, rule2, dispatch);
        }

        async function onRankUp() {
            return changeRuleRank(-1);
        }

        async function onRankDown() {
            return changeRuleRank(1);
        }

        return (
            <div key={rule.Id}
                 className={classNames("item", "selectable", {"selected": selectedRuleId === rule.Id})}
                 onClick={onSelectRule}>

                <StackPanel orientation="vertical" className="description">
                    <div className="title">{rule.Name}</div>
                    <div className="conditions">{getRuleConditionsDescription(rule)}</div>
                    <div className="actions">{getRuleActionsDescription(rule)}</div>
                </StackPanel>

                <StackPanel orientation="vertical" className="rank">
                    <Button onClick={onRankUp}><KeyboardArrowUpIcon/></Button>
                    <Button onClick={onRankDown}><KeyboardArrowDownIcon/></Button>
                </StackPanel>

                <StackPanel orientation="vertical" className="last-used">
                    <span>Last used</span>
                    <RelativeDateTime dateTime={rule.LastUsedDateTime} fallbackText="Never used"/>
                </StackPanel>
            </div>
        );
    }

    return (
        <div className="rules-list">
            {rules.map(renderRule)}
        </div>
    );
}
