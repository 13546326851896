import {performFetch, performMail3RestCall} from "../../util/HttpHelper";
import {DomainValidationStatus} from "../../components/compose/DomainValidationStatus";
import {ValidateDomainsResponseDto} from "../messages/responses/ValidateDomainsResponseDto";
import {AppDispatch} from "../../AppStore";
import {SystemActions} from "../../actions/SystemActions";
import {createDraftEmailFromMailToUrl} from "../../util/DraftEmailUtil";
import {uuid} from "../../util/Uuid";
import {DB} from "../../db/DbManager";
import {locationManager} from "../../BootStrap";
import {EmailLocation} from "../../locations/EmailLocation";

export class MailHelperService {
    static async validateEmailDomain(domain: string | null | undefined): Promise<DomainValidationStatus> {
        try {
            if (!domain) return "invalid";

            const response = await performMail3RestCall<ValidateDomainsResponseDto>("MailHelper/ValidateDomains", {Domains: [domain]});
            if (response.ValidDomains && response.ValidDomains.length > 0) {
                return "valid";
            }

        } catch (e: any) {
            console.info("Unable to validate email domains", e);
        }
        return "invalid";
    }

    static async unsubscribeFromMailingList(dispatch: AppDispatch, emailId: number, unsubscribeUrl: string | undefined) {
        try {
            if (unsubscribeUrl) {
                if (unsubscribeUrl.startsWith("http")) {
                    window.open(`/Mail/LinkSafety.aspx?e=1&url=${encodeURIComponent(encodeURIComponent(unsubscribeUrl))}`, "_blank", "noopener noreferrer popup");
                } else if (unsubscribeUrl.startsWith("mailto:")) {
                    const draftEmail = createDraftEmailFromMailToUrl(unsubscribeUrl, uuid());
                    await DB.saveDraftEmail(draftEmail);
                    locationManager.modifyWindowLocation("email", l => delete l.mailto);
                    EmailLocation.openDraftEmailByUid(draftEmail.Uid);
                }
                return;
            }
            dispatch(SystemActions.showNotification("Attempting to unsubscribe..."));
            await performFetch(`/Mail3/Unsubscribe/${emailId}`, {});

            dispatch(SystemActions.showNotification("Successfully Unsubscribed"));
        } catch (e: any) {
            console.log("Failed to unsubscribe", e);
            dispatch(SystemActions.showFormattedError("Failed to unsubscribe", e));
        }
    }
}
