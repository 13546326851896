import {DateTime} from 'luxon';
import {CalendarViewData, DummyCalendarViewData} from '../services/calendar/CalendarViewData';
import {Calendar} from "../domain/CalendarDto";
import {initialLocation} from "./SystemState";

const today: DateTime = DateTime.local();

export type CalendarMode = "month" | "week" | "none";

export interface CalendarState {
    calendars: Calendar[];
    calendarsLoaded: boolean;
    
    year: number;
    month: number;
    week: number;

    mode: CalendarMode;

    viewData: CalendarViewData;
}

export const initialCalendarState: CalendarState = {
    calendars: [],
    calendarsLoaded: false,
    
    year: initialLocation.year || today.year,
    month: initialLocation.month || today.month,
    week: initialLocation.week || today.weekNumber,

    mode: "month",

    viewData: DummyCalendarViewData,
};
