import {EntityDto} from '../services/messages/EntityDto';
import {ItemChangesDto} from '../services/messages/ItemChangesDto';
import {ClientEntityDto} from "../services/messages/ClientEntityDto";
import {ItemState} from "../state/ItemState";
import {EmailContentDto} from "../domain/EmailContentDto";
import {EmailHeader} from "../services/messages/EmailHeaderDto";
import {isEmailContent} from "../domain/ItemContent";
import Folder, {FolderTypeEnum} from "../domain/Folder";
import {GreylistFolderId} from "./FolderReducer";
import {modifyEmailContentInThread} from "./ItemsReducer";

export function applySyncChangesWithClientIds<TItem extends ClientEntityDto, TResult extends ClientEntityDto>(
    existingItems: TResult[],
    changes: ItemChangesDto<TItem> | null,
    converter: (item: TItem) => TResult,
    filterPredicate?: (item: TItem) => boolean): TResult[] {
    if (!changes) {
        return existingItems;
    }
    populateEntityIdsFromClientIds(existingItems, changes.Changed);

    return applySyncChanges(existingItems, changes, converter, filterPredicate);
}

export function applySyncChanges<TItem extends EntityDto, TResult extends EntityDto>(
    existingItems: TResult[],
    changes: ItemChangesDto<TItem> | null,
    converter: (item: TItem) => TResult,
    filterPredicate?: (item: TItem) => boolean): TResult[] {

    if (!changes || (!changes.Changed && !changes.DeletedIds)) {
        return existingItems;
    }

    const deletedIds = (changes.DeletedIds || []);

    const filteredItems = existingItems.filter(i => !deletedIds.includes(i.Id));

    // Note that removing duplicates here shouldn't be required, but just in case there's already bad data...
    const results = removeDuplicates(filteredItems, i => i.Id);

    changeOrAddSyncItems(results, changes.Changed, converter, filterPredicate);

    return results;
}

function changeOrAddSyncItems<TItem extends EntityDto, TResult extends EntityDto>(
    results: TResult[],
    changedItems: TItem[] | null,
    converter: (item: TItem) => TResult,
    filterPredicate?: (item: TItem) => boolean
) {
    if (!changedItems) {
        return;
    }
    const itemsToProcess = filterPredicate
        ? changedItems.filter(filterPredicate)
        : changedItems;
    for (const changedItem of itemsToProcess) {
        const index = results.findIndex(i => i.Id === changedItem.Id);
        if (index > -1) {
            results[index] = converter(changedItem);
        } else {
            results.push(converter(changedItem));
        }
    }
}

export function removeDuplicates<TItem, TKey>(list: TItem[], keyExtractor: (item: TItem) => TKey) {
    const keySet = new Set<TKey>();

    const result: TItem[] = [];

    for (const item of list) {
        const key = keyExtractor(item);
        if (!keySet.has(key)) {
            keySet.add(key);
            result.push(item);
        }
    }

    return result;
}

export function isSameClientEntityPredicate(change: ClientEntityDto) {
    return (exitingEntity: ClientEntityDto) =>
        (exitingEntity.ClientId && change.ClientId && exitingEntity.ClientId === change.ClientId)
        || (exitingEntity.Id === change.Id && change.Id >= 0);
}

export function populateEntityIdsFromClientIds(existingEntities: ClientEntityDto[], newEntities: ClientEntityDto[] | null) {
    if (!newEntities || !newEntities.length) {
        return;
    }
    const entityIdMap: Map<string, number> = new Map<string, number>();
    for (const newEntity of newEntities) {
        if (newEntity.ClientId) {
            entityIdMap.set(newEntity.ClientId, newEntity.Id);
        }
    }
    for (const entity of existingEntities) {
        if (entity.Id < 0) {
            entity.Id = entityIdMap.get(entity.ClientId || "") || -1;
        }
    }
}

export function applyChangeToEmailContentAndHeader(state: ItemState, emailIds: number[], contentChanges: Partial<EmailContentDto>, headerChanges: Partial<EmailHeader>) {
    const {itemContent} = state;
    let newState: ItemState;
    if (isEmailContent(itemContent) && emailIds.includes(itemContent.Id)) {
        newState = {
            ...state,
            ...modifyEmailContentInThread(state, emailIds, contentChanges),
            showEmailCategoryDialog: false,
        };
    } else {
        newState = {...state, showEmailCategoryDialog: false};
    }
    return {
        ...newState,
        emailHeaders: applyChangesToEmailHeaders(newState.emailHeaders, emailIds, headerChanges),
        selectedEmailHeaders: applyChangesToEmailHeaders(newState.selectedEmailHeaders, emailIds, headerChanges),
    };
}

export function applyChangesToEmailHeaders(emailHeaders: EmailHeader[], emailIds: number[], changes: Partial<EmailHeader>): EmailHeader[] {
    const newHeaders = [...emailHeaders];

    for (const id of emailIds) {
        const index = newHeaders.findIndex(h => h.Id === id);
        if (index > -1) {
            newHeaders[index] = {...newHeaders[index], ...changes};
        }
    }

    return newHeaders;
}

export function isSpecialFolder(folder: Folder) {
    return folder.type === FolderTypeEnum.Contacts
        || folder.type === FolderTypeEnum.Calendar
        || (folder.folderId < 0 && folder.folderId !== GreylistFolderId);
}
