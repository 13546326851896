import {GateKeeperField, GateKeeperFieldType} from "../domain/GateKeeperField";
import {decryptSecretKeys, pgpDecrypt, pgpEncrypt, pgpTextMessage, PublicKey, SecretKey} from "./PGPUtil";
import {PgpKey} from "../domain/PgpKey";
import {Message} from "openpgp";

export function parseGateKeeperXml(xmlText: string): GateKeeperField[] {
    const domParser = new DOMParser();
    const dom = domParser.parseFromString(xmlText, "text/xml") as XMLDocument;

    const result: GateKeeperField[] = [];

    const fieldElts = dom.getElementsByTagName("GateKeeperField");

    for (const fieldElt of fieldElts) {
        const field: GateKeeperField = {id: 0, name: "", isProtected: true, type: "Unknown", value: ""};

        for (const parameterElt of fieldElt.children) {
            const value = parameterElt.textContent;
            if (!value) continue;

            switch (parameterElt.localName) {
                case "Id":
                    field.id = parseInt(value);
                    break;
                case "Name":
                    field.name = value;
                    break;
                case "Value":
                    field.value = value;
                    break;
                case "Type":
                    field.type = value as GateKeeperFieldType;
                    break;
                case "IsProtected":
                    field.isProtected = value === "true";
                    break;
            }
        }
        result.push(field);
    }
    return result;
}

export async function parseGateKeeperEncryptedXml(message: Message<string>, secretKey: SecretKey, password: string): Promise<GateKeeperField[]> {
    if (password.length === 0) {
        throw new Error("Please enter your GateKeeper password");
    }

    const decryptedKeys = await decryptSecretKeys([secretKey], password);

    const {data: decryptedXml} = await pgpDecrypt(message, decryptedKeys);

    return parseGateKeeperXml(decryptedXml);
}

/*
<GateKeeperData xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
<Fields>
  <GateKeeperField>
    <Id>1</Id>
    <Name>Comment</Name>
    <Value>Merida</Value>
    <Type>Text</Type>
    <IsProtected>false</IsProtected>
  </GateKeeperField>
</Fields>
</GateKeeperData>
*/

export function convertGateKeeperFieldsToXml(fields: GateKeeperField[]) {
    const result = document.implementation.createDocument("", "", null);
    const dataElt = result.createElement("GateKeeperData");
    const fieldsElt = result.createElement("Fields");

    for (const field of fields) {
        const fieldElt = result.createElement("GateKeeperField");

        appendXmlTextNode(result, fieldElt, "Id", field.id.toString());
        appendXmlTextNode(result, fieldElt, "Name", field.name);
        appendXmlTextNode(result, fieldElt, "Value", field.value);
        appendXmlTextNode(result, fieldElt, "Type", field.type);
        appendXmlTextNode(result, fieldElt, "IsProtected", field.isProtected ? "true" : "false");

        fieldsElt.appendChild(fieldElt);
    }

    dataElt.appendChild(fieldsElt);

    return dataElt.outerHTML;
}

function appendXmlTextNode(document: XMLDocument, element: HTMLElement, name: string, value: string) {
    const node = document.createElement(name);
    const textNode = document.createTextNode(value);
    node.appendChild(textNode);
    element.appendChild(node);
}

export function findGatekeeperSecretKey(secretKeys: PgpKey[], emailAddresses: string[]) {
    if (secretKeys.length === 0) {
        throw new Error("Please add a PGP secret key in the PGP keys section");
    }

    let applicableSecretKeys = secretKeys.filter(sc => sc.key.getUserIds().some(id => emailAddresses.includes(id)));

    if (applicableSecretKeys.length === 0) {
        //throw new Error("No secret keys found for any of these email addresses: " + emailAddresses.join(", "));
        applicableSecretKeys = secretKeys;
    }
    return applicableSecretKeys.map(k => k.key);
}

export async function convertGateKeeperFieldsToEncryptedXml(fields: GateKeeperField[], secretKey: SecretKey, publicKeyShares: PublicKey[]): Promise<string> {
    const publicKeys = secretKey.toPublic();

    const xmlText = convertGateKeeperFieldsToXml(fields);

    const message = await pgpTextMessage(xmlText);
    return await pgpEncrypt(message, [publicKeys, ...publicKeyShares]);
}
