import HandshakeDto from "../services/messages/HandshakeDto";
import {NotificationMessage} from "../domain/NotificationMessage";
import {LocationAppType} from "../apps/AppConfig";

export enum ConnectionStatus {
    Disconnected,
    Connected,
    Connecting
}

export type AllowedThemes = "light" | "dark";

export const NOOP_ACTION = "NOOP_ACTION";

export interface NoopAction {
    type: typeof NOOP_ACTION;
}

export const SET_APP = "SET_APP";

export interface SetAppAction {
    type: typeof SET_APP;
    newApp: LocationAppType;
}

export const CHANGE_THEME = "CHANGE_THEME";

export interface ChangeThemeAction {
    type: typeof CHANGE_THEME;
    newTheme: AllowedThemes;
}

export const SET_NOTIFICATION_MESSAGES = "SET_NOTIFICATION_MESSAGES";

export interface SetNotificationMessagesAction {
    type: typeof SET_NOTIFICATION_MESSAGES;
    messages: NotificationMessage[];
}

export const HIDE_NOTIFICATION_MESSAGE = "HIDE_NOTIFICATION_MESSAGE";

export interface HideNotificationMessageAction {
    type: typeof HIDE_NOTIFICATION_MESSAGE;
    id: string;
}

export const SHOW_NOTIFICATION_MESSAGES = "SHOW_NOTIFICATION_MESSAGES";

export interface ShowNotificationMessagesAction {
    type: typeof SHOW_NOTIFICATION_MESSAGES;
}

export const RESIZE_WINDOW = "RESIZE_WINDOW";

export interface ResizeWindowAction {
    type: typeof RESIZE_WINDOW;
    newWidth: number;
}

export const CHANGE_CONNECTION_STATE = "CHANGE_CONNECTION_STATE";

export interface ChangeConnectionStateAction {
    type: typeof CHANGE_CONNECTION_STATE;
    status: ConnectionStatus;
}

export const CONNECTION_ERROR = "CONNECTION_ERROR";

export interface ConnectionErrorAction {
    type: typeof CONNECTION_ERROR;
    error: any; // TODO: find out what type this should be!
}

export const LOGIN_ASYNC = "LOGIN_ASYNC";

export interface LoginAsyncAction {
    type: typeof LOGIN_ASYNC;
    username?: string;
    error?: Error;
}

export const SHOW_ERROR = "SHOW_ERROR";

export interface ShowErrorAction {
    type: typeof SHOW_ERROR;
    message: string;
}

export const HIDE_ERROR = "HIDE_ERROR";

export interface HideErrorAction {
    type: typeof HIDE_ERROR;
}

export const SHOW_WARNING = "SHOW_WARNING";

export interface ShowWarningAction {
    type: typeof SHOW_WARNING;
    message: string | undefined;
}

export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";

export interface ShowNotificationAction {
    type: typeof SHOW_NOTIFICATION;
    message: string;
}

export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";

export interface HideNotificationAction {
    type: typeof HIDE_NOTIFICATION;
}

export const SHOW_FOLDER_MENU = "SHOW_FOLDER_MENU";

export interface ShowFolderMenuAction {
    type: typeof SHOW_FOLDER_MENU;
    visible: boolean;
}

export const SHOW_TOOLBAR_MENU = "SHOW_TOOLBAR_MENU";

export interface ShowToolbarMenuAction {
    type: typeof SHOW_TOOLBAR_MENU;
    visible: boolean;
}

export const RESIZE_FOLDER_TREE = "RESIZE_FOLDER_TREE";

export interface ResizeFolderTreeAction {
    type: typeof RESIZE_FOLDER_TREE;
    newWidth: number;
}

export const PERFORM_HANDSHAKE = "PERFORM_HANDSHAKE";

export interface PerformHandshakeAction {
    type: typeof PERFORM_HANDSHAKE;
    data: HandshakeDto;
}

export const NOTIFICATION_PERMISSION = "NOTIFICATION_PERMISSION";

export interface NotificationPermissionAction {
    type: typeof NOTIFICATION_PERMISSION;
    newPermission: NotificationPermission;
}

export type SystemActionTypes = NoopAction
    | SetAppAction | ChangeThemeAction | ResizeWindowAction | ShowFolderMenuAction | ShowToolbarMenuAction
    | ChangeConnectionStateAction | ConnectionErrorAction
    | LoginAsyncAction
    | SetNotificationMessagesAction | HideNotificationMessageAction | ShowNotificationMessagesAction
    | ShowErrorAction | HideErrorAction
    | ShowNotificationAction | HideNotificationAction
    | ShowWarningAction
    | ResizeFolderTreeAction
    | PerformHandshakeAction
    | NotificationPermissionAction
    ;
