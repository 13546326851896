import AutoCompleteDto from "../components/compose/AutoCompleteDto";
import {EmailHeader} from '../services/messages/EmailHeaderDto';
import {EmailContentDto} from '../domain/EmailContentDto';
import {ContactDto} from "../services/messages/ContactDto";
import {DateTime} from "luxon";
import {round} from "lodash";

const SERVER_DATE_FORMAT = "dd/MM/yyyy";
export const TEXTFIELD_DATE_FORMAT = "yyyy-MM-dd";
export const TEXTFIELD_DATETIME_FORMAT = "yyyy-MM-dd HH:mm";

const POSTMASTER_ADDRESS = "postmaster@redgatesoftware.co.uk";

const OneKB = 1024;
const FiveKB = 5 * OneKB;
const OneMB = OneKB * 1024;
const FiveMB = OneMB * 5;
export const OneGB = 1024 * 1024 * 1024;

export interface ContactAddress {
    Address?: string;
    Name?: string;
    Contact?: ContactDto;
}

export function formatFileSize(size: number): string {
    if (size < FiveKB) {
        return size + " bytes";
    }
    if (size < FiveMB) {
        return round(size / OneKB, 2) + " KB";
    }
    if (size < OneGB) {
        return round(size / OneMB, 2) + " MB";
    }
    return round(size / OneGB, 2) + " GB";
}

export function formatError(explanation: string, e: any): string {
    if (e.message) {
        return explanation + " (" + e.message + ")";
    }
    const errorText = e?.toString() || "";
    if (errorText.length === 0) {
        return explanation;
    }
    return explanation + " (" + errorText + ")";
}

export function parseIntSafe(value: string | null | undefined): number | undefined {
    const result = parseInt(value || "");
    if (isNaN(result)) {
        return undefined;
    }
    return result;
}

export function formatSubject(email: EmailHeader | EmailContentDto | undefined, removePrefixes?: boolean) {
    if (!email) return "";
    const {Subject}: EmailHeader | EmailContentDto = email;
    if (Subject && Subject.length > 0) {
        if (removePrefixes) {
            return Subject.replace(/^[rR][eE]: |[Ff][Ww][Dd]?: /, '');
        }
        return Subject;
    } else {
        return "<No Subject>";
    }
}

export function isNullOrWhiteSpace(text: string | null | undefined) {
    return !text || text.trim().length === 0;
}

export function formatFromAddressesString(rawAddresses: string, outgoing: boolean) {
    if (isNullOrWhiteSpace(rawAddresses)) {
        return outgoing ? "<No Recipients>" : "<Unknown Sender>";
    }
    if (rawAddresses === POSTMASTER_ADDRESS) {
        return "Post Master";
    }
    return extractFriendlyNamesFromEmailAddresses(rawAddresses)
        .map(c => c.Name || c.Address)
        .join("; ");
}

export function extractEmailUsername(address: string): string {
    const atIndex = address.indexOf('@');
    if (atIndex > 0) {
        return address.substring(0, atIndex);
    }
    return address;
}

export function convertRecipientAddressesToString(recipients: (AutoCompleteDto | ContactAddress)[]): string {
    return recipients
        .map(r => convertNameAndAddressToString(r.Name, r.Address))
        .join("; ");
}

export function convertNameAndAddressToString(name: string | undefined, address: string | undefined) {
    return name && name.length > 0 ? `"${name}" <${address}>` : "<" + address + ">"
}

export function extractRecipientAddresses(text: string | undefined | null): AutoCompleteDto[] {
    return extractFriendlyNamesFromEmailAddresses(text)
        .map(e => ({Address: e.Address || "", Name: e.Name || ""}));
    // TODO: validate the recipient
}

export function extractEmailAddresses(addresses: string | undefined | null): string[] {
    return extractFriendlyNamesFromEmailAddresses(addresses)
        .filter(a => a.Address)
        .map(a => a.Address as string);
}

export function extractAddressDomain(address_: string | null | undefined): string | null {
    if (!address_) return null;

    const index = address_.indexOf('@');
    let domain = address_.substring(index + 1);
    while (domain.length > 0 && domain.endsWith(".")) {
        domain = domain.substring(0, domain.length - 1);
    }

    return domain;
}

function tryAttachContact(contactMap: Map<string, ContactDto>, result: ContactAddress): boolean {
    if (!result.Address) {
        return false;
    }

    const contact = contactMap.get(result.Address || "");
    if (contact) {
        result.Name = getContactDisplayName(contact);
        result.Contact = contact;
        return true;
    }
    return false;
}

export function createContactLookupMap(contacts: ContactDto[]): Map<string, ContactDto> {
    const contactMap = new Map<string, ContactDto>();
    for (const contact of contacts) {
        for (const email of contact.Emails || []) {
            const addresses = extractEmailAddresses(email);
            if (addresses.length > 0) {
                contactMap.set(addresses[0], contact);
            }
        }
    }
    return contactMap;
}

export function extractFriendlyNamesFromEmailAddresses(addresses_: string | undefined | null, contactMap?: Map<string, ContactDto>): ContactAddress[] {
    if (!addresses_) {
        return [];
    }
    let inName: boolean = false;
    let nameStart: number = 0;
    let otherStart: number = 0;
    let addressStart: number = 0;
    let inAddress: boolean = false;
    let escapedCharacter: boolean = false;
    const results: ContactAddress[] = [];
    let currentAddress: ContactAddress = {};

    results.push(currentAddress);

    for (let i = 0; i < addresses_.length; i++) {
        const c = addresses_[i];
        if (c === '\\' && inName) {
            escapedCharacter = true;
            continue;
        }
        if (escapedCharacter) {
            escapedCharacter = false;
            continue;
        }
        if (c === '"') {
            if (!inName) {
                nameStart = i + 1;
            } else {
                const name = addresses_.substr(nameStart, i - nameStart).trim();
                currentAddress.Name = convertTextToTitleCase(name);
                addressStart = i + 1;
                otherStart = i + 1;
            }
            inName = !inName;
        } else if (c === ' ' || c === ';') {
            if (inAddress && addressStart !== otherStart) {
                if (addressStart > otherStart) {
                    const name = addresses_.substr(otherStart, addressStart - otherStart).trim();
                    if (!isNullOrWhiteSpace(name)) {
                        currentAddress.Name = convertTextToTitleCase(name);
                    }
                }
                currentAddress.Address = extractAddress(addresses_, addressStart, i);
            }
            if (inAddress) {
                otherStart = i + 1;
            }
            if (i > 0 && addresses_[i - 1] === '<') {
                continue;
            }
            if (inAddress) {
                currentAddress.Address = extractAddress(addresses_, addressStart, i);
                results.push(currentAddress = {});
            }
            addressStart = i + 1;
            inAddress = false;
        } else if (c === '@' && !inName) {
            inAddress = true;
        } else if (c === '<' && !inName) {
            addressStart = i;
            inAddress = true;
        }
    }
    if (inAddress && addressStart !== otherStart && isNullOrWhiteSpace(currentAddress.Name)) {
        const name = addresses_.substr(otherStart, addressStart - otherStart).trim();
        currentAddress.Name = convertTextToTitleCase(name);
    }
    if (inAddress) {
        currentAddress.Address = extractAddress(addresses_, addressStart, addresses_.length);
    } else if (addressStart === otherStart && addressStart < addresses_.length && isNullOrWhiteSpace(currentAddress.Name)) {
        const name = addresses_.substr(otherStart, addresses_.length - otherStart).trim();
        currentAddress.Name = convertTextToTitleCase(name).replace("<", "").replace(">", "").replace(";", "").replace(",", "").trim();
    }

    if (!results[0].Address && !results[0].Name && results.length === 1) {
        currentAddress.Address = addresses_.replace("<", "").replace(">", "").trim().toLowerCase();
    }

    // Convert addresses into names if possible and swap around names with commas......
    for (const result of results) {
        if (contactMap && tryAttachContact(contactMap, result)) {
            continue;
        }
        if (!isNullOrWhiteSpace(result.Address) && isNullOrWhiteSpace(result.Name)) {
            const username = getAddressUsername(result.Address);
            const nameTokens = username.split(/[\\._]/).filter(u => u.length > 0);
            if (nameTokens.length > 1) {
                result.Name =
                    nameTokens
                        .map(token => token.replace(/[0-9]+/, ""))
                        .map(convertTextToTitleCase)
                        .join(" ");
            }
        }

        if (!isNullOrWhiteSpace(result.Name) && result.Name) {
            const tokens = result.Name.split(',');
            if (tokens.length === 2) {
                result.Name = tokens[1].trim() + " " + tokens[0].trim();
            }
        }
    }

    return results.filter(c => !isNullOrWhiteSpace(c.Address) || !isNullOrWhiteSpace(c.Name));
}

export function convertTextToTitleCase(text: string): string {
    text = text.replace(/\\([^\\])/g, (e, args) => args[0]);

    if (text.indexOf(".co") > -1) {
        return text;
    }
    return text.indexOf('@') > -1 ? text.toLowerCase() : toTitleCase(text);
}

function toTitleCase(text: string): string {
    return text
        .replace(/([^\W_]+[^\s-]*) */g, convertWordToTitleCase);
}

function convertWordToTitleCase(word: string): string {
    const trimWord = word.trim();
    if (trimWord === "and" || trimWord === "of") {
        return word;
    }
    return word.length > 1
        ? word.substring(0, 1).toUpperCase() + word.substring(1)//.toLowerCase()
        : word.toUpperCase();
}

function extractAddress(addresses_: string, addressStart: number, end_: number): string {
    return addresses_.substring(addressStart, end_).replace("<", "").replace(">", "").replace(";", "").replace(",", "").trim().toLowerCase();
}

export function getAddressUsername(address_: string | undefined) {
    if (!address_) {
        return "";
    }

    const index = address_.indexOf('@');
    if (index === -1) {
        return address_;
    }
    return address_.substr(0, index);
}

export function formatAsPercent(value: number): string {
    return (Math.round(value * 10000) / 100).toFixed() + "%";
}

export function formatDiskSpace(size: number): string {
    return Math.round(size / OneGB).toString();
}

export function appendOptionalName(namePart: string | undefined | null): string {
    return namePart ? namePart + " " : "";
}

export function getContactShortDisplayName(contact: ContactDto): string {
    return contact.NickName || contact.FirstName || contact.LastName || "<unnamed contact>";
}

export function getContactDisplayName(contact: ContactDto) {
    const result =
        (appendOptionalName(contact.Title)
            + appendOptionalName(contact.FirstName)
            + appendOptionalName(contact.MiddleName)
            + appendOptionalName(contact.LastName))
            .trimEnd();

    return result || "<unnamed contact>";
}

export function formatDateForTextField(dateString: string | undefined | null): string {
    if (!dateString || !dateString.includes("/")) {
        return "";
    }
    const date = DateTime.fromFormat(dateString, SERVER_DATE_FORMAT);
    return date.toFormat(TEXTFIELD_DATE_FORMAT);
}

export function parseTextFieldDate(dateString: string | undefined | null): string {
    if (!dateString) {
        return "";
    }
    const date = DateTime.fromFormat(dateString, TEXTFIELD_DATE_FORMAT);
    return date.toFormat(SERVER_DATE_FORMAT);
}

export function getCountryCodeEmoji(countryCode: string) {
    const code = countryCode.toUpperCase();

    if (code === "X_") {
        return undefined;
    }

    return "&#x" + (127397 + code[0].charCodeAt(0)).toString(16) + ";" +
        "&#x" + (127397 + code[1].charCodeAt(0)).toString(16) + ";";
}
