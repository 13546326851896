import {Button, Card, CardContent, CardHeader, TextField} from "@mui/material";
import React, {useState} from "react";
import {StackPanel} from "../panels/StackPanel";
import {performMail3RestCall} from "../../util/HttpHelper";
import {useAppDispatch} from "../../hooks/ReduxHooks";
import {SystemActions} from "../../actions/SystemActions";

export const SecurityPreferences = () => {
    const [isChangingPassword, setChangingPassword] = useState<boolean>(false);

    const [oldPassword, setOldPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [newPassword2, setNewPassword2] = useState<string>("");

    const [isPasswordChangeInProgress, setPasswordChangeInProgress] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    
    async function tryChangingPassword() {
        if (newPassword !== newPassword2) {
            alert("Passwords don't match");
            return;
        }
        try {
            setPasswordChangeInProgress(true);

            await performMail3RestCall("ChangePassword", {oldPassword, newPassword});

            dispatch(SystemActions.showNotification("Password changed successfully"));
            
            setChangingPassword(false);
        } catch(e) {
            console.log(e);
            dispatch(SystemActions.showFormattedError("Unable to change password", e));
        } finally {
            setPasswordChangeInProgress(false);
        }
    }

    return (
        <Card className="preferences-section">
            <CardHeader title="Security"/>
            <CardContent>
                {isChangingPassword
                    ? (<StackPanel orientation="vertical" align={"middle"}>
                        <TextField type="password"
                                   label="Current Password"
                                   autoFocus={true}
                                   disabled={isPasswordChangeInProgress}
                                   value={oldPassword}
                                   onChange={e => setOldPassword(e.target.value)}/>
                        <TextField type="password"
                                   label="New Password"
                                   disabled={isPasswordChangeInProgress}
                                   value={newPassword}
                                   onChange={e => setNewPassword(e.target.value)}/>
                        <TextField type="password"
                                   label="Repeat New Password"
                                   disabled={isPasswordChangeInProgress}
                                   value={newPassword2}
                                   onChange={e => setNewPassword2(e.target.value)}/>

                        <div>&nbsp;</div>

                        <StackPanel orientation="horizontal">
                            <Button variant="contained"
                                    color="primary"
                                    onClick={async () => await tryChangingPassword()}
                                    disabled={isPasswordChangeInProgress}>
                                OK
                            </Button>
                            <Button variant="contained"
                                    color="inherit"
                                    onClick={() => setChangingPassword(false)}
                                    disabled={isPasswordChangeInProgress}>
                                Cancel
                            </Button>
                        </StackPanel>
                    </StackPanel>)
                    : (<StackPanel align={"middle"}>
                            <Button variant="contained"
                                    color="secondary"
                                    onClick={() => setChangingPassword(true)}>
                                Change Password
                            </Button>
                        </StackPanel>
                    )
                }
            </CardContent>
        </Card>
    );
}
