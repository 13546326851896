import {DbGateKeeperAccount} from '../../db/DbSchema';
import {AccountRecordItem} from "../../state/AccountState";

export interface GateKeeperRecordDto extends DbGateKeeperAccount {
}

export function isGateKeeperRecordDto(content: AccountRecordItem | undefined): content is GateKeeperRecordDto {
    if (!content) return false;

    return content && (content as GateKeeperRecordDto).EncryptedDataXml !== undefined;
}
