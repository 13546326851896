import {DateTime, Interval} from "luxon";
import {CalendarEntryData, NonNullInterval} from "./CalendarEntryData";
import {uuid} from "../../util/Uuid";
import {BusyStatusEnum} from "../../domain/BusyStatusEnum";
import {MeetingStatusEnum} from "../../domain/MeetingStatusEnum";
import {AttendeeStatusEnum} from "../../domain/AttendeeStatusEnum";
import {SensitivityEnum} from "../../domain/SensitivityEnum";
import {MAX_OCCURRENCES, Recurrence} from "../../domain/RecurrenceDto";
import {RecurrenceTypeEnum} from "../../domain/RecurrenceTypeEnum";
import {DurationObjectUnits} from "luxon/src/duration";
import {floor} from "lodash";
import {IANAZone} from "luxon/src/zone";

export const DEFAULT_MEETING_LENGTH: DurationObjectUnits = {minutes: 30};

export const DefaultRecurrence: Recurrence = {
    Occurrences: MAX_OCCURRENCES,
    Until: null,
    DaysOfWeek: null,
    Interval: 1,
    WeekOfMonth: 1,
    MonthOfYear: 1,
    DayOfMonth: 1,
    Type: RecurrenceTypeEnum.Monthly,
    CalendarType: null,
    IsLeapMonth: false,
    FirstDayOfWeek: null,
};

export function createDefaultCalendarEntry(initialDate: DateTime) {
    let anchorTime = DateTime.local().plus({minutes: 30});
    const halfHour = floor((anchorTime.minute) / 30) * 30;
    anchorTime = anchorTime.set({minute: halfHour});

    const date = initialDate.set({hour: anchorTime.hour, minute: anchorTime.minute});

    const zone = DateTime.local().zone as IANAZone;
    const entry: CalendarEntryData = {
        calendar: {
            Id: -1,
            Uid: uuid(),
            ClientId: uuid(),
            AllDayEvent: false,
            StartDateTime: date,
            EndDateTime: date.plus(DEFAULT_MEETING_LENGTH),
            CreateDateTime: DateTime.utc(),
            Subject: "New Event",
            Category: null,
            BusyStatus: BusyStatusEnum.Free,
            DisallowNewTimeProposal: true,
            IsHtml: false,  // TODO: support rich content calendar events
            Attendees: [],
            Recurrences: [],
            MeetingStatus: MeetingStatusEnum.Other,
            Timezone: zone,
            TimezoneName: zone.name,
            ResponseType: AttendeeStatusEnum.ResponseUnknown,
            ResponseRequested: false,
            Sensitivity: SensitivityEnum.Normal,
            ReminderMins: 15,
            Body: "",
            OrganizerEmail: null,
            OrganizerName: null,
            AppointmentReplyDateTime: null,
            Exceptions: [],
            Location: null,
            OnlineMeetingConfLink: null,
            OnlineMeetingExternalLink: null,
        },
        recurrence: null,
        instanceId: 0,
        colSpan: 0,
        colStart: 0,
        row: 0,
        intervalUtc: Interval.fromDateTimes(date, date) as NonNullInterval,
        intervalLocal: Interval.fromDateTimes(date, date) as NonNullInterval,
    };
    return entry;
}
