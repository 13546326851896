import React, {FC} from "react";
import {StackPanel} from "../../panels/StackPanel";
import {SimpleSelect} from "../../common/SimpleSelect";
import {formatFileSize} from "../../../util/Formatters";
import {SystemLogActions} from "../../../redux/SystemLogSlice";
import {useAppDispatch, useAppSelector} from "../../../hooks/ReduxHooks";

export const SystemLogControlView: FC = () => {

    const dispatch = useAppDispatch();

    const logs = useAppSelector(({sysLogs}) => sysLogs.logs)
        .map(log => ({value: log, title: log}));
    
    const logFiles = useAppSelector(({sysLogs}) => sysLogs.logFiles) 
        .map(file => ({value: file.Name, title: file.Name + " (" + formatFileSize(file.Size) + ")"}));

    const selectedLog = useAppSelector(({sysLogs}) => sysLogs.selectedLog);
    const selectedLogFile = useAppSelector(({sysLogs}) => sysLogs.selectedLogFile);

    function onHandleNewLogSelected(newLog: string) {
        dispatch(SystemLogActions.selectLog(newLog));
    }

    function onHandleNewLogFileSelected(newLogFile: string) {
        dispatch(SystemLogActions.selectLogFile(newLogFile));
    }

    return (
        <StackPanel align="middle">
            <SimpleSelect options={logs}
                          className="system-log-select"
                          value={selectedLog || ""}
                          onChange={onHandleNewLogSelected}/>

            <SimpleSelect options={logFiles}
                          className="system-log-select"
                          value={selectedLogFile || ""}
                          onChange={onHandleNewLogFileSelected}/>
        </StackPanel>
    );
}
