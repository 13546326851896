import React, {createRef, useLayoutEffect, useState} from "react";

import "./EmailContentView.scss";
import {EmailThreadContentDto} from "../../domain/EmailContentDto";
import {EmailRecipientList} from "./EmailRecipientList";
import {extractEmailAddresses, formatSubject} from '../../util/Formatters';
import {applyEmailContentSafetySettings} from '../../util/SafetyTransformer';
import {EditEmailCategoryDialog} from './EditEmailCategoryDialog';
import ArchiveSettingsDialog from './ArchiveSettingsDialog';
import {InlineAttachmentViewer} from "./InlineAttachmentViewer";
import {dateTimeFromTicks} from "../../util/DateHelper";
import {SignatureState} from "../../security/EncryptedEmailHelper";
import {ContentType} from "../../util/ContentTypes";
import {useAppSelector, useAppStore} from "../../hooks/ReduxHooks";
import {decodeMimePartContent} from "../../parsers/MimeParser";
import {EmailReadLogsView} from "./EmailReadLogsView";
import {EmailContentInfo} from "./info/EmailContentInfo";
import {DropDownMenuItem} from "../toolbar/DropDownMenu";
import GetApp from "@mui/icons-material/GetApp";
import {EmailActions} from "../../actions/EmailActions";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import {AttachmentList} from "./AttachmentList";
import {RemindMeLaterSettingsDialog} from "./RemindMeLaterSettingsDialog";
import {DecryptedAttachmentList} from "./DecryptedAttachmentList";
import {DateTime} from "luxon";
import classNames from "classnames";

export interface EmailContentViewProps {
    emailThreadContent: EmailThreadContentDto;
    emailIndex: number;
}

const EmailContentView: React.FC<EmailContentViewProps> = ({emailThreadContent, emailIndex}) => {
    const [noSafetyId, setNoSafetyId] = useState<number>(-1);
    const [[contentWidth, containerWidth], setWidths] = useState<[number | undefined, number | undefined]>([undefined, undefined]);

    const contentRef = createRef<HTMLDivElement>();

    const store = useAppStore();

    const whitelistedImages = useAppSelector(s => s.preferenceState.imageWhitelist);

    const applySafety = noSafetyId !== emailThreadContent.Id;

    const darkTheme = useAppSelector(({system}) => system.theme === "dark");
    const mobile = useAppSelector(({system}) => system.isMobile);

    const emailContent = emailThreadContent.Emails[emailIndex];
    const isPrimaryEmail = emailThreadContent.Id === emailContent.Id;

    const showSubject = emailIndex !== 0 && emailContent.Subject.toLowerCase() !== emailThreadContent.Emails[0].Subject.toLowerCase();

    useLayoutEffect(() => {
        if (contentRef.current && contentWidth === undefined && containerWidth === undefined) {
            // We need to render the email twice - once to get the content size, and the second time with the fully resized content
            let width = 0;
            for (const child of contentRef.current.children) {
                width += child.scrollWidth;
            }
            // TODO: for non-mobile, improve the width of the container logic
            const containerWidth = contentRef.current.clientWidth ?? 600;
            if (width <= 50) {
                width = containerWidth;
            }

            console.log("Content width: ", width, "Container width: ", containerWidth);
            setWidths([width, containerWidth]);
        } else if (contentRef.current && contentWidth) {
            if (isPrimaryEmail && emailIndex !== 0) {
                const parentElement = contentRef.current?.parentElement;
                setTimeout(() => parentElement?.scrollIntoView(), 0);
            }
        }
    }, [contentRef, contentWidth, emailIndex, isPrimaryEmail, containerWidth]);

    console.log("Rendering email content");

    const applyJunkSafety = emailContent.JunkMailSafetyApplied;

    let contentString = emailContent.Content;
    let isHtmlContent = emailContent.IsHtmlContent;
    let signatures: SignatureState[] = [];
    if (emailContent.IsEncrypted || emailContent.IsSigned) {
        const decryptedContent = emailContent.DecryptedContent;
        if (decryptedContent) {
            contentString = decodeMimePartContent(decryptedContent.bestPart);
            isHtmlContent = decryptedContent.bestPart.contentType === ContentType.HtmlText;
            signatures = decryptedContent.signatures;
        } else {
            contentString = emailContent.Content;
            isHtmlContent = false;
        }
    }

    const fromAddress = (extractEmailAddresses(emailContent.From) || [])[0];
    let matchingSignature: SignatureState | undefined = undefined;
    if (fromAddress) {
        matchingSignature = signatures.find(s => s.users.includes(fromAddress));
    }
    if (!matchingSignature && signatures.length > 0) {
        matchingSignature = signatures[0];
    }
    const isSignedBySender = emailContent.IsSignedSender || matchingSignature?.verified;
    const mismatchedSignature = matchingSignature !== undefined && !matchingSignature.verified;

    const safetyResult = applyEmailContentSafetySettings(emailContent.Id, contentString, isHtmlContent, {
        applySafety,
        applyJunkSafety,
        darkTheme,
        mobile,
        containerWidth: mobile ? window.screen.width : containerWidth ?? 600,    // TODO: for non-mobile, find the width of the container
        contentWidth,//: undefined,
        excludeReplies: emailIndex > 0, //< emailThreadContent.Emails.length - 1,
        whitelistedImages,
    });

    const contextMenuItems: (DropDownMenuItem | string)[] = [
        {
            icon: <GetApp/>,
            name: "Download",
            onClick: () => {
                const attachment = store.getState().itemState.contextAttachment;
                EmailActions.downloadAttachment(attachment?.Id, attachment?.Name);
            }
        }, {
            icon: <FileCopyIcon/>,
            name: "Download All as Zip",
            onClick: () => EmailActions.downloadAttachment(undefined, "attachments.zip", store.getState().itemState.itemId, "zip"),
        }];

    // TODO: allow showing attachments from other emails in the thread!
    const emailDate = dateTimeFromTicks(emailContent.Date);
    const formattedDate = emailDate.toFormat(emailDate.year === DateTime.now().year ? "d MMMM HH:mm" : "d MMMM yyyy HH:mm");

    return (
        <div className="email-content-view">
            <div className={classNames("email-details", {"thread-email": !isPrimaryEmail}, {"outgoing-email": emailContent.IsOutgoing})}>

                {showSubject && <div className="email-subject" title={formatSubject(emailContent)}>{formatSubject(emailContent)}</div>}
                {!showSubject && <div style={{height: "5px"}}></div>}

                <span className="email-date">{formattedDate}</span>
                <EmailRecipientList prefix="From" className="email-from" recipients={emailContent.From}
                                    mismatchedSignature={mismatchedSignature}
                                    isSignedBySender={isSignedBySender}
                                    unverifiedSender={!emailContent.IsVerifiedSender}/>

                <EmailRecipientList prefix="To" recipients={emailContent.To}/>
                <EmailRecipientList prefix="Cc" recipients={emailContent.CC}/>

                <AttachmentList emailId={emailContent.Id}
                                attachments={emailContent.Attachments}
                                contextMenuItems={contextMenuItems}
                                emailContent={emailContent}/>

                {emailContent.IsEncrypted && <DecryptedAttachmentList attachments={emailContent.DecryptedAttachments ?? []}/>}

                <EmailReadLogsView readLogs={emailContent.ReadLogs}/>

                {isPrimaryEmail && <EmailContentInfo emailContent={emailContent}
                                                     safetyResult={safetyResult}
                                                     applySafety={applySafety}
                                                     applyJunkSafety={applyJunkSafety}
                                                     isSignedBySender={!!isSignedBySender}
                                                     mismatchedSignature={mismatchedSignature}
                                                     onSetNoSafety={() => setNoSafetyId(emailContent.Id)}/>}

                {!isPrimaryEmail && <div style={{height: "5px"}}></div>}
            </div>

            {emailContent.ContentError &&
                <div className="content-error">
                    {emailContent.ContentError}
                </div>
            }

            <InlineAttachmentViewer safeContent={safetyResult}
                                    contentWidth={contentWidth}
                                    contentRef={contentRef}
                                    emailId={emailContent.Id}/>

            <EditEmailCategoryDialog/>
            <ArchiveSettingsDialog/>
            <RemindMeLaterSettingsDialog/>
        </div>
    );
}

export default EmailContentView;
