import React, {FC, useEffect, useState} from "react";
import {DateTime} from "luxon";
import {formatDateTimeAsFriendlyString} from "../../util/DateHelper";

export interface DateTimeControlProps {
    title: string;
    time: DateTime | undefined | null;
}

function formatTime(time: DateTime | undefined | null) {
    return time ? formatDateTimeAsFriendlyString(time) : "unknown";
}

export const DateTimeControl: FC<DateTimeControlProps> = ({title, time}) => {
    const [dateTimeString, setDateTimeString] = useState(formatTime(time));
    
    useEffect(() => {
        setDateTimeString(formatTime(time));
        
        const interval = setInterval(()=> setDateTimeString(formatTime(time)), 10000);
        
        return () => clearInterval(interval);
    }, [time]);
    
    return <span>{title} <b>{dateTimeString}</b></span>;
};
