const DEVICE_ID: string = "deviceId";

export class DeviceIdManager {

    static getDeviceId(): string {
        let id = window.localStorage.getItem(DEVICE_ID);
        if (id === null) {
            id = btoa(new Date().getTime().toString());
            window.localStorage.setItem(DEVICE_ID, id);
        }
        return id;
    }
}
