import React, {FC, ReactNode} from "react";
import {ContactDto} from "../../services/messages/ContactDto";
import classNames from "classnames";
import {LazyLoadingImage} from "../common/LazyLoadingImage";

export type ContactImageSize = "small" | "medium" | "large";

export interface ContactImageProps {
    contact: ContactDto;

    size?: ContactImageSize;
    onClick?: () => void;
}

export const ContactImage: FC<ContactImageProps> = ({
                                                        contact,
                                                        size,
                                                        onClick
                                                    }) => {
    let content: ReactNode;

    if (contact.PictureDataUrl) {
        content = <img src={contact.PictureDataUrl} alt=""/>;
    } else if (contact.HasPicture) {
        content = <LazyLoadingImage src={`/Mail/ContactImage.aspx?id=${contact.Id}&r=${contact.ChangeId}`} 
                                    alt=""/>
    } else if (size !== "small") {
        content = <img src="/Static/Images/ContactLarge.png" alt=""/>
    } else {
        return null;
    }

    return (
        <span className={classNames("contact-avatar", size, {"selectable pointer": onClick})} onClick={onClick}>
            {content}
        </span>
    );
}
