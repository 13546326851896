import React, {FC, Fragment} from "react";
import {useAppSelector} from "../../hooks/ReduxHooks";
import {ContentLocation} from "../../locations/ContentLocation";

const PhotoBreadcrumb: FC<{ index: number }> = ({index}) => {
    const breadcrumbs = useAppSelector(s => s.photosState.breadcrumbs);

    return (
        <button className="link" onClick={() => ContentLocation.showPhotoFolder(breadcrumbs.slice(0, index + 1), undefined)}>
            {breadcrumbs[index]}
        </button>
    );
}

export const PhotoBreadcrumbs = () => {
    const breadcrumbs = useAppSelector(s => s.photosState.breadcrumbs);

    return (
        <>
            <button className="link" onClick={() => ContentLocation.showPhotoFolder([], undefined)}>Home</button>
            {breadcrumbs.map((b, i) => {
                return (
                    <Fragment key={i}>
                        /
                        <PhotoBreadcrumb key={i} index={i}/>
                    </Fragment>
                )
            })}
        </>
    );
}
