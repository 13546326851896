import "./NotificationView.scss";

import React, {FC} from "react";
import {shallowEqual} from "react-redux";
import {useAppSelector} from "../../hooks/ReduxHooks";
import {isTearOffWindow} from "../../App";
import {NotificationItemView} from "./NotificationItemView";

export const NotificationView: FC = () => {
    const notifications = useAppSelector(({system}) => system.notificationMessages, shallowEqual);

    if (isTearOffWindow || !notifications || notifications.length === 0) {
        return null;
    }

    const firstNotification = notifications.find(n => n.visible);
    return <>
        {firstNotification && <NotificationItemView notification={firstNotification}/>}
    </>;
};
