import React, {FC, RefObject, useState} from "react";
import {ContextMenu} from "../common/ContextMenu";
import {DropDownMenuItem} from "../toolbar/DropDownMenu";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "../common/ConfirmDialog";
import {NotePageDto} from "../../domain/NotePageDto";
import {NotesActions} from "../../redux/NotesSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";

interface NoteSectionContextMenuProps {
    elementRef: RefObject<HTMLDivElement>;
}

export const NotePageContextMenu: FC<NoteSectionContextMenuProps> = ({
                                                                            elementRef,
                                                                        }) => {

    const [confirmDelete, setConfirmDelete] = useState<NotePageDto>();

    const contextPage = useAppSelector(({notesState}) => notesState.contextPage);

    const dispatch = useAppDispatch();

    function onClose() {
        dispatch(NotesActions.setNotePageContextMenu(undefined));
    }

    function onRename() {
        if (!contextPage) return;
        dispatch(NotesActions.beginRenameNote(contextPage));
    }

    function onBeginDelete() {
        setConfirmDelete(contextPage);
    }

    async function onConfirmDelete(confirmed: boolean) {
        if (!confirmDelete) return;
        if (confirmed) {
            await dispatch(await NotesActions.deleteNotePage(confirmDelete));
        }
        setConfirmDelete(undefined);
    }

    const contextMenuItem: (DropDownMenuItem | string)[] = [
        {icon: <EditIcon/>, name: "Rename", onClick: onRename},

        "div1",

        {icon: <DeleteIcon/>, name: "Delete", onClick: onBeginDelete},
    ];

    return (<>
            <ContextMenu
                menuItems={contextMenuItem}
                elementRef={elementRef}
                onClose={onClose}/>
            <ConfirmDialog
                title="Delete Page"
                message={`Are you sure you want to delete the page '${confirmDelete?.Name}'?`}
                open={!!confirmDelete}
                onConfirm={onConfirmDelete}
                confirmColour="error"/>
        </>
    );
}