import React, {FC, ReactNode} from "react";
import {StackPanel} from "../panels/StackPanel";
import {AccountsFolderId, AttachmentFolderId, NotesFolderId, ProtectedFilesFolderId} from "../../reducers/FolderReducer";
import classNames from "classnames";
import {FolderLocation} from "../../locations/FolderLocation";
import {SystemActions} from "../../actions/SystemActions";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {FolderSelectors} from "../../redux/selectors/FolderSelectors";
import {Badge} from "@mui/material";
import {FolderTypeEnum} from "../../domain/Folder";
import {SearchControl} from "./SearchControl";
import FolderIcon from '@mui/icons-material/Folder';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PeopleIcon from '@mui/icons-material/People';
import LockIcon from '@mui/icons-material/Lock';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import NoteIcon from '@mui/icons-material/Description';

interface SpecialFolderProps {
    type: string;
    folderId: number | undefined;
    icon: (props: any) => ReactNode;
}

const SpecialFolder: FC<SpecialFolderProps> = ({type, folderId, icon}) => {
    const selected = useAppSelector(({folderState}) => folderState.selectedFolderId === folderId);

    const dispatch = useAppDispatch();

    if (!folderId) return null;

    function onSelectSpecialFolder() {
        dispatch(SystemActions.showFolderMenu(false));
        FolderLocation.selectFolderById(folderId!);
    }
    
    const props = {
        color: selected ? "primary" : undefined,
        fontSize: "large",
    };
    return (
        <span className={classNames("selectable", "pointer", )}
              title={type}
              onClick={onSelectSpecialFolder}>
            {icon(props)}
        </span>
    );
};

export const SpecialFolderList: FC = () => {
    const inboxFolderId = useAppSelector(FolderSelectors.selectInboxFolderId);
    const calendarFolderId = useAppSelector(FolderSelectors.selectCalendarFolderId);
    const contactsFolderId = useAppSelector(FolderSelectors.selectContactsFolderId);

    const filesFolderId = useAppSelector(s => s.preferenceState.fileFolderType === "protected" ? ProtectedFilesFolderId : AttachmentFolderId);

    const unreadEmails = useAppSelector(({folderState}) => folderState.folderList.find(f => f.type === FolderTypeEnum.Inbox)?.unreadCount);

    const isMobile = useAppSelector(s => s.system.isMobile);

    const showingSearch = useAppSelector(s => s.preferenceState.showingSearch);

    return (
        <>
            {!isMobile && <div className="folder-separator"/>}

            <StackPanel className="special-folder-list" fill={isMobile}>
                {!showingSearch && <>
                    {unreadEmails && !isMobile ? <Badge badgeContent={unreadEmails} color="primary" className="mail-badge">
                            <SpecialFolder type="Inbox" folderId={inboxFolderId} icon={props => <FolderIcon {...props}/>}/>
                        </Badge>
                        : <SpecialFolder type="Inbox" folderId={inboxFolderId} icon={props => <FolderIcon {...props}/>}/>}

                    <SpecialFolder type="Calendar" folderId={calendarFolderId} icon={props => <CalendarMonthIcon {...props}/>}/>
                    <SpecialFolder type="Contacts" folderId={contactsFolderId} icon={props => <PeopleIcon {...props}/>}/>
                    <SpecialFolder type="Notes" folderId={NotesFolderId} icon={props => <NoteIcon {...props}/>}/>
                    <SpecialFolder type="Accounts" folderId={AccountsFolderId} icon={props => <LockIcon {...props}/>}/>
                    <SpecialFolder type="Misc" folderId={filesFolderId} icon={props => <PermMediaIcon {...props}/>}/>
                </>}
                
                <SearchControl showWhenMobile/>

                {isMobile && <div className="folder-separator"/>}
            </StackPanel>
        </>
    );
};