import React, { RefObject, useState } from "react";
import { DropDownMenuItem } from "../toolbar/DropDownMenu";
import { ContextMenu } from "../common/ContextMenu";
import { ConnectedProps, connect } from "react-redux";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { AppState } from "../../AppState";
import Folder, { FolderTypeEnum } from '../../domain/Folder';
import { FolderActions } from '../../actions/FolderActions';
import ConfirmDialog from '../common/ConfirmDialog';

interface EmailContextMenuExtraProps {
    elementRef: RefObject<HTMLDivElement>;
    onClose: () => void;
}

export function canCreateSubFolder(type: FolderTypeEnum | undefined) {
    return type === FolderTypeEnum.UserMail || type === FolderTypeEnum.Inbox || type === FolderTypeEnum.Generic;
}

function canModifyFolder(folder: Folder | undefined) {
    return (folder?.type === FolderTypeEnum.UserMail || folder?.type === FolderTypeEnum.Generic) && folder?.parentId !== "0";
}

const RenderFolderContextMenu = ({
    elementRef, contextFolder,
    onRenameFolder, onMoveFolder, onCreateSubFolder, onDeleteFolder, 
    onClose,
}: FolderContextMenuProps & EmailContextMenuExtraProps) => {

    const [confirmDeleteFolder, setConfirmDeleteFolder] = useState<Folder>();

    const modifiable = canModifyFolder(contextFolder);
    const creatable = canCreateSubFolder(contextFolder?.type);

    const contextMenuItem: (DropDownMenuItem | string)[] = [
        { icon: <CreateNewFolderIcon />, name: "New Folder", onClick: () => onCreateSubFolder(contextFolder!), disabled: !creatable },
        { icon: <EditIcon />, name: "Rename Folder", onClick: () => onRenameFolder(contextFolder!), disabled: !modifiable },
        { icon: <TrendingFlatIcon />, name: "Move Folder", onClick: () => onMoveFolder(contextFolder!), disabled: !modifiable },

        "div1",

        { icon: <DeleteIcon />, name: "Delete Folder", onClick: () => setConfirmDeleteFolder(contextFolder), disabled: !modifiable },
    ];

    function handleConfirmDeleteFolder(confirmed: boolean) {
        if (confirmed && confirmDeleteFolder) {
            onDeleteFolder(confirmDeleteFolder);
        }
        setConfirmDeleteFolder(undefined);
    }

    return (<>
        <ContextMenu
            menuItems={contextMenuItem}
            elementRef={elementRef}
            onClose={onClose}
        />
        <ConfirmDialog
            title="Delete Folder"
            message={`Are you sure you want to delete the folder ${confirmDeleteFolder?.name}?`}
            open={!!confirmDeleteFolder}
            onConfirm={handleConfirmDeleteFolder}
            confirmColour="error"/>
    </>
    );
};

const mapState = ({ folderState }: AppState) => ({
    contextFolder: folderState.contextFolder,
});

const mapDispatch = {
    onRenameFolder: FolderActions.beginRenameFolder,
    onCreateSubFolder: FolderActions.beginCreateSubFolder,
    onMoveFolder: FolderActions.beginMoveFolder,
    onDeleteFolder: FolderActions.deleteFolder,
};

const connector = connect(mapState, mapDispatch);

type FolderContextMenuProps = ConnectedProps<typeof connector>;

export const FolderContextMenu = connector(RenderFolderContextMenu);
