import {SystemActions} from "../actions/SystemActions";
import {DeviceIdManager} from "../util/DeviceIdManager";
import RequestDto from "./messages/RequestDto";
import {AuthenticationError, performRawRestCall, sendFormData} from "../util/HttpHelper";
import DBManager from "../db/DbManager";
import HandshakeDto from "./messages/HandshakeDto";
import RemoteOperationDto, {AddAttachmentRemoteOperationDto, SaveDraftEmailRemoteOperationDto} from "./messages/RemoteOperationDto";
import {AttachmentActions} from "../actions/AttachmentActions";
import {AppStore} from "../AppStore";
import {SyncState} from "../state/SyncState";
import {ConnectionStatus} from "../actions/SystemActionTypes";
import {EmailLocation} from '../locations/EmailLocation';
import {ComposeEmailActions} from '../actions/ComposeEmailActions';
import DbDraftEmail from '../domain/DbDraftEmail';
import {Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {DecodedPgpKeyChangesDto, PgpKeyChangesDto} from "./messages/PgpKeyChangesDto";
import {SyncActions} from "../redux/SyncSlice";
import {decodePgpKeyDtos} from "../util/PGPUtil";
import {ItemChangeListDto} from "./messages/ItemChangeListDto";
import {OperationResponseDto} from "./messages/OperationResponseDto";
import {ItemActions} from "../actions/ItemActions";

const SYNC_DEBOUNCE_TIME = 200;

const deviceId = DeviceIdManager.getDeviceId();

const IgnoredSyncKeys = ["HasMoreChanges", "StartSyncId", "SyncId"];

export class SyncService {
    private store: AppStore;
    private db: DBManager;
    private outChangeKey: string = "";
    private currentServerId = 1;

    private started = false;
    private doneHandshake = false;

    /**
     * Subject used to prevent many sync changes being sent in very quick succession
     */
    private readonly operationsSubject: Subject<void> = new Subject<void>();

    private lastSyncState: SyncState | null = null;

    private inFlightOpIds: number[] = [];

    constructor(store: AppStore, db: DBManager) {
        this.store = store;
        this.db = db;

        this.operationsSubject
            .pipe(debounceTime(SYNC_DEBOUNCE_TIME))
            .subscribe({next: () => this.sendPendingOperationsSafe().catch(console.error)});

        store.subscribe(() => this.handleStateChanges().catch(console.error));
    }

    private async pollServer() {
        const username = this.store.getState().system.userName;
        if (!username) {
            setTimeout(() => this.pollServer(), 1000);
            return;
        }
        const dispatch = this.store.dispatch;
        try {
            if (this.doneHandshake) {
                const {sync} = this.store.getState();
                const url = `/Mail3/Poll/${this.currentServerId}/${sync.startSyncId}/${sync.endSyncId}?outChangeKey=${encodeURIComponent(this.outChangeKey)}&deviceId=${encodeURIComponent(deviceId)}`;
                const response = await performRawRestCall<ItemChangeListDto>(url, undefined, "GET");

                await this.handleResponseMessage(response);
            } else {
                const handshake = await performRawRestCall<HandshakeDto>("/Mail3/Handshake", undefined, "GET");
                this.doneHandshake = true;
                this.currentServerId = handshake.ServerId ?? 1;
                dispatch(SystemActions.changeConnectionState(ConnectionStatus.Connected));
                dispatch(SystemActions.performHandshake(handshake));
            }
            setTimeout(() => this.pollServer(), 10);

        } catch (e) {
            if (e instanceof AuthenticationError) {
                dispatch(ItemActions.showItemContent({loginRequired: true}));
            }
            this.doneHandshake = false;
            dispatch(SystemActions.changeConnectionState(ConnectionStatus.Disconnected));
            dispatch(SystemActions.connectionError(e));
            setTimeout(() => this.pollServer(), 5000);
        }
    }

    private async handleStateChanges() {
        try {
            const {sync} = this.store.getState();
            if (sync === this.lastSyncState) {
                return;
            }

            this.lastSyncState = sync;

            this.operationsSubject.next();
        } catch (e) {
            console.error("Error in handleStateChanges: ", e);
        }
    }

    private async sendPendingOperationsSafe() {
        if (this.db.isInitialised()) {
            await this.sendPendingOperations();
        }
    }

    start() {
        if (!this.started) {
            this.started = true;
            setTimeout(() => this.pollServer(), 0);
        }
    }

    private async handleResponseMessage(items: ItemChangeListDto) {
        if (!items || !items.SyncId) {
            return;
        }

        if (items.Out) {
            this.outChangeKey = items.Out.ChangeKey;
        }

        // TODO: switch properly to the new server id!
        if (items.ServerId && items.ServerId !== this.currentServerId) {
            this.currentServerId = items.ServerId;
            items.HasMoreChanges = true;
        }

        console.log("Received sync Items response:", items.StartSyncId, "/", items.SyncId, "   ", Object.keys(items).filter(i => !IgnoredSyncKeys.includes(i)), ", HasMoreChanges:", items.HasMoreChanges);

        if (items.PublicKeys) {
            items.DecodedPublicKeys = await this.enrichPgpKeyItemChanges(items.PublicKeys);
        }
        if (items.SecretKeys) {
            items.DecodedSecretKeys = await this.enrichPgpKeyItemChanges(items.SecretKeys);
        }

        this.store.dispatch(SyncActions.syncResponseReceived(items));

        await this.db.saveSyncStateChange(items);

        if (items.Folders) {
            const {folderState} = this.store.getState();
            await this.db.saveFolders(folderState.folderList);
        }
    }

    private async enrichPgpKeyItemChanges(keyChanges: PgpKeyChangesDto): Promise<DecodedPgpKeyChangesDto> {
        return {
            Changed: await decodePgpKeyDtos(keyChanges.Changed),
            DeletedIds: keyChanges.DeletedIds,
        };
    }

    private async removeFinishedOperations(completedOpIds: number[]): Promise<void> {
        await this.db.removeOperations(completedOpIds);

        this.inFlightOpIds = this.inFlightOpIds.filter(id => !completedOpIds.includes(id));

        await this.performOperationCleanup(completedOpIds);
    }

    private async performOperationCleanup(completedOpIds: number[]): Promise<void> {
        const {sync, folderState} = this.store.getState();
        const {operations} = sync;

        const onEmailsChanged = folderState.lazyLoadDataSource?.onEmailsChanged;
        let emailsChanged = false;
        for (const operation of operations) {
            if (completedOpIds.includes(operation.Id)) {
                console.debug("Operation completed: ", operation);

                if (operation.SaveDraftEmail && !operation.SaveDraftEmail.Email.IsDraft) {
                    console.debug("Draft email deleted: ", operation.SaveDraftEmail.Email.Uid);

                    await this.db.deleteDraftEmails([operation.SaveDraftEmail.Email.Uid]);
                }

                if (operation.ManageEmails) {
                    const manageEmails = operation.ManageEmails;
                    emailsChanged = emailsChanged
                        || manageEmails?.Delete !== undefined
                        || manageEmails?.MarkAsJunk !== undefined
                        || manageEmails?.MoveToFolderId !== undefined;
                }
            }
        }
        if (emailsChanged && onEmailsChanged) {
            onEmailsChanged();
        }
    }

    private async handleFailedOperations(response: OperationResponseDto): Promise<void> {
        if (!response.FailedOps || response.FailedOps.length === 0) {
            return;
        }
        const failedOpIds = response.FailedOps.map(fo => fo.Id);
        await this.removeFinishedOperations(failedOpIds);

        let failureMessage = response.FailedOps.length === 1
            ? response.FailedOps[0].Error
            : "Some operations couldn't be synced with the remote server";

        const {operations} = this.store.getState().sync;
        for (const operation of operations) {
            const failedOp = response.FailedOps.find(fo => fo.Id === operation.Id);
            if (failedOp) {
                console.warn("Operation failed: ", failedOp, operation);

                if (operation.SaveDraftEmail) {
                    await this.restoreFailedSentEmail(operation.SaveDraftEmail);

                    failureMessage = `Your email couldn't be sent: ${failedOp.Error}`;
                }
                // TODO: make the error more specific for each type of operation (e.g. indicate that an email couldn't be sent if sending an email!)
            }
        }

        this.store.dispatch(SystemActions.showError(failureMessage));
    }

    private async restoreFailedSentEmail(saveDraftEmail: SaveDraftEmailRemoteOperationDto): Promise<void> {
        const restoredDraftEmail: DbDraftEmail = {...saveDraftEmail.Email, IsNew: false, IsDraft: true};
        await this.db.saveDraftEmail(restoredDraftEmail);
        this.store.dispatch(ComposeEmailActions.amendDraftEmail(restoredDraftEmail));

        EmailLocation.openDraftEmailByUid(saveDraftEmail.Email.Uid);
    }

    private async sendFiles(fileOperations: RemoteOperationDto[]) {
        if (fileOperations.length === 0) {
            return;
        }

        for (const operation of fileOperations) {
            if (operation.AddAttachment) {
                try {
                    const attachment = operation.AddAttachment;

                    console.log("Uploading attachment data: operationId=", operation.Id, ", attachmentName=", attachment.AttachmentUid);

                    const formData = new FormData();
                    formData.append('fileToUpload', attachment.File);
                    formData.append('uid', attachment.EmailUid);
                    formData.append('aid', attachment.AttachmentUid);

                    if (attachment.ContentId) {
                        formData.append('cid', attachment.ContentId);
                    }

                    const {emailId} = await sendFormData("/Mail/UploadAttachment.aspx", formData);

                    await this.markAttachmentAsUploaded(attachment);

                    const callback = operation.AddAttachment.Callback;
                    if (callback && attachment.ContentId) {
                        callback(`/Mail/DownloadAttachment.aspx?emailId=${emailId}&cid=${encodeURIComponent(attachment.ContentId)}`, {alt: attachment.File.name});
                    }

                } catch (ex: any) {
                    console.error(ex);
                    // TODO: handle errors properly here
                    this.store.dispatch(SystemActions.showFormattedError("Error performing operation", ex));
                }
            }
        }
        // Remove the operations from the queued list
        const opIds = fileOperations.map(o => o.Id);

        const response: OperationResponseDto = {CompletedOpIds: opIds, FailedOps: undefined, ServerId: this.currentServerId};
        await this.handleOperationsResponseMessage(response);
    }

    private async markAttachmentAsUploaded(attachmentOpDto: AddAttachmentRemoteOperationDto) {
        const currentAttachmentList = this.store.getState().compose.draftAttachments;
        if (!currentAttachmentList) return;

        this.store.dispatch(await AttachmentActions.markDraftEmailAsUploaded(attachmentOpDto.AttachmentUid));
    }

    private async sendPendingOperations() {
        try {
            const {operations} = this.store.getState().sync;

            const pendingOperations = operations.filter(op => !this.inFlightOpIds.includes(op.Id));

            if (this.inFlightOpIds.length > 0) {
                console.debug("In flight operations: ", this.inFlightOpIds);
            }

            this.inFlightOpIds = [...this.inFlightOpIds, ...pendingOperations.map(op => op.Id)];

            const operationsToSend = pendingOperations.filter(isSimpleOperation);

            if (operationsToSend.length > 0) {
                const request: RequestDto = {
                    Ops: operationsToSend,
                    ServerId: this.currentServerId,
                };

                console.debug(`Sending operations request`);

                const response = await performRawRestCall<OperationResponseDto>(`/Mail3/PerformOperations`, request);
                if (response.ServerId && response.ServerId !== this.currentServerId) {
                    this.currentServerId = response.ServerId;
                    this.inFlightOpIds = [];
                    return;
                }
                // TODO: check the response status etc.!
                await this.handleOperationsResponseMessage(response);
            }

            const fileOperations = pendingOperations.filter(op => !isSimpleOperation(op));

            // TODO: combine queued addition/removal of attachments prior to sending the attachments to the server!

            if (fileOperations.length > 0) {
                await this.sendFiles(fileOperations);
            }

        } catch (ex) {
            console.error("Perform operation error", ex);
            this.inFlightOpIds = [];
            // TODO: handle processing errors
        }
    }

    private async handleOperationsResponseMessage(response: OperationResponseDto) {
        if (response.CompletedOpIds) {
            const completedOpIds = response.CompletedOpIds;
            await this.removeFinishedOperations(completedOpIds);
        }
        await this.handleFailedOperations(response);

        if (response.CompletedOpIds || response.FailedOps) {
            console.log("Operations response:", Object.keys(response).join(", "));

            if (response.CompletedOpIds && response.CompletedOpIds.length > 0) {
                console.debug("CompletedOpIds:", response.CompletedOpIds);
            }
            if (response.FailedOps && response.FailedOps.length > 0) {
                console.warn("FailedOps:", response.FailedOps);
            }
        }

        const operations = await this.db.getAllOperations();
        this.store.dispatch(SyncActions.setOperations({operations}));
    }
}

function isSimpleOperation(operation: RemoteOperationDto) {
    return !operation.AddAttachment;
}
