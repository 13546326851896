import Cookies, {CookieSetOptions} from "universal-cookie";

const cookies = new Cookies();

export function getUsernameCookie(): string {
    return cookies.get("un") ?? "";
}

export function clearUsernameCookie() {
    cookies.set("un", "");
}

export function setCookie(name: string, value: string, options: CookieSetOptions) {
    cookies.set(name, value, options);
}
