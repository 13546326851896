import {Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio} from "@mui/material";
import {DateField} from "../common/DateField";
import {dateTimeFromNullableTicks, ticksFromNullableDateTime, UtcZone} from "../../util/DateHelper";
import React, {FC} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {EmailActions} from "../../actions/EmailActions";
import {DateTime} from "luxon";
import styled from "@emotion/styled";
import {RelativeDateTimeOption, RelativeDateTimeStringOption} from "../../domain/RelativeDateTimeOption";

const OptionDiv = styled.div`
  display: flex;
  align-items: center;

  .MuiInput-root {
    margin-top: 0;
  }

  .MuiFormControlLabel-root {
    margin-left: 0;
  }
`;

interface ReminderOptionProps {
    label: string;
    option: RelativeDateTimeStringOption;
    value: RelativeDateTimeOption;
}

const ReminderOption: FC<ReminderOptionProps> = (props) => {
    const dispatch = useAppDispatch();

    function setRemindMeLaterOption(newOption: RelativeDateTimeOption) {
        dispatch(EmailActions.setRemindMeLaterDate(newOption));
    }
    
    return (
        <OptionDiv>
            <FormControlLabel label={props.label}
                              control={
                                  <Radio checked={props.value === props.option}
                                         onChange={() => setRemindMeLaterOption(props.option)}/>
                              }/>
        </OptionDiv>
    )
}

export const RemindMeLaterSettingsDialog = () => {
    const dispatch = useAppDispatch();

    const open = useAppSelector(s => s.itemState.showRemindMeLaterSettingsDialog);
    const remindMeLaterDate = useAppSelector(s => s.itemState.remindMeLaterDate);
    const emailId = useAppSelector(s => s.itemState.selectedEmailHeaders[0]?.Id);   // TODO: use correct email ids here!

    function closeDialog() {
        dispatch(EmailActions.showRemindMeLaterSettingsDialog(false));
    }

    function setRemindMeLaterDate(newDate: DateTime | null) {
        const newDateValue = ticksFromNullableDateTime(newDate, UtcZone);
        dispatch(EmailActions.setRemindMeLaterDate(newDateValue));
    }

    async function clearDateAndClose() {
        if (emailId) {
            await EmailActions.setRemindMeLaterDateOnEmails(dispatch, [emailId], null);
            closeDialog();
        }
    }

    async function handleDateChange() {
        if (emailId) {
            await EmailActions.setRemindMeLaterDateOnEmails(dispatch, [emailId], remindMeLaterDate);
            closeDialog();
        }
    }

    return (
        <Dialog open={open}
                onClose={closeDialog}>

            <DialogTitle id="alert-dialog-title">Remind Me Later</DialogTitle>
            <DialogContent className="edit-email-category-dialog">

                <ReminderOption label="Tomorrow" option="Tomorrow" value={remindMeLaterDate}/>
                <ReminderOption label="In a week" option="OneWeek" value={remindMeLaterDate}/>
                <ReminderOption label="In a month" option="OneMonth" value={remindMeLaterDate}/>

                <OptionDiv>
                    <Radio checked={typeof remindMeLaterDate === "number" || !remindMeLaterDate}
                           onChange={() => setRemindMeLaterDate(null)}/>
                    <DateField label=" "
                               fullWidth
                               value={dateTimeFromNullableTicks(typeof remindMeLaterDate === "number" ? remindMeLaterDate : null, UtcZone)}
                               onChange={newDate => setRemindMeLaterDate(newDate)}/>
                </OptionDiv>
                
                <div>&nbsp;</div>
            </DialogContent>

            <DialogActions className="archive-dialog-buttons">
                <Button onClick={handleDateChange} color="primary" variant="contained">OK</Button>
                <Button onClick={clearDateAndClose} variant="contained" color="inherit" disabled={!remindMeLaterDate}>Clear</Button>
                <Button onClick={closeDialog} color="secondary" variant="contained">Cancel</Button>
            </DialogActions>

        </Dialog>
    );
}
