import {useAppSelector} from "../../hooks/ReduxHooks";
import {AttachmentFileCard} from "./AttachmentFileCard";

export const AttachmentFilesListView = () => {

    const attachments = useAppSelector(s => s.attachmentsState.attachments);

    return (
        <>
            {attachments.map(a => <AttachmentFileCard key={a.AttachmentId} attachment={a}/>)}
        </>
    );
}
