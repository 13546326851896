import {createAppProperties} from "./LocationUtil";
import {AppDispatch} from "../AppStore";
import {AppState} from "../AppState";
import {LocationProperties} from "../util/LocationParser";
import {locationManager} from "../BootStrap";
import {UsersActions} from "../redux/UsersSlice";

export interface UsersLocationProperties {
    uId?: number;
}

export const UsersLocationPropertyNames = createAppProperties<UsersLocationProperties>("uId");

export class UsersLocation {

    static handleLocationChange(dispatch: AppDispatch, getState: () => AppState, newLocation: LocationProperties): void {

        const {usersState} = getState();

        if (usersState.selectedUser?.WebId !== newLocation.uId) {
            dispatch(UsersActions.selectUser({userId: newLocation.uId}));
        }
    }

    static selectUser(userId: number | undefined) {
        locationManager.updateWindowLocation("users", {uId: userId});
    }
}
