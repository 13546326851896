import "./FolderSelectionOverlay.scss";
import { ConnectedProps, connect } from 'react-redux';
import { AppState } from '../../AppState';
import React from 'react';
import { Centre } from '../Centre';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { FolderActions } from '../../actions/FolderActions';
import { Button } from '@mui/material';

const RenderFolderSelectionOverlay = ({ folderSelectionMode, onCancelMove }: FolderSelectionOverlayProps) => {
    if (folderSelectionMode === "email") {
    }

    return (
        <Centre className="folder-selection-overlay">
            <span>
                <KeyboardBackspaceIcon fontSize="large" />
                
                <span>Select the destination folder on the left</span>

                <Button onClick={() => onCancelMove()} variant="contained" color="secondary">Cancel</Button>
            </span>
        </Centre>
    );
};

const mapProps = ({ folderState }: AppState) => ({
    folderSelectionMode: folderState.folderSelectionMode,
});

const mapDispatch = {
    onCancelMove: FolderActions.cancelMoveFolder,
};

const connector = connect(mapProps, mapDispatch);

type FolderSelectionOverlayProps = ConnectedProps<typeof connector>;

const FolderSelectionOverlay = connector(RenderFolderSelectionOverlay);

export default FolderSelectionOverlay;
