import {SimpleCheckbox} from "../common/SimpleCheckbox";
import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import ComposeOptions from "../../domain/ComposeOptions";
import {SET_COMPOSE_OPTIONS, SetComposeOptionsAction} from "../../actions/ComposeEmailActionTypes";
import {ComposeEmailActions} from "../../actions/ComposeEmailActions";

export interface ComposeEmailSettingsDialogProps {
    hasCC: boolean;
    hasBCC: boolean;
    hasSender: boolean;
}

export const ComposeEmailSettingsDialog = ({hasCC, hasBCC, hasSender}: ComposeEmailSettingsDialogProps) => {
    const open = useAppSelector(s => s.compose.settingsDialogOpen);
    const options = useAppSelector(s => s.compose.composeOptions);

    const dispatch = useAppDispatch();

    function onSetOption(newOptions: Partial<ComposeOptions>) {
        const action: SetComposeOptionsAction = {
            type: SET_COMPOSE_OPTIONS,
            composeOptions: {...options, ...newOptions},
        };
        dispatch(action);
    }

    function onClose() {
        dispatch(ComposeEmailActions.setComposeEmailOptionsDialogOpen(false));
    }

    return (
        <Dialog open={open}
                onClose={onClose}>

            <DialogTitle id="alert-dialog-title">Compose Email Options</DialogTitle>

            <DialogContent className="edit-email-category-dialog">
                <div>
                    <SimpleCheckbox label="Show CC"
                                    checked={options.showCC}
                                    onChange={checked => onSetOption({showCC: checked})}
                                    disabled={hasCC}/>
                </div>
                <div>
                    <SimpleCheckbox label="Show BCC"
                                    checked={options.showBCC}
                                    onChange={checked => onSetOption({showBCC: checked})}
                                    disabled={hasBCC}/>
                </div>
                <div>
                    <SimpleCheckbox label="Show Sender"
                                    checked={options.showSender}
                                    onChange={checked => onSetOption({showSender: checked})}
                                    disabled={hasSender}/>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} color="primary" variant="contained">OK</Button>
            </DialogActions>
        </Dialog>
    );
}