import {locationManager} from '../BootStrap';
import {Dispatch} from 'redux';
import {AppState} from '../AppState';
import {LocationProperties} from '../util/LocationParser';
import {CalendarMode, CalendarState} from '../state/CalendarState';
import {DateTime} from 'luxon';
import {createAppProperties} from "./LocationUtil";
import {AllActionTypes} from "../actions/AllActionTypes";
import {parseIntSafe} from "../util/Formatters";
import {ContentLocation} from "./ContentLocation";
import {SERIES_CALENDAR_UID} from "../actions/CalendarActionTypes";
import {AppDispatch} from "../AppStore";
import {CalendarActions} from "../redux/CalendarSlice";
import {ItemActions} from "../actions/ItemActions";
import {DB} from "../db/DbManager";
import {calendarFromDto} from "../domain/CalendarDto";
import {ServiceWorkerMessage} from "../domain/ServiceWorkerMessaging";

export interface CalendarLocationProperties {
    year?: number;
    month?: number;
    week?: number;
    calMode?: CalendarMode;
}

export const CalendarLocationPropertyNames = createAppProperties<CalendarLocationProperties>("year", "month", "week", "calMode");

export class CalendarLocation {
    static setMonthAndYear(year: number, month: number): void {
        locationManager.updateWindowLocation("email", {year, month, calMode: "month"});
    }

    static setMonthWeekAndYear(year: number, month: number, week: number): void {
        locationManager.updateWindowLocation("email", {year, month, week});
    }

    static setCalendarViewMode(mode: CalendarMode): void {
        locationManager.updateWindowLocation("email", {calMode: mode || "month"});
    }

    static addToCurrentViewDate(direction: number): void {
        const {month, year, week, calMode} = locationManager.getLocation();
        if (!year) return;
        try {
            // TODO: use the mode appropriately here!
            let newDate = null;
            switch (calMode || "month") {
                case "month":
                    const monthDate = DateTime.fromObject({year, month});
                    newDate = monthDate.plus({months: direction});
                    break;
                case "week":
                    if (!week) return;
                    const weekDate = DateTime.fromObject({weekYear: year, weekNumber: week});
                    newDate = weekDate.plus({days: direction * 7});
                    break;
            }

            if (newDate) {
                this.setMonthWeekAndYear(newDate.year, newDate.month, newDate.weekNumber);
            }
        } catch (e) {
            console.log(e);
        }
    }

    static setCurrentViewToToday(): void {
        const now = DateTime.local();
        // TODO: use the mode appropriately here!
        locationManager.updateWindowLocation("email", {year: now.year, month: now.month});
    }

    static handleLocationChange(dispatch: AppDispatch, getState: () => AppState, newLocation: LocationProperties): void {
        const state = getState();

        const {calendarState} = state;
        const {year, month, week, mode} = calendarState;

        const newMonth = newLocation.month;
        const newYear = newLocation.year;
        const newWeek = newLocation.week;
        const newMode = newLocation.calMode || "month";

        switch (newMode) {
            case "month":
                if (newMonth && newYear && (month !== newMonth || year !== newYear || newMode !== mode)) {
                    dispatch(CalendarActions.setCalendarMode(newMode, newYear, newMonth, newWeek || week));
                }
                break;
            case "week":
                if (newWeek && newYear && (newYear !== year || newWeek !== week || newMode !== mode)) {
                    dispatch(CalendarActions.setCalendarMode(newMode, newYear, newMonth || month, newWeek));
                }
                break;
        }
    }

    static handleOpenWindowMessage(data: ServiceWorkerMessage) {
        const calId = parseIntSafe(data.calId);

        if (calId) {
            ContentLocation.showCalendar(SERIES_CALENDAR_UID, calId);
        }
    }
}

export async function ensureCalendarsLoaded(dispatch: AppDispatch, calendarState: CalendarState){
    if (!calendarState.calendarsLoaded) {
        console.log("Loading calendars from DB...");
        const calendars = await DB.getAllCalendars();
        if (calendars) {
            console.log("Calendars loaded");
            dispatch(CalendarActions.calendarsLoaded(calendars.map(calendarFromDto), true));
        }
    }
}

export async function handleViewCalendar(state: AppState, instanceId: number | undefined, calendarUid: string | undefined, dispatch: Dispatch<AllActionTypes>) {
    await ensureCalendarsLoaded(dispatch, state.calendarState);
    
    dispatch(ItemActions.showCalendarEntry(calendarUid, instanceId));
}
