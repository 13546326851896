import {AppStore} from "../AppStore";
import {RxSubjects} from "../state/RxSubjectsState";
import {debounceTime} from "rxjs/operators";
import {AttachmentFilesService} from "../services/attachments/AttachmentFileService";

const SEARCH_DEBOUNCE_TIME = 100;

export function registerAttachmentSearchTextSubjectHandler(store: AppStore) {
    const service = new AttachmentFilesService(store.dispatch);
    
    let lastSearchText = "";
    
    function handleNextLoad() {
        const {attachmentsState} = store.getState();
        
        console.log("Load flags", attachmentsState.shouldLoadMore, attachmentsState.moreAttachmentsAvailable);
        if (!attachmentsState.shouldLoadMore || !attachmentsState.moreAttachmentsAvailable) {
            return;
        }

        const offset = lastSearchText !== attachmentsState.searchText ? 0 : attachmentsState.currentOffset;
        lastSearchText = attachmentsState.searchText;
        
        service.searchAttachmentFiles(offset, 20, attachmentsState.searchText)
            .then(() => {
                setTimeout(() => RxSubjects.attachmentSearchText$.next(), 100);
            });
    }
    
    RxSubjects.attachmentSearchText$
        .pipe(debounceTime(SEARCH_DEBOUNCE_TIME))
        .subscribe(() => handleNextLoad());
}
