import {EmailLocationProperties} from "../locations/EmailLocation";
import {FolderLocationProperties} from "../locations/FolderLocation";
import {CalendarLocationProperties} from '../locations/CalendarLocation';
import {GlobalLocationProperties} from "./LocationManager";
import {SystemStatusLocationProperties} from "../locations/SystemStatusLocation";
import {ContentLocationProperties} from "../locations/ContentLocation";
import {parseIntSafe} from "./Formatters";
import {NotesLocationProperties} from "../locations/NotesLocation";
import {PgpKeysLocationProperties} from "../locations/PgpKeysLocation";
import {UsersLocationProperties} from "../locations/UsersLocation";

export type LocationProperties =
    GlobalLocationProperties
    & EmailLocationProperties
    & FolderLocationProperties
    & CalendarLocationProperties
    & SystemStatusLocationProperties
    & ContentLocationProperties
    & NotesLocationProperties
    & PgpKeysLocationProperties
    & UsersLocationProperties;

type LocationKeys = keyof LocationProperties;

export function parseLocation(location: string): LocationProperties {
    let result: LocationProperties = {app: "email"};

    if (location.length < 2) {
        return result;
    }

    const tokenList = (location.charAt(0) === '#' ? location.substring(1) : location)
        .split('&')
        .map(e => e.split('='))
        .filter(t => t.length === 2);

    for (const tokens of tokenList) {
        const propName: LocationKeys = tokens[0] as LocationKeys;

        if (tokens[1].match(/^[-\d]+$/) && propName !== "searchText") {
            const numValue = parseIntSafe(tokens[1]);
            setProp(result, propName, numValue);
        } else {
            setProp(result, propName, unescape(tokens[1]));
        }
    }
    return result;
}

function prop<T, K extends keyof T>(obj: T, key: K) {
    return obj[key];
}

function setProp<T, K extends keyof T>(obj: T, key: K, value: any) {
    return obj[key] = value;
}

export function deleteExtraProps(obj: any, propsToInclude: readonly string[]): any {
    const keys = Object.keys(obj);

    for (const key of keys) {
        if (!propsToInclude.includes(key)) {
            delete obj[key];
        }
    }
    return obj;
}

export function buildGenericLocation(prefix: string, location: any): string {
    if (!location) {
        return "";
    }
    const keys = Object.keys(location);
    if (keys.length === 0) {
        return "";
    }

    return prefix + keys
        .map(key => key + "=" + escape(prop(location, key)?.toString() || ""))
        .join("&");
}

export function buildLocation(location: any): string {
    return buildGenericLocation("#", location);
}
