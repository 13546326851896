import {InputProps as StandardInputProps} from "@mui/material/Input/Input";
import {parseIntSafe} from "../../util/Formatters";
import {TextField} from "@mui/material";
import React from "react";

export interface SimpleNumberTextFieldProps {
    label?: string;
    value: number | undefined;

    type?: React.InputHTMLAttributes<unknown>['type'];

    onChange: (newValue: number | undefined) => void;

    className?: string;
    InputProps?: Partial<StandardInputProps>;
}

export const SimpleNumberTextField = ({
                                          label, value, onChange, type,
                                          className, InputProps
                                      }: SimpleNumberTextFieldProps) => {
    return (
        <TextField className={"number-control " + className}
                   label={label}
                   value={value??0}
                   type={type ?? "number"}
                   InputProps={InputProps}
                   onChange={e => onChange(parseIntSafe(e.target.value))}/>
    );
}
