import {AppState} from "../AppState";
import {Dispatch} from "redux";
import {AccountActionHelper} from "../actions/AccountActionHelper";
import {GateKeeperRecordDto} from "../services/messages/GateKeeperRecordDto";
import {DB} from "../db/DbManager";
import {AccountsActions} from "../redux/AccountsSlice";

export async function handleViewAccount(state: AppState, newAccountId: number, newAccountIdStr: string | undefined, dispatch: Dispatch) {
    let {accounts} = state.accountsState;

    if (!accounts.length) {
        accounts = await DB.getAllActiveGateKeeperAccounts();

        dispatch(AccountsActions.accountsLoaded(accounts));
    }
    
    const newAccount: GateKeeperRecordDto | undefined =
        accounts.find(c =>
            c.IsArchived === 0 && (
                (c.Id === newAccountId && !newAccountIdStr && newAccountId !== -1)
                // eslint-disable-next-line
                || (c.ClientId && newAccountIdStr && c.ClientId == newAccountIdStr)));

    if (!newAccount || (newAccount.Id === -1 && !newAccountIdStr)) {
        return;
    }

    try {
        await AccountActionHelper.selectAccount(newAccount, dispatch, state);
    } catch(e) {
        console.error("Error selecting account", e);
    }
}
