import React from 'react';
import "./EditFolderDialog.scss";
import {FolderActions} from '../../actions/FolderActions';
import {TextFieldDialog} from "../common/TextFieldDialog";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";

export const EditFolderDialog = () => {
    const dispatch = useAppDispatch();

    const showDialog = useAppSelector(({folderState}) => folderState.showEditFolderDialog);
    const editingFolder = useAppSelector(({folderState}) => folderState.editingFolder);

    async function handleFinishEditFolder(newName: string | null) {
        if (newName) {
            dispatch(await FolderActions.completeEditFolder(editingFolder, newName));
        }
        dispatch(FolderActions.hideEditFolderDialog());
    }

    const isNewFolder = editingFolder.folderId === -1;

    return (
        <TextFieldDialog title={isNewFolder ? "Create Folder" : "Rename Folder"}
                         message="Enter the folder name below:"
                         initialValue={editingFolder.name}
                         open={showDialog}
                         onClose={handleFinishEditFolder}
        />
    );
}
