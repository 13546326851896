import {AppState} from '../../AppState';
import {useSelector} from 'react-redux';
import React, {FC} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from '@mui/material';
import {NotesActions} from "../../redux/NotesSlice";
import {useAppDispatch, useAppStore} from "../../hooks/ReduxHooks";
import {NotesLocation} from "../../locations/NotesLocation";
import {isNotePage} from "../../domain/NotePageDto";

export const EditNoteDialog: FC = () => {

    const isOpen = useSelector(({notesState}: AppState) => notesState.showEditDialog);
    const editingItem = useSelector(({notesState}: AppState) => notesState.editingSectionOrPage);

    const dispatch = useAppDispatch();

    const isNew = editingItem.Id === -1;

    const store = useAppStore();
    
    async function handleFinishEdit() {
        dispatch(await NotesActions.completeEditNote(editingItem));
        onClose();

        const clientId = editingItem.ClientId;
        if (clientId && isNew) {
            if (isNotePage(editingItem)) {
                NotesLocation.selectPageByClientId(clientId);
            } else {
                NotesLocation.selectSectionByClientId(clientId);
                NotesLocation.selectPageByClientId(store.getState().notesState.pages[0].ClientId!);
            }
        }
    }

    function onClose() {
        dispatch(NotesActions.hideRenameNoteDialog());
    }
    
    function onSetEditFolderName(newName: string) {
        dispatch(NotesActions.setEditNoteName(newName));
    }
    
    return (
        <Dialog open={isOpen}
                onClose={onClose}>
            <DialogTitle id="alert-dialog-title">{isNew ? "Create Page" : "Rename Page"}</DialogTitle>

            <DialogContent className="edit-email-category-dialog">
                <DialogContentText id="alert-dialog-description">Enter the name below:</DialogContentText>
                <TextField value={editingItem.Name}
                           onChange={e => onSetEditFolderName(e.target.value)}
                           onKeyPress={async (e) => {
                               if (e.key === "Enter") await handleFinishEdit();
                           }}
                           autoFocus fullWidth/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleFinishEdit} color="primary" variant="contained">OK</Button>
                <Button onClick={onClose} color="secondary" variant="contained">Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}
