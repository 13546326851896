import {CalendarEntryData} from "../services/calendar/CalendarEntryData";

export const SERIES_CALENDAR_UID = "series";

export const SHOW_CALENDAR_ENTRY = "SHOW_CALENDAR_ENTRY";

export interface ShowCalendarEntryAction {
    type: typeof SHOW_CALENDAR_ENTRY;
    calendarUid: string | undefined;
    instanceId: number | undefined;
}

export const CREATE_CALENDAR_ENTRY = "CREATE_CALENDAR_ENTRY";

export interface CreateCalendarEntryAction {
    type: typeof CREATE_CALENDAR_ENTRY;
    calendar: CalendarEntryData;
}

export type CalendarActionTypes =
    ShowCalendarEntryAction
    | CreateCalendarEntryAction
;
