import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {initialAttachmentFilesState} from "../state/AttachmentFilesState";
import {AttachmentFileDto} from "../services/attachments/dto/AttachmentFileDto";
import {AttachmentFileListDto} from "../services/attachments/dto/AttachmentFileListDto";
import {RxSubjects} from "../state/RxSubjectsState";

export const attachmentFilesSlice = createSlice({
    name: "files",
    initialState: initialAttachmentFilesState,
    reducers: {
        addAttachmentFiles(state, {payload}: PayloadAction<AttachmentFileListDto>) {
            if (payload.Offset === 0) {
                state.attachments = payload.Attachments;
            } else {
                const amountToDelete = state.attachments.length - payload.Offset;
                console.log("Amount to delete: ", amountToDelete);
                state.attachments.splice(payload.Offset, amountToDelete, ...payload.Attachments);
            }
            state.currentOffset = state.attachments.length;
            state.moreAttachmentsAvailable = payload.MoreAvailable;
        },
        setShouldLoadMore(state, {payload: shouldLoadMore}: PayloadAction<boolean>) {
            state.shouldLoadMore = shouldLoadMore;
        },
        setSelectedAttachment(state, {payload: file}: PayloadAction<AttachmentFileDto | undefined>) {
            state.selectedAttachment = file;
        },
        // setSelectedFileIncrementally(state, {payload: direction}: PayloadAction<number>) {
        //     const {files} = state;
        //     const {selectedFile} = state;
        //     if (selectedFile) {
        //         const index = files.findIndex(f => f.Name === selectedFile.Name);
        //         const newFile = files[index + direction];
        //         state.selectedFile = newFile || state.selectedFile;
        //     } else {
        //         state.selectedFile = direction > 0 ? files[0] : files[files.length + direction];
        //     }
        // },
        setAttachmentsSearchText(state, {payload: text}: PayloadAction<string>) {
            state.searchText = text;
            state.moreAttachmentsAvailable = true;
            state.shouldLoadMore = true;
            RxSubjects.attachmentSearchText$.next();
        },
        // showConfirmDeleteSelectedFile(state, {payload: show}: PayloadAction<boolean>) {
        //     if (state.selectedFile || state.showConfirmDeleteSelectedFile) {
        //         state.showConfirmDeleteSelectedFile = show;
        //     }
        // },
    }
});

export namespace AttachmentFilesActions {
    export const {
        addAttachmentFiles,
        setSelectedAttachment,
        setShouldLoadMore,
        // setSelectedFileIncrementally,
        setAttachmentsSearchText,
        // showConfirmDeleteSelectedFile,
    } = attachmentFilesSlice.actions;

    export const actions = attachmentFilesSlice.actions;
}
