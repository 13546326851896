import {Dispatch} from "redux";
import {SystemActions} from "./actions/SystemActions";

export function isNotificationApiSupported() {
    return ('serviceWorker' in navigator) && ('PushManager' in window);
}

export function registerForPushNotifications() {
    if (isNotificationApiSupported()) {
        performServiceWorkerAndPushNotificationRegistration();
    } else {
        // Not supported
        console.warn("Browser doesn't support push notifications or service workers");
    }
}

function performServiceWorkerAndPushNotificationRegistration() {
    window.addEventListener('load', registerServiceWorker);
}

export function attachPermissionChangeHook(dispatch: Dispatch) {
    if ('permissions' in navigator) {
        // Listen for changes to the notification permissions
        navigator.permissions
            .query({name: 'notifications'})
            .then(function (notificationPerm) {
                notificationPerm.onchange = function () {
                    dispatch(SystemActions.updateNotificationPermission());

                    switch (Notification.permission) {
                        case  "granted":
                            dispatch(SystemActions.showNotification("You will now receive push notifications"));
                            break;
                        case "denied":
                            dispatch(SystemActions.showWarning("Push notifications disabled by the browser"));
                            break;
                    }
                };
            });
    }
}

export async function registerServiceWorker() {
    console.log("Registering service worker");
    try {
        const registration = await navigator.serviceWorker.register("./mail-service-worker.js")
        console.log("Service worker registered");
        registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            if (installingWorker == null) {
                console.debug("Service worked state changed to", registration.active?.state);
                return;
            }
            installingWorker.onstatechange = () => {
                console.debug("Service worked state changed to", installingWorker.state);
                if (installingWorker.state === 'installed') {
                    if (navigator.serviceWorker.controller) {
                        handleRegistrationUpdate(registration);
                    } else {
                        handleSuccessfulRegistration(registration);
                    }
                }
            };
        };
        handleSuccessfulRegistration(registration);
    } catch (error) {
        console.error('Error during service worker registration:', error);
    }
}

function handleSuccessfulRegistration(registration: ServiceWorkerRegistration) {
    console.log('ServiceWorker registration successful with scope: ', registration.scope);

    if (Notification.permission !== "granted") {
        console.log("Notification permissions not yet granted - not requesting push notification");
        return;
    }

    const subscribeOptions = {
        userVisibleOnly: true,
        applicationServerKey: 'BJlglodA4duv1pnfYtN4cIAW-PBA-Uu-AmZ-msBYbSUvVPBh6Q7cisZkXJHjclPyxwL3ssVTYgD83n_1ssL04Qc'
    };

    registration.pushManager
        .subscribe(subscribeOptions)
        .then(function (pushSubscription) {

            // TODO: use a new API instead of the legacy one
            fetch("/Mail/MailService.asmx/RegisterPushSubscription?service=email", {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                credentials: 'include',
                body: JSON.stringify(pushSubscription)
            }).then(function (response) {
                if (!response.ok) {
                    console.error('Bad status code from server', response);
                    return;
                }
                response.json().then(function (result) {
                    if (result.Error) {
                        console.error('PushSubscription error: ', result.Error);
                    } else {
                        console.log('PushSubscription saved successfully');
                    }
                });
            });
        })
        .catch(error => {
            console.log('PushSubscription failed: ', error);
        });
}

function handleRegistrationUpdate(registration: ServiceWorkerRegistration) {
    console.log('New content is available and will be used when all tabs for this page are closed');
}
