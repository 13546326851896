import { CssSelector } from './CssSelector';
import { CssProperty } from './CssProperty';
import { CssRuleType } from './CssRuleType';

export class CssRule {
    selectors: CssSelector[] = [];
    properties: CssProperty[] = [];
    type: CssRuleType = CssRuleType.Normal;

    toString() {
        if (this.type === CssRuleType.MediaStart) {
            return this.ruleStartString();
        }
        if (this.type === CssRuleType.MediaEnd) {
            return "}";
        }
        return this.ruleStartString() +
            this.properties.join("") +
            "}";
    }

    private ruleStartString() {
        return this.selectors.join(", ") + " {";
    }
}
