import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {Rating} from "@mui/material";
import React from "react";
import {PhotoBrowserService} from "../../services/photos/PhotoBrowserService";

export const PhotoRatingControls = () => {
    const breadcrumbs = useAppSelector(s => s.photosState.breadcrumbs);
    const selectedPhotoName = useAppSelector(s => s.photosState.selectedPhotoName);
    const selectedPhotoRating = useAppSelector(s => s.photosState.photos.find(p => p.Name === selectedPhotoName)?.Rating ?? null);

    const dispatch = useAppDispatch();

    async function onSetPhotoRating(rating: number | null) {
        if (!selectedPhotoName) return;

        await new PhotoBrowserService(dispatch).ratePhoto(breadcrumbs, selectedPhotoName, rating);
    }

    return (
        <Rating className="rating-control"
                value={selectedPhotoRating}
                onChange={(_, rating) => onSetPhotoRating(rating)}/>
    );
}
