import {FC, MouseEvent} from "react";
import {AttachmentFileDto} from "../../services/attachments/dto/AttachmentFileDto";
import {StackPanel} from "../panels/StackPanel";
import classNames from "classnames";
import {extractFriendlyNamesFromEmailAddresses, formatFileSize} from "../../util/Formatters";
import {RelativeDateTime} from "../common/RelativeDateTime";
import {EmailActions} from "../../actions/EmailActions";
import {AppState} from "../../AppState";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {ContentLocation} from "../../locations/ContentLocation";
import {AttachmentFilesActions} from "../../redux/AttachmentFilesSlice";
import {EmailLocation} from "../../locations/EmailLocation";

export const AttachmentFileCard: FC<{ attachment: AttachmentFileDto }> = props => {
    const {attachment} = props;
    
    const selected = useAppSelector(s=>s.attachmentsState.selectedAttachment?.AttachmentId === attachment.AttachmentId);
    
    const dispatch = useAppDispatch();
    
    const contactLookupMap = useAppSelector(({contactState}: AppState) => contactState.contactLookupMap);

    const contacts = extractFriendlyNamesFromEmailAddresses(attachment.EmailFrom, contactLookupMap);
    
    function onDownloadFile() {
        EmailActions.downloadAttachment(attachment.AttachmentId, attachment.Name);
    }

    function onOpenEmail(event: MouseEvent) {
        EmailLocation.openEmailById(attachment.EmailId, attachment.EmailFolderId);
        event.stopPropagation();
    }
    
    function onOpenContact(event: MouseEvent) {
        const contactId = contacts[0]?.Contact?.Id;
        if (contactId) {
            ContentLocation.showContact(contactId, undefined);
        }
        console.log(contacts[0]);
        event.stopPropagation();
    }
    
    function onContextMenu() {
        dispatch(AttachmentFilesActions.setSelectedAttachment(attachment));
    }

    return (
        <StackPanel orientation="horizontal"
                    className={classNames("file", "selectable", "pointer", {"selected": selected})}
                    title={attachment.Name}
                    onClick={onDownloadFile}
                    onContextMenu={onContextMenu}>
            <span className="image">
                <img src={attachment.Image} alt="Icon"/>
            </span>

            <StackPanel orientation="vertical" middle={true} className="details">
                <span className="name">{attachment.Name}</span>
                
                <span className="subject" title={attachment.EmailSubject}>
                    <button className="link" onClick={e => onOpenEmail(e)}>{attachment.EmailSubject}</button>
                </span>
                
                <span className="from only-on-hover" title={contacts[0]?.Address}>
                    {contacts[0]?.Contact 
                        ? <button className="link" onClick={e => onOpenContact(e)}>{contacts[0]?.Name}</button>
                        : contacts[0]?.Name ?? contacts[0]?.Address}
                </span>
                
                <StackPanel className="size-and-date only-on-hover" align={"stretch"}>
                    <span>{formatFileSize(attachment.Size)}</span>
                    <span><RelativeDateTime dateTime={attachment.EmailDate}/></span>
                </StackPanel>
            </StackPanel>

        </StackPanel>
    );
}
