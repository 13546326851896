import React, {FC} from "react";

import "./NotificationRequestBanner.scss";

import {registerServiceWorker} from "../../NotificationManager";
import {SystemActions} from "../../actions/SystemActions";
import {useAppDispatch} from "../../hooks/ReduxHooks";
import {DB} from "../../db/DbManager";
import {isTearOffWindow} from "../../App";

export const NotificationRequestBanner: FC = () => {
    const dispatch = useAppDispatch();

    async function requestPermission() {
        const newPermission = await Notification.requestPermission();

        dispatch(SystemActions.updateNotificationPermission());
        if (newPermission === "granted") {
            await registerServiceWorker();
        }
    }
    
    if (DB.isPrivateBrowserMode() || isTearOffWindow) {
        return null;
    }

    return (
        <div className="notification-request-banner" onClick={requestPermission}>
            Click here to receive email and calendar notifications
        </div>
    );
};
