import React from "react";
import {EmailThreadContentDto} from "../../domain/EmailContentDto";
import EmailContentView from "./EmailContentView";
import {formatSubject} from "../../util/Formatters";
import {HideContentButton} from "../common/HideContentButton";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {EmailViewMode} from "../../domain/EmailViewMode";
import {ItemActions} from "../../actions/ItemActions";
import {EmailViewOptions} from "../../domain/EmailViewOptions";

export interface EmailThreadViewProps {
    itemContent: EmailThreadContentDto;
}

export const EmailThreadView = ({itemContent}: EmailThreadViewProps) => {
    const emailViewOptions = useAppSelector(s => s.itemState.emailViewOptions);

    const dispatch = useAppDispatch();

    function onChangeViewMode(newValue: string | null) {
        const newMode: EmailViewMode = newValue === "thread-view" ? "thread-view" : "single";
        const newOptions: EmailViewOptions = {...emailViewOptions, viewMode: newMode};
        dispatch(ItemActions.setEmailViewOptions(newOptions));
    }

    const isEmailThread = itemContent.Emails.length > 1;
    let emails = itemContent.Emails;

    if (emailViewOptions.viewMode === "single") {
        emails = [itemContent.Emails.find(e => e.Id === itemContent.Id)!];
        itemContent = {...itemContent, Emails: emails};
    }
    
    const hasUnverifiedSender = itemContent.Emails.some(e => !e.IsVerifiedSender && !e.IsOutgoing);

    const subject = formatSubject(emails[0], emailViewOptions.viewMode === "thread-view" && itemContent.Emails.length > 1);

    return (
        <div className="email-thread-view">
            <div className="email-subject-placeholder">&nbsp;</div>
            {hasUnverifiedSender && 
                <div className="unverified-sender">
                    {emails.length > 1 ? "One or more of the senders' names might be a forgery" : "The sender name might be a forgery"}
                </div>
            }

            <HideContentButton/>

            <div className="email-thread-content">
                {emails.map((_, i) => (
                    <EmailContentView key={i}
                                      emailThreadContent={itemContent}
                                      emailIndex={i}/>
                ))}
            </div>

            <div className="email-subject" title={subject}>
                {isEmailThread && (
                    <ToggleButtonGroup value={emailViewOptions.viewMode}
                                       exclusive
                                       onChange={(_, newValue) => onChangeViewMode(newValue)}>
                        <ToggleButton value="thread-view" title="Toggle Thread View"><DynamicFeedIcon/></ToggleButton>
                    </ToggleButtonGroup>
                )}

                <span>{subject}</span>
            </div>
        </div>
    );
}
