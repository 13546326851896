import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ContactState, initialContactState} from "../state/ContactState";
import {createContactLookupMap} from "../util/Formatters";
import {DbContact} from "../db/DbSchema";
import {applySyncChangesWithClientIds} from "../reducers/SyncHelper";
import {applyClientDtoRemoteOperationLocally} from "../util/ReducerUtil";
import {AddRemoteOperationPayload, SyncActions} from "./SyncSlice";
import {ItemChangeListDto} from "../services/messages/ItemChangeListDto";

// See here:
// https://redux-toolkit.js.org/api/createSlice#extrareducers

export const contactsSlice = createSlice({
    name: "contacts",
    initialState: initialContactState,
    reducers: {
        contactsLoaded: handleContactsLoaded,
    },
    extraReducers: builder =>
        builder
            .addCase(SyncActions.syncResponseReceived, handleSyncResponse)
            .addCase(SyncActions.addRemoteOperation, handleAddRemoteOperation)
});

function handleContactsLoaded(state: ContactState, {payload: contacts}: PayloadAction<DbContact[]>) {
    state.contacts = contacts;
    state.contactLookupMap = createContactLookupMap(contacts);
}

function handleSyncResponse(state: ContactState, {payload: changes}: PayloadAction<ItemChangeListDto>) {
    if (changes.Contacts) {
        state.contacts = applySyncChangesWithClientIds(state.contacts, changes.Contacts, c => c);
        state.contactLookupMap = createContactLookupMap(state.contacts);
    }
}

function handleAddRemoteOperation(state: ContactState, {payload}: PayloadAction<AddRemoteOperationPayload>) {
    const newContacts = applyClientDtoRemoteOperationLocally(payload.operation.ManageContact, state.contacts, c => c);
    if (newContacts !== state.contacts) {
        state.contactLookupMap = createContactLookupMap(newContacts);
        state.contacts = newContacts;
    }
}

export namespace ContactActions {
    export const {
        contactsLoaded,
    } = contactsSlice.actions;
}
