import React, {RefObject} from "react";
import {isJunkFolder, isOutgoingFolder} from "../../reducers/FolderReducer";
import {DropDownMenuItem} from "../toolbar/DropDownMenu";
import {ContextMenu} from "../common/ContextMenu";
import {EmailHeader} from "../../services/messages/EmailHeaderDto";
import {EmailActions} from "../../actions/EmailActions";
import {connect, ConnectedProps} from "react-redux";
import DraftsIcon from '@mui/icons-material/Drafts';
import FlagIcon from '@mui/icons-material/Flag';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReplyIcon from '@mui/icons-material/Reply';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import ForwardIcon from '@mui/icons-material/Forward';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import {EmailLocation, handleViewEmail} from "../../locations/EmailLocation";
import {AppState} from "../../AppState";
import {isEmailContent} from "../../domain/ItemContent";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlined from "@mui/icons-material/PushPinOutlined";

interface EmailContextMenuExtraProps {
    elementRef: RefObject<HTMLDivElement>;
}

const RenderEmailContextMenu = ({
                                    elementRef,
                                    selectedFolder,
                                    selectedEmailHeaders,
                                    hasReadEmails, hasUnreadEmails,
                                    hasFlaggedEmails, hasUnflaggedEmails,
                                }: EmailContextMenuProps & EmailContextMenuExtraProps) => {

    const isOutgoing = isOutgoingFolder(selectedFolder?.type);
    const isJunk = isJunkFolder(selectedFolder?.icon);

    const haveEmailContent = useAppSelector(s => isEmailContent(s.itemState.itemContent) && selectedEmailHeaders[0]?.Id === s.itemState.itemContent.Id);

    const hasPinnedEmails = useAppSelector(s => s.itemState.selectedEmailHeaders.some(e => !!e.Pinned));
    const hasUnpinnedEmails = useAppSelector(s => s.itemState.selectedEmailHeaders.some(e => !e.Pinned));

    const plural = selectedEmailHeaders.length > 1 ? "s" : "";

    const noEmails = selectedEmailHeaders.length === 0;

    const dispatch = useAppDispatch();

    const onMarkAsRead = async (read: boolean) => await EmailActions.markEmailsAsRead(dispatch, selectedEmailHeaders, read);
    const onSetFlag = async (emails: EmailHeader[], flag: boolean) => await EmailActions.setEmailFlags(dispatch, emails, flag);
    const onSetPinned = async (emails: EmailHeader[], pinned: boolean) => await EmailActions.setEmailPinned(dispatch, emails, pinned);
    const onDeleteEmails = async (emails: EmailHeader[]) => await EmailActions.deleteEmails(dispatch, emails);

    const canRespond = haveEmailContent && selectedEmailHeaders.length === 1;

    const contextMenuItem: (DropDownMenuItem | string)[] = [
        {
            icon: <DraftsIcon/>,
            name: "Mark as Read",
            onClick: () => onMarkAsRead(true),
            hide: !hasUnreadEmails || isOutgoing
        },
        {
            icon: <MarkunreadIcon/>,
            name: "Mark as Unread",
            onClick: () => onMarkAsRead(false),
            hide: !hasReadEmails || isOutgoing
        },
        {
            icon: <FlagIcon/>,
            name: "Flag Email" + plural,
            onClick: () => onSetFlag(selectedEmailHeaders, true),
            hide: !hasUnflaggedEmails || isOutgoing
        },
        {
            icon: <FlagIcon/>,
            name: "Remove Flag" + plural,
            onClick: () => onSetFlag(selectedEmailHeaders, false),
            hide: !hasFlaggedEmails || isOutgoing
        },
        {
            icon: <PushPinIcon/>,
            name: "Pin Email" + plural,
            onClick: () => onSetPinned(selectedEmailHeaders, true),
            hide: !hasUnpinnedEmails
        },
        {
            icon: <PushPinOutlined/>,
            name: "Unpin Email" + plural,
            onClick: () => onSetPinned(selectedEmailHeaders, false),
            hide: !hasPinnedEmails
        },
        {
            icon: <AssignmentReturnIcon/>,
            name: "Move Email" + plural,
            onClick: () => dispatch(EmailActions.beginMoveEmails(selectedEmailHeaders)),
            disabled: noEmails || isOutgoing
        },
        {
            icon: <NotificationsPausedIcon/>,
            name: "Remind Me Later",
            onClick: () => dispatch(EmailActions.showRemindMeLaterSettingsDialog(true)),
            disabled: selectedEmailHeaders.length !== 1 || isOutgoing || !haveEmailContent,
        },
        canRespond ? "div1" : null!,
        {
            icon: <ReplyIcon/>,
            name: "Reply",
            onClick: () => handleViewEmail(selectedEmailHeaders[0].Id, dispatch, itemContent => EmailLocation.respondToEmail(dispatch, itemContent, "Reply")),
            disabled: noEmails,
            hide: !canRespond,
        },
        {
            icon: <ReplyAllIcon/>,
            name: "Reply All",
            onClick: () => handleViewEmail(selectedEmailHeaders[0].Id, dispatch, itemContent => EmailLocation.respondToEmail(dispatch, itemContent, "ReplyAll")),
            disabled: noEmails,
            hide: !canRespond,
        },
        {
            icon: <ForwardIcon/>,
            name: "Forward",
            onClick: () => handleViewEmail(selectedEmailHeaders[0].Id, dispatch, itemContent => EmailLocation.respondToEmail(dispatch, itemContent, "Forward")),
            disabled: noEmails,
            hide: !canRespond,
        },
        "div2",
        {
            icon: <DeleteIcon/>,
            name: "Delete",
            onClick: () => onDeleteEmails(selectedEmailHeaders),
            disabled: noEmails,
        },
        {
            icon: isJunk ? <CheckCircleIcon/> : <BlockIcon/>,
            name: isJunk ? "Mark as not Junk" : "Mark as Junk",
            onClick: async () => dispatch(await EmailActions.markEmailsAsJunk(selectedEmailHeaders, selectedFolder)),
            disabled: noEmails,
            hide: isOutgoing,
        },
    ];

    return (
        <ContextMenu
            menuItems={contextMenuItem}
            elementRef={elementRef}
        />
    );
};

const mapContextMenuProps = ({itemState, folderState}: AppState) => ({
    selectedFolder: folderState.selectedFolder,

    selectedEmailHeaders: itemState.selectedEmailHeaders,

    hasReadEmails: itemState.selectedEmailHeaders.some(e => e.Read),
    hasUnreadEmails: itemState.selectedEmailHeaders.some(e => !e.Read),

    hasFlaggedEmails: itemState.selectedEmailHeaders.some(e => e.Flagged),
    hasUnflaggedEmails: itemState.selectedEmailHeaders.some(e => !e.Flagged),
});

const contextConnector = connect(mapContextMenuProps);

type EmailContextMenuProps = ConnectedProps<typeof contextConnector>;

export const EmailContextMenu = contextConnector(RenderEmailContextMenu);
