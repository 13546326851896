import {useAppDispatch, useAppSelector, useAppStore} from "../../hooks/ReduxHooks";
import React, {useEffect, useRef} from "react";
import {RxSubjects} from "../../state/RxSubjectsState";
import {horizontalWrapPanelStyle, PhotoFolders} from "./PhotoFolders";
import {LoadingIndicator} from "../common/LoadingIndicator";
import {Centre} from "../Centre";
import {PhotosList} from "./PhotosList";
import styled from "@emotion/styled";
import {WrapPanel} from "../panels/WrapPanel";
import {DropDownMenuItem} from "../toolbar/DropDownMenu";
import {Rating} from "@mui/material";
import {ContextMenu} from "../common/ContextMenu";
import {PhotoBrowserService} from "../../services/photos/PhotoBrowserService";

const PhotosBrowserDiv = styled.div`
  flex: 1 1 auto;

  .selectable,
  .selectable-2 {
    border-radius: 5px;
  }
`;

export const PhotosBrowserView = () => {

    useEffect(() => {
        RxSubjects.photosSearchText$.next(false);
    });

    return (
        <PhotosBrowserDiv>
            <MainPhotoView/>
        </PhotosBrowserDiv>
    );
}

const MainPhotoView = () => {
    const menuRef = useRef<HTMLDivElement>(null);

    const loading = useAppSelector(s => s.photosState.loadingPhotos);
    const contextRating = useAppSelector(s => s.photosState.contextPhoto?.Rating);

    const dispatch = useAppDispatch();
    const store = useAppStore();

    async function changePhotoRating(rating: number | null) {
        const state = store.getState().photosState;
        if (!state.contextPhoto) return;

        await new PhotoBrowserService(dispatch).ratePhoto(state.breadcrumbs, state.contextPhoto.Name, rating);
    }
    
    const contextMenuItems: (DropDownMenuItem | string)[] = [
        {
            icon: <Rating value={contextRating ?? null}
                          onChange={(e, value) => changePhotoRating(value)}/>,
            name: " ",
            onClick: () => {
            },
        }];

    if (loading) {
        return (
            <Centre>
                <LoadingIndicator/>
            </Centre>
        );
    }

    return (<>
        <PhotoFolders/>
        <WrapPanel className={horizontalWrapPanelStyle} elementRef={menuRef}>
            <PhotosList/>
        </WrapPanel>

        <ContextMenu menuItems={contextMenuItems}
                     elementRef={menuRef}/>
    </>);
};
