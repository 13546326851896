import {EmailLocation} from "../locations/EmailLocation";
import {AppStore} from "../AppStore";
import {CalendarLocation} from "../locations/CalendarLocation";
import {androidApp, isAndroidApp} from "../util/DeviceUtil";
import {ServiceWorkerMessage} from "../domain/ServiceWorkerMessaging";
import {ContentLocation} from "../locations/ContentLocation";

export class AndroidMessageHandler {
    private readonly store;

    constructor(store: AppStore) {
        this.store = store;
    }

    listenForMessages() {
        window.addEventListener("message", event => {
            const data = event.data as ServiceWorkerMessage;
            if (!data || !data.type || (!data.isAndroid && !data.android)) {
                return;
            }
            console.log("Received AndroidMessage type=", data.type);

            const dispatch = this.store.dispatch;

            switch (data.type) {
                case "openEmail":
                    EmailLocation.handleOpenWindowMessage(dispatch, data);
                    break;
                case "openCalendar":
                    CalendarLocation.handleOpenWindowMessage(data);
                    break;
                case "openContact":
                    ContentLocation.handleOpenWindowMessage(data);
                    break;
            }
        });
    }

    static init() {
        if (isAndroidApp()) {
            setTimeout(() => androidApp.onLoad(), 0);
        }
    }
}
