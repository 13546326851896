import Folder, {FolderTypeEnum} from '../domain/Folder';
import {ExpandedFolderIdPref, SearchOptionsPref} from '../util/Preferences';
import {EmailDataSource} from '../domain/EmailDataSource';
import {EmailHeader} from '../services/messages/EmailHeaderDto';
import {OutgoingEmailDto} from '../services/messages/OutgoingEmailDto';
import SearchOptions from "../domain/SearchOptions";

export interface FolderState {
    folderList: Folder[];

    outgoingEmails: OutgoingEmailDto[];

    expandedFolderIds: number[];
    selectedFolderId?: number;
    selectedFolder?: Folder;
    showOnlySynced: boolean;

    searchText: string;
    searchOptions: SearchOptions;
    currentSearchText?: string;
    searchInProgress: boolean;

    contextFolder?: Folder;

    folderSelectionMode?: "email" | "folder";
    editingFolder: Folder;
    emailsToMove: EmailHeader[];
    showEditFolderDialog: boolean;

    lazyLoadDataSource?: EmailDataSource;
}

export const FolderTemplate: Folder = {type: FolderTypeEnum.UserMail, folderId: -1, hidden: false, parentId: "0", icon: "", indent: 0, name: "", serverId: "", unreadCount: 0};

export const initialFolderState: FolderState = {
    folderList: [],

    outgoingEmails: [],

    expandedFolderIds: ExpandedFolderIdPref.get(),
    showOnlySynced: true,
    searchText: "",
    searchOptions: SearchOptionsPref.get(),
    searchInProgress: false,

    editingFolder: FolderTemplate,
    showEditFolderDialog: false,
    emailsToMove: [],
};
