export class CssProperty {
    name: string;

    value: string;

    constructor(name: string, value: string) {
        this.name = name;
        this.value = value;
    }

    toString(): string {
        return this.name + ": " + this.value + ";";
    }
}
