import {ManageClientItemRemoteOperationDto} from "../services/messages/RemoteOperationDto";
import {isSameClientEntityPredicate} from "../reducers/SyncHelper";
import {ClientEntityDto} from "../services/messages/ClientEntityDto";

export function changeArrayItem<T>(array: T[], predicate: (item: T) => boolean, changeOperation: (item: T) => void): T[] {
    const index = array.findIndex(predicate);
    if (index > -1) {
        const newArray = [...array];
        const newItem = {...array[index]};

        changeOperation(newItem);

        newArray[index] = newItem;

        return newArray;
    }
    return array;
}

export function changeArrayItems<T>(array: T[], changeOperation: (item: T) => void): T[] {
    const newArray = [...array];
    for (let i = 0; i < array.length; i++) {
        const newItem = {...array[i]};
        changeOperation(newItem);
        newArray[i] = newItem;
    }
    return newArray;
}

export function replaceOrAddArrayItem<T>(array: T[], predicate: (item: T) => boolean, replacement: T): T[] {
    const index = array.findIndex(predicate);
    if (index > -1) {
        const newArray = [...array];

        newArray[index] = replacement;

        return newArray;
    } else {
        return [...array, replacement];
    }
}

export function extractMapValuesToArray<K, V>(map: Map<K, V>): V[] {
    const result: V[] = [];

    for (const value of map.values()) {
        result.push(value);
    }

    return result;
}

export function applyClientDtoRemoteOperationLocally<TDto extends ClientEntityDto, TEntity extends ClientEntityDto>(
    operation: ManageClientItemRemoteOperationDto<TDto> | undefined,
    entities: TEntity[],
    converterFunc: (dto: TDto) => TEntity,
    filterFunc?: (dto: TDto) => boolean): TEntity[] {
    
    if (!operation) return entities;

    const change = operation.Change;
    if (change && (!filterFunc || filterFunc(change))) {
        return replaceOrAddArrayItem(entities, isSameClientEntityPredicate(change), converterFunc(change));
    }

    if (operation.DeleteClientId) {
        return entities.filter(c => c.ClientId !== operation.DeleteClientId);
    }
    if (operation.DeleteId) {
        return entities.filter(c => c.Id !== operation.DeleteId);
    }

    return entities;
}
