import {
    Data,
    decrypt,
    decryptKey,
    encrypt,
    enums,
    generateKey,
    Key,
    Keyid,
    MaybeStream,
    Message,
    readCleartextMessage,
    readKey,
    readMessage,
    readSignature,
    Signature,
    verify,
} from "openpgp";
import {performRestCallDirectly} from "./HttpHelper";
import {Dispatch} from "redux";
import {AccountsState} from "../state/AccountState";
import {PgpKeyDto} from "../services/messages/PgpKeyDto";
import {PgpKey} from "../domain/PgpKey";
import {AccountsActions} from "../redux/AccountsSlice";
import {DB} from "../db/DbManager";
import {convertTextToTitleCase} from "./Formatters";

// See here for OpenPGPJS documentation: https://openpgpjs.org/openpgpjs/

export type PasswordGenerationVariant = "PIN" | "password";

// Forward compatibility
export type PrivateKey = Key;
export type PublicKey = Key;
export type KeyID = Keyid;
export type SecretKey = PrivateKey;

export function pgpReadPrivateKey(options: { armoredKey: string }): Promise<SecretKey> {
    return readKey(options);
}

export function pgpReadKey(options: { armoredKey: string, checksumRequired?: boolean }): Promise<Key> {
    return readKey(options);
}

export function pgpReadArmoredMessage(message: string): Promise<Message<string>> {
    return readMessage({armoredMessage: message});
}

export function pgpReadSignature(signature: string): Promise<Signature> {
    return readSignature({armoredSignature: signature});
}

export function pgpReadCleartextMessage(message: string) {
    return readCleartextMessage({cleartextMessage: message});
}

export function pgpReadBinaryMessage(message: Uint8Array) {
    return readMessage({binaryMessage: message});
}

export function pgpTextMessage(text: string) {
    return Message.fromText(text);
    //return createMessage({text});
}

export function pgpDecrypt<T extends MaybeStream<Data>>(message: Message<T>, decryptionKeys: Key | Key[] | undefined, verificationKeys?: Key | Key[], format?: "binary" | "utf8") {
    return decrypt({
        message,
        //decryptionKeys: decryptedKeys,
        privateKeys: decryptionKeys,
        // expectSigned: false,
        publicKeys: verificationKeys,
        format,
    });
}

export function pgpVerify<T extends MaybeStream<Data>>(message: Message<T>, verificationKeys: Key | Key[], detachedSignature: Signature | undefined) {
    return verify({
        message,
        // expectSigned: false,
        publicKeys: verificationKeys,
        signature: detachedSignature,
    });
}

export async function pgpEncrypt(message: Message<any>, encryptionKeys: Key | Key[]) {
    const encryptedData = await encrypt({
        message,
        publicKeys: encryptionKeys, //: publicKeys,
        // publicKeys,
        // config: {preferredCompressionAlgorithm: enums.compression.zip},
        config: {compression: enums.compression.zip},
    });
    // ?????!
    return encryptedData as unknown as string;
}

export async function pgpEncryptBinary(message: Message<any>, encryptionKeys: Key | Key[]) {
    const encryptedData = await encrypt({
        message,
        publicKeys: encryptionKeys, //: publicKeys,
        // publicKeys,
        // config: {preferredCompressionAlgorithm: enums.compression.zip},
        config: {compression: enums.compression.zip},
        armor: false,
    });
    // ?????!
    return encryptedData as unknown as Uint8Array;
}

export async function pgpSignAndEncrypt(message: Message<any>, encryptionKeys: Key | Key[], signingKey: Key) {
    const encryptedData = await encrypt({
        message,//: await pgpTextMessage(xmlText),
        publicKeys: encryptionKeys, //: publicKeys,
        privateKeys: signingKey, // to sign        
        // publicKeys,
        // config: {preferredCompressionAlgorithm: enums.compression.zip},
        config: {compression: enums.compression.zip},
    });
    // ?????!
    return encryptedData as unknown as string;
}

export function pgpReadTextSignature(signature: string) {
    return readSignature({armoredSignature: signature});
}

export function pgpReadBinarySignature(signature: Uint8Array) {
    return readSignature({binarySignature: signature});
}

// *********************

export function findPgpKey(keyId: KeyID | undefined, allKeys: PgpKey[]): PgpKey | undefined {
    if (!keyId) return;
    return allKeys
        .find(k => k.key.getKeyIds()
            .some(k => k.bytes === keyId.bytes));
}

export function findPgpKeyByHex(keyId: string | undefined, allKeys: PgpKey[]): PgpKey | undefined {
    if (!keyId) return;
    return allKeys.find(k => k.keyHexId === keyId);
}

const validPasswordChars = [
    "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M",
    "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
    "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m",
    "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z",
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "+", "/", "!",
    "£", "$", "%", "^", "&", "*", "(", ")", "-", "=", "[", "]", "#",
    "@", ",", ".", "<", ">", "?", "_"
];

const validPinChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

export async function ensurePgpKeysLoadedBoolean(dispatch: Dispatch, pgpKeysLoaded: boolean) {
    try {
        if (!pgpKeysLoaded) {
            const encodedKeys = await DB.getAllPgpKeys();
            const keys = await decodePgpKeyDtos(encodedKeys) || [];

            dispatch(AccountsActions.setPgpKeys({keys, loadedIfEmpty: true}));
        }
    } catch (e) {
        console.error(e);
        throw e;
    }
}

export async function ensurePgpKeysLoaded(dispatch: Dispatch, accountsState: AccountsState) {
    return ensurePgpKeysLoadedBoolean(dispatch, accountsState.pgpKeysLoaded);
}

export function findDecryptionKeys(message: Message<any>, secretKeys: PgpKey[]): SecretKey[] {
    const potentialKeys = [];

    try {
        const potentialKeyIds = message.getEncryptionKeyIds();

        for (const secretKey of secretKeys) {
            for (const keyId of secretKey.key.getKeyIds()) {

                if (potentialKeyIds.some((p: any) => p.bytes === keyId.bytes)) {
                    potentialKeys.push(secretKey.key);
                }
            }
        }
    } catch (e) {
        console.error(e);
    }

    if (potentialKeys.length > 0) {
        console.log(`Found potential keys: ${potentialKeys.map(k => k.getKeyId().toHex()).join(", ")}`);
    }
    return potentialKeys;
}

export async function decryptSecretKeys(secretKeys: SecretKey[], password: string) {
    const decryptedKeys: PrivateKey[] = [];
    let error: any = null;

    for (const secretKey of secretKeys) {
        try {
            if (!secretKey.isDecrypted()) {
                const privateKey = await decryptKey({privateKey: secretKey, passphrase: password});

                if (privateKey.isDecrypted()) {
                    decryptedKeys.push(privateKey);
                }
            }
        } catch (e) {
            error = e;
        }
    }

    if (decryptedKeys.length === 0 && error) {
        throw error;
    } else if (decryptedKeys.length === 0) {
        throw new Error("You have no private keys");
    }

    return decryptedKeys;
}

export async function getSecretKeysFromWebService() {
    const results = await performRestCallDirectly<PgpKeyDto[]>("GetPgpPrivateKeys", {});

    await DB.saveSecretKeys(results);

    return results;
}

export interface PasswordGenerationOptions {
    length: number;
    requireNumbers?: boolean;
    requireSymbols?: boolean;
    excludeNumbers?: boolean;
    excludeSymbols?: boolean;
    variant: PasswordGenerationVariant;
    useWords?: boolean;
    availableWords?: string[];
}

async function getRandomBytes(length: number): Promise<Uint8Array> {
    // This is a bit hacky as crypto is no longer available...
    // return crypto.randomBytes(length);
    const key = await generateKey({userIds: [{}], rsaBits: length});
    const result = key.key.getKeys()[0].keyPacket.getFingerprintBytes()!;
    if (result.length < length) {
        const result2 = await getRandomBytes(length - result.length);

        const merged = new Uint8Array(result.length + result2.length);
        merged.set(result, 0);
        merged.set(result2, result.length);

        return merged.slice(0, length);
    }
    return result.slice(0, length);
}

export async function generateRandomPassword(options: PasswordGenerationOptions) {
    let result = "";
    let count = 0;

    const passwordValidCharsLength = options.excludeSymbols && options.excludeNumbers ?
        26 * 2
        : (options.excludeSymbols ? 26 * 2 + 10 : validPasswordChars.length);

    const pinValidCharsLength = 10;
    const validCharsLength = options.variant === "password" ? passwordValidCharsLength : pinValidCharsLength;

    const validChars = options.variant === "password" ? validPasswordChars : validPinChars;

    do {
        const randomData = await getRandomBytes(options.useWords ? options.length * 4 : options.length);
        let temp = "";
        let number = 0;
        let offset = 0;
        for (const data of randomData) {
            if (options.useWords && options.availableWords && offset < 32) {
                if (offset === 24) {
                    let randomWord = options.availableWords[number % options.availableWords.length];
                    if ((number + data) % 2 === 0) {
                        randomWord = convertTextToTitleCase(randomWord);
                    }
                    temp += randomWord;
                } else {
                    number += (data << offset);
                }
                offset += 8;
                if (temp.length >= options.length) {
                    break;
                }
                continue;
            } else if (options.excludeNumbers && !options.excludeSymbols) {
                const normalisedData = data % validCharsLength;
                if (normalisedData >= 26 * 2 && normalisedData < 26 * 2 + 10) {
                    temp += validChars[data % (26 * 2)];
                } else {
                    temp += validChars[data % validCharsLength];
                }
            } else {
                temp += validChars[data % validCharsLength];
            }
            number = 0;
            offset = 0;
        }
        result = temp;

        if (passwordRequirementsMet(options, result)) {
            console.info(`Password generated in ${count + 1} attempts`);
            return result;
        }
    } while (count++ < 100);

    console.warn("Exceeded maximum password generation attempts");

    return result;
}

export function passwordRequirementsMet(options: PasswordGenerationOptions, text: string): boolean {
    if (options.requireNumbers) {
        if (!/[0-9]/.test(text)) {
            return false;
        }
    }

    if (options.requireSymbols) {
        if (!/[^0-9a-zA-Z]/.test(text)) {
            return false;
        }
    }

    return true;
}

export async function decodePgpKeyDtos(keyDtos: PgpKeyDto[] | null): Promise<PgpKey[] | null> {
    if (!keyDtos) return null;

    const results: PgpKey[] = [];
    for (const dto of keyDtos) {
        try {
            const decodedKey = await pgpReadKey({armoredKey: dto.Key});
            results.push({
                key: decodedKey,
                keyHexId: decodedKey.getKeyId().toHex(),
                isPrivate: decodedKey.isPrivate(),
                Id: dto.Id
            });
        } catch (error: any) {
            console.info("Unable to decode key", dto.Id, error.message);
        }
    }
    return results;
}

export function getKeyDescriptions(keys: PublicKey[]): string {
    if (!keys.length) {
        return "No keys";
    }
    return keys.map(k => k.getUserIds()[0]).join(", ");
}
