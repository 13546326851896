import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CalendarMode, CalendarState, initialCalendarState} from "../state/CalendarState";
import {calculateCalendarView} from "../services/calendar/CalendarService";
import {AddRemoteOperationPayload, SyncActions} from "./SyncSlice";
import {applySyncChangesWithClientIds} from "../reducers/SyncHelper";
import {Calendar, calendarFromDto} from "../domain/CalendarDto";
import {applyClientDtoRemoteOperationLocally} from "../util/ReducerUtil";
import {DB} from "../db/DbManager";
import {ItemChangeListDto} from "../services/messages/ItemChangeListDto";

export interface SetCalendarModeActionPayload {
    mode: CalendarMode;
    year: number;
    month: number;
    week: number;
}

export interface CalendarsLoadedPayload {
    calendars: Calendar[];
    loadedIfEmpty: boolean;
}

export const calendarSlice = createSlice({
    name: "calendar",
    initialState: initialCalendarState as CalendarState,
    reducers: {
        calendarsLoaded: {
            reducer: handleCalendarsLoaded,
            prepare: (calendars: Calendar[], loadedIfEmpty: boolean) => ({payload: {calendars, loadedIfEmpty}}),
        },
        setCalendarMode: {
            reducer: handleSetCalendarMode,
            prepare: (mode: CalendarMode, year: number, month: number, week: number) => ({payload: {mode, year, month, week}}),
        }
    },
    extraReducers: builder => builder
        .addCase(SyncActions.syncResponseReceived, handleSyncResponse)
        .addCase(SyncActions.addRemoteOperation, applyRemoteOperationLocally)
});

function handleSetCalendarMode(state: CalendarState, {payload}: PayloadAction<SetCalendarModeActionPayload>) {
    state.mode = payload.mode;

    state.month = payload.month;
    state.year = payload.year;
    state.week = payload.week;

    state.viewData = calculateCalendarView(state, state.calendars);
}

function handleSyncResponse(state: CalendarState, {payload: changes}: PayloadAction<ItemChangeListDto>) {
    if (!state.calendarsLoaded && !DB.isPrivateBrowserMode()) {
        return;
    }

    if (changes.Calendars) {
        state.calendars = applySyncChangesWithClientIds(state.calendars, changes.Calendars, calendarFromDto);
        updateCalendars(state);
    }
}

function applyRemoteOperationLocally(state: CalendarState, {payload: remoteOperation}: PayloadAction<AddRemoteOperationPayload>) {
    if (!state.calendarsLoaded && !DB.isPrivateBrowserMode()) {
        return;
    }
    const operation = remoteOperation.operation;
    const newCalendars = applyClientDtoRemoteOperationLocally(operation.ManageCalendar, state.calendars, calendarFromDto);
    if (newCalendars !== state.calendars) {
        state.calendars = newCalendars;
        updateCalendars(state);
    }
}

function handleCalendarsLoaded(state: CalendarState, {payload}: PayloadAction<CalendarsLoadedPayload>) {
    state.calendars = payload.calendars;
    state.calendarsLoaded = payload.calendars.length > 0 || payload.loadedIfEmpty;

    updateCalendars(state);
}

function updateCalendars(state: CalendarState) {
    state.viewData = calculateCalendarView(state, state.calendars);
}

export namespace CalendarActions {
    export const {
        calendarsLoaded,
        setCalendarMode,
    } = calendarSlice.actions;
}
