import _ from "lodash";
import {EmailHeader} from "../../services/messages/EmailHeaderDto";
import {formatFromAddressesString} from "../../util/Formatters";

const ATTACHMENT_SVG = '<svg focusable="false" viewBox="0 0 24 24"><path d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"></path></svg>';

const UserShieldIcon = '<svg focusable="false" viewBox="0 0 24 24"><path d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm-2 16l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z"></path></svg>';

export function renderAttachment(attachmentCount: number) {
    return attachmentCount > 0 ? ATTACHMENT_SVG : "";
}

// export function renderDesktopLoadingIndicator(params: ValueFormatterParams) {
//     if (params.value === undefined) {
//         return `<div class="round-spin round-spin-small"></div>`;
//     } else {
//         return params.value;
//     }
// }

export function renderFromAddresses(header: EmailHeader, isOutgoing: boolean) {

    const addresses = _.escape(formatFromAddresses(header, isOutgoing));

    if (header.ShowTrustShield) {
        return addresses + UserShieldIcon;
    }
    return addresses;
}

export function formatFromAddresses(data: EmailHeader, isOutgoing: boolean) {
    if (!data) return "";
    const header: EmailHeader = data;
    if (!header.DisplayFrom) {
        const addresses = isOutgoing ? (header.To ?? "") + ";" + (header.CC ?? "") : header.From;
        header.DisplayFrom = formatFromAddressesString(addresses, isOutgoing) || ""; // TODO: use the correct flags here
    }
    return header.DisplayFrom;
}