import React, {FC, Fragment} from "react";
import {ContentLocation} from "../../../locations/ContentLocation";
import {EmailRuleDto} from "../../../services/rules/dto/EmailRuleDto";

export const RuleAppliedInfo: FC<{ rules: EmailRuleDto[] }> = ({rules}) => {
    return (
        <span>
            Rules applied:
            {rules.map((r, i) => (
                <Fragment key={r.Id}>
                    {i > 0 ? ", " : ""}
                    <button className="link" onClick={() => ContentLocation.showEmailRule(r.Id)}>{r.Name}</button>
                </Fragment>
            ))}
        </span>
    );
}
