import {Tab, Tabs} from "@mui/material";
import React, {PropsWithChildren, useState} from "react";

export interface ViewModeSelectorProps<TViewMode> {
    options: TViewMode[];
    labels: React.ReactNode[];
    onSelectViewMode: (newViewMode: TViewMode) => void;

    defaultValue?: TViewMode;
}

export function ViewModeSelector<TViewMode>({
                                                options,
                                                defaultValue,
                                                labels,
                                                onSelectViewMode,
                                                children,
                                            }: ViewModeSelectorProps<TViewMode> & PropsWithChildren) {
    const [viewMode, setViewMode] = useState<TViewMode>(defaultValue ?? options[0]);

    function handleFilterChange(modeIndex: number) {
        const mode = options[modeIndex];
        setViewMode(mode);
        onSelectViewMode(mode);
    }

    const modeIndex = options.indexOf(viewMode);

    return (
        <Tabs className="quick-options"
              value={modeIndex}
              textColor={modeIndex > 0 ? "secondary" : "inherit"}
              indicatorColor={modeIndex > 0 ? "secondary" : "primary"}
              onChange={(e, mode: number) => handleFilterChange(mode)}>
            {labels.map((label, i)=><Tab key={i} label={label} value={i}/>)}

            {children}
        </Tabs>
    );
}