import {EmailActions} from '../../actions/EmailActions';
import React, {FC} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {isEmailContent} from '../../domain/ItemContent';
import "./EditEmailCategoryDialog.scss";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import SimpleAutocomplete from "../common/SimpleAutocomplete";
import {KnownCategoriesMap} from "../../domain/KnownCategories";

export const EditEmailCategoryDialog: FC = () => {

    const dispatch = useAppDispatch();

    const selectedEmailId = useAppSelector(({itemState}) => isEmailContent(itemState.itemContent) ? itemState.itemContent.Id : undefined);
    const category = useAppSelector(({itemState}) => itemState.editEmailCategory);
    const open = useAppSelector(({itemState}) => itemState.showEmailCategoryDialog);

    const availableCategories = [...new Set<string>(Object.keys(KnownCategoriesMap)
        .concat(useAppSelector(s => s.itemState.availableCategories)))];
    availableCategories.sort();

    const availableUiCategories = availableCategories.map(c => ({title: c, value: c}));

    if (!selectedEmailId) return null;

    async function handleCategoryChange() {
        dispatch(await EmailActions.setEmailCategory(selectedEmailId!, category === "" ? "Other" : category));
    }

    
    function onSetEditCategory(newValue: string) {
        dispatch(EmailActions.showEmailCategoryDialog(newValue));
    }

    function onClose() {
        dispatch(EmailActions.hideEmailCategoryDialog());
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle id="alert-dialog-title">Change Category</DialogTitle>
            <DialogContent className="edit-email-category-dialog">
                <DialogContentText id="alert-dialog-description">Enter the new category below:</DialogContentText>
                <SimpleAutocomplete
                    fullWidth
                    options={availableUiCategories}
                    autoFocus
                    freeSolo
                    value={category}
                    setValue={newValue => onSetEditCategory(newValue)}
                    onFocus={e => e.target.select()}
                    onKeyPress={e => {
                        if (e.key === "Enter") {
                            handleCategoryChange();
                        }
                    }}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCategoryChange} color="primary" variant="contained">OK</Button>
                <Button onClick={onClose} color="secondary" variant="contained">Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}
