import {AllActionTypes} from "../actions/AllActionTypes";
import {
    CHANGE_DRAFT_EMAIL,
    COMPOSE_EMAIL,
    SET_COMPOSE_OPTIONS,
    SET_COMPOSE_SETTINGS_OPEN
} from "../actions/ComposeEmailActionTypes";
import {ComposeEmailState, initialComposeEmailState} from '../state/ComposeEmailState';
import {DbAttachment} from '../db/DbSchema';
import {MARK_DRAFT_EMAIL_ATTACHMENT_UPLOADED} from '../actions/AttachmentActionTypes';
import {ADD_REMOTE_OPERATION, AddRemoteOperationAction} from "../redux/SyncSlice";
import {AccountsState} from "../state/AccountState";
import {extractEmailAddresses} from "../util/Formatters";
import {ComposeOptionsPref} from "../util/Preferences";

export default function composeEmailReducer(state = initialComposeEmailState, accountsState: AccountsState, action: AllActionTypes): ComposeEmailState {
    switch (action.type) {
        case COMPOSE_EMAIL:
            if (action.show) {
                return {
                    ...state,
                    isComposingEmail: true,
                    hasModifications: false,
                    draftEmail: action.email,
                    draftAttachments: action.attachments
                };
            }
            return {...state, isComposingEmail: false, hasModifications: false, draftEmail: action.email};

        case CHANGE_DRAFT_EMAIL:
            if (state.draftEmail) {
                const draftEmail = {...state.draftEmail, ...action.changes, IsNew: false};
                const addresses = [
                    ...extractEmailAddresses(draftEmail.To),
                    ...extractEmailAddresses(draftEmail.Cc),
                    ...extractEmailAddresses(draftEmail.Bcc),
                ];

                if (addresses.length > 0 && accountsState.pgpKeyAddresses.length > 0 && addresses.every(a => accountsState.pgpKeyAddresses.includes(a)) && draftEmail.IsEncrypted === undefined) {
                    // draftEmail.IsEncrypted = true;
                }
                return {
                    ...state,
                    hasModifications: true,
                    draftEmail,
                    draftAttachments: !state.draftAttachments || (state.draftAttachments!.length > 0 && state.draftEmail.Uid !== (state.draftAttachments!)[0].emailUid) ? undefined : state.draftAttachments,
                };
            }
            break;

        case ADD_REMOTE_OPERATION:
            return handleAddRemoteOperation(state, action);

        case MARK_DRAFT_EMAIL_ATTACHMENT_UPLOADED:
            if (!state.draftAttachments) {
                return state;
            }
            const newAttachments = [...state.draftAttachments];
            const attachmentIndex = newAttachments.findIndex(a => a.attachmentUid === action.attachmentUid);
            if (attachmentIndex >= 0) {
                newAttachments[attachmentIndex] = {...newAttachments[attachmentIndex], isUploaded: true};
            }
            return {
                ...state,
                draftAttachments: newAttachments
            };

        case SET_COMPOSE_SETTINGS_OPEN:
            return {...state, settingsDialogOpen: action.open};
        case SET_COMPOSE_OPTIONS:
            ComposeOptionsPref.set(action.composeOptions);
            return {...state, composeOptions: action.composeOptions};
    }

    return state;
}

function handleAddRemoteOperation(state: ComposeEmailState, {payload}: AddRemoteOperationAction) {
    const {operation} = payload;
    if (operation.AddAttachment && payload.state) {
        const attachment: DbAttachment = payload.state;
        if (attachment.emailUid === state.draftEmail?.Uid) {
            return {
                ...state,
                draftAttachments: [...(state.draftAttachments || []), attachment]
            };
        }
    }
    if (operation.RemoveAttachment) {
        const removeAttachment = operation.RemoveAttachment;
        if (removeAttachment.EmailUid === state.draftEmail?.Uid) {
            return {
                ...state,
                draftAttachments: (state.draftAttachments || []).filter(a => a.attachmentUid !== removeAttachment.AttachmentUid)
            };
        }
    }
    return state;
}