import React, {FC, useRef} from "react";
import {Tab, Tabs} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../AppState";
import {NoteSectionDto} from "../../domain/NoteSectionDto";
import {NotesLocation} from "../../locations/NotesLocation";
import {NoteSectionContextMenu} from "./NoteSectionContextMenu";
import AddBoxIcon from '@mui/icons-material/AddBox';
import {NotesActions} from "../../redux/NotesSlice";

export const NoteSectionSidebar: FC = () => {
    const noteSections = useSelector(({notesState}: AppState) => notesState.sections);
    const selectedSectionClientId = useSelector(({notesState}: AppState) => notesState.selectedSectionClientId);

    const dispatch = useDispatch();

    const menuRef = useRef<HTMLDivElement>(null);

    function onAddNoteSection() {
        dispatch(NotesActions.addNoteSection());
    }
    
    function onNoteSectionChange(clientId: string) {
        NotesLocation.selectSectionByClientId(clientId);
    }
    
    function onSetContextSection(section: NoteSectionDto | undefined) {
        if (section) {
            dispatch(NotesActions.setNoteSectionContextMenu(section));
        }
    }

    function renderNoteSection(section: NoteSectionDto) {
        return (
            <Tab className="selectable"
                 key={section.Id}
                 color="secondary"
                 value={section.ClientId}
                 label={section.Name}
                 onContextMenu={() => onSetContextSection(section)}
            />
        );
    }

    return (
        <>
            <Tab className="selectable add-item"
                 value={-1}
                 icon={<AddBoxIcon/>}
                 onClick={onAddNoteSection}
                 title="Add Section"
            />
            <Tabs
                ref={menuRef}
                orientation="vertical"
                variant="scrollable"
                indicatorColor="secondary"
                textColor="inherit"
                value={selectedSectionClientId || noteSections[0]?.ClientId}
                onChange={(e, newValue) => onNoteSectionChange(newValue)}
            >

                {noteSections.map(renderNoteSection)}
            </Tabs>
            <NoteSectionContextMenu elementRef={menuRef}/>
        </>
    );
};
