import {Key, Message, VerificationResult} from "openpgp";
import {Base64} from "js-base64";
import {decodeMimePartContent, MimeParser, MimePart} from "../parsers/MimeParser";
import {extractEmailAddresses} from "../util/Formatters";
import {ContentType} from "../util/ContentTypes";
import {
    decryptSecretKeys,
    findDecryptionKeys,
    pgpDecrypt,
    pgpEncrypt,
    pgpReadArmoredMessage,
    pgpReadBinaryMessage,
    pgpReadCleartextMessage,
    pgpReadKey,
    pgpReadTextSignature,
    pgpTextMessage,
    pgpVerify,
    SecretKey
} from "../util/PGPUtil";
import {PgpKey} from "../domain/PgpKey";
import DbDraftEmail from "../domain/DbDraftEmail";
import {AccountsState} from "../state/AccountState";
import {uuid} from "../util/Uuid";
import {findGatekeeperSecretKey} from "../util/GateKeeperXmlParser";

export interface SignatureState {
    verified: boolean;
    users: string[];
}

export interface DecryptedEmail {
    bestPart: MimePart;
    subject?: string;
    signatures: SignatureState[];
    errors: string[];
    attachments: MimePart[];
}

export function checkDraftEmailEncryptionRecipients(draftEmail: DbDraftEmail | undefined, publicKeys: PgpKey[]): string[] {
    if (!draftEmail) return [];

    const addresses = [
        ...extractEmailAddresses(draftEmail.To),
        ...extractEmailAddresses(draftEmail.Cc),
        ...extractEmailAddresses(draftEmail.Bcc)
    ];

    if (publicKeys.length === 0) return addresses;

    const allKeyAddresses = publicKeys.flatMap(pk => pk.key.getUserIds().flatMap(extractEmailAddresses));

    // console.log(allKeyAddresses);
    return addresses
        .filter(address => !allKeyAddresses.includes(address));
}

export function findEmailEncryptionRecipientKeys(draftEmail: DbDraftEmail | undefined, publicKeys: PgpKey[]): PgpKey[] {
    if (!draftEmail) return [];

    const addresses = [
        ...extractEmailAddresses(draftEmail.To),
        ...extractEmailAddresses(draftEmail.Cc),
        ...extractEmailAddresses(draftEmail.Bcc)
    ];

    if (publicKeys.length === 0) return [];

    return publicKeys.filter(pk => addresses.some(a => pk.key.getUserIds().flatMap(extractEmailAddresses).includes(a)));
}

export function findEmailEncryptionSenderKey(draftEmail: DbDraftEmail, secretKeys: PgpKey[]): Key[] {
    const addresses = extractEmailAddresses(draftEmail.From);

    const foundKeys = findGatekeeperSecretKey(secretKeys, addresses);

    if (foundKeys.length === 0) {
        throw new Error("Unable to encrypt the email as you don't have a key");
    }

    return foundKeys;
}

export async function signAndEncryptDraftEmail(draftEmail: DbDraftEmail, {
    password,
    pgpKeys,
    secretKeys,
}: AccountsState) {
    if (draftEmail.IsSigned) {
        // TODO: sign the email
        throw new Error("Not implemented yet");
    }
    if (draftEmail.IsEncrypted) {
        draftEmail = {...draftEmail};

        const boundary = "--" + uuid();

        draftEmail.VerbatimContentType = `multipart/encrypted;\r\n\tprotocol="application/pgp-encrypted";\r\n\tboundary="${boundary}"`;

        const errors = checkDraftEmailEncryptionRecipients(draftEmail, pgpKeys);
        if (errors.length > 0) {
            throw new Error("Unable to encrypt for these recipients: " + errors.join("; "));
        }

        const messageBoundary = "--XX" + uuid();

        const fullText = draftEmail.BodyText + (draftEmail.ExtendedBodyText ?? "");
        const mimeMessage = "Content-Type: multipart/mixed; boundary=\"" + messageBoundary + "\";\r\n" +
            "\tprotected-headers=\"v1\"\r\n" +
            "Subject: " + draftEmail.Subject + "\r\n" + // TODO: encode the subject if required!
            "\r\n--" + messageBoundary + "\r\n" +
            "Content-Type: text/html; charset=UTF-8;\r\n" +
            "Content-Transfer-Encoding: base64\r\n" +
            "\r\n" + Base64.encode(fullText) + "\r\n\r\n" + // Base64 required for UTF8 characters
            "--" + messageBoundary + "--\r\n";

        draftEmail.Subject = "..."; // Hide the email subject - included above instead

        const message = pgpTextMessage(mimeMessage);

        const recipientKeys = findEmailEncryptionRecipientKeys(draftEmail, pgpKeys).map(k => k.key);
        recipientKeys.push(...findEmailEncryptionSenderKey(draftEmail, secretKeys));

        const encryptedMessage = await pgpEncrypt(message, recipientKeys);

        draftEmail.BodyText = "This is an OpenPGP/MIME encrypted message (RFC 4880 and 3156)\r\n" +
            "--" + boundary + "\r\n" +
            "Content-Type: application/pgp-encrypted\r\n" +
            "Content-Description: PGP/MIME version identification\r\n" +
            "\r\n" +
            "Version: 1\r\n" +
            "\r\n" +
            "--" + boundary + "\r\n" +
            "Content-Type: application/octet-stream; name=\"encrypted.asc\"\r\n" +
            "Content-Description: OpenPGP encrypted message\r\n" +
            "Content-Disposition: inline; filename=\"encrypted.asc\"\r\n" +
            "\r\n" + encryptedMessage.replaceAll("\n", "\r\n") + "\r\n" +
            "\r\n--" + boundary + "--\r\n\r\n";
    }
    if (!draftEmail.IsSigned && !draftEmail.IsEncrypted) {
        draftEmail.VerbatimContentType = undefined;
    }
    return draftEmail;
}

async function getEncryptedMessageFromContent(encryptedContentString: string, contentType: string) {
    if (contentType === ContentType.PgpBinary) {
        console.log("Decrypting binary message");
        const encryptedData = Base64.toUint8Array(encryptedContentString);
        return await pgpReadBinaryMessage(encryptedData);
    } else {
        return await pgpReadArmoredMessage(encryptedContentString);
    }
}

export async function findEmailDecryptionKeys(encryptedContentString: string, contentType: string, secretKeys: PgpKey[]): Promise<SecretKey[]> {
    try {
        const binaryMessage = await getEncryptedMessageFromContent(encryptedContentString, contentType);

        return findDecryptionKeys(binaryMessage, secretKeys);
    } catch (e) {
        console.error(e);
    }

    return [];
}

export async function decryptEncryptedEmail(encryptedContentString: string, contentType: string, secretKeys: SecretKey[], publicKeys: Key[], password: string): Promise<DecryptedEmail> {

    if (secretKeys.length === 0) {
        throw new Error("You don't have a key that can decrypt this email");
    }
    if (password.length === 0) {
        throw new Error("Please enter your password");
    }

    const binaryMessage = await getEncryptedMessageFromContent(encryptedContentString, contentType);

    console.log(`Encrypted to keys: ${binaryMessage.getEncryptionKeyIds().map(k => k.toHex()).join(", ")}`);

    const decryptedKeys = await decryptSecretKeys(secretKeys, password);

    console.log(`Decrypted keys: ${decryptedKeys.map(k => k.getKeyId().toHex()).join(", ")}`);

    const {data: decryptedData, signatures} = await pgpDecrypt(binaryMessage, decryptedKeys, publicKeys);

    const parser = new MimeParser(decryptedData);
    parser.parse();

    const attachments: MimePart[] = parser.getAttachmentParts();
    const errors: string[] = [];
    let signatureStates: SignatureState[];

    try {
        const allKeys = [...publicKeys];

        const keyPart = parser.findExactPart("application/pgp-keys");
        if (keyPart) {
            try {
                const verificationKey = await pgpReadKey({
                    armoredKey: keyPart.contentLines.join("\n"),
                    checksumRequired: false
                });
                allKeys.push(verificationKey);
            } catch (e) {
                // console.log("Key: ", parser.findExactPart("application/pgp-keys")?.contentLines.join("\r\n"));
            }
        }

        const detachedSignaturePart = parser.findExactPart("application/pgp-signature");
        const messagePart = parser.findExactPart("multipart/mixed");
        if (detachedSignaturePart && messagePart && keyPart) {
            const result = await verifyParsedEmailSignatures(parser, publicKeys);
            signatureStates = result.signatures;
            errors.push(...result.errors);
        } else {
            signatureStates = await convertSignatures(signatures, publicKeys);
            console.log("Signatures: ", signatureStates);
        }
    } catch (e) {
        console.log("Verification error:", e);
        errors.push("Unable to verify signatures: " + e);
        signatureStates = [];
    }
    const bestPart = parser.findBestPart(ContentType.HtmlText);

    return {
        bestPart: bestPart,
        signatures: signatureStates,
        subject: parser.subject,
        errors,
        attachments,
    };
}

export async function verifyEmailSignatures(content: string, publicKeys: Key[]): Promise<DecryptedEmail> {
    const parser = new MimeParser(content);
    parser.parse();

    return verifyParsedEmailSignatures(parser, publicKeys);
}

export async function verifyParsedEmailSignatures(parser: MimeParser, publicKeys: Key[]): Promise<DecryptedEmail> {
    let bestPart = parser.findBestPart(ContentType.HtmlText);
    //const signaturePart = parser.findBestPart("application/octet-stream");

    let signatures: SignatureState[] = [];

    const errors: string[] = [];

    try {
        const bestPartContent = decodeMimePartContent(bestPart);
        if (bestPartContent.includes("-----BEGIN PGP SIGNED MESSAGE-----")) {
            // Inline signature
            // TODO: check if we need this...
            // bestPartContent = bestPart.content.includes("-----END PGP SIGNED MESSAGE-----") ? bestPart.content : bestPart.content + "\r\n-----END PGP SIGNED MESSAGE-----";

            const messageToVerify = await pgpReadCleartextMessage(bestPartContent);
            const {signatures: encodedSignatures} = await pgpVerify(messageToVerify as unknown as Message<any>, publicKeys, undefined);

            // TODO: check if we need this...
            // bestPart.content = data as unknown as string;

            signatures = await convertSignatures(encodedSignatures, publicKeys);

            // console.log("Signatures: ", signatures);
            // return {signatures, bestPart};
        } else {
            // TODO: Support detached signatures
            const signaturePart = parser.findBestPart(ContentType.PgpSignature);
            if (signaturePart.contentType !== ContentType.PgpSignature) {
                console.error("Signature part not found");
            } else {
                const signedDataPart = parser.findBestPart("multipart/signed").subParts[0];
                // TODO: detect if the signature is armored
                // const binarySignature = Base64.toUint8Array(signaturePart.content);
                const signature = await pgpReadTextSignature(decodeMimePartContent(signaturePart));

                try {
                    const signedData = parser.getMimePartLines(signedDataPart).join("\r\n");
                    // console.log("Signed data:\r\n", signedData);

                    const messageToVerify = await pgpTextMessage(signedData);
                    const {signatures: encodedSignatures} = await pgpVerify(messageToVerify as unknown as Message<any>, publicKeys, signature);

                    bestPart = parser.findBestPart(ContentType.HtmlText);

                    signatures = await convertSignatures(encodedSignatures, publicKeys);

                    // TODO: read in the headers from the signed part of the message

                } catch (e: any) {
                    console.info("Unable to verify email: ", e);
                    errors.push("Unable to verify email: " + e);
                    signatures = [{users: [e.message], verified: false}];
                }
            }
        }
    } catch (e: any) {
        console.info("Unable to verify email: ", e);
        errors.push("Unable to verify email: " + e);
    }

    console.log("Signatures: ", signatures);

    return {
        signatures,
        bestPart,
        errors,
        attachments: [],
    };
}

async function convertSignatures(signatures: VerificationResult[], publicKeys: Key[]) {
    const signatureStates: SignatureState[] = [];
    for (const signature of signatures) {
        const publicKey = publicKeys.find(pk => pk.getKeyId().equals(signature.keyid));
        if (publicKey) {
            const verified = await signature.verified;
            signatureStates.push({
                verified: !!verified,
                users: extractEmailAddresses(publicKey.getUserIds().join(";")),
            });
        } else {
            console.info("Signing key not found: " + signature.keyid.toHex());
            signatureStates.push({verified: false, users: [signature.keyid.toHex()]});
        }
    }
    return signatureStates;
}
