import DbDraftEmail from "../domain/DbDraftEmail";
import {ticksFromDateTime} from "./DateHelper";
import {DateTime} from "luxon";
import {convertNameAndAddressToString} from "./Formatters";
import {BlankDraftEmail} from "../actions/ComposeEmailActions";
import {HandshakePref} from "./Preferences";

export function createDraftEmailFromMailToUrl(url: string, uid: string): DbDraftEmail {
    // E.g. mailto:compsoc-freshers-leave@lists.ox.compsoc.net
    if (!url.startsWith("mailto:")) {
        throw Error("Invalid mailto address");
    }
    const {DefaultAddress, DisplayName} = HandshakePref.get();

    let subject = "Unsubscribe";
    let body = "Please unsubscribe me";
    let cc = "";
    let bcc = "";

    let mailto = url.substring(7);
    const paramsStart = mailto.indexOf('?');
    if (paramsStart > -1) {
        const paramsString = mailto.substring(paramsStart + 1);
        mailto = mailto.substring(0, paramsStart);

        const params = paramsString.split('&');
        for (const param of params) {
            const [name, value] = param.split('=');
            if (!name || !value) continue;
            switch (name.toLowerCase()) {
                case "subject":
                    subject = decodeURIComponent(value);
                    break;
                case "body":
                    body = decodeURIComponent(value);
                    break;
                case "cc":
                    cc = decodeURIComponent(value);
                    break;
                case "bcc":
                    bcc = decodeURIComponent(value);
                    break;
            }
        }
    }
    return {
        ...BlankDraftEmail,
        Uid: uid,
        ThreadId: uid,
        ModifiedDate: ticksFromDateTime(DateTime.local()),
        From: convertNameAndAddressToString(DisplayName, DefaultAddress),
        BodyText: body,
        Subject: subject,
        To: mailto,
        Cc: cc,
        Bcc: bcc,
    };
}