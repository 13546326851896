import {AppState} from "../../../AppState";
import {FC, PropsWithChildren, useRef, useState} from "react";
import {Popover} from "@mui/material";
import {useAppDispatch, useAppSelector, useAppStore} from "../../../hooks/ReduxHooks";
import classNames from "classnames";
import {SimpleCheckbox} from "../SimpleCheckbox";
import {AnyAction} from "redux";

export interface SimpleGridHeaderProps {
    className?: string;
    filterSelector: (state: AppState) => string[];
    selectedFilterOptionsSelector: (state: AppState) => string[];
    selectFilterOptionAction: (options: string[]) => AnyAction;
}

export const SimpleGridHeader: FC<SimpleGridHeaderProps & PropsWithChildren> = ({
                                                                className,
                                                                filterSelector,
                                                                selectedFilterOptionsSelector,
                                                                selectFilterOptionAction,
                                                                children,
                                                            }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [options, setOptions] = useState<string[]>([]);
    const menuRef = useRef<HTMLDivElement>(null);

    const store = useAppStore();
    const dispatch = useAppDispatch();

    const selectedFilterOptions = useAppSelector(selectedFilterOptionsSelector);

    function handleClick() {
        const options = filterSelector(store.getState());

        const newSelectedFilterOptions = selectedFilterOptions.filter(s => options.includes(s));
        if (newSelectedFilterOptions.length !== selectedFilterOptions.length) {
            dispatch(selectFilterOptionAction(newSelectedFilterOptions));
        }
        
        setOptions(options);
        setOpen(true);
    }

    function handleOptionSelected(option: string) {
        const currentlySelected = selectedFilterOptions.includes(option);

        const newOptions = currentlySelected
            ? [...selectedFilterOptions].filter(o => o !== option)
            : [...selectedFilterOptions, option];

        dispatch(selectFilterOptionAction(newOptions));
    }

    return (
        <>
            <div className={classNames(className, "selectable", {
                "header-filterable": filterSelector,
                "header-filtered": selectedFilterOptions.length
            })}
                 ref={menuRef}
                 onClick={handleClick}>
                {children}
            </div>
            <Popover open={open}
                     onClose={() => setOpen(false)}
                     anchorEl={menuRef.current}
                     anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                     transformOrigin={{horizontal: "center", vertical: "top"}}>

                {options.map((o, i) => (
                    <div key={i} className="header-option">
                        <SimpleCheckbox label={o} checked={selectedFilterOptions.includes(o)} onChange={() => handleOptionSelected(o)}/>
                    </div>))}
            </Popover>
        </>
    );
}
