import React, { FC } from 'react';
import classNames from 'classnames';

export interface AccountImageProps {
    image: string;
    showDefault?: boolean;
}

export const AccountImage: FC<AccountImageProps> = ({ image, showDefault }) => {
    const isCard = image.indexOf("card-") === 0;

    if (image.length > 0) {
        const classes = classNames("glyph", { "card-glyph": isCard }, image);
        return (<span className={classes}></span>);
    }
    if (showDefault) {
        const classes = classNames("small-glyph", { "card-glyph": isCard });
        return (<span className={classes}></span>)
    }
    return null;
};
