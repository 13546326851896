
export enum FolderTypeEnum {
    Generic = 1,
    Inbox = 2,
    Drafts = 3,
    Deleted = 4,
    SentItems = 5,
    Outbox = 6,
    Tasks = 7,
    Calendar = 8,
    Contacts = 9,
    Notes = 10,
    Journal = 11,
    UserMail = 12,
    UserCalendar = 13,
    UserContacts = 14,
    UserTasks = 15,
    UserJournal = 16,
    UserNotes = 17,
    UnknownType = 18,
    RecipientInformationCache = 19,

    // Client side only
    Accounts = 100,
    Greylist = 101,
    ProtectedFiles = 102,
    Attachments = 103,
    Files = 104,
    Photos = 105,
}

export type FolderIconType = "inbox" | "deleted" | "folder";

export default interface Folder {
    folderId: number;
    serverId: string;
    parentId: string;
    name: string;
    type: FolderTypeEnum;
    indent: number;
    icon: string;
    hidden: boolean;
    unreadCount: number;
}
