import React, {FC, useState} from "react";

import "./ContactContentView.scss";
import {extractEmailAddresses, getContactDisplayName} from "../../util/Formatters";
import {PhysicalAddressDto} from "../../services/messages/PhysicalAddressDto";
import {ContactDto} from "../../services/messages/ContactDto";
import {HideContentButton} from "../common/HideContentButton";
import {ContactImage} from "./ContactImage";
import {StackPanel} from "../panels/StackPanel";
import ConfirmDialog from "../common/ConfirmDialog";
import {ContentLocation} from "../../locations/ContentLocation";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../AppState";
import {ContactEditView} from "./ContactEditView";
import {EmailActions} from "../../actions/EmailActions";
import classNames from "classnames";
import {SimpleField} from "../common/SimpleField";
import {EditButtons} from "../common/EditButtons";

function renderContactDetailList(fieldName: string, details: string[] | null | undefined, highlightValue: string | undefined) {
    if (!details || details.length === 0 || details.every(d => !d || d.trim().length === 0)) return null;
    return (
        <div>
            <span className="field-name">{fieldName}</span>
            {details.map((detail, i) => (
                <span key={i}
                      className={classNames("field", {"highlighted": highlightValue === detail})}>
                    {detail}
                </span>))}
        </div>
    );
}

function renderLine(text: string | null | undefined) {
    if (!text) return null;
    return (<>{text}<br/></>);
}

function renderContactPhysicalAddress(addresses: PhysicalAddressDto[] | null | undefined) {
    if (!addresses || addresses.length === 0) return null;
    return addresses.map((address, i) => (
        <div key={i}>
            <span className="field-name">{address.Type} Address</span>
            <span className="field">
                {renderLine(address.Street)}
                {renderLine(address.City)}
                {renderLine(address.State)}
                {renderLine(address.Country)}
                {renderLine(address.Postcode)}
            </span>
        </div>
    ));
}

export interface ContactContentViewProps {
    contact: ContactDto;
}

export interface ContactContentLightViewProps {
    small?: boolean;
    highlightEmail?: string;
}

export const ContactContentLiteView: FC<ContactContentViewProps & ContactContentLightViewProps> = ({contact, small, highlightEmail}) => {
    return (
        <StackPanel
            className={classNames("contact-right-pane", {"small": small})}
            orientation="vertical">

            <div className="name">
                {small ? <ContactImage contact={contact} size="small"/> : null}
                {getContactDisplayName(contact)}
            </div>

            <SimpleField fieldName="Nickname" detail={contact.NickName}/>
            <SimpleField fieldName="Notes" detail={contact.Notes}/>
            {renderContactDetailList("Email", contact.Emails.map(e => extractEmailAddresses(e).slice(0, 1)[0]), highlightEmail)}
            <SimpleField fieldName="Birthday" detail={contact.Birthday}/>
            <SimpleField fieldName="Anniversary" detail={contact.Anniversary}/>
            <SimpleField fieldName="Spouse" detail={contact.Spouse}/>
            <SimpleField fieldName="Children" detail={contact.Children}/>

            {contact.WebPage && contact.WebPage?.trim().length > 0
                ? (<div>
                        <span className="field-name">Web Page</span>
                        <span className="field">
                                        <a href={contact.WebPage} target="_blank" rel="noopener noreferrer">{contact.WebPage}</a>
                                    </span>
                    </div>
                ) : null}
            <SimpleField fieldName="Mobile" detail={contact.MobilePhone}/>
            <SimpleField fieldName="Home" detail={contact.HomePhone}/>
            <SimpleField fieldName="Home" detail={contact.Home2Phone}/>

            {renderContactDetailList("IM Address", contact.IMAddresses, highlightEmail)}

            {renderContactPhysicalAddress(contact.Addresses)}

            <SimpleField fieldName="Home Fax" detail={contact.HomeFax}/>
            <SimpleField fieldName="Business Phone" detail={contact.BusinessPhone}/>
            <SimpleField fieldName="Business Phone" detail={contact.Business2Phone}/>
            <SimpleField fieldName="Business Fax" detail={contact.BusinessFax}/>
            <SimpleField fieldName="Company Phone" detail={contact.CompanyPhone}/>
            <SimpleField fieldName="Pager" detail={contact.Pager}/>
            <SimpleField fieldName="Radio Phone" detail={contact.RadioPhone}/>
            <SimpleField fieldName="Car Phone" detail={contact.CarPhone}/>
            <SimpleField fieldName="MMS" detail={contact.Home2Phone}/>

            <SimpleField fieldName="Company" detail={contact.Company}/>
            <SimpleField fieldName="Office Location" detail={contact.OfficeLocation}/>
            <SimpleField fieldName="Job Title" detail={contact.JobTitle}/>
            <SimpleField fieldName="Department" detail={contact.Department}/>
            <SimpleField fieldName="Manager Name" detail={contact.ManagerName}/>
            <SimpleField fieldName="Assistant Name" detail={contact.AssistantName}/>
            <SimpleField fieldName="Assistant Phone" detail={contact.AssistantPhone}/>

        </StackPanel>
    );
}

export const ContactContentView: FC<ContactContentViewProps> = ({contact}) => {

    const isEditMode = useSelector(({itemState}: AppState) => itemState.itemType === "editcontact");

    const [confirmDeleteContact, setConfirmDeleteContact] = useState(false);

    const dispatch = useDispatch();

    function onEditContact() {
        ContentLocation.editContact(contact.Id, contact.ClientId);
    }

    function onBeginDeleteContact() {
        setConfirmDeleteContact(true);
    }

    async function onDeleteContact(confirm: boolean) {
        setConfirmDeleteContact(confirm);
        if (confirm) {
            dispatch(await EmailActions.addRemoteOperation({ManageContact: {DeleteId: contact.Id, DeleteClientId: contact.ClientId}}));
            dispatch(EmailActions.clearItemContent());
        }
    }

    return (
        <div className="contact-content-container edit-buttons-container">
            {isEditMode
                ? <ContactEditView contact={contact}/>
                : <>
                    <div>
                        <HideContentButton/>
                        <StackPanel className="contact-left-pane" orientation="vertical" align="middle">
                            <ContactImage contact={contact} size="large"/>

                            <div className="category"><span className="field">{contact.Category}</span></div>
                        </StackPanel>

                        <ContactContentLiteView contact={contact}/>

                        <ConfirmDialog title="Delete Contact"
                                       message={`Are you sure you want to delete the contact '${getContactDisplayName(contact)}'?`}
                                       open={confirmDeleteContact}
                                       onConfirm={onDeleteContact}
                                       confirmColour="error"/>

                    </div>

                    <EditButtons onSetEditing={onEditContact}
                                 onDelete={onBeginDeleteContact}/>
                </>}
        </div>
    );
};
