import {createTheme} from "@mui/material";
import Preference from "../util/Preferences";
import {AllowedThemes} from "../actions/SystemActionTypes";

export default function createAppTheme(theme: AllowedThemes) {
    return createTheme({
        palette: {
            mode: theme,
            primary: {
                //theme === "light" : palette.primary.light
                main: theme === "light" ? "#4791db" : '#1976d2',
            },
            secondary: {
                main: theme === "light" ? "#9a0036" : "#a42244",
            }
        },
        components: {
            MuiButtonGroup: {
                defaultProps: {
                    color: "inherit",
                },
            },
            MuiButton: {
                defaultProps: {
                    color: "inherit",
                },
            },

            MuiTextField: {
                defaultProps: {
                    variant: "standard",
                },
            },
            MuiSelect: {
                defaultProps: {
                    variant: "standard",
                },
            },

            MuiSnackbar: {
                defaultProps: {
                    anchorOrigin: {vertical: "bottom", horizontal: "center"},
                }
            },
            MuiAlert: {
                defaultProps: {
                    variant: "filled",
                    elevation: 6,
                },
                styleOverrides: {
                    filledSuccess: {
                        color: "inherit",
                    },                    
                },
            },
        },
    });
}

export const ThemePref = new Preference<AllowedThemes>("system", "theme", "light");
