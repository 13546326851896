import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {initialUsersState} from "../state/UsersState";
import {UserDto} from "../domain/UserDto";
import {LoginAuditDto} from "../domain/LoginAuditDto";

export const usersSlice = createSlice({
    name: "users",
    initialState: initialUsersState,
    reducers: {
        setUsers(state, {payload}: PayloadAction<UserDto[]>) {
            state.users = payload;
        },
        selectUser(state, {payload}: PayloadAction<{ userId: number | undefined }>) {
            state.selectedUser = state.users.find(k => k.WebId === payload.userId);
        },
        showUser(state, {payload: user}: PayloadAction<UserDto | undefined>) {
            state.selectedUser = user;
        },

        setLoginAudits(state, {payload}: PayloadAction<LoginAuditDto[]>) {
            state.logins = payload;
        },
        selectLoginAudits(state, {payload}: PayloadAction<LoginAuditDto[]>) {
            state.selectedLogins = payload;
        },
        toggleSelectAllLoginAudits(state) {
            state.selectedLogins = state.selectedLogins.length > 0 ? [] : state.logins;
        },
    },
});

export namespace UsersActions {
    export const {
        setUsers,
        selectUser,
        showUser,

        setLoginAudits,
        selectLoginAudits,
        toggleSelectAllLoginAudits,
    } = usersSlice.actions;
}
