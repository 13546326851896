import React, {FC, PropsWithChildren, ReactNode} from "react";
import {connect, ConnectedProps} from "react-redux";
import {EmailHeaderView} from "./email/EmailHeaderView";
import "./ContentView.scss";
import {Centre} from "./Centre";
import ItemView from "./item/ItemView";
import classNames from "classnames";
import {AppState} from "../AppState";
import Folder, {FolderTypeEnum} from "../domain/Folder";
import {ContactListView} from "./contacts/ContactListView";
import AccountsListView from "./accounts/AccountsListView";
import {hasItemContent} from '../domain/ItemContent';
import OutboxHeaderView from './email/OutboxHeaderView';
import {CalendarView} from './calendar/CalendarView';
import FolderSelectionOverlay from './folder/FolderSelectionOverlay';
import {SmallLoadingIndicator} from "./common/SmallLoadingIndicator";
import {NotesView} from "./notes/NotesView";
import {ErrorBoundary} from "./common/ErrorBoundary";
import {FilesView} from "./files/FilesView";

interface ContentViewContainerProps {
    hasContent: boolean;
    contentExpanded?: boolean;
}

export const ContentViewContainer: FC<ContentViewContainerProps & PropsWithChildren> = ({hasContent, contentExpanded, children}) => {
    return <div className={classNames("content-view", {
        "content-only": hasContent,
        "headers-only": !hasContent,
        "expanded-content": contentExpanded && hasContent
    },)}>
        {children}
    </div>;
}

const RenderContentView = ({
                               contentExpanded,
                               selectedFolder,
                               folderSelectionMode,
                               hasContent,
                               syncInProgress
                           }: ContentsViewProps) => {
    let content: ReactNode;
    let contentClass = "content-pane";

    // console.log("Folder type: ", selectedFolder?.type);

    if (!selectedFolder) {
        content = (<Centre className="empty-content">Select a folder</Centre>);

    } else if (selectedFolder.type === FolderTypeEnum.Contacts) {
        content = (
            <ContentViewContainer hasContent={hasContent} contentExpanded={contentExpanded}>
                <ContactListView/>
                <ItemView/>
            </ContentViewContainer>
        );

    } else if (selectedFolder.type === FolderTypeEnum.Calendar) {
        content = (
            <ContentViewContainer hasContent={hasContent} contentExpanded={contentExpanded}>
                <CalendarView/>
                <ItemView/>
            </ContentViewContainer>
        );

    } else if (selectedFolder.type === FolderTypeEnum.Notes) {
        content = (
            <ContentViewContainer hasContent={hasContent} contentExpanded={contentExpanded}>
                <NotesView/>
            </ContentViewContainer>
        );

    } else if (selectedFolder.type === FolderTypeEnum.Files || selectedFolder.type === FolderTypeEnum.ProtectedFiles || selectedFolder.type === FolderTypeEnum.Attachments) {
        content = <div className="content-view"><FilesView/></div>;

    } else {
        const listView = getFolderListView(selectedFolder);

        content = (
            <ContentViewContainer hasContent={hasContent} contentExpanded={contentExpanded}>
                {listView}
                <ItemView/>
            </ContentViewContainer>
        );
    }

    return (
        <div className={contentClass} style={{minWidth: "300px"}}>
            {syncInProgress ? <Centre className="sync-in-progress"><SmallLoadingIndicator/>
                <div>Syncing in progress...</div>
            </Centre> : null}
            <ErrorBoundary>
                {content}
            </ErrorBoundary>
            {folderSelectionMode ? <FolderSelectionOverlay/> : null}
        </div>
    );
};

function getFolderListView(folder: Folder): ReactNode {
    switch (folder.type) {
        case FolderTypeEnum.Accounts:
            return <AccountsListView/>;
        case FolderTypeEnum.Outbox:
            return <OutboxHeaderView/>;
    }
    return <EmailHeaderView/>;
}

const mapProps = ({folderState, itemState, sync}: AppState) => ({
    selectedFolder: folderState.selectedFolder,

    syncInProgress: sync.syncInProgress,

    folderSelectionMode: folderState.folderSelectionMode,

    hasContent: hasItemContent(itemState.itemContent),

    contentExpanded: itemState.contentExpanded,
});

const connector = connect(mapProps);

type ContentsViewProps = ConnectedProps<typeof connector>;

const ContentView = connector(RenderContentView);

export default ContentView;
