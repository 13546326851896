import React, {FC, RefObject, useState} from "react";
import {ContextMenu} from "../common/ContextMenu";
import {DropDownMenuItem} from "../toolbar/DropDownMenu";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "../common/ConfirmDialog";
import {NoteSectionDto} from "../../domain/NoteSectionDto";
import {useSelector} from "react-redux";
import {AppState} from "../../AppState";
import {NotesActions} from "../../redux/NotesSlice";
import {useAppDispatch} from "../../hooks/ReduxHooks";

interface NoteSectionContextMenuProps {
    elementRef: RefObject<HTMLDivElement>;
}

export const NoteSectionContextMenu: FC<NoteSectionContextMenuProps> = ({
                                                                            elementRef,
                                                                        }) => {

    const [confirmDeleteSection, setConfirmDeleteSection] = useState<NoteSectionDto>();

    const contextSection = useSelector(({notesState}: AppState) => notesState.contextSection);

    const dispatch = useAppDispatch();

    function onClose() {
        dispatch(NotesActions.setNoteSectionContextMenu(undefined));
    }
    
    function onRenameSection() {
        if (!contextSection) return;
        dispatch(NotesActions.beginRenameNote(contextSection));
    }

    function onBeginDeleteSection() {
        setConfirmDeleteSection(contextSection);
    }

    async function onConfirmDeleteSection(confirmed: boolean) {
        if (!confirmDeleteSection) return;
        if (confirmed) {
            dispatch(await NotesActions.deleteNoteSection(confirmDeleteSection));
        }
        setConfirmDeleteSection(undefined);
    }

    const contextMenuItem: (DropDownMenuItem | string)[] = [
        {icon: <EditIcon/>, name: "Rename", onClick: onRenameSection},

        "div1",

        {icon: <DeleteIcon/>, name: "Delete", onClick: onBeginDeleteSection},
    ];

    return (<>
            <ContextMenu
                menuItems={contextMenuItem}
                elementRef={elementRef}
                onClose={onClose}/>
            <ConfirmDialog
                title="Delete Section"
                message={`Are you sure you want to delete the section '${confirmDeleteSection?.Name}'?`}
                open={!!confirmDeleteSection}
                onConfirm={onConfirmDeleteSection}
                confirmColour="error"/>
        </>
    );
}