import {AttachmentFileDto} from "../services/attachments/dto/AttachmentFileDto";

export interface AttachmentFilesState {
    attachments: AttachmentFileDto[];
    currentOffset: number;
    moreAttachmentsAvailable: boolean;
    shouldLoadMore: boolean;
    
    selectedAttachment?: AttachmentFileDto;

    searchText: string;
}

export const initialAttachmentFilesState: AttachmentFilesState = {
    attachments: [],
    currentOffset: 0,
    moreAttachmentsAvailable: true,
    shouldLoadMore: false,
    
    searchText: "",
};
