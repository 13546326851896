import {NoteSectionDto} from "../domain/NoteSectionDto";
import {NotePageDto} from "../domain/NotePageDto";

export interface NotesState {
    sections: NoteSectionDto[];

    pages: NotePageDto[];

    selectedSection?: NoteSectionDto;
    selectedSectionClientId?: string;

    selectedPage?: NotePageDto;
    selectedPageClientId?: string;

    contextSection?: NoteSectionDto;
    contextPage?: NotePageDto;

    editingSectionOrPage: NoteSectionDto | NotePageDto;
    showEditDialog: boolean;
    
    editingPage?: NotePageDto;
    editPageContent?: string;
}

export const initialNotesState: NotesState = {
    sections: [],

    pages: [],

    editingSectionOrPage: {Id: -1, Name: "", ClientId: undefined, DummySectionValue: undefined},
    showEditDialog: false,
};