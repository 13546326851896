import {EmailContentDto} from "../domain/EmailContentDto";
import DbDraftEmail from "../domain/DbDraftEmail";
import {uuid} from "../util/Uuid";
import {BlankDraftEmail} from "../actions/ComposeEmailActions";
import {DB} from "../db/DbManager";
import {
    ContactAddress,
    convertNameAndAddressToString,
    convertRecipientAddressesToString,
    extractEmailAddresses,
    extractFriendlyNamesFromEmailAddresses
} from "../util/Formatters";
import {EmailMutator, MailMutateAction} from "./EmailMutator";
import {DbAttachment} from "../db/DbSchema";
import {HandshakePref} from "../util/Preferences";

export type EmailResponseType = "Reply" | "ReplyAll" | "Forward";

export class EmailResponseFormatter {

    private removeSubjectPrefix(subject: string): string {
        const lowerSubject = subject.toLowerCase();
        if (lowerSubject.startsWith("fw:") || lowerSubject.startsWith("fw:") || lowerSubject.startsWith("re:")) {
            return subject.substr(3).trim();
        }
        if (lowerSubject.startsWith("fwd:")) {
            return subject.substr(4).trim();
        }
        return subject;
    }

    prefixSubjectForReplying(subject: string | null): string {
        if (subject === null) {
            return "Re: ";
        }
        return "Re: " + this.removeSubjectPrefix(subject);
    }

    prefixSubjectForForwarding(subject: string | null): string {
        if (subject === null) {
            return "Fw: ";
        }
        return "Fw: " + this.removeSubjectPrefix(subject);
    }

    deriveFromAddress(email: EmailContentDto, displayName: string, defaultAddress: string, availableAddresses: string[]): string {
        const originalToAddresses = extractEmailAddresses(email.To);
        const originalCcAddresses = extractEmailAddresses(email.CC);

        const selectedAddress = originalToAddresses
            .concat(originalCcAddresses)
            .filter(a => availableAddresses.includes(a))[0];
        if (selectedAddress) {
            return convertNameAndAddressToString(displayName, selectedAddress);
        }
        return convertNameAndAddressToString(displayName, defaultAddress);
    }

    async respondToEmail(email: EmailContentDto, responseType: EmailResponseType) {
        const { DisplayName, DefaultAddress, AvailableAddresses } = HandshakePref.get();

        const fromAddress = this.deriveFromAddress(email, DisplayName, DefaultAddress, AvailableAddresses);

        const draft: DbDraftEmail = {
            ...BlankDraftEmail,
            Uid: uuid(),
            From: fromAddress,
            OriginalEmailId: email.Id,
            ThreadId: email.ThreadId || uuid()
        };

        switch (responseType) {
            case "Reply":
                this.replyToEmail(email, draft);
                break;
            case "ReplyAll":
                this.replyAllToEmail(email, draft, AvailableAddresses);
                break;
            case "Forward":
                await this.forwardEmail(email, draft);
                break;
        }

        await DB.saveDraftEmail(draft);

        return draft;
    }

    private replyAllToEmail(email: EmailContentDto, draft: DbDraftEmail, availableAddresses: string[]) {
        this.replyToEmail(email, draft);

        const toAddresses = extractFriendlyNamesFromEmailAddresses(email.To).filter(a => a.Address !== draft.From);
        draft.To = convertRecipientAddressesToString([{ Address: email.ReplyTo }, ...this.filterAddresses(toAddresses, availableAddresses)]);

        const ccAddresses = extractFriendlyNamesFromEmailAddresses(email.CC).filter(a => a.Address !== draft.From);
        draft.Cc = convertRecipientAddressesToString(this.filterAddresses(ccAddresses, availableAddresses));
    }

    private filterAddresses(addresses: ContactAddress[], availableAddresses: string[]) {
        return addresses.filter(a => !availableAddresses.includes(a.Address?.toLowerCase() || ""));
    }

    private replyToEmail(email: EmailContentDto, draft: DbDraftEmail): void {
        draft.Subject = this.prefixSubjectForReplying(email.Subject);

        const mutator = new EmailMutator(email, MailMutateAction.Reply, email.Content);
        draft.BodyText = mutator.mutateMailInHtml();
        // TODO: support plain text emails

        draft.To = email.ReplyTo;
    }

    private async forwardEmail(email: EmailContentDto, draft: DbDraftEmail) {
        draft.Subject = this.prefixSubjectForForwarding(email.Subject);

        const mutator = new EmailMutator(email, MailMutateAction.Forward, email.Content);
        draft.BodyText = mutator.mutateMailInHtml();
        // TODO: support plain text emails

        for (const attachment of email.Attachments) {
            const draftAttachment: DbAttachment = {
                emailUid: draft.Uid,
                attachmentUid: attachment.Uid,
                contentId: attachment.ContentId,
                isUploaded: true,
                contentType: attachment.ContentType,
                name: attachment.Name,
                size: attachment.DataSize,
                // TODO: include the thumbnail
            };

            await DB.saveEmailAttachment(draftAttachment);
        }

        draft.Attachments = email.Attachments;
    }
}
