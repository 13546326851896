import React, {FC, useState} from "react";
import classNames from "classnames";
import {QuickFilterMode, QuickFilterModes} from "../../actions/QuickFilterActionTypes";
import SearchIcon from '@mui/icons-material/Search';
import {InputAdornment, TextField} from '@mui/material';

import "./QuickFilterOption.scss";
import CloseIcon from "@mui/icons-material/Close";
import {ViewModeSelector} from "./ViewModeSelector";

export interface QuickSearchTextProps {
    onQuickSearchTextChange: (text: string) => void;
    searchPrompt?: string;
    fullWidth?: boolean;
    initialSearchText?: string;
    autoFocus?: boolean;
}

export interface QuickFilterOptionsProps {
    onSelectQuickFilterMode: (mode: QuickFilterMode) => void;
}

export const QuickFilterOptionList: FC<QuickFilterOptionsProps & QuickSearchTextProps> = ({
                                                                                              onSelectQuickFilterMode,
                                                                                              onQuickSearchTextChange,
                                                                                              searchPrompt
                                                                                          }) => {
    return (
        <ViewModeSelector options={QuickFilterModes}
                          labels={["All", "Unread", "Flagged"]}
                          onSelectViewMode={onSelectQuickFilterMode}>
            <QuickSearchText searchPrompt={searchPrompt} onQuickSearchTextChange={onQuickSearchTextChange}/>
        </ViewModeSelector>
    )
};

export const QuickSearchText: FC<QuickSearchTextProps> = ({searchPrompt, fullWidth, onQuickSearchTextChange, initialSearchText, autoFocus}) => {
    const [quickSearchText, setQuickSearchText] = useState<string>(initialSearchText ?? "");

    function changeQuickSearchText(newValue: string) {
        setQuickSearchText(newValue);
        onQuickSearchTextChange(newValue);
    }

    return (
        <TextField className={classNames("hide-underline", {"search-highlight": !!quickSearchText})}
                   placeholder={searchPrompt || "Filter Folder"}
                   fullWidth={fullWidth || fullWidth === undefined}
                   InputProps={{
                       startAdornment: (
                           <InputAdornment position="start">
                               <SearchIcon/>
                           </InputAdornment>
                       ),
                       endAdornment: (
                           <InputAdornment position="end">
                               {quickSearchText && <CloseIcon className="selectable pointer"
                                                              onClick={() => changeQuickSearchText("")}/>}
                           </InputAdornment>
                       )
                   }}
                   value={quickSearchText}
                   onChange={e => changeQuickSearchText(e.target.value)}
                   autoFocus={autoFocus}
        />

    );
};
