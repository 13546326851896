import Folder, {FolderTypeEnum} from "../domain/Folder";
import FolderChangeDto from "../services/messages/FolderChangeDto";
import {AllActionTypes} from "../actions/AllActionTypes";
import {FolderState, FolderTemplate, initialFolderState} from "../state/FolderState";
import {
    CREATE_SUB_FOLDER_BEGIN,
    EXPAND_FOLDER,
    ExpandFolderAction,
    FOLDERS_LOADED,
    FoldersLoadedAction,
    HIDE_EDIT_FOLDER_DIALOG,
    MOVE_FOLDER_BEGIN,
    MOVE_FOLDER_CANCEL,
    PERFORM_SEARCH_ASYNC,
    RENAME_FOLDER_BEGIN,
    SEARCH_RESULTS_LOADED,
    SELECT_FOLDER,
    SET_CONTEXT_FOLDER,
    SET_SEARCH_OPTIONS,
    SET_SEARCH_TEXT,
    SHOW_ONLY_SYNCED_FOLDERS
} from "../actions/FolderActionTypes";
import {MOVE_EMAILS_BEGIN} from '../actions/EmailActionTypes';
import RemoteOperationDto from '../services/messages/RemoteOperationDto';
import {changeArrayItem, extractMapValuesToArray} from '../util/ReducerUtil';
import {uuid} from '../util/Uuid';
import {OutgoingEmailDto} from '../services/messages/OutgoingEmailDto';
import {ADD_REMOTE_OPERATION, SYNC_RESPONSE_RECEIVED} from "../redux/SyncSlice";
import {ItemChangeListDto} from "../services/messages/ItemChangeListDto";
import {ItemChangesDto} from "../services/messages/ItemChangesDto";
import {SearchOptionsPref} from "../util/Preferences";
import {getUsernameCookie} from "../util/CommonCookies";

export const SearchFolderId = -1;
export const AttachmentFolderId = -9;
export const NotesFolderId = -12;
export const AccountsFolderId = -13;
export const PgpKeyManagementId = -14;
export const ProtectedFilesFolderId = -19;
export const PhotosFolderId = -20;
export const GreylistFolderId = -22;

const AccountsFolder: Folder = {
    folderId: AccountsFolderId,
    hidden: false,
    icon: "Accounts",
    indent: 0,
    name: "Accounts",
    parentId: "0",
    serverId: AccountsFolderId.toString(),
    type: FolderTypeEnum.Accounts,
    unreadCount: 0,
};

const NotesFolder: Folder = {
    folderId: NotesFolderId,
    hidden: false,
    icon: "Notes",
    indent: 0,
    name: "Notes",
    parentId: "0",
    serverId: NotesFolderId.toString(),
    type: FolderTypeEnum.Notes,
    unreadCount: 0,
};

const GreylistFolder: Folder = {
    folderId: GreylistFolderId,
    hidden: false,
    icon: "Greylist",
    indent: 0,
    name: "Greylist",
    parentId: "0",
    serverId: GreylistFolderId.toString(),
    type: FolderTypeEnum.Greylist,
    unreadCount: 0,
};

const SearchFolder: Folder = {
    folderId: SearchFolderId,
    hidden: false,
    icon: "Search",
    indent: 0,
    name: "Search",
    parentId: "0",
    serverId: "-1",
    type: FolderTypeEnum.UserMail,
    unreadCount: 0,
};

const ProtectedFilesFolder: Folder = {
    folderId: ProtectedFilesFolderId,
    hidden: false,
    icon: "Files",
    indent: 0,
    name: "Files/Attachments",
    parentId: "0",
    serverId: ProtectedFilesFolderId.toString(),
    type: FolderTypeEnum.Files,
    unreadCount: 0,
};

const AttachmentsFolder: Folder = {
    folderId: AttachmentFolderId,
    hidden: false,
    icon: "Files",
    indent: 0,
    name: "Files/Attachments",
    parentId: "0",
    serverId: AttachmentFolderId.toString(),
    type: FolderTypeEnum.Files,
    unreadCount: 0,
};

const PhotosFolder: Folder = {
    folderId: PhotosFolderId,
    hidden: false,
    icon: "Files",
    indent: 0,
    name: "Photos",
    parentId: "0",
    serverId: PhotosFolderId.toString(),
    type: FolderTypeEnum.Files,
    unreadCount: 0,
};

export default function folderReducer(state = initialFolderState, action: AllActionTypes): FolderState {
    switch (action.type) {
        case SELECT_FOLDER:
            const selectedFolder = action.folderId === -999
                ? state.folderList.find(f => f.type === FolderTypeEnum.Inbox)
                : state.folderList.find(f => f.folderId === action.folderId);

            console.log("Selected folder: ", selectedFolder, action.folderId);
            return {
                ...state,
                selectedFolderId: selectedFolder?.folderId,
                selectedFolder,
                lazyLoadDataSource: action.virtualDataSource,
                currentSearchText: action.virtualDataSource ? "" + selectedFolder?.folderId : state.currentSearchText,
            };

        case FOLDERS_LOADED:
            return handleFoldersLoaded(state, action);

        case SHOW_ONLY_SYNCED_FOLDERS:
            return {...state, showOnlySynced: action.showOnlySynced};

        case SYNC_RESPONSE_RECEIVED:
            return handleSyncResponseReceived(state, action.payload);

        case EXPAND_FOLDER:
            return {
                ...state,
                expandedFolderIds: handleExpandFolder(state, action)
            };

        case SET_SEARCH_TEXT:
            return {...state, searchText: action.searchText};

        case SET_SEARCH_OPTIONS:
            SearchOptionsPref.set(action.searchOptions);
            return {...state, searchOptions: action.searchOptions};

        case PERFORM_SEARCH_ASYNC:
            return {
                ...state,
                selectedFolderId: SearchFolderId,
                selectedFolder: SearchFolder,
                lazyLoadDataSource: action.emailDataSource,
                currentSearchText: action.currentSearchText,
                searchInProgress: !action.error
            };
        case SEARCH_RESULTS_LOADED:
            return {...state, searchInProgress: false};

        case SET_CONTEXT_FOLDER:
            return {...state, contextFolder: action.folder};

        case RENAME_FOLDER_BEGIN:
            return {...state, editingFolder: action.folder, showEditFolderDialog: true};
        case CREATE_SUB_FOLDER_BEGIN:
            const subFolder = {...FolderTemplate, parentId: action.parentFolder.serverId, serverId: uuid()};
            return {...state, editingFolder: subFolder, showEditFolderDialog: true};
        case MOVE_FOLDER_BEGIN:
            return {...state, folderSelectionMode: "folder", editingFolder: action.folder};
        case MOVE_EMAILS_BEGIN:
            return {...state, folderSelectionMode: "email", emailsToMove: action.emails};
        case MOVE_FOLDER_CANCEL:
            return {...state, folderSelectionMode: undefined};
        case HIDE_EDIT_FOLDER_DIALOG:
            return {...state, showEditFolderDialog: false};

        case ADD_REMOTE_OPERATION:
            return applyRemoteOperationLocally(state, action.payload.operation);
    }
    return state;
}

function handleFoldersLoaded(state: FolderState, action: FoldersLoadedAction): FolderState {
    const newFolders = action.folders;

    if (!action.folders.some(f => f.folderId === AccountsFolderId)) {
        newFolders.push(AccountsFolder);
    }
    // TODO: fix this hack - the server should provide the list of entitlements to the client!
    if (!action.folders.some(f => f.folderId === GreylistFolderId) && getUsernameCookie() === "joshuaredgate") {
        newFolders.push(GreylistFolder);
    }
    if (!action.folders.some(f => f.folderId === NotesFolderId)) {
        newFolders.push(NotesFolder);
    }
    if (!action.folders.some(f => f.folderId === ProtectedFilesFolderId)) {
        newFolders.push(ProtectedFilesFolder);
    }
    if (!action.folders.some(f => f.folderId === AttachmentFolderId)) {
        newFolders.push(AttachmentsFolder);
    }
    if (!action.folders.some(f => f.folderId === PhotosFolderId)) {
        newFolders.push(PhotosFolder);
    }

    return {...state, folderList: newFolders};
}

function handleExpandFolder(state: FolderState, action: ExpandFolderAction) {
    if (action.expand) {
        return [...state.expandedFolderIds, action.folderId];
    } else {
        return state.expandedFolderIds.filter(id => id !== action.folderId);
    }
}

function handleSyncResponseReceived(state: FolderState, responseDto: ItemChangeListDto): FolderState {
    const folderChanges = responseDto.Folders;

    let newFolders: Folder[] = state.folderList;

    if (folderChanges) {
        newFolders = applyChangesToFolders(folderChanges, [...state.folderList]);
    }

    const tempOutgoingEmails: OutgoingEmailDto[] | undefined = responseDto.Out?.Items;

    const outgoingEmails = tempOutgoingEmails
        ? reduceOutgoingEmails(tempOutgoingEmails)
        : state.outgoingEmails;

    return {...state, folderList: newFolders, outgoingEmails};
}

function reduceOutgoingEmails(outgoingEmails: OutgoingEmailDto[]): OutgoingEmailDto[] {
    const map = new Map<string, OutgoingEmailDto>();

    for (const outgoingEmail of outgoingEmails) {
        const current = map.get(outgoingEmail.Uid);
        if (current) {
            current.Address += "; " + outgoingEmail.Address;
            current.IsOutgoing = outgoingEmail.IsOutgoing || current.IsOutgoing;
            current.NextRetry = Math.max(outgoingEmail.NextRetry || 0, current.NextRetry || 0) || null;
            current.RetryCount = Math.max(outgoingEmail.RetryCount, current.RetryCount);
        } else {
            map.set(outgoingEmail.Uid, outgoingEmail);
        }
    }

    return extractMapValuesToArray(map);
}

function applyChangesToFolders(data: ItemChangesDto<FolderChangeDto>, tempResult: Folder[]) {
    if (data.Changed) {
        for (const changedFolder of data.Changed) {
            const existingIndex = tempResult.findIndex(f => f.serverId === changedFolder.ServerId);
            if (existingIndex >= 0) {
                tempResult[existingIndex] = convertFolder(changedFolder);
            } else {
                tempResult.push(convertFolder(changedFolder));
            }
        }
    }

    if (data.DeletedIds) {
        tempResult = tempResult.filter(f => !data.DeletedIds?.includes(f.folderId))
    }

    return tempResult;
}

export function isOutgoingFolder(type: FolderTypeEnum | undefined) {
    return type === FolderTypeEnum.Drafts
        || type === FolderTypeEnum.Outbox
        || type === FolderTypeEnum.SentItems;
}

export function isJunkFolder(icon: string | undefined) {
    return icon === "Junk";
}

export function isVirtualFolder(type: FolderTypeEnum | undefined, icon: string | undefined, folderId: number | undefined): boolean {
    if (folderId && folderId < 0) {
        return true;
    }

    return type === FolderTypeEnum.Drafts
        || type === FolderTypeEnum.Outbox;
}

function convertFolder(folder: FolderChangeDto): Folder {
    const icon = getFolderIcon(folder);
    return {
        folderId: folder.Id,
        serverId: folder.ServerId,
        indent: 0,
        name: folder.Name,
        type: folder.Type,
        parentId: folder.ParentId,
        icon,
        hidden: folder.IsHidden && !isVirtualFolder(folder.Type, icon, folder.Id),
        unreadCount: folder.UnreadCount,
    };
}

function getFolderIcon(folder: FolderChangeDto): string {
    if (folder.ParentId === "0") {
        if (folder.Name === "Archive") {
            return "Archive";
        }
        if (folder.Name === "Junk E-mail") {
            return "Junk";
        }
    }
    return FolderTypeEnum[folder.Type];
}

function applyRemoteOperationLocally(state: FolderState, operation: RemoteOperationDto): FolderState {
    const {ManageFolder} = operation;
    if (ManageFolder) {
        const {RenameFolder, CreateSubFolder, DeleteFolder, MoveFolder} = ManageFolder;

        if (RenameFolder) {
            return {
                ...state,
                folderList: changeArrayItem(state.folderList, f => f.serverId === RenameFolder.ServerId, f => f.name = RenameFolder.Name)
            };
        }
        if (CreateSubFolder) {
            const newFolder: Folder = {
                ...FolderTemplate,
                name: CreateSubFolder.Name,
                parentId: CreateSubFolder.ParentServerId,
                serverId: CreateSubFolder.ServerId
            };
            const folderList = [...state.folderList, newFolder];
            return {...state, folderList};
        }
        if (DeleteFolder) {
            const folderList = state.folderList.filter(f => f.serverId !== DeleteFolder.ServerId);
            return {...state, folderList};
        }
        if (MoveFolder) {
            return {
                ...state,
                folderList: changeArrayItem(state.folderList, f => f.serverId === MoveFolder.ServerId, f => f.parentId = MoveFolder.NewParentServerId)
            };
        }
    }

    return state;
}