import React, {FC, useState} from "react";

import "./ContactListView.scss";
import {DbContact} from "../../db/DbSchema";
import classNames from "classnames";
import {ContactDto} from "../../services/messages/ContactDto";
import {ContentLocation} from "../../locations/ContentLocation";
import {ContactImage} from "./ContactImage";
import {StackPanel} from "../panels/StackPanel";
import {QuickSearchText} from "../common/QuickFilterOption";
import {extractEmailAddresses} from "../../util/Formatters";
import {ContactViewModePref} from "../../util/Preferences";
import {ContactViewModes, ContactViewModeType} from "../../domain/ContactViewModeType";
import GridIcon from '@mui/icons-material/Apps';
import CategoryIcon from '@mui/icons-material/Category';
import {ViewModeSelector} from "../common/ViewModeSelector";
import {useAppSelector} from "../../hooks/ReduxHooks";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";

function getContactName(contact: DbContact) {
    return contact.FirstName + " " + contact.MiddleName + " " + contact.LastName;
}

function sortContacts(a: DbContact, b: DbContact): number {
    return getContactName(a).localeCompare(getContactName(b));
}

const ContactCard: FC<{ contact: DbContact, selected: boolean, showCategory?: boolean }> = ({contact, selected, showCategory}) => (
    <div key={contact.Id}
         className={classNames("card-small", {"selected": selected})}
         onClick={() => ContentLocation.showContact(contact.Id, contact.ClientId)}>

        <ContactImage contact={contact}/>

        <StackPanel orientation="vertical" className="overflow-hidden">
            <span className="name">{contact.FirstName} {contact.MiddleName} {contact.LastName} </span>
            <span className="detail">{contact.MobilePhone ?? contact.HomePhone ?? contact.BusinessPhone}</span>
            <span className="detail">{extractEmailAddresses(contact.Emails[0] ?? contact.Emails[1] ?? contact.Emails[2])[0]}</span>
            {showCategory && <span className="category">{contact.Category}</span>}
        </StackPanel>
    </div>
);

export const ContactListView: FC = () => {
    const contacts = useAppSelector(s => s.contactState.contacts);

    const selectedContact = useAppSelector(s => s.itemState.itemContent as ContactDto);

    const [searchText, setSearchText] = useState("");
    const [viewMode, setViewMode] = useState(ContactViewModePref.get());

    function onSelectViewMode(newViewMode: ContactViewModeType) {
        ContactViewModePref.set(newViewMode);
        setViewMode(newViewMode);
    }

    function onQuickSearch(text: string) {
        setSearchText(text.toLowerCase());
    }

    const filteredContacts = contacts
        .filter(c => !searchText
            || (JSON.stringify(c).toLowerCase().includes(searchText)))
        .sort(sortContacts);

    let contactsView: React.ReactNode;

    switch (viewMode) {
        case "grid":
            contactsView = <div className="contact-grid-view">
                {filteredContacts.map(contact => (
                    <ContactCard key={contact.Id}
                                 contact={contact}
                                 showCategory
                                 selected={selectedContact === contact}/>
                ))}
            </div>
            break;
        case "byCategory":
            const contactsByCategory: Record<string, ContactDto[]> = {};
            for (const contact of filteredContacts) {
                const category = contact.Category ?? "Uncategorized";
                if (!contactsByCategory[category]) {
                    contactsByCategory[category] = [];
                }
                contactsByCategory[category].push(contact);
            }

            const sortedCategories = Object.entries(contactsByCategory).sort(([a], [b]) => a.localeCompare(b));
            contactsView = (
                <div className="contact-category-view">
                    {sortedCategories.map(([category, contacts]) => (
                        <Accordion key={category}>
                            <AccordionSummary>{category}</AccordionSummary>
                            <AccordionDetails>
                                <div className="contact-grid-view">
                                    {contacts.map(contact => <ContactCard key={contact.Id}
                                                                          contact={contact}
                                                                          selected={selectedContact === contact}/>)}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            );
            break;
    }

    return (
        <div className="contact-view-container content-item-list">
            <ViewModeSelector defaultValue={viewMode}
                              options={ContactViewModes}
                              labels={[<GridIcon/>, <CategoryIcon/>]}
                              onSelectViewMode={onSelectViewMode}>
                <QuickSearchText onQuickSearchTextChange={onQuickSearch} searchPrompt="Search Contacts"/>
            </ViewModeSelector>

            {contactsView}
        </div>
    );
};
