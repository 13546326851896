import {FC, Fragment} from "react";
import {EmailReadLogDto} from "../../domain/EmailReadLogDto";
import {RelativeDateTime} from "../common/RelativeDateTime";
import MarkEmailReadTwoToneIcon from '@mui/icons-material/MarkEmailReadTwoTone';

export const EmailReadLogsView: FC<{ readLogs: EmailReadLogDto[] | undefined }> = ({readLogs}) => {
    if (!readLogs || readLogs.length === 0) return null;

    return (<>
        <div className="read-log">
            <MarkEmailReadTwoToneIcon className="success"/>Read&nbsp;by&nbsp;
            <span>
                {readLogs?.map((log, i) => (<Fragment key={i}>{i > 0 && ", "} <span><b>{log.User}</b>&nbsp;at&nbsp;<b><RelativeDateTime dateTime={log.Date}/></b></span></Fragment>))}
            </span>
        </div>
    </>);
}
