import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RxSubjects} from "../state/RxSubjectsState";
import {PhotoBrowserDto} from "../services/photos/dto/PhotoBrowserDto";
import {initialPhotosState, PhotosState} from "../state/PhotosState";
import {RatePhotoResponse} from "../services/photos/PhotoBrowserService";
import {PhotoDto} from "../services/photos/dto/PhotoDto";

export const PictureSize = 108;

function scrollToSelectedPhoto(state: PhotosState) {
    if (!state.selectedPhoto) return;

    const windowWidth = window.innerWidth ? window.innerWidth : 0;

    state.scrollX = state.photos.findIndex(p => p.Name === state.selectedPhotoName) * PictureSize - (windowWidth - PictureSize) / 2;
    limitScrolling(state);
}

function limitScrolling(state: PhotosState) {
    const windowWidth = window.innerWidth ? window.innerWidth : 0;

    if (state.scrollX < 0) state.scrollX = 0;

    const maxScrollX = state.photos.length * PictureSize - windowWidth;
    if (state.scrollX > maxScrollX && maxScrollX > 0) {
        state.scrollX = maxScrollX;
    }
}

export const photosSlice = createSlice({
    name: "files",
    initialState: initialPhotosState,
    reducers: {
        handleBrowserResponse(state, {payload}: PayloadAction<PhotoBrowserDto>) {
            state.folders = [...payload.Folders].sort((a, b) => a.Name.localeCompare(b.Name)) ?? [];
            state.photos = payload.Photos ?? [];
            state.breadcrumbs = payload.Breadcrumbs ?? [];
            state.contextFolder = undefined;
            state.contextPhoto = undefined;
            scrollToSelectedPhoto(state);
        },
        handleRatePhotoResponse(state, {payload}: PayloadAction<RatePhotoResponse>) {
            state.photos = state.photos.map(p => {
                if (p.Name === payload.photo) {
                    p.Rating = payload.rating;
                }
                return p;
            });
            scrollToSelectedPhoto(state);
        },
        setSelectedPhoto(state, {payload}: PayloadAction<string | undefined>) {
            if (payload) {
                const temp = payload.split('/');
                state.breadcrumbs = temp.slice(0, temp.length - 1);
                state.selectedPhotoName = temp[temp.length - 1];
            } else {
                state.breadcrumbs = [];
                state.selectedPhotoName = undefined;
                state.scrollX = 0;
            }
            state.selectedPhoto = payload;
            scrollToSelectedPhoto(state);
        },
        setPhotosSearchText(state, {payload: text}: PayloadAction<string>) {
            state.searchText = text;
            RxSubjects.photosSearchText$.next(true);
        },
        selectFolder(state, {payload}: PayloadAction<string | undefined>) {
            state.breadcrumbs = (payload || "").split('/');
            RxSubjects.photosSearchText$.next(false);
            state.selectedPhoto = undefined;
            state.selectedPhotoName = undefined;
        },
        setLoading(state, {payload}: PayloadAction<boolean>) {
            state.loadingPhotos = payload;
        },
        scrollX(state, {payload: delta}: PayloadAction<number>) {
            state.scrollX += delta;
            limitScrolling(state);
        },
        setContextFolder(state, {payload}: PayloadAction<string | undefined>) {
            state.contextFolder = payload;
        },
        setContextPhoto(state, {payload}: PayloadAction<PhotoDto | undefined>) {
            state.contextPhoto = payload;
        },
    }
});

export namespace PhotosActions {
    export const {
        handleBrowserResponse,
        handleRatePhotoResponse,
        setSelectedPhoto,
        setPhotosSearchText,
        selectFolder,
        setContextFolder,
        setContextPhoto,
        setLoading,
        scrollX,
    } = photosSlice.actions;

    export const actions = photosSlice.actions;
}
