
export function getCreditCardType(accountNumber: string) {
    var result = "UnknownCard";

    if (/^5[1-5]/.test(accountNumber)) {
        result = "MasterCard";
    } else if (/^4/.test(accountNumber)) {
        result = "Visa";
    } else if (/^3[47]/.test(accountNumber)) {
        result = "Amex";
    } else if (/^633(110|312|304|303|301|300)/.test(accountNumber)) {
        result = "Switch";
    } else if (/^634/.test(accountNumber)) {
        result = "Clubcard";
    }

    return result;
}

// export function isValidCard(expiryDate: string, cardNumber: string, cvv: string) {
//     if (cardNumber.length < 10) {
//         alert("Please enter a valid credit card number");
//         return false;
//     }
//     if (!isValidCvv(cvv)) {
//         return confirm("The card CVV you entered is not valid. Are you sure you want to add this card as it is?");
//     }
//     if (!isValidCardNumber(cardNumber)) {
//         return confirm("The card number you entered is not valid. Are you sure you want to add this card as it is?");
//     }
//     if (!isValidExpiryDate(expiryDate)) {
//         return confirm("The expiry date you entered is not valid. Are you sure you want to add this card as it is?");
//     }
//     return true;
// }

export function isValidCardNumber(cardNumber: string) {
    cardNumber = cardNumber.trim();
    if (cardNumber.length === 0) {
        return false;
    }
    var digits = cardNumber;

    var offset = digits.length % 2;

    var sum = 0;
    for (var i = 0; i < digits.length; i++) {
        if ((i + offset) % 2 === 0) {
            var num = parseInt(digits[i]) * 2;
            if (num > 9) {
                num = num % 10 + Math.floor(num / 10);
            }
            sum += num;
        } else {
            sum += parseInt(digits[i]);
        }
    }

    return sum % 10 === 0;
}

export function isValidExpiryDate(expiryDate: string) {
    expiryDate = expiryDate.trim();
    return /^[0-1]?[0-9]\/[2-9][0-9]$/.test(expiryDate);
}

export function isValidCvv(cvv: string) {
    return cvv.length === 3 || cvv.length === 4;
}

export function formatCreditCardNumber(number: string): string {
    if (!number) return "";

    let result = "";
    for (let i = 0; i < number.length; i += 4) {
        if (i > 0) {
            result += " ";
        }
        result += number.substr(i, 4);
    }
    return result;
}
