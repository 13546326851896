import {FC, useEffect, useRef, useState} from "react";
import {Centre} from "../Centre";
import {SmallLoadingIndicator} from "./SmallLoadingIndicator";

interface LazyLoadProps {
    src: string;
    alt: string;
    className?: string;
}

const observer = typeof (IntersectionObserver) !== "undefined" ? new IntersectionObserver(entries => {
    for (const entry of entries) {
        if (entry.isIntersecting) {
            // @ts-ignore
            entry.target.onVisible();
        }
    }
}, {threshold: [0]}) : {
    observe(target: Element) {
    },
    unobserve(target: Element) {
    }
};

export const LazyLoadingImage: FC<LazyLoadProps> = (props) => {
    const element = useRef<HTMLElement>(null);
    const [visible, setVisible] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
            if (!element.current) return;

            const currentElement = element.current.parentElement!;
            // @ts-ignore
            currentElement.onVisible =
                () => {
                    setVisible(true);
                    observer.unobserve(currentElement);
                }

            observer.observe(currentElement);
            return () => observer.unobserve(currentElement);
        },
        [element]);

    function onLoad() {
        setLoaded(true);
    }

    return (
        <span className={props.className} ref={element}>
            {<img src={visible ? props.src : ""}
                  alt={props.alt}
                  style={{display: loaded ? "" : "none"}}
                  onLoad={() => onLoad()}/>}

            {!loaded && <Centre><SmallLoadingIndicator/></Centre>}
        </span>
    );
}
