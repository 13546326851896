import {isNotePage, NotePageDto} from "../domain/NotePageDto";
import {initialNotesState, NotesState} from "../state/NotesState";
import {AddRemoteOperationPayload, SyncActions} from "./SyncSlice";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {applySyncChangesWithClientIds} from "../reducers/SyncHelper";
import {ManageNotePageRemoteOperationDto, ManageNoteSectionRemoteOperationDto} from "../services/messages/RemoteOperationDto";
import {applyClientDtoRemoteOperationLocally} from "../util/ReducerUtil";
import {NoteSectionDto} from "../domain/NoteSectionDto";
import {uuid} from "../util/Uuid";
import {EmailActions} from "../actions/EmailActions";
import {ItemChangeListDto} from "../services/messages/ItemChangeListDto";

function findPage(pages: NotePageDto[], pageClientId: string | undefined): NotePageDto | undefined {
    return pages.find(p => p.ClientId === pageClientId) ?? pages[0];
}

export const notesSlice = createSlice({
    name: "notes",
    initialState: initialNotesState,
    reducers: {
        noteSectionsLoaded(state, {payload}: PayloadAction<NoteSectionDto[]>) {
            handleNoteSectionsLoaded(state, payload);
        },

        selectNoteSection(state, {payload}: PayloadAction<{ sectionClientId: string | undefined, pageClientId: string | undefined, pages: NotePageDto[] }>) {
            const selectedPage = findPage(payload.pages, payload.pageClientId);
            state.selectedSectionClientId = payload.sectionClientId;
            state.selectedSection = state.sections.find(s => s.ClientId === payload.sectionClientId);
            state.pages = payload.pages;
            state.selectedPage = selectedPage;
            state.selectedPageClientId = selectedPage?.ClientId;
            state.editingPage = undefined;
        },

        selectNotePage(state, {payload}: PayloadAction<string | undefined>) {
            const selectedPage = findPage(state.pages, payload);
            state.selectedPage = selectedPage;
            state.selectedPageClientId = selectedPage?.ClientId;
            state.editingPage = undefined;
        },

        setNoteSectionContextMenu(state, {payload}: PayloadAction<NoteSectionDto | undefined>) {
            state.contextSection = payload;
        },
        setNotePageContextMenu(state, {payload}: PayloadAction<NotePageDto | undefined>) {
            state.contextPage = payload;
        },

        beginRenameNote(state, {payload}: PayloadAction<NoteSectionDto | NotePageDto>) {
            state.editingSectionOrPage = payload;
            state.showEditDialog = true;
        },

        hideRenameNoteDialog(state) {
            state.showEditDialog = false;
        },
        setEditNoteName(state, {payload}: PayloadAction<string>) {
            state.editingSectionOrPage.Name = payload;
        },

        addNoteSection(state) {
            handleAddNoteSection(state);
        },

        addNotePage(state, {payload}: PayloadAction<NoteSectionDto>) {
            handleAddNotePage(state, payload);
        },

        beginEditNotePage(state) {
            state.editingPage = state.selectedPage;
            state.editPageContent = state.selectedPage?.HtmlContent;
        },
        stopEditingNotePage(state) {
            state.editingPage = undefined;
            state.editPageContent = undefined;
        },
        setEditNoteContent(state, {payload}: PayloadAction<string>) {
            state.editPageContent = payload;
        },
    },
    extraReducers: builder =>
        builder
            .addCase(SyncActions.syncResponseReceived, handleSyncResponse)
            .addCase(SyncActions.addRemoteOperation, applyRemoteOperationLocally),
});

export namespace NotesActions {
    export const {
        noteSectionsLoaded,
        selectNoteSection,
        hideRenameNoteDialog,
        beginRenameNote,
        addNoteSection,
        selectNotePage,
        setEditNoteName,
        setNotePageContextMenu,
        setNoteSectionContextMenu,
        addNotePage,
        beginEditNotePage,
        stopEditingNotePage,
        setEditNoteContent,
    } = notesSlice.actions;

    export const actions = notesSlice.actions;

    export function completeEditNote(sectionOrPage: NoteSectionDto | NotePageDto, includeContent?: boolean): Promise<PayloadAction<AddRemoteOperationPayload>> {
        if (isNotePage(sectionOrPage)) {
            const page = {...sectionOrPage};
            if (!includeContent) {
                delete page.HtmlContent;
            }
            const ManageNotePage: ManageNotePageRemoteOperationDto = {Change: page};
            return EmailActions.addRemoteOperation({ManageNotePage});
        } else {
            const ManageNoteSection: ManageNoteSectionRemoteOperationDto = {Change: sectionOrPage};

            const ManageNotePage: ManageNotePageRemoteOperationDto | undefined
                = sectionOrPage.Id < 0 ? {Change: createNewNotePage(sectionOrPage)} : undefined;

            return EmailActions.addRemoteOperation({ManageNoteSection, ManageNotePage});
        }
    }

    export function deleteNoteSection(section: NoteSectionDto): Promise<PayloadAction<AddRemoteOperationPayload>> {
        console.log("Deleting note section ", section);
        const ManageNoteSection: ManageNoteSectionRemoteOperationDto = {DeleteClientId: section.ClientId, DeleteId: section.Id};
        return EmailActions.addRemoteOperation({ManageNoteSection});
    }

    export function deleteNotePage(page: NotePageDto): Promise<PayloadAction<AddRemoteOperationPayload>> {
        console.log("Deleting note page ", page);
        const ManageNotePage: ManageNotePageRemoteOperationDto = {DeleteClientId: page.ClientId, DeleteId: page.Id};
        return EmailActions.addRemoteOperation({ManageNotePage});
    }
}

function handleNoteSectionsLoaded(state: NotesState, sections: NoteSectionDto[]): void {
    state.sections = sections.sort((a, b) => a.Name.localeCompare(b.Name));
    state.selectedSectionClientId = sections[0]?.ClientId;
    state.selectedSection = sections[0];
}

function handleSyncResponse(state: NotesState, {payload: changes}: PayloadAction<ItemChangeListDto>): void {
    let sections = state.sections;
    let pages = state.pages;

    const noteSections = changes.NoteSections;
    const notePages = changes.NotePages;

    if (noteSections) {
        state.sections = applySyncChangesWithClientIds(sections, noteSections, n => n)
            .sort((a, b) => a.Name.localeCompare(b.Name));
    }
    if (notePages) {
        state.pages = applySyncChangesWithClientIds(pages, notePages, n => n,
            c => c.ClientSectionId === state.selectedSectionClientId);
        
        state.selectedPage = findPage(state.pages, state.selectedPage?.ClientId);
    }
}

function applyChangesToNotePageDto(dto: NotePageDto, state: NotesState) {
    const existingPage = findPage(state.pages, dto.ClientId);
    const result: NotePageDto = {
        ...dto,
        HtmlContent: dto.HtmlContent || existingPage?.HtmlContent,
    };
    return result;
}

function applyRemoteOperationLocally(state: NotesState, payload: PayloadAction<AddRemoteOperationPayload>): void {
    const remoteOperation = payload.payload.operation;

    state.sections = applyClientDtoRemoteOperationLocally(remoteOperation.ManageNoteSection, state.sections, n => n)
        .sort((a, b) => a.Name.localeCompare(b.Name));

    state.pages = applyClientDtoRemoteOperationLocally(remoteOperation.ManageNotePage, state.pages,
        n => applyChangesToNotePageDto(n, state),
        p => p.ClientSectionId === state.selectedSection?.ClientId);

    state.selectedPage = findPage(state.pages, state.selectedPage?.ClientId) ?? state.pages[0];
}

export function createNewNoteSection() {
    const newSection: NoteSectionDto = {
        Id: -1,
        ClientId: uuid(),
        Name: "New Section",

        DummySectionValue: undefined,
    };
    return newSection;
}

function handleAddNoteSection(state: NotesState) {
    const newSection = createNewNoteSection();

    console.debug("Adding note section:", newSection);
    state.editingSectionOrPage = newSection;
    state.showEditDialog = true;
}

export function createNewNotePage(parentSection: NoteSectionDto) {
    const newPage: NotePageDto = {
        Id: -1,
        ClientId: uuid(),
        ClientSectionId: parentSection.ClientId,
        Name: "New Page",
        HtmlContent: "Change me",
        ModifiedTime: 0,
    };
    return newPage;
}

function handleAddNotePage(state: NotesState, parentSection: NoteSectionDto) {
    const newPage = createNewNotePage(parentSection);
    console.debug("Adding note page:", newPage);
    state.editingSectionOrPage = newPage;
    state.showEditDialog = true;
}
