import React, {FC, useLayoutEffect} from "react";
import {useSelector} from "react-redux";
import {AppState} from "../../../AppState";

export const SystemLogFileView: FC = () => {

    const scrollRef = React.useRef<HTMLDivElement | null>(null);
    
    const lines = useSelector(({sysLogs}: AppState) => sysLogs.lines);
    
    useLayoutEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });    
    });
    
    return (
        <div className="log-lines">
            <div>&nbsp;</div>
            {lines.map((line, i) => <div key={i} className={"L" + line.l}>{line.t}</div>)}
            <div ref={scrollRef}>&nbsp;</div>
        </div>
    );
}
