import {FC} from "react";
import {FileDto} from "../../services/protected/dto/FileDto";
import {formatFileSize} from "../../util/Formatters";
import {StackPanel} from "../panels/StackPanel";
import {RelativeDateTime} from "../common/RelativeDateTime";
import {useAppDispatch, useAppSelector, useAppStore} from "../../hooks/ReduxHooks";
import {ProtectedFilesService} from "../../services/protected/ProtectedFilesService";
import classNames from "classnames";
import {ProtectedFilesActions} from "../../redux/ProtectedFilesSlice";

export const ProtectedFileItemView: FC<{
    file: FileDto,
}> = ({file}) => {
    const dispatch = useAppDispatch();

    const store = useAppStore();
    const selected = useAppSelector(({filesState}) => filesState.selectedFile === file);

    async function onDownloadFile() {
        const {accountsState} = store.getState();
        await ProtectedFilesService.downloadFile(file, accountsState.secretKeys, accountsState.password, dispatch);
    }

    return (
        <StackPanel orientation="horizontal"
                    className={classNames("file", "selectable", "pointer", {"selected": selected})}
                    title={file.Name}
                    onClick={onDownloadFile}
                    onContextMenu={() => dispatch(ProtectedFilesActions.setSelectedFile(file))}>
            <span className="image">
                <img src={file.Image} alt="Icon"/>
            </span>

            <StackPanel orientation="vertical" middle={true} className="details">
                <span className="subject name">{file.Name}</span>
                <span>{formatFileSize(file.Size)}</span>
                <span className="size-and-date"><RelativeDateTime dateTime={file.Date}/></span>
            </StackPanel>

        </StackPanel>
    );
}
