export const KnownCategoriesMap: Record<string, string> = {
    "Alert": "⚠️",
    "Bill": "💲",
    "Failed": "❌",
    "Passed": "✅",
    "Newsletter": "🗞️",
    "OTP": "🔑",
    "Personal": "🧍‍️",
    "Promotion": "⬆️",
    "Post Master": "✉️",
    "Social": "👪",
    "Shopping": "🛍",
    "Ticket": "🎟️",
}
