import {FC} from "react";
import {UnknownPgpKey} from "../../domain/PgpKey";
import {HideContentButton} from "../common/HideContentButton";

export const UnknownPgpKeyItemView: FC<{ pgpKey: UnknownPgpKey }> = ({pgpKey}) => (
    <div className="pgp-item-panel">
        <div className="title">Unknown Key</div>
        <HideContentButton/>
        <div className="key-info">Key Id: {pgpKey.unknownKeyHexId}</div>
    </div>
);
