import React, {FC} from "react";
import {useSelector} from "react-redux";
import {AppState} from "../AppState";
import {isNotificationApiSupported} from "../NotificationManager";

export const DebugView: FC = () => {
    const notificationPermission = useSelector(({system}:AppState) => system.notificationPermission);

    if (window.location.hash.indexOf("debug=1") === -1) {
        return null;
    }
    
    const supported = isNotificationApiSupported();
    
    return (
        <div className="offline-banner">{notificationPermission} - {supported ? "supported" : "not supported"}</div>
    );
};
