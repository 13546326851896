import React, {ChangeEvent, FC, MutableRefObject, useRef} from "react";

export interface FileUploadControlCallback {
    openFileDialog: () => void;
}

export type FileUploadControlRef = MutableRefObject<FileUploadControlCallback | null | undefined>;

export interface FileUploadControlProps {
    fileControlRef: FileUploadControlRef;
    onFilesSelected: (files: FileList) => Promise<void>;

    multiple?: boolean;
}

export const FileUploadControl: FC<FileUploadControlProps> = ({
                                                                    fileControlRef,
                                                                    onFilesSelected,
                                                                    multiple,
                                                                }) => {
    const fileRef = useRef<HTMLInputElement | null>();

    async function onFilesSelectedInternal({target}: ChangeEvent<HTMLInputElement>) {
        const {files} = target;
        if (!files || files.length === 0) {
            return;
        }
        await onFilesSelected(files);
    }

    fileControlRef.current = {
        openFileDialog() {
            fileRef.current?.click();
        }
    };

    return (
        <input
            type="file"
            style={{display: "none"}}
            onChange={onFilesSelectedInternal}
            ref={instance => fileRef.current = instance}
            multiple={multiple}/>
    );
}
