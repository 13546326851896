import "./SearchControl.scss";

import React from 'react';
import {InputAdornment, TextField} from '@mui/material';
import {FolderLocation} from '../../locations/FolderLocation';
import SearchIcon from '@mui/icons-material/Search';
import {SmallLoadingIndicator} from '../common/SmallLoadingIndicator';
import classNames from 'classnames';
import CloseIcon from "@mui/icons-material/Close";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {PreferenceActions} from "../../redux/PreferencesSlice";

export interface SearchControlProps {
    showWhenMobile: boolean;
}

export const SearchControl = ({
                                  showWhenMobile,
                              }: SearchControlProps) => {

    const dispatch = useAppDispatch();

    const showingSearch = useAppSelector(s => s.preferenceState.showingSearch);

    const searchText = useAppSelector(s => s.folderState.searchText);
    const isMobile = useAppSelector(s => s.system.isMobile);
    const isOffline = useAppSelector(s => s.system.isOffline);

    const searchInProgress = useAppSelector(s => s.folderState.searchInProgress);
    const selectedFolderId = useAppSelector(s => s.folderState.selectedFolderId);

    if (showWhenMobile !== isMobile) {
        return null;
    }

    if (isOffline) {
        return <TextField value="Offline" InputProps={{readOnly: true}} className="hide-underline search-offline"/>;
    }

    if (isMobile && !searchText && !showingSearch) {
        return (
            <span className={"selectable pointer"}>
                <SearchIcon fontSize="large" onClick={() => dispatch(PreferenceActions.changeShowingSearch(true))}/>
            </span>
        );
    }

    function cancelSearch() {
        FolderLocation.searchForText("");
        dispatch(PreferenceActions.changeShowingSearch(false));
    }

    return (<>
            <TextField
                className={classNames({
                    "search-highlight": !!searchText && selectedFolderId === -1,
                    "hide-underline": isMobile
                })}
                fullWidth
                value={searchText}
                autoFocus
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon/>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            {(searchText || isMobile) && <CloseIcon className="selectable pointer"
                                                                    onClick={cancelSearch}/>}
                        </InputAdornment>
                    )
                }}
                placeholder={showWhenMobile ? "" : "Search Everywhere"}
                onChange={e => FolderLocation.searchForText(e.target.value)}/>

            {searchInProgress ? <SmallLoadingIndicator className="search-indicator"/> : null}
        </>
    );
};
