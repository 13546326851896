import {AppDispatch, AppStore} from "../AppStore";
import {AppState} from "../AppState";
import {SystemActions} from "../actions/SystemActions";
import {buildLocation, deleteExtraProps, LocationProperties, parseLocation} from "./LocationParser";
import {shallowEqual} from "react-redux";
import {LocationAppType} from "../apps/AppConfig";

export interface GlobalLocationProperties {
    app: LocationAppType;
}

export type LocationListener = (dispatch: AppDispatch, getState: () => AppState, newLocation: LocationProperties, oldLocation: LocationProperties) => void;

export class LocationManager {
    private readonly store: AppStore;

    private currentApp: LocationAppType = "email";

    private oldLocation: LocationProperties = {app: this.currentApp};
    private currentLocation: LocationProperties = {app: this.currentApp};

    private listenersMap: Map<LocationAppType, LocationListener[]> = new Map<LocationAppType, LocationListener[]>();
    private appTypesMap: Map<LocationAppType, readonly string[]> = new Map<LocationAppType, readonly string[]>();

    constructor(store: AppStore) {
        this.store = store;
    }

    getCurrentApp(): LocationAppType {
        return this.currentApp;
    }

    getLocation(): LocationProperties {
        return this.currentLocation;
    }

    setAppProperties(app: LocationAppType, appProperties: readonly string[]) {
        this.appTypesMap.set(app, appProperties);
    }

    addListener(app: LocationAppType, listener: LocationListener) {
        let listeners = this.listenersMap.get(app);
        if (!listeners) {
            this.listenersMap.set(app, listeners = []);
        }
        listeners.push(listener);
    }

    startListening() {
        window.addEventListener('hashchange', () => this.handleLocationChange(window.location.hash));

        this.handleLocationChange(window.location.hash);
    }

    switchApp(newApp: LocationAppType) {
        this.updateWindowLocation(newApp, {});
    }

    updateWindowLocation(app: LocationAppType | undefined, newLocation: Partial<LocationProperties>) {
        const updatedLocation = {...this.currentLocation, ...newLocation, app: app || this.currentApp};
        this.setWindowLocation(updatedLocation);
    }

    modifyWindowLocation(app: LocationAppType | undefined, mutator: (location: LocationProperties) => void) {
        const newLocation = {...this.currentLocation, app: app || this.currentApp};
        mutator(newLocation);
        this.setWindowLocation(newLocation);
    }

    private handleLocationChange(locationString: string) {
        const newLocation = parseLocation(locationString);
        if (shallowEqual(this.currentLocation, newLocation)) return;

        this.currentLocation = newLocation;

        this.invokeLocationAwareServices();
    }

    private setWindowLocation(updatedLocation: LocationProperties) {
        if (shallowEqual(this.currentLocation, updatedLocation)) {
            return;
        }

        this.currentLocation = updatedLocation;

        window.location.hash = buildLocation(this.currentLocation);

        this.invokeLocationAwareServices();
    }

    private invokeLocationAwareServices() {
        const dispatch = this.store.dispatch;

        if (this.currentApp !== this.currentLocation.app) {
            if (!this.currentLocation.app) {
                this.currentLocation.app = this.oldLocation.app;
            }

            this.currentApp = this.currentLocation.app;

            if (this.currentLocation.app) {
                dispatch(SystemActions.setApp(this.currentApp));
                document.title = this.store.getState().system.appConfig.title;
            }
        }

        // Remove properties not associated with the app:
        const appProperties = [...this.appTypesMap.get(this.currentApp) || [], "app"];

        const appLocation = {...this.currentLocation};
        deleteExtraProps(appLocation, appProperties);

        // Invoke the app location listeners
        const listeners = this.listenersMap.get(this.currentApp) || [];
        for (const listener of listeners) {
            listener(dispatch, this.store.getState, appLocation, this.oldLocation);
        }

        this.oldLocation = appLocation;
    };
}