import "./WrapPanel.scss";

import React, {FC, PropsWithChildren, RefObject} from "react";
import classNames from "classnames";

interface WrapPanelProps {
    className?: string;
    elementRef?: RefObject<HTMLDivElement>;
    onContextMenu?: (event: React.MouseEvent) => void;
}

export const WrapPanel: FC<WrapPanelProps & PropsWithChildren> = ({
                                                  children,
                                                  className,
                                                  elementRef,
                                                  onContextMenu,
                                              }) => {
    return (
        <div className={classNames("wp", className)} ref={elementRef} onContextMenu={onContextMenu}>
            {children}
        </div>
    );
}
