export namespace ContentType {
    export const PlainText = "text/plain";

    export const HtmlText = "text/html";

    export const Json = "application/json";

    export const PgpArmoured = "application/x-pgp-encrypted";
    export const PgpBinary = "application/pgp-encrypted";

    export const PgpMultipartSigned = "multipart/signed";
    export const PgpSignature = "application/pgp-signature";
    export const PgpKeys = "application/pgp-keys";

    export const BinaryStream = "application/octet-stream";
}