import React from "react";
import {UserListView} from "./UserListView";
import {ContentViewContainer} from "../ContentView";
import {UserItemView} from "./UserItemView";
import {AppContainer} from "../common/AppContainer";
import {useAppSelector} from "../../hooks/ReduxHooks";

export const UserManagementView = () => {
    const hasContent = useAppSelector(({usersState}) => !!usersState.selectedUser);

    return (
        <AppContainer>
            <ContentViewContainer hasContent={hasContent}>
                <UserListView/>
                <UserItemView/>
            </ContentViewContainer>
        </AppContainer>
    );
};
