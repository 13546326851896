import "./ProtectedFilesView.scss";

import {ButtonPanel} from "../common/ButtonPanel";
import React, {ReactNode} from "react";
import HttpsIcon from '@mui/icons-material/Https';
import {PreferenceActions} from "../../redux/PreferencesSlice";
import AppsIcon from "@mui/icons-material/Apps";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ListIcon from "@mui/icons-material/List";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {ProtectedFileControls} from "./ProtectedFileControls";
import classNames from "classnames";
import {AttachmentFileControls} from "../attachments/AttachmentFileControls";
import {AttachmentFilesView} from "../attachments/AttachmentFilesView";
import {ProtectedFileListView} from "./ProtectedFileListView";
import {FolderLocation} from "../../locations/FolderLocation";
import {AttachmentFolderId, PhotosFolderId, ProtectedFilesFolderId} from "../../reducers/FolderReducer";
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import {PhotosControls} from "../photos/PhotosControls";
import {PhotosBrowserView} from "../photos/PhotosBrowserView";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";

export const FilesView = () => {
    const dispatch = useAppDispatch();

    const selectedFolderId = useAppSelector(s => s.folderState.selectedFolderId);

    const isMobile = useAppSelector(({system}) => system.isMobile);

    const viewMode = useAppSelector(s => s.preferenceState.viewMode);

    const [controlsElt, viewElt, showViewMode] = getViewElements(selectedFolderId);

    const buttons = (
        <>
            <ToggleButtonGroup exclusive={true}
                               value={selectedFolderId}
                               onChange={(_, value) => value && FolderLocation.selectFolderById(value)}>
                <ToggleButton value={ProtectedFilesFolderId} title="Protected Files">
                    <HttpsIcon/>
                </ToggleButton>
                <ToggleButton value={AttachmentFolderId} title="Attachments">
                    <AttachFileIcon/>
                </ToggleButton>
                <ToggleButton value={PhotosFolderId} title="Photos">
                    <PhotoLibraryIcon/>
                </ToggleButton>
            </ToggleButtonGroup>

            {showViewMode &&
                (<ToggleButtonGroup exclusive={true}
                                    value={viewMode}
                                    onChange={(_, value) => dispatch(PreferenceActions.changeViewMode(value))}>
                    <ToggleButton value="icons" title="Icons View">
                        <AppsIcon/>
                    </ToggleButton>
                    <ToggleButton value="list" title="List View">
                        <ListIcon/>
                    </ToggleButton>
                </ToggleButtonGroup>)}

            {controlsElt}

            <div className="filler">&nbsp;</div>
        </>
    );
    return (
        <ButtonPanel className={classNames("files-container", viewMode)}
                     buttonsClassName="files-buttons"
                     buttonLocation="top"
                     buttons={buttons}
                     fillContent={isMobile}>
            {/*<ProtectedFileListView onUploadFileClick={onUploadFiles}/>*/}
            {viewElt}
        </ButtonPanel>
    );
}

function getViewElements(selectedFolderId: number | undefined) : [ReactNode, ReactNode, boolean] {
    switch (selectedFolderId) {
        case ProtectedFilesFolderId:
            return [<ProtectedFileControls/>, <ProtectedFileListView/>, true];
        case AttachmentFolderId:
            return [<AttachmentFileControls/>, <AttachmentFilesView/>, true];
        case PhotosFolderId:
            return [<PhotosControls/>, <PhotosBrowserView/>, false];
    }
    return [<div>No controls defined</div>, <div>No view defined</div>, false];
}