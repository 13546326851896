import React, {FC, ReactNode} from "react";
import {Button} from "@mui/material";

export interface ToolbarButtonProps {
    onClick: () => void;
    title: string;
    label?: string;
    disabled?: boolean;
    icon: ReactNode;
    className?: string;
    toggled?: boolean;
}

export const ToolbarButton: FC<ToolbarButtonProps>
    = ({onClick, title, label, disabled, icon, className, toggled}) =>
    (<Button title={title}
             onClick={_ => onClick()}
             disabled={disabled}
             className={className}
             color={toggled ? "primary" : undefined}>
        {icon}
        <span className="label">{label || title}</span>
    </Button>)
;
