import {ImageWhitelistDto} from "../redux/PreferenceActions";

export type EmailClassType = "" | "quote" | "signature";

export function getPixelSize(stringValue: string, containerWidth?: number) {
    // TODO: handle "calc" values more intelligently
    if (containerWidth && stringValue.startsWith("calc")) {
        return containerWidth - 10;
    }
    let value = parseFloat(stringValue);
    if (stringValue.endsWith("in")) {
        value *= 96;  // TODO: calculate this
    }
    return value;
}

export function getEmailClassType(existingClass: EmailClassType, value: string): EmailClassType {
    if (isEmailQuoteClass(value)) {
        return "quote";
    }
    if (isEmailSignatureClass(value)) {
        return "signature";
    }
    return existingClass;
}

export function isEmailSignatureClass(value: string) {
    return value === "gmail_signature";
}

export function isEmailQuoteClass(value: string) {
    return value === "gmail_quote"
        || value === "email-jrlh-quote";
}

export function isRedgateSoftwareTracker(link_: string): boolean {
    if (!link_) return false;
    return link_.indexOf("Mail/ContactImage.aspx") !== -1;
}

export function isSpacerImage(link_: string): boolean {
    if (!link_) return false;
    return link_.endsWith("/spacer.gif")
        || link_.endsWith("/spacer.png")
        || link_.startsWith("https://cdn-images.mailchimp.com/icons/");
}

export function isTrackerImage(link_: string): boolean {
    if (!link_) return false;
    const lowerLink = link_.toLowerCase();

    return lowerLink.includes("emltrk.com/")
        || lowerLink.includes("tracking")
        || lowerLink.startsWith("https://click.")
        || lowerLink.startsWith("http://click.")
        || lowerLink.startsWith("https://clickom.")
        || lowerLink.startsWith("http://clickom.")
        || lowerLink.startsWith("https://click-batch.")
        || lowerLink.startsWith("http://click-batch.")
        // || lowerLink.includes("click.email.microsoftemail.com/open.aspx") ||
        || (lowerLink.includes("images-eu.amazon") && lowerLink.includes("transp.gif")) ||
        lowerLink.includes("www.linkedin.com/emimp/") ||
        lowerLink.includes("transp.gif") ||
        // lowerLink.includes("click.eprizemail.com/open.aspx") ||
        lowerLink.includes("ad.doubleclick.net") ||
        lowerLink.includes("ea.pstmrk.it/open/djjf") ||
        lowerLink.includes("pubads.g.doubleclick.net/") ||
        link_.includes("link.mx.expediamail.com/o/3/T") ||
        link_.includes("link.uk.expediamail.com/o/3/T") ||
        link_.includes("link.expediamail.com/o/3/T") ||
        lowerLink.includes("beacon.netflix.com/img/") ||
        lowerLink.includes("info.volaris.mx/custloads/") ||
        lowerLink.includes("google-analytics.com") ||
        lowerLink.includes("appspot.com") ||
        lowerLink.includes("mandrillapp.com/track") ||
        lowerLink.includes("links.govdelivery.com/track") ||
        lowerLink.startsWith("https://cdn.exponea.com/") ||
        lowerLink.startsWith("https://pixel.app.returnpath.net") ||
        // lowerLink.startsWith("https://click.emails.barclaycard.co.uk") ||
        lowerLink.startsWith("http://li.dictionary.com") ||
        lowerLink.startsWith("https://em.realtime.email") ||
        lowerLink.includes("cmail20.com/t/") ||
        lowerLink.startsWith("http://clickom.info.pactcoffee.com") ||
        lowerLink.startsWith("https://image.e-mails.barclaycard.co.uk") //|| lowerLink.startsWith("https://click.e-mails.barclaycard.co.uk") ||
        || lowerLink.startsWith("https://link.info.easyjet.com") ||
        lowerLink.includes(".awstrack.me/") ||
        lowerLink.startsWith("https://emails.southernrailway.com/t") ||
        lowerLink.startsWith("https://emails.southernrailway.com/r") ||
        lowerLink.startsWith("https://r.email.chillblast.com/")
        // || lowerLink.startsWith("https://click.mailing.ticketmaster.com")
        || lowerLink.includes("facebook.co")
        || lowerLink.includes(".collect.igodigital.com")
        // || lowerLink.includes("click.mail.tesco.com")
        || lowerLink.includes("email.notification.paack.co")
        || lowerLink.includes("clicks.deliveroo.co.uk")
        || lowerLink.includes("mailjet.lafourchette.com")
        || lowerLink.includes(".delivery-status.com/open")
        || lowerLink.includes("tools.eacommunications.co.uk/")
        || lowerLink.includes("save.spirit-airlines.com/")
        || lowerLink.includes("tags.bluekai.com/")
        || lowerLink.includes("wrqualtricscontacts")
        || lowerLink.startsWith("https://beacon.krxd.net/")
        || lowerLink.startsWith("https://tc.businesscommunication.bt.com")
        || lowerLink.startsWith("http://links.opentable.com/")
        || lowerLink.startsWith("http://email.partners.collinsbookings.com/")
        || lowerLink.includes(".ct.sendgrid.net")
        || !!lowerLink.match("//t.info[0-9]+.giffgaff.com")
        ;
}

export function isContentIdValue(value_: string): boolean {
    if (!value_) {
        return false;
    }
    return value_.toLowerCase().startsWith("cid:");
}

export function isWhitelistedUrl(externalUrl: string, whitelistedImages: ImageWhitelistDto): boolean {
    for (const startsWith of whitelistedImages.startsWith) {
        if (externalUrl.startsWith(startsWith)) {
            return true;
        }
    }
    for (const startsWith of whitelistedImages.includes) {
        if (externalUrl.includes(startsWith)) {
            return true;
        }
    }
    for (const startsWith of whitelistedImages.regex) {
        if (externalUrl.match(startsWith)) {
            return true;
        }
    }

    return externalUrl.startsWith("https://assets.nflxext.com/")
        || externalUrl.startsWith("https://dnm.nflximg.net/api")
        || externalUrl.startsWith("https://mcusercontent.com/")
        || externalUrl.startsWith("https://content.govdelivery.com/")

        // CDNS
        || externalUrl.includes(".rackcdn.com/")
        || externalUrl.startsWith("https://images-na.ssl-images-amazon.com")
        || externalUrl.includes(".cloudfront.net/")
        || externalUrl.startsWith("https://images.emlcdn.net/cdn")
        || externalUrl.startsWith("https://static.licdn.com/") || externalUrl.startsWith("https://media.licdn.com/")
        || externalUrl.startsWith("https://cdn.southeastwater.co.uk/")
        || externalUrl.startsWith("https://cdn.braze.eu/")
        || externalUrl.startsWith("https://static.cdn.responsys.net/")
        || externalUrl.startsWith("https://gallery.mailchimp.com")
        || externalUrl.startsWith("https://awsprodemailassets.s3.amazonaws.com") || externalUrl.includes("s3.amazonaws.com/") || externalUrl.includes("://s3-eu-west-1.amazonaws.com/")
        || externalUrl.startsWith("https://cdn.shopify.com/")
        || externalUrl.startsWith("http://cdn.flipboard.com/")
        || externalUrl.startsWith("https://img.ppwebstatic.com/")
        || externalUrl.startsWith("https://sr.rlcdn.com/")
        || externalUrl.startsWith("http://pixel.mathtag.com/")
        || externalUrl.startsWith("https://consumer.cdn.dpduk.live/")
        || externalUrl.startsWith("https://cdn.jotfor.ms")

        // Specific sites
        || externalUrl.startsWith("https://images.trvl-media.com/media/content/")
        || externalUrl.startsWith("https://axapppcs.login.prescience.cloud/clients/")
        || externalUrl.startsWith("https://business.bt.com/content") || externalUrl.startsWith("https://business.bt.com/etc") || externalUrl.startsWith("https://res.businesscommunication.bt.com/res")
        || externalUrl.startsWith("http://image.emails.lloydsbank.com/")
        || externalUrl.startsWith("http://www208.americanexpress.com/")
        || externalUrl.startsWith("https://www.brewdog.com/pub/media")
        || externalUrl.startsWith("https://image.emails.barclaycard.co.uk")
        || externalUrl.startsWith("https://store.cloudflare.steamstatic.com/") || externalUrl.startsWith("https://cdn.cloudflare.steamstatic.com/") || externalUrl.startsWith("https://store.akamai.steamstatic.com/")
        || externalUrl.startsWith("https://www.britishairways.com/assets/images/") || externalUrl.startsWith("https://www.britishairways.com/cms/global/assets/images")
        || externalUrl.startsWith("https://www.dictionary.com/assets") || externalUrl.startsWith("https://static.sfdict.com/static/email/") || externalUrl.startsWith("https://www.dictionary.com/e/wp-content")
        || externalUrl.startsWith("https://media.sailthru.com/")
        || externalUrl.startsWith("https://www.dpd.co.uk/asset_files/") || externalUrl.startsWith("https://www.dpd.co.uk/images") || externalUrl.startsWith("https://www.dpd.co.uk/_assets")
        || externalUrl.startsWith("https://pp.images.harmony.epsilon.com/")
        || externalUrl.startsWith("https://www.ordernow.pizza/Content/")
        || externalUrl.startsWith("https://ceremony-media.withjoy.com/ir")
        || externalUrl.startsWith("http://travelup.co.uk/staticlib/images/emails")
        || externalUrl.startsWith("https://images.trvl-media.com/media/content/expus/email")
        || externalUrl.startsWith("https://hosting-source.bm23.com/")
        || externalUrl.startsWith("https://image.ecomms.ovoenergy.com/lib/")
        || externalUrl.includes(".cmail20.com/") || externalUrl.includes(".cmail19.com/")
        || !!externalUrl.match("https?://res.info[0-9]+.giffgaff.com/res/img/") || externalUrl.startsWith("https://static.giffgaff.com/")
        || externalUrl.startsWith("https://www.evri.com/_assets/images/emails") || externalUrl.startsWith("https://www.myhermes.co.uk/_assets/images/")
        || (externalUrl.startsWith("https://image.e-mails.barclaycard.co.uk/lib/") && externalUrl.endsWith(".png"))
        || externalUrl.startsWith("https://media.pactcoffee.com/images")
        || externalUrl.startsWith("https://www.chillblast.com/files")
        || externalUrl.startsWith("https://link.info.easyjet.com/custloads")
        || externalUrl.startsWith("http://bcu.com.mx/mailing/redes/") || externalUrl.startsWith("https://www.bcu.com.mx/mailing")
        || externalUrl.includes("://emails.southernrailway.com/")
        || externalUrl.startsWith("https://img.email.chillblast.com/im")
        || externalUrl.startsWith("https://image.mailing.ticketmaster.com/lib") || externalUrl.startsWith("https://media.ticketmaster.co.uk/tm/en-gb") || externalUrl.startsWith("https://s3-eu-west-1.amazonaws.com/ccp-salesforce-images")
        || externalUrl.startsWith("https://secureir.ebaystatic.com")
        || externalUrl.includes("://stormsend1.djicdn.com/") || externalUrl.startsWith("http://asset1.djicdn.com")
        || externalUrl.startsWith("https://images.booking.lufthansa.com")
        || externalUrl.startsWith("https://account.login.nhs.uk/assets/")
        || externalUrl.startsWith("https://www.evisa.gov.tr/assets/")
        || externalUrl.startsWith("https://image.email.microsoftemail.com")
        || externalUrl.startsWith("http://www.travelup.co.uk")
        || externalUrl.startsWith("https://tapestryjournal.com/images")
        || externalUrl.startsWith("https://images.mxtoolbox.com")
        || externalUrl.startsWith("https://beefree-storage.onesignal.com/images")
        || externalUrl.startsWith("http://ecx.images-amazon.com/images") || externalUrl.startsWith("https://m.media-amazon.com/images") || externalUrl.startsWith("http://g-ec2.images-amazon.com/images") || externalUrl.startsWith("http://g-ecx.images-amazon.com/images") || externalUrl.startsWith("https://gb-prod-temp.s3.eu-west-1.amazonaws.com")
        || externalUrl.startsWith("https://tools.tasty-restaurants.co.uk/Public/") || externalUrl.startsWith("https://atreemo.tasty-restaurants.co.uk/")
        || externalUrl.startsWith("https://redeyereporting.travelodge.co.uk/images/")
        || externalUrl.startsWith("https://a.travel-assets.com/")
        || externalUrl.startsWith("https://digitalcontent.api.tesco.com") || externalUrl.startsWith("https://email.tesco.com/")// || externalUrl.startsWith("https://email.tesco.com/images")
        || externalUrl.startsWith("http://t.paack.co/") || externalUrl.includes("://static.zara.net/")
        || externalUrl.startsWith("https://einfo.petco.com.mx") || externalUrl.startsWith("https://suite16.emarsys.net")
        || externalUrl.startsWith("https://rs-menus-api.roocdn.com/")
        || externalUrl.startsWith("https://api.patpat.com/assets/")
        || externalUrl.startsWith("https://u.tfstatic.com/")
        || externalUrl.startsWith("https://ukstgpdb.blob.core.windows.net/")
        || externalUrl.startsWith("http://www.paul-uk.com/static")
        || externalUrl.startsWith("https://kana-connect-content.hsbc.com.mx/")
        || externalUrl.startsWith("https://images.ctfassets.net/")
        || externalUrl.startsWith("https://www.sportsdirect.com/images")
        || externalUrl.startsWith("https://parcel-cdn.delivery-status.com/")
        || externalUrl.startsWith("https://www.cvs.com/bizcontent")
        || externalUrl.startsWith("https://www.addisonlee.com/") || externalUrl.startsWith("https://static.addisonlee.com/")
        || externalUrl.startsWith("https://www.myepaywindow.com/Content/")
        || externalUrl.startsWith("http://b2b.petco.com.mx/")
        || externalUrl.startsWith("http://tools.eacommunications.co.uk/Public/images/")
        || externalUrl.startsWith("http://mi.spirit.com/p")
        || externalUrl.startsWith("https://spiritairlines.co1.qualtrics.com/CP/Graphic.php")
        || externalUrl.startsWith("https://www.ryanair.com/content/") || externalUrl.startsWith("http://www.ryanairemail.com/res")
        || externalUrl.includes("://vuela.aeromexico.com/") || externalUrl.startsWith("https://www.aeromexico.com/cms")
        || externalUrl.startsWith("https://checkin.si.amadeus.net/static/")
        || externalUrl.startsWith("https://checkin.alitalia.com/")
        || externalUrl.startsWith("http://image.email.hollandandbarrett.com/")
        || externalUrl.includes(".recs.igodigital.com/rr")
        || externalUrl.startsWith("https://st.mngbcn.com/web")
        || externalUrl.startsWith("http://www2.hm.com/")
        || externalUrl.startsWith("http://image.email.gap.com/lib") || externalUrl.startsWith("http://mi.gap.com/p") || externalUrl.startsWith("http://image.email.gap.eu/lib") || externalUrl.startsWith("https://image.email.gapinc.com/lib") || externalUrl.startsWith("http://image.email.gapinc.com/lib/")
        || externalUrl.startsWith("http://familycard.athenakiosk.eu/api/") || externalUrl.startsWith("https://secure.ikea.com/") || externalUrl.startsWith("https://messagecontent.ikea.com/") || externalUrl.startsWith("https://ww9.ikea.com/") || externalUrl.startsWith("http://contentz.mkt4364.com/")
        || externalUrl.startsWith("https://asset.swarovski.com/")
        || externalUrl.includes("://barcode.") || externalUrl.includes("qrcode")
        || externalUrl.startsWith("https://link.e.kurtgeiger.com")
        || externalUrl.startsWith("https://image.e-atlantisthepalm.com/") || externalUrl.startsWith("http://image.e-atlantisthepalm.com/")
        || externalUrl.startsWith("http://www.judgeservice.com/")
        || externalUrl.startsWith("https://www.jotform.com/uploads")
        || externalUrl.startsWith("http://www.nhm.ac.uk/")
        || externalUrl.startsWith("http://content.vivaaerobus.com/cdn")
        || externalUrl.startsWith("https://app.digitickets.co.uk/")
        || externalUrl.startsWith("https://profiles.responsemail.co.uk/") || externalUrl.includes(".fusemetrix.com/assets/")
        || externalUrl.startsWith("https://www.1stairporttaxis.co.uk/img")
        || externalUrl.startsWith("https://components.otstatic.com/") || externalUrl.startsWith("https://images.otstatic.com/")
        || externalUrl.startsWith("http://static.designmynight.com/")
        || externalUrl.startsWith("https://www.travelodge.co.uk/")
        || externalUrl.startsWith("http://image.exct.net/") || externalUrl.startsWith("http://image.em.redcross.org.uk/")
        || externalUrl.includes("://info.tfl.gov.uk/")
        ;
}
