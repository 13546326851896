import {FC} from "react";
import ReplyIcon from "@mui/icons-material/Reply";
import ForwardIcon from "@mui/icons-material/Forward";
import SentIcon from '@mui/icons-material/MarkEmailRead';

export const VerbIcon: FC<{ verb: string | undefined | null, className?: string }> = ({verb, className}) => {
    if (!verb) return null;

    switch (verb) {
        case "ReplyToSender":
        case "ReplyToAll":
        case "replied to":
            return <div title="You replied to this email" className={className}><ReplyIcon/></div>;
        case "Forward":
        case "forwarded":
            return <div title="You forwarded this email" className={className}><ForwardIcon/></div>;
        case "sent":
            return <div title="You sent this email" className={className}><SentIcon/></div>;
    }
    console.log("Unknown verb type: ", verb);
    return <div title={`You ${verb} this email`} className={className}><ReplyIcon/></div>;
}
