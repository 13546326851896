import "./NotesView.scss";

import React, {FC} from "react";
import {shallowEqual} from "react-redux";
import {dateTimeFromNullableTicks, UtcZone} from "../../util/DateHelper";
import {DateTimeControl} from "../common/DateTimeControl";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {ButtonPanel} from "../common/ButtonPanel";
import {Button} from "@mui/material";
import {StackPanel} from "../panels/StackPanel";
import {NotesActions} from "../../redux/NotesSlice";
import {NoteEditPageContent} from "./NoteEditPageContent";
import {NoteEditPageButtons} from "./NoteEditPageButtons";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import {popOutContent} from "../common/HideContentButton";

export const NotePageContentView: FC = () => {
    const selectedPage = useAppSelector(({notesState}) => notesState.selectedPage, shallowEqual)!;
    const isMobile = useAppSelector(s => s.system.isMobile);
    
    const dispatch = useAppDispatch();

    const isEditing = useAppSelector(({notesState}) => !!notesState.editingPage);

    console.log("Render Note Page:", selectedPage?.ClientId);

    const buttons = (
        <StackPanel orientation="horizontal"
                    className="panel">
            {!isEditing && selectedPage && <Button variant="contained" color="primary"
                                                   onClick={() => dispatch(NotesActions.beginEditNotePage())}>Edit</Button>}
            <NoteEditPageButtons/>

            {!isMobile && <Button variant="contained" onClick={() => popOutContent()} size="small" title="Expand" color="inherit">
                <AspectRatioIcon/>
            </Button>}
        </StackPanel>);

    return (
        <div className="notes-content">

            <ButtonPanel buttons={buttons} fill={true} buttonLocation="bottom">
                {!isEditing &&
                    <div className="notes-html" dangerouslySetInnerHTML={{__html: selectedPage?.HtmlContent ?? ""}}/>}

                <NoteEditPageContent/>

                <div className="last-modified">
                    <DateTimeControl title={isMobile ? "" : "Modified:"}
                                     time={dateTimeFromNullableTicks(selectedPage?.ModifiedTime, UtcZone)}/>
                </div>
            </ButtonPanel>
        </div>
    );
};
