import "./NotesView.scss";

import React, {FC} from "react";
import {StackPanel} from "../panels/StackPanel";
import {NotePageContentView} from "./NotePageContentView";
import {NotePageTabs} from "./NotePageTabs";

export const NotesView: FC = () => {
    return (
        <StackPanel className="notes-view" orientation="vertical">
            <NotePageTabs/>

            <NotePageContentView/>
        </StackPanel>
    );
};
