import React, {MouseEventHandler, PropsWithChildren} from "react";
import classNames from "classnames";

import "./StackPanel.scss";

export type OrientationType = "vertical" | "horizontal";
export type AlignmentType = "start" | "middle" | "end" | "stretch" | "space-around";

export interface CommonPanelProps {
    className?: string;
    onClick?: MouseEventHandler;
    onContextMenu?: MouseEventHandler<HTMLDivElement>;
}

export interface StackPanelProps extends CommonPanelProps {
    orientation?: OrientationType;
    align?: AlignmentType;

    middle?: boolean;
    fill?: boolean;
    wrap?: boolean;

    title?: string;
}

export const StackPanel = React.forwardRef<HTMLDivElement, PropsWithChildren<StackPanelProps>>((props, ref) => {
    const {
        orientation,
        align,
        className,
        onClick,
        fill,
        wrap,
        middle,
        title,
        onContextMenu,
        children
    } = props;
    
    const orientationClass = orientation === "vertical" ? "o-v" : "o-h";

    function getAlignmentClass(): string | undefined {
        return align ? "a-" + align : undefined;
    }

    const fullClassName = classNames(className, "sp", orientationClass, getAlignmentClass(), {"fill": fill, "wrap": wrap, "middle": middle});

    return (
        <div className={fullClassName} onClick={onClick} title={title} onContextMenu={onContextMenu} ref={ref}>
            {children}
        </div>
    );
});
