import DbDraftEmail from "../domain/DbDraftEmail";
import {Dispatch} from "redux";
import {DbAttachment} from "../db/DbSchema";
import {
    AMEND_DRAFT_EMAIL,
    AmendDraftEmailAction,
    CHANGE_DRAFT_EMAIL,
    ChangeDraftEmailAction,
    COMPOSE_EMAIL,
    ComposeEmailAction, SET_COMPOSE_SETTINGS_OPEN, SetComposeSettingsOpenAction
} from "./ComposeEmailActionTypes";
import {EmailLocation} from "../locations/EmailLocation";
import {EmailActions} from "./EmailActions";
import {SystemActions} from "./SystemActions";
import {HandshakePref} from "../util/Preferences";
import {convertNameAndAddressToString} from "../util/Formatters";
import {uuid} from "../util/Uuid";
import {ticksFromDateTime} from "../util/DateHelper";
import {DateTime} from "luxon";
import {DB} from "../db/DbManager";
import {AppDispatch, AppStore} from "../AppStore";
import {signAndEncryptDraftEmail} from "../security/EncryptedEmailHelper";

export const DRAFT_EMAIL_FOLDER = -999;

export const BlankDraftEmail: DbDraftEmail = {
    EditUid: "",
    Id: -1,
    Uid: "",
    Attachments: [],
    Bcc: "",
    BodyText: "",
    Cc: "",
    To: "",
    ExtendedBodyText: "",
    From: "",
    IsDraft: true,
    IsNew: true,
    Password: "",
    Subject: "",
    ModifiedDate: 0,
    ThreadId: "",
};

export class ComposeEmailActions {
    static async sendEmail(draftEmail: DbDraftEmail, isOffline: boolean, store: AppStore) {
        const {dispatch} = store;

        draftEmail.IsDraft = false; // Directly mutate - once we've sent the email there's no going back!!

        await DB.saveDraftEmail(draftEmail);

        try {
            const {accountsState} = store.getState();
            const emailToSend = await signAndEncryptDraftEmail(draftEmail, accountsState);

            if (isOffline) {
                dispatch(SystemActions.showWarning("Your email has been queued for sending and will be sent when you're next online"));
            } else {
                dispatch(SystemActions.showNotification("Your email is being sent"));
            }

            dispatch(await EmailActions.addRemoteOperation({SaveDraftEmail: {Email: emailToSend}}));

            EmailLocation.stopComposingEmail();
        } catch (e) {
            console.log("Error sending email", e);

            draftEmail.IsDraft = true;  // Revert to it being a draft email again!
            await DB.saveDraftEmail(draftEmail);

            if (draftEmail.IsSigned) {
                // TODO: show the password dialog for signing! dispatch(ComposeEmailActions...
                dispatch(SystemActions.showError("Signing emails is not supported yet"));
            } else {
                dispatch(SystemActions.showFormattedError("Error sending email", e));
            }
        }
    }

    private static composeEmailAction(show: boolean, email: DbDraftEmail | undefined, attachments: DbAttachment[] | undefined): ComposeEmailAction {
        return {type: COMPOSE_EMAIL, show, email, attachments};
    }

    static composeEmail(uid: string) {
        return async (dispatch: Dispatch) => {
            const draftEmail = await DB.getDraftEmail(uid);
            const draftAttachments = (await DB.getEmailAttachments(uid)) || [];

            if (draftEmail) {
                dispatch(ComposeEmailActions.composeEmailAction(true, draftEmail, draftAttachments));
            } else {
                dispatch(ComposeEmailActions.composeNewEmail(uid));
            }
        }
    }

    private static composeNewEmail(uid: string): ComposeEmailAction {
        const {DefaultAddress, DisplayName} = HandshakePref.get();

        const email: DbDraftEmail = {
            ...BlankDraftEmail,
            Uid: uid,
            ThreadId: uuid(),
            ModifiedDate: ticksFromDateTime(DateTime.local()),
            From: convertNameAndAddressToString(DisplayName, DefaultAddress),
            BodyText: " ",
            ExtendedBodyText: "",
        };

        return ComposeEmailActions.composeEmailAction(true, email, []);
    }

    static async hideEmailComposer(draftEmail: DbDraftEmail | undefined, dispatch: AppDispatch) {
        if (!draftEmail) {
            dispatch(ComposeEmailActions.composeEmailAction(false, undefined, undefined));
            return;
        }

        if (draftEmail && !draftEmail.IsNew && draftEmail.IsDraft) {
            draftEmail.ModifiedDate = ticksFromDateTime(DateTime.local());
            await DB.saveDraftEmail(draftEmail);
        }

        dispatch(ComposeEmailActions.composeEmailAction(false, draftEmail, undefined));
    }

    static changeDraftEmail(changes: Partial<DbDraftEmail>): ChangeDraftEmailAction {
        return {type: CHANGE_DRAFT_EMAIL, changes};
    }

    static amendDraftEmail(draftEmail: DbDraftEmail): AmendDraftEmailAction {
        return {type: AMEND_DRAFT_EMAIL, email: draftEmail};
    }

    static setComposeEmailOptionsDialogOpen(open: boolean): SetComposeSettingsOpenAction {
        return {type: SET_COMPOSE_SETTINGS_OPEN, open};
    }
}
