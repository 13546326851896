import React, {FC} from "react";
import classNames from "classnames";
import {ToolbarView} from "../toolbar/ToolbarView";
import {ResizeContainer, ResizeSplitter} from "../common/ResizeContainer";
import {FolderControls} from "../folder/FolderControls";
import ContentView from "../ContentView";
import ComposeEmailView from "../compose/ComposeEmailView";
import {initialTreeSize} from "../MainView";
import {SystemActions} from "../../actions/SystemActions";
import {NotificationView} from "../notifications/NotificationView";
import {SpecialFolderList} from "../folder/SpecialFolderList";
import {SidebarView} from "../folder/SidebarView";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {isTearOffWindow} from "../../App";
import {PhotoContentViewer} from "../photos/PhotoContentViewer";
import {CreateItemMobileMenu} from "./CreateItemMobileMenu";
import {hasItemContent} from "../../domain/ItemContent";
import {NotesFolderId} from "../../reducers/FolderReducer";

export const EmailView: FC = () => {
    const isMobile = useAppSelector(({system}) => system.isMobile);
    const isComposingEmail = useAppSelector(({compose}) => compose.isComposingEmail);

    const hasSelectedPhoto = useAppSelector(s => !!s.photosState.selectedPhoto);

    const hasContent = useAppSelector(s => hasItemContent(s.itemState.itemContent) || s.folderState.selectedFolderId === NotesFolderId);

    const dispatch = useAppDispatch();

    function onFolderTreeResize(domElement1: HTMLElement) { //}, domElement2: HTMLElement) {
        dispatch(SystemActions.resizeFolderTree(domElement1.offsetWidth || domElement1.clientWidth || 350));
    }

    if (hasSelectedPhoto) {
        return <PhotoContentViewer/>;
    }

    return (
        <>
            <div className={classNames("main-container"/*, { "hide": isComposingEmail }*/)}>

                <ToolbarView/>

                <NotificationView/>

                <div className="main-content">
                    <ResizeContainer orientation="vertical">
                        {isMobile || isTearOffWindow ? null : (<div
                            className="folder-tree-pane"
                            style={{width: initialTreeSize, minWidth: "200px", flex: "0 0 auto"}}
                        >
                            <FolderControls/>
                            <SidebarView/>
                            <SpecialFolderList/>
                        </div>)}

                        {isMobile || isTearOffWindow ? null : (<ResizeSplitter onResize={onFolderTreeResize}/>)}

                        <ContentView/>
                    </ResizeContainer>
                </div>
            </div>

            {isMobile && !hasContent && !isComposingEmail && <CreateItemMobileMenu/>}

            <ComposeEmailView className={classNames({"hide": !isComposingEmail})}/>
        </>
    );
}
