
export function createImageThumbnail(file: File, size: number = 64): Promise<string> {
    return new Promise<string>((resolve) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext("2d");
        if (context) {
            const reader = new FileReader();
            reader.onload = () => {
                const img = new Image();
                img.onload = () => {
                    let targetWidth = size;
                    let targetHeight = size;
                    if (img.width > img.height) {
                        targetHeight = size * img.height / img.width;
                    } else {
                        targetWidth = size * img.width / img.height;
                    }
                    canvas.width = targetWidth;
                    canvas.height = targetHeight;
                    context.drawImage(img, 0, 0, img.width, img.height, 0, 0, targetWidth, targetHeight);

                    resolve(canvas.toDataURL());
                }
                img.src = reader.result as string;
            }
            reader.readAsDataURL(file);
        }
    });
}
