import {ItemChangeListDto} from "../services/messages/ItemChangeListDto";
import {ItemChangesDto} from "../services/messages/ItemChangesDto";
import {ClientEntityDto} from "../services/messages/ClientEntityDto";
import {NotePageDto} from "../domain/NotePageDto";
import {NoteSectionDto} from "../domain/NoteSectionDto";

type DbStringItem<TValue> = {
    [key: string]: TValue;
};

export class InMemoryDb {

    private noteSectionStore: DbStringItem<NoteSectionDto> = {};
    private notePageStore: DbStringItem<NotePageDto> = {};

    saveSyncStateChange(changes: ItemChangeListDto) {
        this.saveSyncChangesClientId(this.noteSectionStore, changes.NoteSections);
        this.saveSyncChangesClientId(this.notePageStore, changes.NotePages);
    }

    private saveSyncChangesClientId<T extends ClientEntityDto>(store: DbStringItem<T>, items: ItemChangesDto<T> | null) {
        if (!items) return;

        if (items.DeletedIds) {
            for (const deletedId of items.DeletedIds) {
                try {
                    const item = Object.values(store).find(i => i.Id === deletedId);
                    delete store[item!.ClientId!];
                } catch (e) {
                    console.error("Unable to find deleted item by id: ", deletedId);
                }
            }
        }

        if (items.Changed) {
            for (const item of items.Changed) {
                store[item.ClientId!] = item;
            }
        }
    }

    getAllNoteSections() : NoteSectionDto[] {
        return Object.values(this.noteSectionStore);
    }

    getNoteSectionPages(noteSectionClientId: string): NotePageDto[] {
        return Object.values(this.notePageStore)
            .filter(page => page.ClientSectionId === noteSectionClientId);
    }
}
