import {DbAttachment} from "../db/DbSchema";
import {EmailActions} from "./EmailActions";
import {MARK_DRAFT_EMAIL_ATTACHMENT_UPLOADED, MarkDraftEmailAttachmentUploadedAction} from './AttachmentActionTypes';
import {EditorFileCallback} from "../components/compose/Editor";
import {DB} from "../db/DbManager";
import {AddRemoteOperationPayload} from "../redux/SyncSlice";
import {PayloadAction} from "@reduxjs/toolkit";

export class AttachmentActions {
    static async addAttachment(emailUid: string, file: File, attachment: DbAttachment, callback?: EditorFileCallback): Promise<PayloadAction<AddRemoteOperationPayload>> {
        console.log(`Adding attachment: ${file.name} to email ${emailUid}`);

        await DB.saveEmailAttachment(attachment);

        return EmailActions.addRemoteOperation({
            AddAttachment: {
                EmailUid: emailUid,
                AttachmentUid: attachment.attachmentUid,
                Name: attachment.name,
                ContentId: attachment.contentId,
                File: file,
                Callback: callback,
            }
        }, attachment);
    }

    static async removeAttachment(emailUid: string, attachmentUid: string): Promise<PayloadAction<AddRemoteOperationPayload>> {

        await DB.deleteEmailAttachment(attachmentUid);

        return EmailActions.addRemoteOperation({
            RemoveAttachment: {
                EmailUid: emailUid,
                AttachmentUid: attachmentUid,
            }
        });
    }

    static async markDraftEmailAsUploaded(attachmentUid: string): Promise<MarkDraftEmailAttachmentUploadedAction> {
        const attachment = await DB.getEmailAttachment(attachmentUid);
        if (attachment) {
            attachment.isUploaded = true;
            await DB.saveEmailAttachment(attachment);
        }

        return { type: MARK_DRAFT_EMAIL_ATTACHMENT_UPLOADED, attachmentUid };
    }
}
