import {StackPanel} from "../panels/StackPanel";
import {Button} from "@mui/material";
import React from "react";
import {useAppDispatch} from "../../hooks/ReduxHooks";
import {UsersActions} from "../../redux/UsersSlice";
import {BlankUser} from "../../state/UsersState";

export const UserControlsView = () => {
    const dispatch = useAppDispatch();

    function onCreateNewUser() {
        dispatch(UsersActions.showUser({...BlankUser}));
    }

    return (
        <StackPanel orientation="horizontal"
                    className="panel">

            <Button variant="contained" onClick={onCreateNewUser}>New User</Button>
        </StackPanel>
    );
}
