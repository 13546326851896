import {LocationManager} from './util/LocationManager';
import {getAppStore_DoNotCall} from './AppStore';
import {SyncService} from './services/SyncService';
import {FolderLocation, FolderLocationPropertyNames, selectInboxFolderByDefault} from './locations/FolderLocation';
import {EmailLocation, EmailLocationPropertyNames} from './locations/EmailLocation';
import {SystemActions} from './actions/SystemActions';
import {registerSearchTextSubjectHandler} from './subjects/SearchSubjectHandler';
import {EmailActions} from './actions/EmailActions';
import {FolderActions} from './actions/FolderActions';
import {CalendarLocation, CalendarLocationPropertyNames} from './locations/CalendarLocation';
import {SystemStatusLocation, SystemStatusLocationPropertyNames} from "./locations/SystemStatusLocation";
import {SystemStatusService} from "./services/system/SystemStatusService";
import {ContentLocation, ContentLocationPropertyNames} from "./locations/ContentLocation";
import {ServiceWorkerMessageHandler} from "./locations/ServiceWorkerMessageHandler";
import {NotificationService} from "./services/NotificationService";
import {decodePgpKeyDtos, getSecretKeysFromWebService} from "./util/PGPUtil";
import {NotesLocation, NotesLocationPropertyNames} from "./locations/NotesLocation";
import {DB} from "./db/DbManager";
import {ContactActions} from './redux/ContactSlice';
import {SyncActions} from "./redux/SyncSlice";
import {AccountsActions} from "./redux/AccountsSlice";
import {PgpKeysLocation, PgpKeysLocationPropertyNames} from "./locations/PgpKeysLocation";
import {UsersLocation, UsersLocationPropertyNames} from "./locations/UsersLocation";
import {NotesActions} from "./redux/NotesSlice";
import {registerAttachmentSearchTextSubjectHandler} from "./subjects/AttachmentSearchSubjectHandler";
import {registerPhotosSearchTextSubjectHandler} from "./subjects/PhotosSearchSubjectHandler";
import {AndroidMessageHandler} from "./android/AndroidMessageHandler";
import {isAndroidApp} from "./util/DeviceUtil";
import {updateDateRangeCategories} from "./util/DateHelper";
import {DateTime} from "luxon";
// import {initWorker} from "openpgp";

const store = getAppStore_DoNotCall();

const _locationManager = new LocationManager(store);
export const systemStatusService = new SystemStatusService(store);

function promiseRejectionHandler(event: PromiseRejectionEvent) {
    // TODO: show a warning on the UI?
    console.log("Unhandled rejection", event.reason, event);
}

export function bootstrapUser(syncService?: SyncService) {
    const userName = store.getState().system.userName;

    if (userName.length > 0) {
        // tslint:disable-next-line: no-floating-promises
        DB.init(userName)
            .then(async () => {
                const folders = await DB.getFolders();
                const syncState = await DB.getSyncStates();
                const operations = await DB.getAllOperations();
                const draftEmailHeaders = (await DB.getAllDraftEmails())?.filter(d => d.IsDraft).map(EmailActions.convertDraftToEmailHeader);
                const contacts = await DB.getAllContacts() || [];

                let encodedSecretKeys = await DB.getAllSecretKeys();

                const noteSections = await DB.getAllNoteSections();

                store.dispatch(NotesActions.noteSectionsLoaded(noteSections));

                store.dispatch(EmailActions.draftEmailsLoaded(draftEmailHeaders ?? []));
                store.dispatch(FolderActions.foldersLoaded(folders));
                store.dispatch(SyncActions.setOperations({operations}));
                store.dispatch(SyncActions.setSyncState(syncState));

                store.dispatch(ContactActions.contactsLoaded(contacts));

                if (encodedSecretKeys.length === 0) {
                    try {
                        encodedSecretKeys = await getSecretKeysFromWebService();
                    } catch (e: any) {
                        console.error("Error retrieving secret keys", e);
                    }
                }
                const secretKeys = await decodePgpKeyDtos(encodedSecretKeys) || [];
                store.dispatch(AccountsActions.setSecretKeys({keys: secretKeys, loadedIfEmpty: true}));

                syncService?.start();

                _locationManager.startListening();

                new NotificationService(store).start();

                if (locationManager.getCurrentApp() === "email") {
                    selectInboxFolderByDefault(store);
                }
            });
        return true;
    } else {
        return false;
    }
}

export function startApplication() {
    updateDateRangeCategories(DateTime.local());

    window.addEventListener('unhandledrejection', promiseRejectionHandler)

    // TODO: support web workers for offloading the PGP decryption/encryption
    // initWorker({path: 'openpgp/openpgp.worker.min.js'});

    const syncService = new SyncService(store, DB);

    if (!bootstrapUser(syncService)) {
        syncService.start();
    }

    function handleWindowResize() {
        store.dispatch(SystemActions.resizeWindow(window.innerWidth));
    }

    window.addEventListener("resize", () => handleWindowResize());

    handleWindowResize();

    registerSearchTextSubjectHandler(store);
    registerAttachmentSearchTextSubjectHandler(store);
    registerPhotosSearchTextSubjectHandler(store);

    _locationManager.setAppProperties("email", [...EmailLocationPropertyNames, ...FolderLocationPropertyNames, ...CalendarLocationPropertyNames, ...ContentLocationPropertyNames, ...NotesLocationPropertyNames]);
    _locationManager.addListener("email", FolderLocation.handleLocationChange);
    _locationManager.addListener("email", EmailLocation.handleLocationChange);
    _locationManager.addListener("email", CalendarLocation.handleLocationChange);
    _locationManager.addListener("email", ContentLocation.handleLocationChange);
    _locationManager.addListener("email", NotesLocation.handleLocationChange);

    _locationManager.setAppProperties("system", SystemStatusLocationPropertyNames);
    _locationManager.addListener("system", SystemStatusLocation.handleLocationChange);

    _locationManager.setAppProperties("pgpkeys", PgpKeysLocationPropertyNames);
    _locationManager.addListener("pgpkeys", PgpKeysLocation.handleLocationChange);

    _locationManager.setAppProperties("users", UsersLocationPropertyNames);
    _locationManager.addListener("users", UsersLocation.handleLocationChange);

    _locationManager.setAppProperties("rules", ContentLocationPropertyNames);
    _locationManager.addListener("rules", ContentLocation.handleLocationChange);

    ServiceWorkerMessageHandler.addServiceWorkerMessageListener("openEmail", (data) => EmailLocation.handleOpenWindowMessage(store.dispatch, data));
    ServiceWorkerMessageHandler.addServiceWorkerMessageListener("openCalendar", (data) => CalendarLocation.handleOpenWindowMessage(data));
    ServiceWorkerMessageHandler.addServiceWorkerMessageListener("openContact", (data) => ContentLocation.handleOpenWindowMessage(data));

    ServiceWorkerMessageHandler.listenForServiceWorkerMessages();

    if (isAndroidApp()) {
        const handler = new AndroidMessageHandler(store);
        handler.listenForMessages();
        AndroidMessageHandler.init();
    }

    setInterval(() => {
        console.log("Updating email categories");
        updateDateRangeCategories(DateTime.local());//.local(2024, 5, 28));
        // TODO: refresh the emails shown in the current folder
        // const state = store.getState();
        // store.dispatch(EmailActions.showEmailHeaders(state.itemState.emailHeaders, ));
    }, 1000 * 60 * 60);
}

export function getStore_DoNotCall() {
    return store;
}

export type VisibilityChangeHandler = (visible: boolean) => void;

export function registerVisibilityChangeHandler(handler: VisibilityChangeHandler) {
    document.addEventListener('visibilitychange', function () {
        handler(document.hidden);
    });
}

export const locationManager = _locationManager;

