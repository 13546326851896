import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {ButtonPanel} from "../common/ButtonPanel";
import {Button, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {UserDto} from "../../domain/UserDto";
import {UsersLocation} from "../../locations/UsersLocation";
import ConfirmDialog from "../common/ConfirmDialog";
import {Centre} from "../Centre";
import {UserManagementService} from "../../services/UserManagementService";
import {HideContentButton} from "../common/HideContentButton";

export const UserItemView = () => {
    const dispatch = useAppDispatch();

    const selectedUser = useAppSelector(({usersState}) => usersState.selectedUser);

    const [editUser, setEditUser] = useState<UserDto>();
    const [confirmChangePassword, setConfirmChangePassword] = useState<boolean>(false);
    const [confirmDeleteTokens, setConfirmDeleteTokens] = useState<boolean>(false);

    useEffect(() => setEditUser(selectedUser), [selectedUser]);

    if (!editUser) {
        return <Centre>Please select a user</Centre>;
    }

    function onAddMailUser() {
        setEditUser({...editUser!, MailId: -1, DisplayName: "", MailUsername: editUser!.WebUsername});
    }

    function onCancel() {
        UsersLocation.selectUser(undefined);
    }

    async function onBeginSave() {
        if (!editUser) return;
        if (editUser.NewPassword && editUser.WebId > -1) {
            setConfirmChangePassword(true);
        } else {
            await onSave();
        }
    }

    async function onConfirmChangePassword(confirm: boolean) {
        if (confirm) return onSave();
        setConfirmChangePassword(false);
    }

    async function onSave() {
        if (!editUser) return;
        if (await UserManagementService.saveUser(editUser, dispatch)) {
            onCancel();
        }
        setConfirmChangePassword(false);
    }

    function onBeginDeleteTokens() {
        setConfirmDeleteTokens(true);
    }

    async function onConfirmDeleteTokens(confirm: boolean) {
        if (!editUser) return;
        if (confirm) {
            await UserManagementService.deleteUserTokens(editUser.WebId, dispatch);
        }
        setConfirmDeleteTokens(false);
    }

    const buttons = (
        <>
            <Button variant="contained" color="secondary" onClick={onBeginDeleteTokens}>Delete Tokens</Button>
            {!editUser.MailId && <Button variant="contained" color="inherit" onClick={onAddMailUser}>Add Mail User</Button>}
            <Button variant="contained" color="primary" onClick={onBeginSave}>Save</Button>
            <Button variant="contained" color="inherit" onClick={onCancel}>Cancel</Button>

            <ConfirmDialog title="Change Password"
                           message={`Are you sure you want to change the password for ${editUser.WebUsername}?`}
                           open={confirmChangePassword}
                           onConfirm={onConfirmChangePassword}/>

            <ConfirmDialog title="Delete User Tokens"
                           message={`Are you sure you want to delete all ${editUser.WebUsername}'s tokens? This will force the user to re-authenticate\r\n`}
                           open={confirmDeleteTokens}
                           onConfirm={onConfirmDeleteTokens}
                           confirmColour="error"/>
        </>
    );

    return (
        <ButtonPanel buttons={buttons} className="user-edit-panel">
            <HideContentButton/>

            <TextField label={"Web Username"}
                       value={editUser.WebUsername}
                       type="text"
                       onChange={e => setEditUser({...editUser, WebUsername: e.target.value})}/>
            <div>&nbsp;</div>

            {editUser.MailId &&
                <TextField label={"Mail Username"}
                           value={editUser.MailUsername ?? ""}
                           type="text"
                           onChange={e => setEditUser({...editUser, MailUsername: e.target.value})}/>
            }
            <div>&nbsp;</div>

            {editUser.MailId &&
                <TextField label={"Display Name"}
                           value={editUser.DisplayName ?? ""}
                           type="text"
                           onChange={e => setEditUser({...editUser, DisplayName: e.target.value})}/>
            }
            <div>&nbsp;</div>

            <TextField label={editUser.WebId === -1 ? "Password" : "Change Password"}
                       value={editUser.NewPassword ?? ""}
                       type="text"
                       onChange={e => setEditUser({...editUser, NewPassword: e.target.value})}/>
            <div>&nbsp;</div>

        </ButtonPanel>
    );
}
