import {AccountsState, initialAccountsState} from './state/AccountState';
import {initialItemState, ItemState} from './state/ItemState';
import {FolderState, initialFolderState} from './state/FolderState';
import {initialSystemState, SystemState} from './state/SystemState';
import {ComposeEmailState, initialComposeEmailState} from './state/ComposeEmailState';
import {ContactState, initialContactState} from './state/ContactState';
import {initialSyncState, SyncState} from './state/SyncState';
import {CalendarState, initialCalendarState} from './state/CalendarState';
import {initialSystemStatusState, SystemStatusState} from "./state/SystemStatusState";
import {initialPreferenceState, PreferenceState} from "./state/PreferenceState";
import {initialSystemLogState, SystemLogState} from "./state/SystemLogState";
import {initialNotesState, NotesState} from "./state/NotesState";
import {initialUsersState, UsersState} from "./state/UsersState";
import {initialRulesState, RulesState} from "./state/RulesState";
import {initialProtectedFilesState, ProtectedFilesState} from "./state/ProtectedFilesState";
import {AttachmentFilesState, initialAttachmentFilesState} from "./state/AttachmentFilesState";
import {initialPhotosState, PhotosState} from "./state/PhotosState";

export interface AppState {
    preferenceState: PreferenceState;
    folderState: FolderState;
    itemState: ItemState;
    contactState: ContactState;
    accountsState: AccountsState;
    system: SystemState;
    sync: SyncState;
    compose: ComposeEmailState;
    calendarState: CalendarState;
    sysStatus: SystemStatusState;
    sysLogs: SystemLogState;
    notesState: NotesState;
    usersState: UsersState;
    rulesState: RulesState;
    filesState: ProtectedFilesState;
    attachmentsState: AttachmentFilesState;
    photosState: PhotosState;
}

export const initialAppState: AppState = {
    preferenceState: initialPreferenceState,
    folderState: initialFolderState,
    itemState: initialItemState,
    contactState: initialContactState,
    accountsState: initialAccountsState,
    system: initialSystemState,
    sync: initialSyncState,
    compose: initialComposeEmailState,
    calendarState: initialCalendarState,
    sysStatus: initialSystemStatusState,
    sysLogs: initialSystemLogState,
    notesState: initialNotesState,
    usersState: initialUsersState,
    rulesState: initialRulesState,
    filesState: initialProtectedFilesState,
    attachmentsState: initialAttachmentFilesState,
    photosState: initialPhotosState,
}
