import "./NotesView.scss";

import React, {FC, useRef} from "react";
import {shallowEqual} from "react-redux";
import {Tab, Tabs} from "@mui/material";
import {NotesLocation} from "../../locations/NotesLocation";
import {StackPanel} from "../panels/StackPanel";
import {EditNoteDialog} from "./EditNoteDialog";
import AddBoxIcon from "@mui/icons-material/AddBox";
import {NotePageContextMenu} from "./NotePageContextMenu";
import {NotePageDto} from "../../domain/NotePageDto";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {NotesActions} from "../../redux/NotesSlice";

export const NotePageTabs: FC = () => {
    const parentSection = useAppSelector(({notesState}) => notesState.selectedSection, shallowEqual);
    const pages = useAppSelector(({notesState}) => notesState.pages, shallowEqual);
    const selectedPageClientId = useAppSelector(({notesState}) => notesState.selectedPageClientId, shallowEqual);

    const menuRef = useRef<HTMLDivElement>(null);

    const dispatch = useAppDispatch();

    function onAddNotePage() {
        if (parentSection) {
            dispatch(NotesActions.addNotePage(parentSection));
        }
    }

    function onNotePageChange(clientId: string) {
        NotesLocation.selectPageByClientId(clientId);
    }

    function onSetContextPage(page: NotePageDto | undefined) {
        if (page) {
            dispatch(NotesActions.setNotePageContextMenu(page));
        }
    }

    function renderNotePageTab(page: NotePageDto) {
        return (
            <Tab className="selectable"
                 key={page.ClientId}
                 value={page.ClientId}
                 label={page.Name}
                 onContextMenu={() => onSetContextPage(page)}/>
        );
    }

    return (
        <>
            <StackPanel className="notes-page-tabs" orientation="horizontal">
                <Tab className="selectable add-item"
                     title="Add Page"
                     value={-1}
                     icon={<AddBoxIcon/>}
                     onClick={onAddNotePage}
                />
                <Tabs
                    ref={menuRef}
                    variant="scrollable"
                    indicatorColor="secondary"
                    textColor="inherit"
                    value={selectedPageClientId}
                    onChange={(e, newValue) => onNotePageChange(newValue)}
                >
                    {pages.map(renderNotePageTab)};
                </Tabs>
            </StackPanel>
            <NotePageContextMenu elementRef={menuRef}/>

            <EditNoteDialog/>
        </>
    );
};
