import React from "react";
import {ToolbarButton} from "./ToolbarTypes";
import {locationManager} from "../../BootStrap";
import WarningIcon from "@mui/icons-material/Warning";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import EmailIcon from "@mui/icons-material/Email";
import {Permission} from "../common/Permission";
import {useAppSelector} from "../../hooks/ReduxHooks";

export const AppNavToolbar = () => {
    const app = useAppSelector(({system}) => system.app);

    const systemMessages = useAppSelector(({system}) => system.systemMessages);
    const systemStateType = useAppSelector(s => s.system.systemStateType);

    return (<>
        <div className="filler"/>

        {app !== "email" ?
            <ToolbarButton title="Emails"
                           onClick={() => locationManager.switchApp("email")}
                           icon={<EmailIcon/>}/> : null}

        <Permission requiredRole="admin">
            {app !== "system" ?
                <ToolbarButton label="Server Status"
                               title={systemMessages.length > 0 ? systemMessages.join("\r\n") : "All Services OK"}
                               onClick={() => locationManager.switchApp("system")}
                               icon={systemStateType ? <WarningIcon/> : <BeenhereIcon/>}
                               className={systemStateType ? "error" : "all-ok"}/> : null}
        </Permission>
    </>);
}
