import {AppDispatch} from "../../AppStore";
import {performMail3RestCall} from "../../util/HttpHelper";
import {SystemActions} from "../../actions/SystemActions";
import {PhotosActions} from "../../redux/PhotosSlice";
import {PhotoBrowserDto} from "./dto/PhotoBrowserDto";

export class PhotoBrowserService {
    constructor(private dispatch: AppDispatch) {
    }
    
    async browsePhotos(breadcrumbs: string[], searchText: string | undefined) {
        try {
            this.dispatch(PhotosActions.setLoading(true));
            
            const request = {breadcrumbs, searchText};
            const response = 
                searchText 
                    ? await performMail3RestCall<PhotoBrowserDto>("Photos/Search", request)
                    : await performMail3RestCall<PhotoBrowserDto>("Photos/Browse", breadcrumbs);

            this.dispatch(PhotosActions.handleBrowserResponse(response));
        } catch (e: any) {
            this.dispatch(SystemActions.showFormattedError("Something went wrong loading your photos", e));
        }
        this.dispatch(PhotosActions.setLoading(false));
    }

    async ratePhoto(breadcrumbs: string[], photo: string, rating: number | null) {
        try {
            this.dispatch(PhotosActions.setLoading(true));

            const request: RatePhotoRequest = {
                breadcrumbs, 
                photo,
                rating,
            };
            const response = await performMail3RestCall<RatePhotoResponse>("Photos/Rate", request);

            this.dispatch(PhotosActions.handleRatePhotoResponse(response));
        } catch (e: any) {
            this.dispatch(SystemActions.showFormattedError("Something went wrong while rating the photo", e));
        }
        this.dispatch(PhotosActions.setLoading(false));
    }

    async updateExclusions(breadcrumbs: string[], exclude: boolean, excludeRecursively: boolean) {
        try {
            this.dispatch(PhotosActions.setLoading(true));

            const response = await performMail3RestCall<PhotoBrowserDto>(`Photos/SetFolderExclusions/${exclude}/${excludeRecursively}`, breadcrumbs);

            this.dispatch(PhotosActions.handleBrowserResponse(response));
        } catch (e: any) {
            this.dispatch(SystemActions.showFormattedError("Something went wrong while rating the photo", e));
        }
        this.dispatch(PhotosActions.setLoading(false));
    }
}

interface RatePhotoRequest {
    breadcrumbs: string[];
    photo: string;
    rating: number | null;
}

export type RatePhotoResponse = RatePhotoRequest;
