import React, {FC} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {AppState} from '../../AppState';
import {EmailSourceDto} from '../../domain/EmailSourceDto';
import "./EmailSourceView.scss";
import {HideContentButton} from '../common/HideContentButton';

const RenderEmailSourceView: FC<EmailSourceViewProps> = ({ emailSource }) => {

    return (<>
        <div className="email-source-button">
            <HideContentButton />
        </div>
        {emailSource.Decoded ? (
            <div className="email-source" dangerouslySetInnerHTML={{ __html: emailSource.Source }}>
            </div>
        ) : (
                <div className="email-source">
                    <pre>
                        {emailSource.Source}
                    </pre>
                </div>
            )}
    </>);
};

const mapState = ({ itemState }: AppState) => ({
    emailSource: itemState.itemContent as EmailSourceDto,
});

const connector = connect(mapState);

type EmailSourceViewProps = ConnectedProps<typeof connector>;

const EmailSourceView = connector(RenderEmailSourceView);

export default EmailSourceView;
