import {Button, Card, CardContent, CardHeader, TextField} from "@mui/material";
import {StackPanel} from "../panels/StackPanel";
import {SmallLoadingIndicator} from "../common/SmallLoadingIndicator";
import React, {useState} from "react";
import {performRestCallDirectly} from "../../util/HttpHelper";
import {useSelector} from "react-redux";
import {AppState} from "../../AppState";
import ConfirmDialog from "../common/ConfirmDialog";
import {ChangeAliasesResponseDto} from "../../services/messages/responses/ChangeAliasesResponseDto";
import {useAppDispatch} from "../../hooks/ReduxHooks";
import {PreferenceActions} from "../../redux/PreferencesSlice";

export const EmailAliasPreferences = () => {

    const dispatch = useAppDispatch();

    const [isConfirmRemoveAliasOpen, setConfirmRemoveAliasOpen] = useState(false);
    const [confirmRemoveAlias, setConfirmRemoveAlias] = useState("");
    const [newAlias, setNewAlias] = useState("");

    const aliases = useSelector(({preferenceState}: AppState) => preferenceState.aliases);
    const isSavingAliases = useSelector(({preferenceState}: AppState) => preferenceState.isSavingAliases);
    
    async function onSaveNewAlias() {
        dispatch(PreferenceActions.addAlias({data: newAlias}));
        try {
            const response = await performRestCallDirectly<ChangeAliasesResponseDto>("AddAlias", {Alias: newAlias});
            dispatch(PreferenceActions.addAlias({response, data: newAlias}));
        } catch (error) {
            dispatch(PreferenceActions.addAlias({error, data: newAlias}));
        }
        setNewAlias("");
    }

    async function onConfirmRemoveAlias(confirm: boolean) {
        if (confirm) {
            dispatch(PreferenceActions.removeAlias({data: confirmRemoveAlias}));
            try {
                const response = await performRestCallDirectly<ChangeAliasesResponseDto>("RemoveAlias", {Alias: confirmRemoveAlias});
                dispatch(PreferenceActions.removeAlias({response, data: confirmRemoveAlias}));
            } catch (error) {
                dispatch(PreferenceActions.removeAlias({error, data: confirmRemoveAlias}));
            }
        }
        setConfirmRemoveAliasOpen(false);
    }

    function onRemoveAlias(alias: string) {
        setConfirmRemoveAlias(alias);
        setConfirmRemoveAliasOpen(true);
    }

    return (
        <Card className="preferences-section">
            <CardHeader title="Email Aliases"/>
            <CardContent>
                <StackPanel align="end">
                    <TextField label="New Alias/Filter"
                               value={newAlias}
                               onChange={e => setNewAlias(e.target.value)}
                               disabled={isSavingAliases}/>

                    <Button variant="contained" color="primary"
                            onClick={onSaveNewAlias}
                            disabled={isSavingAliases || !newAlias}>Add Alias</Button>

                    {isSavingAliases ? <SmallLoadingIndicator/> : null}
                </StackPanel>
                <div className="alias-list">
                    {aliases
                        .filter(a => newAlias === "" || a.includes(newAlias.toLowerCase()))
                        .map(a => <div key={a} className="pointer selectable-2" onClick={() => onRemoveAlias(a)}>{a}</div>)}
                </div>
            </CardContent>

            <ConfirmDialog title="Remove Alias"
                           message={`Are you sure you want to remove the alias '${confirmRemoveAlias}'?`}
                           open={isConfirmRemoveAliasOpen}
                           onConfirm={onConfirmRemoveAlias}
                           confirmColour="error"/>
        </Card>
    );
};