import {AppDispatch} from "../../AppStore";
import {performMail3RestCall} from "../../util/HttpHelper";
import {SystemActions} from "../../actions/SystemActions";
import {EmailRuleListDto} from "./dto/EmailRuleListDto";
import {RulesActions} from "../../redux/RulesSlice";
import {EmailRuleDto} from "./dto/EmailRuleDto";
import {ContentLocation} from "../../locations/ContentLocation";

export class RulesService {
    static async updateRules(dispatch: AppDispatch): Promise<EmailRuleDto[]> {
        try {
            const response = await performMail3RestCall<EmailRuleListDto>("Rules/List", {});

            dispatch(RulesActions.setRulesList(response.Rules));

            return response.Rules;
        } catch (e: any) {
            dispatch(SystemActions.showFormattedError("Unable to get your email rules", e));
            return [];
        }
    }

    static async deleteRule(ruleId: number, dispatch: AppDispatch) {
        try {
            await performMail3RestCall<boolean>("Rules/Delete", {RuleId: ruleId});

            dispatch(RulesActions.deleteRule(ruleId));

            dispatch(SystemActions.showNotification("Rule deleted"));
            return true;
        } catch (e: any) {
            dispatch(SystemActions.showFormattedError("Unable to delete your rule", e));
            return false;
        }
    }

    static async saveRule(rule: EmailRuleDto, dispatch: AppDispatch) {
        try {
            const response = await performMail3RestCall<EmailRuleDto>("Rules/Save", rule);

            dispatch(RulesActions.saveRule(response));

            if (rule.Id === -1) {
                ContentLocation.clearItemContent();
            }

            dispatch(SystemActions.showNotification("Rule saved"));
            return true;
        } catch (e: any) {
            dispatch(SystemActions.showFormattedError("Unable to save your rule", e));

            return false;
        }
    }

    static async saveRuleRanks(rule1: EmailRuleDto, rule2: EmailRuleDto, dispatch: AppDispatch) {
        try {
            await performMail3RestCall<boolean>("Rules/SaveRanks", {Rule1Id: rule1.Id, Rule2Id: rule2.Id, Rank1: rule1.Rank, Rank2: rule2.Rank});

            dispatch(RulesActions.saveRule(rule1));
            dispatch(RulesActions.saveRule(rule2));

            return true;
        } catch (e: any) {
            dispatch(SystemActions.showFormattedError("Unable to update the rule ordering", e));

            return false;
        }
    }
}
