import React, {CSSProperties, FC} from "react";
import {useAppSelector} from "../../../hooks/ReduxHooks";
import classNames from "classnames";
import {Checkbox} from "@mui/material";
import {GridRowData, SimpleGridBaseProps} from "./SimpleGrid";
import ExpandMore from "@mui/icons-material/ExpandMore";

export interface SimpleGridRowProps<TItem, TKey> extends SimpleGridBaseProps<TItem, TKey> {
    index: number;
    rowData: GridRowData<TItem>;
    style: CSSProperties;
    onRangeSelection: (selectedItems: TItem[], endItem: TItem) => void;
    onSegmentCollapsed: (segment: GridRowData<TItem>, collapsed: boolean) => void;
}

export function SimpleGridRow<TItem, TKey>({
                                               index,
                                               style,
                                               rowData,
                                               rowRenderer,
                                               rowIdExtractor,
                                               rowClassRules,
                                               selectedItemSelector,
                                               onUpdateSelection,
                                               onRangeSelection,
                                               onItemClick,
                                               onSegmentCollapsed,
                                           }: SimpleGridRowProps<TItem, TKey>) {

    const selectedItems = useAppSelector(state => selectedItemSelector(state));

    const selected = selectedItems.some(h => rowData.data && rowIdExtractor(h) === rowIdExtractor(rowData.data));

    const itemEquals = (other: TItem) => rowData.data && rowIdExtractor(other) === rowIdExtractor(rowData.data);

    function setSelected(checkboxSelection: boolean, e: any) {
        if (!rowData.data) return;
        const item = rowData.data;
        const newSelected = !selected;
        let newSelection: TItem[];
        if (newSelected) {
            if (e?.shiftKey && selectedItems.length > 0) {
                onRangeSelection(selectedItems, rowData.data);
                return;
            } else if (checkboxSelection || e?.ctrlKey) {
                newSelection = [...selectedItems, item];
            } else {
                newSelection = [item];
            }
        } else if (selectedItems.length !== 1) {
            newSelection = selectedItems.filter(h => !itemEquals(h));
        } else {
            newSelection = selectedItems;
            if (checkboxSelection) {
                newSelection = [];
            }
        }

        if (!checkboxSelection && onItemClick) {
            onItemClick(item, newSelection);
        }

        if (newSelection !== selectedItems) {
            onUpdateSelection(newSelection);
        }
    }

    function showContextMenu() {
        if (rowData.data) {
            onUpdateSelection([rowData.data]);
        }
    }

    if (rowData.data) {
        return (
            <div
                className={classNames(
                    (index % 2 ? "grid-item-odd" : "grid-item-even"),
                    "email-grid-item",
                    "selectable",
                    {"selected": selected},
                    rowClassRules(rowData.data))}
                style={style}
                onClick={e => setSelected(false, e)}
                onContextMenu={showContextMenu}>

                <div className="header-checkbox">
                    <Checkbox
                        checked={selected}
                        onClick={e => e.stopPropagation()}
                        onChange={e => setSelected(true, e)}
                        color="primary"
                    />
                </div>

                {rowRenderer(rowData.data)}
            </div>
        );
    }
    return <MemoizedSimpleGridSegment
        style={style}
        onSegmentCollapsed={onSegmentCollapsed}
        rowData={rowData}/>;
}

interface SimpleGridSegmentProps<TItem> {
    rowData: GridRowData<TItem>;
    style: React.CSSProperties;
    onSegmentCollapsed: (segment: GridRowData<TItem>, collapsed: boolean) => void;
}

const SimpleGridSegment: FC<SimpleGridSegmentProps<any>> = ({rowData, style, onSegmentCollapsed}) => {
    return (
        <div
            className={"grid-segment-row selectable-2 pointer"}
            style={style}
            onClick={() => onSegmentCollapsed(rowData, !rowData.collapsed)}
        >
            <div className="title expander"><ExpandMore className={classNames({"expanded": !rowData.collapsed})}/></div>
            <div className="title">{rowData.title}</div>
        </div>
    );
}

// TODO: make this work by extracting the delegate function for collapsing segments
const MemoizedSimpleGridSegment = React.memo(SimpleGridSegment);
