import React, {FC} from "react";
import {SystemStatusUpdateTime} from "./controls/SystemStatusUpdateTime";
import {useSelector} from "react-redux";
import {AppState} from "../../AppState";

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import HelpIcon from '@mui/icons-material/Help';

import {ServiceStatusType} from "../../services/system/ServiceStatusDto";
import {TimeControl} from "../common/TimeControl";
import {formatDiskSpace, OneGB} from "../../util/Formatters";
import {SystemPropertyDto} from "../../services/system/SystemStatusResponseDto";

const CertificateExpiryThreshold = 14;

export const SystemStatusView: FC = () => {

    const status = useSelector(({sysStatus}: AppState) => sysStatus.status);

    function getStatusIcon(status: ServiceStatusType) {
        switch (status) {
            case "ok":
                return <CheckIcon className="status-ok"/>;
            case "failed":
                return <ClearIcon className="status-error"/>;
            default:
                return <HelpIcon className="status-unknown"/>;
        }
    }

    const serviceElts = status.Services
        .map((s, i) => (
            <div className="item-status" key={i}>
                <span>{getStatusIcon(s.Status)}</span>
                <span className="sub-status">
                    <span className="name">{s.ServiceName}</span>
                    <span className="sub-heading"><TimeControl title="last seen" time={s.LastSeen}/></span>
                </span>
            </div>
        ));

    const certificateElts = status.Certificates
        .map((s, i) => (
            <div className="item-status" key={i}>
                <span>{getStatusIcon(s.ExpiryDays < CertificateExpiryThreshold ? "failed" : "ok")}</span>
                <span className="sub-status">
                    <span className="name">{s.Name} Certificate</span>
                    <span className="sub-heading">expires in {s.ExpiryDays} days</span>
                </span>
            </div>
        ));

    const driveElts = status.Drives
        .map((s, i) => (
            <div className="item-status" key={i}>
                <span>{getStatusIcon(s.FreeSpace < 30 * OneGB && s.FreeSpace < s.TotalSize / 10 ? "failed" : "ok")}</span>
                <span className="sub-status">
                    <span className="name">{s.Name}</span>
                    <span
                        className="sub-heading">{formatDiskSpace(s.TotalSize - s.FreeSpace)} / {formatDiskSpace(s.TotalSize)} GB</span>
                </span>
            </div>
        ));

    const propertyElts = status.Properties && Object.entries(status.Properties)
        .sort(([a], [b]) => a.localeCompare(b))
        .map(([name, property]) => {
            const className: string = getStatusClass(property);

            return (
                <div className="item-status" key={name}>
                    <span className="sub-status">
                        <span className="name">
                            {name}:&nbsp;
                            <b><span className={className}>{property.Value}</span></b>
                        </span>
                    </span>
                </div>
            );
        });

    return (
        <div className="system-status-panel">
            <div className="status-panel-list panel-with-status">
                <div className="status-panel">
                    <span className="title">Services</span>
                    {serviceElts}
                </div>

                <div className="status-panel">
                    <span className="title">Certificates</span>
                    {certificateElts}
                </div>

                <div className="status-panel">
                    <span className="title">Disks</span>
                    {driveElts}
                </div>

                <div className="status-panel">
                    <span className="title">Properties</span>
                    {propertyElts}
                </div>
            </div>

            <SystemStatusUpdateTime/>
        </div>
    );
};

function getStatusClass(property: SystemPropertyDto) {
    switch (property.Status) {
        case 1:
            return "status-ok";
        case 2:
            return "status-warning";
        case 3:
            return "status-error";
        default:
            return "status-unknown";
    }
}