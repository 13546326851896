import {createAction, createSlice, PayloadAction} from "@reduxjs/toolkit";
import RemoteOperationDto from "../services/messages/RemoteOperationDto";
import {initialSyncState, SyncState} from "../state/SyncState";
import {ItemChangeListDto} from "../services/messages/ItemChangeListDto";

export const SYNC_RESPONSE_RECEIVED = "SYNC_RESPONSE_RECEIVED";
export const ADD_REMOTE_OPERATION = "ADD_REMOTE_OPERATION";

const pendingFolderAutoSync = new Set<string>();

export interface SetSyncStatePayload {
    startSyncId: number;
    endSyncId: number;
}

export interface SetOperationsPayload {
    operations: RemoteOperationDto[];
}

export interface AddRemoteOperationPayload {
    operation: RemoteOperationDto;
    state?: any;
}

export namespace SyncActions {
    export const syncResponseReceived = createAction<ItemChangeListDto>(SYNC_RESPONSE_RECEIVED);

    export const addRemoteOperation = createAction<AddRemoteOperationPayload>(ADD_REMOTE_OPERATION);

    export const setSyncState = createAction<SetSyncStatePayload>("SET_SYNC_STATE");

    export const setOperations = createAction<SetOperationsPayload>("SET_OPERATIONS");
}

export const syncSlice = createSlice({
    name: "sync",
    initialState: initialSyncState,
    reducers: {},
    extraReducers: builder =>
        builder
            .addCase(SyncActions.syncResponseReceived, handleSyncResponseReceived)
            .addCase(SyncActions.setSyncState, handleSetSyncState)
            .addCase(SyncActions.setOperations, handleSetOperations)
            .addCase(SyncActions.addRemoteOperation, handleAddRemoteOperation)
});

function handleAddRemoteOperation(state: SyncState, {payload}: PayloadAction<AddRemoteOperationPayload>): SyncState {
    const {operation} = payload;
    const changes: Partial<SyncState> = {operations: [...state.operations, operation]};

    console.log("Pending remote operations: ", changes.operations);

    const CreateSubFolder = operation.ManageFolder?.CreateSubFolder;
    if (CreateSubFolder) {
        pendingFolderAutoSync.add(CreateSubFolder.ServerId);
    }

    return {...state, ...changes};
}

function handleSetSyncState(state: SyncState, {payload}: SetSyncStateAction): SyncState {
    return {
        ...state,
        startSyncId: payload.startSyncId,
        endSyncId: payload.endSyncId,
    };
}

function handleSetOperations(state: SyncState, {payload}: SetOperationsAction): SyncState {
    return {
        ...state,
        operations: payload.operations,
    };
}

function handleSyncResponseReceived(state: SyncState, {payload: changes}: PayloadAction<ItemChangeListDto>): SyncState {

    return {
        ...state,
        serverId: changes.ServerId,
        startSyncId: changes.StartSyncId,
        endSyncId: changes.SyncId,
        syncInProgress: changes.HasMoreChanges,
    };
}

// Backwards compatibility...
export interface SyncResponseReceivedAction {
    type: typeof SYNC_RESPONSE_RECEIVED;
    payload: ItemChangeListDto;
}

export interface AddRemoteOperationAction {
    type: typeof ADD_REMOTE_OPERATION;
    payload: AddRemoteOperationPayload;
}

export type SetSyncStateAction = PayloadAction<SetSyncStatePayload>;

export type SetOperationsAction = PayloadAction<SetOperationsPayload>;
