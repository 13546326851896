import {connect, ConnectedProps} from "react-redux";
import React, {useEffect, useState} from "react";
import {Subject} from "rxjs";
import "./ComposeEmailView.scss";
import {ComposeEmailActions} from "../../actions/ComposeEmailActions";
import classNames from "classnames";
import Editor, {EditorFileCallback} from "./Editor";
import DbDraftEmail from "../../domain/DbDraftEmail";
import {throttleTime} from "rxjs/operators";
import {DB} from "../../db/DbManager";
import {convertFileToAttachment} from "./AttachmentManagerView";
import {AppState} from "../../AppState";
import {uuid} from "../../util/Uuid";
import {AttachmentActions} from "../../actions/AttachmentActions";
import {ComposeEmailHeader} from "./ComposeEmailHeader";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";

const MAX_EMAIL_SAVE_RATE = 5000;

export interface RenderComposeEmailViewProps {
    className?: string
}

const RenderComposeEmailView = ({
                                    className,
                                    draftEmail,
                                }: ComposeEmailViewProps & RenderComposeEmailViewProps) => {


    const [bodyText, setBodyText] = useState<string>(draftEmail?.BodyText || "");  // TODO: use an email signature here instead

    const [saveDraftSubject] = useState(new Subject<DbDraftEmail | undefined>());
    const draftEmailId = draftEmail?.Uid;

    const isMobile = useAppSelector(s => s.system.isMobile);

    const dispatch = useAppDispatch();

    useEffect(
        () => {
            setBodyText(draftEmail?.BodyText || "");

            const subscription = saveDraftSubject
                .pipe(throttleTime(MAX_EMAIL_SAVE_RATE, undefined, {leading: false, trailing: true}))
                .subscribe(emailToSave => {
                    if (emailToSave) {
                        DB.saveDraftEmail(emailToSave)
                            .catch((ex) => {
                                // TODO: show a warning to the user
                                console.error("Failed to save email draft", ex);
                            });
                    }
                });

            return () => {
                subscription.unsubscribe();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [draftEmailId]  // Only update if the draft email has changed
    );

    // Emit a new version of the email when it changes
    useEffect(
        () => saveDraftSubject.next(draftEmail)
        , [draftEmail, saveDraftSubject]
    );

    async function onAddInlineImageAttachment(callback: EditorFileCallback, file: File) {
        if (!draftEmailId) return;

        const newAttachment = await convertFileToAttachment(draftEmailId, file);
        newAttachment.contentId = uuid();

        dispatch(await AttachmentActions.addAttachment(draftEmailId, file, newAttachment, callback));
    }

    function onChangeDraftEmail(changes: Partial<DbDraftEmail>) {
        dispatch(ComposeEmailActions.changeDraftEmail(changes));
    }

    return (
        <div className={classNames("compose-email-view", className)}>

            {draftEmail && <ComposeEmailHeader draftEmail={draftEmail}/>}

            <Editor
                menubar={false}
                statusbar={false}
                toolbar="undo redo | bold italic underline formatpainter removeformat | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | link image media | forecolor backcolor emoticons code | searchreplace " // | help"
                plugins={["autolink", "image", "paste", "imagetools", "table", "media", "searchreplace", "lists", "emoticons", "code"]} //advlist autolink link lists hr anchor pagebreak spellchecker searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking table emoticons template paste"
                height={isMobile ? "calc(100vh - 48px)" : undefined}
                initialValue={bodyText}
                instanceId={draftEmailId}

                onChange={html => onChangeDraftEmail({BodyText: html})}

                onAddInlineImage={onAddInlineImageAttachment}
            />
        </div>
    );
};

const mapProps = ({compose}: AppState) => ({
    draftEmail: compose.draftEmail,
});

const connector = connect(mapProps);

type ComposeEmailViewProps = ConnectedProps<typeof connector>;

const ComposeEmailView = connector(RenderComposeEmailView);

export default ComposeEmailView;
