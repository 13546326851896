import {NotesActions} from "../../redux/NotesSlice";
import {useAppDispatch, useAppSelector, useAppStore} from "../../hooks/ReduxHooks";
import {Button} from "@mui/material";
import React, {useState} from "react";
import ConfirmDialog from "../common/ConfirmDialog";

export const NoteEditPageButtons = () => {
    const dispatch = useAppDispatch();

    const store = useAppStore();

    const editingPage = useAppSelector(({notesState}) => notesState.editingPage);
    const editPageContent = useAppSelector(({notesState}) => notesState.editPageContent);

    const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);

    if (!editingPage) return null;

    async function onSavePageContent() {
        const newPage = {...editingPage!, HtmlContent: editPageContent};
        dispatch(await NotesActions.completeEditNote(newPage, true));
        dispatch(NotesActions.stopEditingNotePage());
    }

    function handleConfirmDiscard(confirmed: boolean) {
        if (confirmed) {
            dispatch(NotesActions.stopEditingNotePage())
        }
        setConfirmDialogOpen(false);
    }

    function onDiscardContent() {
        const {notesState} = store.getState();
        if (notesState.selectedPage?.HtmlContent === editPageContent) {
            handleConfirmDiscard(true);
        } else {
            setConfirmDialogOpen(true);
        }
    }

    return <>
        <Button variant="contained" color="primary" onClick={() => onSavePageContent()}>Save</Button>
        <Button variant="contained" color="inherit" onClick={() => onDiscardContent()}>Discard</Button>

        <ConfirmDialog title="Discard changes?"
                       message="Are you sure you want to discard the changes you made to this note?"
                       open={confirmDialogOpen}
                       onConfirm={handleConfirmDiscard}
                       confirmColour="error"/>
    </>;
};
