import DbDraftEmail from "../domain/DbDraftEmail";
import { ValidationResult } from "./ValidationResult";
import { extractEmailAddresses } from "../util/Formatters";

export function validateEmailToSend(email: DbDraftEmail): ValidationResult {
    const to = extractEmailAddresses(email.To);
    const cc = extractEmailAddresses(email.Cc);
    const bcc = extractEmailAddresses(email.Bcc);

    const result: ValidationResult = { title: undefined, errors: [] };

    if (to.length === 0 && cc.length === 0 && bcc.length === 0) {
        result.title = "Recipients Missing";
        result.errors.push("You must add at least one recipient to be able to send the email");
    }

    return result;
}
