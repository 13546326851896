import React, {useState} from "react";
import {Button, Grid, TextField} from "@mui/material";
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import {AppState} from "../../AppState";
import {useDispatch, useSelector} from "react-redux";
import {Centre} from "../Centre";
import {SystemActions} from "../../actions/SystemActions";
import "./LoginView.scss";
import {bootstrapUser, locationManager} from "../../BootStrap";
import {useAppSelector} from "../../hooks/ReduxHooks";
import {getUsernameCookie} from "../../util/CommonCookies";

const LoginView: React.FC = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const loginInProgress = useSelector(({system}: AppState) => system.loginInProgress);
    const loggedInUserName = getUsernameCookie();

    const isEmailApp = useAppSelector(s => s.system.app === "email");

    const dispatch = useDispatch();

    async function performLogin() {
        await SystemActions.performLogin(dispatch, loggedInUserName || username, password);
        bootstrapUser();
    }

    return (
        <Centre>
            <Grid container spacing={1} alignItems="flex-end" style={{maxWidth: "300px"}}>
                <Grid item>
                    <AccountCircle/>
                </Grid>
                <Grid item xs>
                    {loggedInUserName
                        ? <span className="fixed-username">{loggedInUserName}</span>
                        : (<TextField id="username" label="Username" type="email" fullWidth autoFocus
                                      disabled={loginInProgress}
                                      onChange={e => setUsername(e.target.value)}/>)
                    }
                </Grid>
            </Grid>

            <Grid container spacing={1} alignItems="flex-end" style={{maxWidth: "300px"}}>
                <Grid item>
                    <LockIcon/>
                </Grid>
                <Grid item xs>
                    <TextField id="password" label="Password" type="password" disabled={loginInProgress}
                               autoFocus={!!loggedInUserName}
                               onChange={e => setPassword(e.target.value)}
                               onKeyPress={async e => {
                                   if (e.key === "Enter") {
                                       await performLogin();
                                   }
                               }} fullWidth/>
                </Grid>
            </Grid>

            <Button
                style={{marginTop: "8px"}}
                variant="contained"
                color="primary"
                disabled={loginInProgress}
                onClick={() => performLogin()}>
                Login
            </Button>

            {loggedInUserName
                ? (<div>
                        <Button
                            style={{marginTop: "8px"}}
                            variant="contained"
                            disabled={loginInProgress}
                            onClick={() => SystemActions.performFullLogout()}>
                            Switch User
                        </Button>

                        {!isEmailApp && <Button
                            style={{marginTop: "8px", marginLeft: "8px"}}
                            variant="contained"
                            disabled={loginInProgress}
                            onClick={() => locationManager.switchApp("email")}>
                            Work Offline
                        </Button>}
                    </div>
                )
                : null
            }
        </Centre>
    );
}

export default LoginView;
