import React, {FC} from "react";
import {useSelector} from "react-redux";
import {AppState} from "../../AppState";

import InfoIcon from '@mui/icons-material/Info';

import "./EmailHeaderStatusBar.scss";

export const EmailHeaderStatusBar: FC = () => {
    
    const selectionCount = useSelector(({itemState}:AppState) => itemState.selectedEmailHeaders.length);
    
    if (selectionCount <= 1) {
        return null;
    }
    
    return (
        <div className={"email-header-status-bar"}>
            <InfoIcon/>
            <span>{selectionCount} emails selected</span>
        </div>
    );
};
