import {AllActionTypes} from "../actions/AllActionTypes";
import {ANALYZE_EMAIL_ASYNC, GET_EMAIL_CONTENT_ASYNC, GET_EMAIL_SOURCE_ASYNC, REMOVE_GREYLISTED_EMAILS} from "../actions/EmailActionTypes";
import {initialSystemState, SystemState} from "../state/SystemState";
import {
    CHANGE_CONNECTION_STATE,
    CHANGE_THEME,
    ConnectionStatus,
    HIDE_ERROR,
    HIDE_NOTIFICATION,
    HIDE_NOTIFICATION_MESSAGE,
    LOGIN_ASYNC,
    NOTIFICATION_PERMISSION,
    PERFORM_HANDSHAKE,
    RESIZE_FOLDER_TREE,
    RESIZE_WINDOW,
    SET_APP,
    SET_NOTIFICATION_MESSAGES,
    SHOW_ERROR,
    SHOW_FOLDER_MENU,
    SHOW_NOTIFICATION,
    SHOW_NOTIFICATION_MESSAGES,
    SHOW_TOOLBAR_MENU,
    SHOW_WARNING,
} from "../actions/SystemActionTypes";
import {GET_GREYLISTED_EMAILS_ASYNC, PERFORM_SEARCH_ASYNC} from '../actions/FolderActionTypes';
import {getAppConfig} from "../apps/AppConfig";
import {changeArrayItem, changeArrayItems} from "../util/ReducerUtil";
import {SYSTEM_LOG_RESPONSE} from "../redux/SystemLogSlice";
import {SYNC_RESPONSE_RECEIVED} from "../redux/SyncSlice";
import {ApiVersion} from "../APIVersion";
import {ADD_ALIAS, REMOVE_ALIAS} from "../redux/PreferenceActions";
import {ItemChangeListDto} from "../services/messages/ItemChangeListDto";

export default function systemReducer(state = initialSystemState, action: AllActionTypes): SystemState {
    switch (action.type) {
        case SET_APP:
            return {...state, app: action.newApp, appConfig: getAppConfig(action.newApp)};

        case CHANGE_THEME:
            return {...state, theme: action.newTheme};

        case RESIZE_WINDOW:
            return {...state, isMobile: action.newWidth < 760};

        case SET_NOTIFICATION_MESSAGES:
            return {...state, notificationMessages: action.messages};

        case HIDE_NOTIFICATION_MESSAGE:
            return {
                ...state,
                notificationMessages: changeArrayItem(state.notificationMessages, n => n.id === action.id, n => n.visible = false),
            };
        case SHOW_NOTIFICATION_MESSAGES:
            return {
                ...state,
                notificationMessages: changeArrayItems(state.notificationMessages, n => n.visible = true),
            };

        case RESIZE_FOLDER_TREE:
            return {...state, folderTreeWidth: action.newWidth};

        case CHANGE_CONNECTION_STATE:
            let newOfflineStatus = state.isOffline;
            if (action.status === ConnectionStatus.Disconnected) {
                newOfflineStatus = true;
            } else if (action.status === ConnectionStatus.Connected) {
                newOfflineStatus = false;
            }
            // console.log("Offline:", newOfflineStatus);
            return {...state, connectionStatus: action.status, isOffline: newOfflineStatus};

        // TODO: handle CONNECTION_ERROR
        case LOGIN_ASYNC:
            if (action.username !== undefined) {
                return {
                    ...state,
                    loginInProgress: false,
                    userName: action.username,
                    error: {...state.error, show: false}
                };
            } else if (action.error) {
                return {
                    ...state,
                    userName: "",
                    error: {show: true, message: action.error.message},
                    loginInProgress: false
                };
            }
            return {...state, loginInProgress: true};

        case GET_EMAIL_CONTENT_ASYNC:
        case GET_EMAIL_SOURCE_ASYNC:
        case ANALYZE_EMAIL_ASYNC:
        case GET_GREYLISTED_EMAILS_ASYNC:
            if (action.error) {
                return {...state, error: {show: true, message: action.error.message}};
            }
            return state;
        case REMOVE_GREYLISTED_EMAILS:
            return {
                ...state,
                notification: {show: true, message: `${action.emailServerIds.length} emails released successfully`}
            };

        case SHOW_ERROR:
            return showError(state, action.message);
        case HIDE_ERROR:
            return {...state, error: {...state.error, show: false}};

        case SYSTEM_LOG_RESPONSE:
            if (action.payload.Error) {
                return showError(state, action.payload.Error);
            }
            break;

        case SHOW_NOTIFICATION:
            return {...state, notification: {show: true, message: action.message}};
        case HIDE_NOTIFICATION:
            return {...state, notification: {...state.notification, show: false}};

        case SHOW_WARNING:
            if (action.message) {
                return {...state, warning: {show: true, message: action.message}};
            }
            return {...state, warning: {...state.warning, show: false}};

        case SHOW_FOLDER_MENU:
            return {...state, showFolderMenu: action.visible};

        case SHOW_TOOLBAR_MENU:
            return {...state, showToolbarMenu: action.visible};

        case ADD_ALIAS:
        case REMOVE_ALIAS:
            if (action.payload.response) {
                return {...state, availableAddresses: action.payload.response.AvailableAddresses};
            }
            if (action.payload.error) {
                return {...state, error: {show: true, message: action.payload.error.message}};
            }
            break;

        case PERFORM_HANDSHAKE:
            console.log("Server API version: ", action.data.ApiVersion, " vs ", ApiVersion);
            return {
                ...state,
                availableAddresses: action.data.AvailableAddresses,
                displayName: action.data.DisplayName,
                defaultAddress: action.data.DefaultAddress,
                roles: action.data.Roles || [],
                warning: action.data.ApiVersion > ApiVersion ? {
                    message: "A new version is available - please refresh your page",
                    show: true
                } : {...state.warning, show: false},
            };

        case PERFORM_SEARCH_ASYNC:
            return showError(state, action.error?.toString());

        case SYNC_RESPONSE_RECEIVED:
            return handleSyncResponseReceived(state, action.payload);

        case NOTIFICATION_PERMISSION:
            return {...state, notificationPermission: action.newPermission};
    }
    return state;
}

function showError(state: SystemState, message: string | undefined): SystemState {
    if (!message) return state;
    return {
        ...state,
        error: {show: true, message},
        notification: {...state.notification, show: false},
        warning: {...state.warning, show: false}
    };
}

function handleSyncResponseReceived(state: SystemState, changes: ItemChangeListDto): SystemState {
    if (changes?.Notification) {
        return {
            ...state, 
            systemMessages: changes.Notification.Messages,
            systemStateType: changes.Notification.Type,
        };
    }
    return state;
}
