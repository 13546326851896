import React, {FC, useRef, useState} from "react";
import {ContactContentViewProps} from "./ContactContentView";
import {StackPanel} from "../panels/StackPanel";
import {ContactImage} from "./ContactImage";
import {Button, TextField} from "@mui/material";
import {convertTextToTitleCase, getContactDisplayName} from "../../util/Formatters";
import {ContentLocation} from "../../locations/ContentLocation";
import {ContactDto} from "../../services/messages/ContactDto";
import {blankAddress, PhysicalAddressDto} from "../../services/messages/PhysicalAddressDto";
import {AppState} from "../../AppState";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";
import {EmailActions} from "../../actions/EmailActions";
import {ListTextField} from "./ListTextField";
import {ConditionalTextField} from "./ConditionalTextField";
import {FileUploadControl, FileUploadControlCallback} from "../common/FileUploadControl";
import {createImageThumbnail} from "../../util/ImageUtil";
import {isAndroidApp} from "../../util/DeviceUtil";
import {HideContentButton} from "../common/HideContentButton";
import {EditButtons} from "../common/EditButtons";

function setContactNames(contact: ContactDto, title: string, firstName: string, middleName: string, lastNames: string) {
    contact.Title = title;
    contact.FirstName = firstName;
    contact.MiddleName = middleName;
    contact.LastName = lastNames;
}

export function setContactName(contact: ContactDto, fullName: string) {
    const tokens = fullName.trim().split(/ +/);

    let title = "";
    let firstName = "";
    let middleName = "";
    let lastNames = "";

    if (tokens.length > 0) {
        const lowerToken = tokens[0].toLowerCase()
        if (lowerToken === "mr" || lowerToken === "dr" || lowerToken === "miss" || lowerToken === "ms" || lowerToken === "mrs") {
            title = convertTextToTitleCase(tokens[0]);
            tokens.splice(0, 1);
        }
    }

    if (tokens.length === 2) {
        lastNames = convertTextToTitleCase(tokens.slice(1).join(" "));
    } else if (tokens.length >= 3) {
        lastNames = convertTextToTitleCase(tokens.slice(2).join(" "));
    }
    if (tokens.length > 0) {
        firstName = convertTextToTitleCase(tokens[0]);
    }
    if (tokens.length > 2) {
        middleName = convertTextToTitleCase(tokens[1]);
    }

    setContactNames(contact, title, firstName, middleName, lastNames);
}

export const ContactEditView: FC<ContactContentViewProps> = (props) => {
    const [contact, setContact] = useState({...props.contact});

    const [name, setName] = useState(getContactDisplayName(contact));

    const isMobile = useSelector(({system}: AppState) => system.isMobile);

    const dispatch = useDispatch();

    const imageFileRef = useRef<FileUploadControlCallback | null>();

    function onContactPhotoClick() {
        imageFileRef.current?.openFileDialog();
    }

    async function contactImageFileSelected(files: FileList) {
        if (files.length !== 1) return;

        setContact({...contact, PictureDataUrl: await createImageThumbnail(files[0], 256)});
    }

    async function onSaveContact() {
        setContactName(contact, name);

        dispatch(await EmailActions.addRemoteOperation({ManageContact: {Change: contact}}));

        ContentLocation.showContact(contact.Id, contact.ClientId);
    }

    function onDiscardChanges() {
        ContentLocation.showContact(contact.Id, contact.ClientId);
    }

    function showCommonFields() {
        setContact({
            ...contact,
            Birthday: contact.Birthday || "",
            Anniversary: contact.Anniversary || "",
            NickName: contact.NickName || "",
            BusinessPhone: contact.BusinessPhone || "",
            WebPage: contact.WebPage || "",
            Spouse: contact.Spouse || "",
            Children: contact.Children || "",
            Company: contact.Company || "",
        });
    }

    function showAllFields() {
        showCommonFields();

        setContact({
            ...contact,
            Home2Phone: contact.Home2Phone || "",
            HomeFax: contact.HomeFax || "",
            Business2Phone: contact.Business2Phone || "",
            BusinessFax: contact.BusinessFax || "",
            Pager: contact.Pager || "",
            RadioPhone: contact.RadioPhone || "",
            CarPhone: contact.CarPhone || "",
            MMS: contact.MMS || "",

            JobTitle: contact.JobTitle || "",
            OfficeLocation: contact.OfficeLocation || "",
            Department: contact.Department || "",
            ManagerName: contact.ManagerName || "",
            AssistantName: contact.AssistantName || "",
            AssistantPhone: contact.AssistantPhone || "",
        });
    }

    function changeAddress(index: number, change: Partial<PhysicalAddressDto>) {
        const newValues = [...contact.Addresses];
        newValues.splice(index, 1, {...newValues[index], ...change});

        setContact({...contact, Addresses: newValues});
    }

    return (
        <>
            <div>
                <HideContentButton/>
                <StackPanel className={classNames("contact-left-pane", {"contact-mobile": isMobile})} orientation="vertical"
                            align="middle">
                    <ContactImage contact={contact} size={isMobile ? "medium" : "large"} onClick={onContactPhotoClick}/>

                    <FileUploadControl fileControlRef={imageFileRef} onFilesSelected={contactImageFileSelected}/>

                    {contact.Emails.length < 3 ?
                        <Button variant="contained"
                                onClick={() => setContact({...contact, Emails: [...contact.Emails, ""]})}>
                            {isMobile ? "" : "Add"} Email
                        </Button>
                        : null}

                    <Button variant="contained"
                            onClick={() => setContact({...contact, Addresses: [...contact.Addresses, {...blankAddress}]})}>
                        {isMobile ? "" : "Add"} Address
                    </Button>

                    {contact.IMAddresses.length < 3 ?
                        <Button variant="contained"
                                onClick={() => setContact({...contact, IMAddresses: [...contact.IMAddresses, ""]})}>
                            Add IM
                        </Button>
                        : null}

                </StackPanel>

                <StackPanel className="contact-right-pane edit-contact" orientation="vertical" align="start">
                    <TextField label="Name" value={name} fullWidth onChange={e => setName(e.target.value)}/>

                    <TextField label="Category" value={contact.Category || ""}
                               onChange={e => setContact({...contact, Category: e.target.value})}/>

                    <TextField label="Notes" value={contact.Notes || ""} fullWidth multiline
                               onChange={e => setContact({...contact, Notes: e.target.value})}/>

                    <ListTextField label="Email"
                                   values={contact.Emails}
                                   type="email"
                                   onChange={v => setContact({...contact, Emails: v})}/>

                    {/* Physical Addresses */}
                    {(contact.Addresses || [])
                        .map((address, i) => (
                            <StackPanel key={i} orientation="horizontal" className="address-panel">
                                <TextField label="Type" value={address.Type} className="small-field"
                                           onChange={e => changeAddress(i, {Type: e.target.value})}/>

                                <StackPanel orientation="vertical" align="start">
                                    <TextField label="Street" value={address.Street || ""} fullWidth
                                               onChange={e => changeAddress(i, {Street: e.target.value})}/>
                                    <TextField label="City" value={address.City || ""}
                                               onChange={e => changeAddress(i, {City: e.target.value})}/>
                                    <TextField label="State" value={address.State || ""}
                                               onChange={e => changeAddress(i, {State: e.target.value})}/>
                                    <TextField label="Country" value={address.Country || ""}
                                               onChange={e => changeAddress(i, {Country: e.target.value})}/>
                                    <TextField label="Postcode" value={address.Postcode || ""}
                                               onChange={e => changeAddress(i, {Postcode: e.target.value})}/>
                                </StackPanel>
                            </StackPanel>))}

                    {/* Dates */}
                    <ConditionalTextField label="Birthday" value={contact.Birthday} type={isAndroidApp() ? "text" : "date"}
                                          onChange={v => setContact({...contact, Birthday: v})}/>

                    <ConditionalTextField label="Anniversary" value={contact.Anniversary} type={isAndroidApp() ? "text" : "date"}
                                          onChange={v => setContact({...contact, Anniversary: v})}/>

                    <ConditionalTextField label="NickName" value={contact.NickName} type="text"
                                          onChange={v => setContact({...contact, NickName: v})}/>

                    {/*Phone Numbers*/}
                    <TextField label="Mobile Phone Number" value={contact.MobilePhone || ""} type="tel"
                               onChange={e => setContact({...contact, MobilePhone: e.target.value})}/>

                    <TextField label="Home Phone Number" value={contact.HomePhone || ""} type="tel"
                               onChange={e => setContact({...contact, HomePhone: e.target.value})}/>

                    <ConditionalTextField label="Home Phone Number 2" value={contact.Home2Phone} type="tel"
                                          onChange={v => setContact({...contact, Home2Phone: v})}/>

                    <ConditionalTextField label="Home Fax Number" value={contact.HomeFax} type="tel"
                                          onChange={v => setContact({...contact, HomeFax: v})}/>

                    <ConditionalTextField label="Work Phone Number" value={contact.BusinessPhone} type="tel"
                                          onChange={v => setContact({...contact, BusinessPhone: v})}/>

                    <ConditionalTextField label="Work Phone Number 2" value={contact.Business2Phone} type="tel"
                                          onChange={v => setContact({...contact, Business2Phone: v})}/>

                    <ConditionalTextField label="Work Fax Number" value={contact.BusinessFax} type="tel"
                                          onChange={v => setContact({...contact, BusinessFax: v})}/>

                    <ConditionalTextField label="Pager Number" value={contact.Pager} type="tel"
                                          onChange={v => setContact({...contact, Pager: v})}/>

                    <ConditionalTextField label="Radio Phone Number" value={contact.RadioPhone} type="tel"
                                          onChange={v => setContact({...contact, RadioPhone: v})}/>

                    <ConditionalTextField label="Car Phone Number" value={contact.CarPhone} type="tel"
                                          onChange={v => setContact({...contact, CarPhone: v})}/>

                    <ConditionalTextField label="MMS Number" value={contact.MMS} type="tel"
                                          onChange={v => setContact({...contact, MMS: v})}/>

                    {/* Job related fields */}

                    <ConditionalTextField label="Company" value={contact.Company} type="text"
                                          onChange={v => setContact({...contact, Company: v})}/>

                    <ConditionalTextField label="Job Title" value={contact.JobTitle} type="text"
                                          onChange={v => setContact({...contact, JobTitle: v})}/>

                    <ConditionalTextField label="Office Location" value={contact.OfficeLocation} type="text"
                                          onChange={v => setContact({...contact, OfficeLocation: v})}/>

                    <ConditionalTextField label="Department" value={contact.Department} type="text"
                                          onChange={v => setContact({...contact, Department: v})}/>

                    <ConditionalTextField label="Manager Name" value={contact.ManagerName} type="text"
                                          onChange={v => setContact({...contact, ManagerName: v})}/>

                    <ConditionalTextField label="Company Phone Number" value={contact.CompanyPhone} type="tel"
                                          onChange={v => setContact({...contact, CompanyPhone: v})}/>

                    <ConditionalTextField label="Assistant Name" value={contact.AssistantName} type="text"
                                          onChange={v => setContact({...contact, AssistantName: v})}/>

                    <ConditionalTextField label="Assistant Phone Number" value={contact.AssistantPhone} type="tel"
                                          onChange={v => setContact({...contact, AssistantPhone: v})}/>

                    {/*Random stuff*/}
                    <ConditionalTextField label="Web Page" value={contact.WebPage} type="url" fullWidth
                                          onChange={v => setContact({...contact, WebPage: v})}/>

                    <ConditionalTextField label="Spouse" value={contact.Spouse} type="text"
                                          onChange={v => setContact({...contact, Spouse: v})}/>

                    <ConditionalTextField label="Children" value={contact.Children} type="text"
                                          onChange={v => setContact({...contact, Children: v})}/>

                    <ListTextField label="IM Address"
                                   values={contact.IMAddresses}
                                   type="text"
                                   onChange={v => setContact({...contact, IMAddresses: v})}/>

                    {/* Show fields buttons */}
                    <div>
                        <Button variant="contained" onClick={showCommonFields} color="primary">Show common fields</Button>
                    </div>

                    <div>
                        <Button variant="contained" onClick={showAllFields} color="primary">Show all fields</Button>
                    </div>

                    <div>&nbsp;</div>
                </StackPanel>
            </div>

            <EditButtons canSave={name.length > 0}
                         onSetEditing={onDiscardChanges}
                         onSave={onSaveContact}/>
        </>
    );
}
