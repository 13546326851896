import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import React, {useState} from "react";
import {Button, Card, CardContent, CardHeader, TextField} from "@mui/material";
import {StackPanel} from "../panels/StackPanel";
import {SmallLoadingIndicator} from "../common/SmallLoadingIndicator";
import ConfirmDialog from "../common/ConfirmDialog";
import {PreferenceActions} from "../../redux/PreferencesSlice";
import {ImageWhitelistDto, ImageWhitelistType} from "../../redux/PreferenceActions";
import {SystemActions} from "../../actions/SystemActions";
import {performMail3RestCall} from "../../util/HttpHelper";
import styled from "@emotion/styled";

const WhitelistEntryDiv = styled.div`
    .type {
        display: inline-block;
        min-width: 100px;
        font-weight: bold;
    }
`;

export const ImageWhitelistPreferences = () => {

    const dispatch = useAppDispatch();

    const [isConfirmRemoveWhitelistOpen, setConfirmRemoveWhitelistOpen] = useState(false);
    const [confirmRemoveWhitelist, setConfirmRemoveWhitelist] = useState<{ type: ImageWhitelistType, text: string }>({type: "Includes", text: ""});
    const [newWhitelist, setNewWhitelist] = useState("");

    const whitelists = useAppSelector(({preferenceState}) => preferenceState.imageWhitelist);
    const isSavingWhitelist = useAppSelector(({preferenceState}) => preferenceState.isSavingWhitelist);

    const isAdmin = useAppSelector(s => s.system.roles.includes("admin"));
    if (!isAdmin) return null;

    async function onSaveNewWhitelist(type: ImageWhitelistType) {
        dispatch(PreferenceActions.setSavingWhitelist(true));
        try {
            const response = await performMail3RestCall<ImageWhitelistDto>(`Whitelist/Add/${type}`, newWhitelist);
            dispatch(PreferenceActions.updateWhitelist(response));
        } catch (error) {
            dispatch(SystemActions.showFormattedError("Unable to save new image whitelist entry", error));
            dispatch(PreferenceActions.setSavingWhitelist(false));
        }
        setNewWhitelist("");
    }

    async function onConfirmRemoveWhitelist(confirm: boolean) {
        if (confirm) {
            dispatch(PreferenceActions.setSavingWhitelist(true));
            try {
                const response = await performMail3RestCall<ImageWhitelistDto>(`Whitelist/Remove/${confirmRemoveWhitelist.type}`, confirmRemoveWhitelist.text);
                dispatch(PreferenceActions.updateWhitelist(response));
            } catch (error) {
                dispatch(SystemActions.showFormattedError("Unable to remove image whitelist entry", error));
                dispatch(PreferenceActions.setSavingWhitelist(false));
            }
        }
        setConfirmRemoveWhitelistOpen(false);
    }

    function onRemoveWhitelist(type: ImageWhitelistType, text: string) {
        setConfirmRemoveWhitelist({type, text});
        setConfirmRemoveWhitelistOpen(true);
    }

    function renderWhitelist(items: string[], type: ImageWhitelistType) {
        return items
            .filter(a => newWhitelist === "" || a.includes(newWhitelist.toLowerCase()))
            .map(a => <WhitelistEntryDiv key={a} className="pointer selectable-2" onClick={() => onRemoveWhitelist(type, a)}><span className="type">{type}</span><span>{a}</span></WhitelistEntryDiv>)
    }

    return (
        <Card className="preferences-section">
            <CardHeader title="Image Whitelist"/>
            <CardContent>
                <StackPanel align="end">
                    <TextField label="New Whitelist"
                               value={newWhitelist}
                               fullWidth
                               onChange={e => setNewWhitelist(e.target.value)}
                               disabled={isSavingWhitelist}/>

                    {isSavingWhitelist ? <SmallLoadingIndicator/> : null}
                </StackPanel>

                <div>&nbsp;</div>

                <Button variant="contained" color="primary"
                        onClick={() => onSaveNewWhitelist("StartsWith")}
                        disabled={isSavingWhitelist || !newWhitelist}>Starts With</Button>

                <Button variant="contained" color="primary"
                        onClick={() => onSaveNewWhitelist("Includes")}
                        disabled={isSavingWhitelist || !newWhitelist}>Includes</Button>

                <Button variant="contained" color="primary"
                        onClick={() => onSaveNewWhitelist("Regex")}
                        disabled={isSavingWhitelist || !newWhitelist}>Regex</Button>

                <div className="alias-list">
                    {renderWhitelist(whitelists.startsWith, "StartsWith")}
                    {renderWhitelist(whitelists.includes, "Includes")}
                    {renderWhitelist(whitelists.regex, "Regex")}
                </div>
            </CardContent>

            <ConfirmDialog title="Remove Whitelist"
                           message={`Are you sure you want to remove the whitelisted image '${confirmRemoveWhitelist.text}'?`}
                           open={isConfirmRemoveWhitelistOpen}
                           onConfirm={onConfirmRemoveWhitelist}
                           confirmColour="error"/>
        </Card>
    );
}