import {FC, useState} from "react";
import {StackPanel} from "../panels/StackPanel";
import {Button} from "@mui/material";
import ConfirmDialog from "../common/ConfirmDialog";
import {SystemActions} from "../../actions/SystemActions";
import {DB} from "../../db/DbManager";
import {useAppDispatch} from "../../hooks/ReduxHooks";
import {Permission} from "../common/Permission";
import {PgpKey, TemporaryKeyId} from "../../domain/PgpKey";
import {PgpKeyService} from "../../services/system/PgpKeyService";
import {downloadFileFromMemory} from "../../util/FileUtil";

export const PgpKeyActionsView: FC<{ pgpKey: PgpKey }> = ({pgpKey}) => {
    const [isConfirmDelete, setConfirmDelete] = useState<boolean>(false);
    const [isConfirmSign, setConfirmSign] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    async function onImportKey() {
        return PgpKeyService.importKey(pgpKey, dispatch);
    }

    function onBeginDeleteKey() {
        setConfirmDelete(true);
    }

    async function onConfirmDelete(confirmed: boolean) {
        if (confirmed) {
            await PgpKeyService.deleteKey(pgpKey.Id, dispatch);
        }
        setConfirmDelete(false);
    }

    function onBeginSignKey() {
        setConfirmSign(true);
    }

    function onConfirmSign(confirmed: boolean) {
        // TODO: sign the key !!
        if (confirmed) {
            dispatch(SystemActions.showWarning("Not implemented yet"));
        }
        setConfirmSign(false);
    }

    async function onDownloadKey() {
        if (!pgpKey) return;
        try {
            const dbKey = pgpKey.isPrivate ? await DB.getSecretPgpKey(pgpKey.Id) : await DB.getPgpKey(pgpKey.Id);
            if (dbKey) {
                const {key} = pgpKey;

                const fileName = `${key.users[0].userId?.userid.trim() || key.getKeyId().toHex()}${pgpKey.isPrivate ? "-private" : ""}.asc`
                    .replaceAll(/[ <>]/g, "_");

                downloadFileFromMemory(fileName, dbKey.Key);
            }
        } catch (e: any) {
            dispatch(SystemActions.showFormattedError("Unable to download key", e));
        }
    }

    const isTempKey = pgpKey.Id === TemporaryKeyId;

    return (
        <StackPanel orientation="horizontal"
                    className="panel">
            {isTempKey && <Button variant="contained" color="primary" onClick={onImportKey}>Import</Button>}
            {!isTempKey && <Button variant="contained" color="primary" onClick={onBeginSignKey}>Sign</Button>}
            {!isTempKey && <Button variant="contained" color="inherit" onClick={onDownloadKey}>Download</Button>}

            <Permission requiredRole={"admin"}>
                {!isTempKey && !pgpKey.isPrivate && <Button variant="contained" color="secondary" onClick={onBeginDeleteKey}>Delete</Button>}

                <ConfirmDialog title="Delete Key"
                               message={"Are you sure you want to DELETE the key with id " + pgpKey.keyHexId + " ?"}
                               open={isConfirmDelete}
                               onConfirm={onConfirmDelete}
                               confirmColour="error"/>
            </Permission>

            <ConfirmDialog title="Sign Key"
                           message={"Are you sure you want to SIGN the key with id " + pgpKey.keyHexId + " ?"}
                           open={isConfirmSign}
                           onConfirm={onConfirmSign}/>
        </StackPanel>
    );
};
