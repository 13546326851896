import { Dispatch, AnyAction } from "redux";
import { AllActionTypes } from "../actions/AllActionTypes";

export type RectActionFunction = (dispatch: Dispatch) => AllActionTypes;
export type RestAction = AllActionTypes | RectActionFunction;

export type ContextMenuEvent<T> = React.MouseEvent<T, MouseEvent>;

export interface MousePosition {
    x: number | null;
    y: number | null;
}

export function thunkDispatch(dispatch: Dispatch, action: RestAction) {
    dispatch((action as unknown) as AnyAction);
}

export function dispatchPromise(dispatch: Dispatch, action: (dispatch: Dispatch) => Promise<void>) {
    dispatch((action as unknown) as AnyAction);
}

export function getPointerId(e: React.MouseEvent): number {
    // @ts-ignore
    return e.nativeEvent.pointerId;
}
