import {AnyAction, Dispatch} from "redux";
import {RestAction, thunkDispatch} from "./TypeHelper";
import {DateTime} from "luxon";
import {SystemActions} from "../actions/SystemActions";
import {ContentType} from "./ContentTypes";
import {setCookie} from "./CommonCookies";

export const ErrorCode_NotAuthenticated = 4;

export function dispatchRestCall(dispatch: Dispatch, url: string, payload: any,
                                 beginAction: () => RestAction,
                                 successAction: (response: any) => RestAction,
                                 failAction: (response: Error) => RestAction) {
    dispatch((performRestCall(url, payload, beginAction, successAction, failAction) as unknown) as AnyAction);
}

export function performRestCallDirectly<T>(action: string, payload: any): Promise<T> {
    return performRawRestCall("/Mail3/WebService?a=" + action, payload);
}

export function performMail3RestCall<T>(action: string, payload: any): Promise<T> {
    return performRawRestCall("/Mail3/" + action, payload);
}

export class WebError {
    public readonly message: string;
    public readonly code: number;

    constructor(message: string, code: number) {
        this.message = message;
        this.code = code;
    }
}

export class AuthenticationError {
    public readonly message: string;
    
    constructor(message: string) {
        this.message = message;
    }
}

export async function performRawRestCall<T>(url: string, payload: any, method = "POST"): Promise<T> {
    const body = JSON.stringify(payload);

    const options: RequestInit = {
        body,
        method: method,
        redirect: "follow",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            "Accepts": "application/json",
        },
    };

    const response = await fetch(url, options);

    let data: any = null;
    try {
        data = await response.json();
    } catch (e) {
        throw new Error(response.status + ": " + response.statusText);
    }
    if (data && data.ErrorCode) {
        switch (data.ErrorCode) {
            case 4:
                throw new AuthenticationError(data.Error);
        }
    }
    if (data && data.Error) {
        if (data.ErrorCode) {
            throw new WebError(data.Error, data.ErrorCode);
        }
        throw new Error(data.Error, {cause: data.ErrorCode});
    }
    if (data && data.errors && data.title) {
        throw new Error(data.title);
    }
    return data;
}

export function performRestCall(action: string, payload: any,
                                beginAction: () => RestAction,
                                successAction: (response: any) => RestAction,
                                failAction: (response: Error) => RestAction) {

    return performRestCall_Internal("/Mail3/WebService?a=" + action, payload, beginAction, successAction, failAction);
}

function convertResponseToError(response: Response): Error {
    switch (response.status) {
        case 502:
            return new Error("server temporarily unavailable");
        case 504:
            return new Error("connection timed out");
        case 400:
            return new Error("badly formatted request");
        case 404:
            return new Error("not found");
    }
    return new Error(response.statusText || ("unknown error code: " + response.status));
}

function performRestCall_Internal(url: string, payload: any,
                                  beginAction: () => RestAction,
                                  successAction: (response: any) => RestAction,
                                  failAction: (response: Error) => RestAction) {

    return async (dispatch: Dispatch) => {
        thunkDispatch(dispatch, beginAction());

        try {
            const body = JSON.stringify(payload);

            const options: RequestInit = {
                body,
                method: "POST",
                redirect: "follow",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                    "Accepts": "application/json",
                }
            };

            const response = await fetch(url, options);
            if (response.status === 401 && url.indexOf("PerformFullLogin") === -1) {
                SystemActions.logout(dispatch);
            } else if (response.status === 200) {
                const data = await response.json();

                if (data.Cookies) {
                    for (const cookie of data.Cookies) {
                        setCookie(cookie.Name, cookie.Value, {
                            path: '/',
                            sameSite: "strict",
                            secure: true,
                            expires: DateTime.local().plus({months: 1}).toJSDate()
                        });
                    }
                }
                if (data.ErrorCode === ErrorCode_NotAuthenticated && url.indexOf("PerformFullLogin") === -1) {
                    SystemActions.logout(dispatch);
                } else if (data.Error) {
                    thunkDispatch(dispatch, failAction(new Error(data.Error)));
                } else {
                    thunkDispatch(dispatch, successAction(data));
                }

            } else {
                thunkDispatch(dispatch, failAction(convertResponseToError(response)));
            }

        } catch (err: any) {
            if (err.message && err.message.indexOf("NetworkError when attempting to fetch resource") > -1) {
                thunkDispatch(dispatch, failAction(new Error("Please check your internet connection")));
            } else {
                thunkDispatch(dispatch, failAction(err));
            }
        }
    }
}

export function isSecureConnection() {
    return window.location.protocol === "https:";
}

export async function sendFormData(url: string, formData: FormData): Promise<any> {
    const body = formData;

    const options: RequestInit = {
        body,
        method: "POST",
        redirect: "follow",
        credentials: "same-origin",
        headers: {
            "Accepts": "application/json",
        }
    };

    const response = await fetch(url, options);

    if (response.status === 200) {
        const data = await response.json();

        if (data && data.error) {
            throw new Error(data.error);
        }
        return data;
    } else {
        throw convertResponseToError(response);
    }
}

export async function performFetch(url: string, payload?: any) {

    const options: RequestInit = {
        body: payload ? JSON.stringify(payload) : undefined,
        method: payload ? "POST" : "GET",
        redirect: "follow",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            "Accepts": "application/json",
        }
    };

    const response = await fetch(url, options);
    if (response.status === 200) {
        const contentType = response.headers.get("content-type") || ContentType.Json;

        if (contentType === ContentType.BinaryStream) {
            return response.blob();
        }

        const data = await response.json();
        if (data && data.Error) {
            throw new Error(data.Error);
        }

        return data;
    } else {
        throw convertResponseToError(response);
    }
}
