import {FC, PropsWithChildren} from "react";
import {useAppSelector} from "../../hooks/ReduxHooks";
import {PermissionRole} from "../../domain/PermissionRoles";

export interface PermissionProps {
    requiredRole: PermissionRole;
}

export const Permission: FC<PermissionProps & PropsWithChildren> = ({requiredRole, children}) => {
    const roles = useAppSelector(({system}) => system.roles);

    if (roles.some(r => r === requiredRole)) {
        return <>{children}</>
    }
    return null;
};
