import React, {FC} from "react";
import {TextField} from "@mui/material";

interface ListTextFieldProps {
    label: string;
    values: string[] | null;
    type: string;
    onChange: (newValues: string[]) => void;
}

export const ListTextField: FC<ListTextFieldProps> = (props) => {
    const {values} = props;
    if (values === null) return null;
    return (<>
            {values.map((value, i) => (
                <TextField key={i} label={props.label + " " + (i + 1)} value={value} type={props.type} fullWidth
                           onChange={v => {
                               const newValues = [...values];
                               newValues.splice(i, 1, v.target.value);
                               props.onChange(newValues);
                           }}/>
            ))}
        </>
    );
}
