import HandshakeDto from "../services/messages/HandshakeDto";
import {FileViewModeType} from "../domain/FileViewModeType";
import {FileFolderType} from "../domain/FileFolderType";
import {blankImageWhitelist} from "../redux/PreferenceActions";
import {ContactViewModeType} from "../domain/ContactViewModeType";
import SearchOptions from "../domain/SearchOptions";
import {defaultEmailViewOptions, EmailViewOptions} from "../domain/EmailViewOptions";
import ComposeOptions from "../domain/ComposeOptions";

export default class Preference<T> {
    private readonly key: string;
    private readonly defaultValue: T | null;

    constructor(namespace: string, name: string, defaultValue: T | null = null) {
        this.key = namespace + "_" + name;
        this.defaultValue = defaultValue;
    }

    get(): T {
        const stringValue = localStorage.getItem(this.key);
        return stringValue ? JSON.parse(stringValue) : this.defaultValue;
    }

    set(value: T) {
        if (!value) {
            localStorage.removeItem(this.key);
        } else {
            const stringValue = JSON.stringify(value);
            localStorage.setItem(this.key, stringValue);
        }
    }
}

export const ExpandedFolderIdPref = new Preference<number[]>("folder", "expanded", []);
export const FolderTreeSizePref = new Preference<number>("folder", "size", 300);

export const HandshakePref = new Preference<HandshakeDto>("system", "handshake", {
    ServerId: 1,
    AvailableAddresses: [],
    DefaultAddress: "",
    DisplayName: "",
    Roles: [],
    ApiVersion: 0,
    ImageWhitelist: blankImageWhitelist,
});

export const PreferPlainTextEmailsPref = new Preference<boolean>("email", "plain", false);
export const EmailViewOptionsPref = new Preference<EmailViewOptions>("email", "viewMode", defaultEmailViewOptions);

export const FileViewModePref = new Preference<FileViewModeType>("files", "viewMode", "icons");

export const FileFolderTypePref = new Preference<FileFolderType>("files", "folderType", "attachments");

export const ContactViewModePref = new Preference<ContactViewModeType>("contacts", "viewMode", "grid");

export const DefaultSearchOptions: SearchOptions ={
    IncludeAllMailboxes: false,
    IncludeArchive: true,
    IncludeDeleted: false,
    IncludeSentItems: true,
    IncludeSpam: false,
};

export const SearchOptionsPref = new Preference<SearchOptions>("search", "options", DefaultSearchOptions);

export const DefaultComposeOptions: ComposeOptions={
    showCC: false,
    showBCC: false,
    showSender: false,
};

export const ComposeOptionsPref = new Preference<ComposeOptions>("compose", "options", DefaultComposeOptions);
