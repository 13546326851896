import React, {FC} from "react";
import {AttachmentContentDto} from "../../domain/AttachmentContentDto";
import {applyEmailContentSafetySettings} from "../../util/SafetyTransformer";
import {LoadingIndicator} from "../common/LoadingIndicator";
import {Centre} from "../Centre";
import {ContentType} from "../../util/ContentTypes";
import {useAppSelector} from "../../hooks/ReduxHooks";

export interface AttachmentContentViewProps {
    content: AttachmentContentDto;
}

export const AttachmentContentView: FC<AttachmentContentViewProps> = ({content}) => {

    const darkTheme = useAppSelector(({system}) => system.theme === "dark");
    const whitelistedImages = useAppSelector(s => s.preferenceState.imageWhitelist);

    switch (content.ContentType) {
        case "loading":
            return <Centre><LoadingIndicator/></Centre>;

        case ContentType.PlainText:
            return <div className="email-content simple-content">
                <pre className="text-attachment">{content.Content}</pre>
            </div>;

        case ContentType.HtmlText: {
            if (content.IsSafeHtml) {
                return <div dangerouslySetInnerHTML={({__html: content.Content})}/>
            } else {
                const safetyResult = applyEmailContentSafetySettings(0, content.Content, true, {
                    applySafety: true,
                    applyJunkSafety: false,
                    darkTheme,
                    mobile: false,
                    containerWidth: 600,    // TODO: for non-mobile, find the width of the container
                    whitelistedImages,
                });
                return <div className="email-content simple-content" dangerouslySetInnerHTML={({__html: safetyResult.safeContent})}/>
            }
        }

        default:
            return <div>Unsupported content type: {content.ContentType}</div>;
    }
}