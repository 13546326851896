import {AnyAction, Dispatch, MiddlewareAPI} from "redux";

import {AppState, initialAppState} from "./AppState";

import folderReducer from "./reducers/FolderReducer";
import itemContentReducer from "./reducers/ItemsReducer";
import systemReducer from "./reducers/SystemReducer";
import composeEmailReducer from "./reducers/ComposeEmailReducer";
import {systemStatusReducer} from "./reducers/SystemStatusReducer";
import {contactsSlice} from "./redux/ContactSlice";
import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import {systemLogSlice} from "./redux/SystemLogSlice";
import {syncSlice} from "./redux/SyncSlice";
import {accountsSlice} from "./redux/AccountsSlice";
import {calendarSlice} from "./redux/CalendarSlice";
import {usersSlice} from "./redux/UsersSlice";
import {rulesSlice} from "./redux/RulesSlice";
import {filesSlice} from "./redux/ProtectedFilesSlice";
import {notesSlice} from "./redux/NotesSlice";
import {attachmentFilesSlice} from "./redux/AttachmentFilesSlice";
import {PreferenceActions} from "./redux/PreferencesSlice";
import {photosSlice} from "./redux/PhotosSlice";

// See here:
// https://redux.js.org/recipes/usage-with-typescript

const asyncMiddleware = (store: MiddlewareAPI<Dispatch, AppState>) => (next: Dispatch) => (action: any) => {
    try {
        if (typeof action.then !== "undefined") {
            action.then((result: any) => {
                if (result) {
                    console.log(result);
                    next(result);
                }
            });
        } else {
            return next(action);
        }
    } catch (err) {
        console.error('An error occurred', err, store.getState())
        throw err
    }
}

function rootReducer(state = initialAppState, action: AnyAction): AppState {
    return {
        system: systemReducer(state.system, action),
        preferenceState: PreferenceActions.preferencesSlice.reducer(state.preferenceState, action),

        folderState: folderReducer(state.folderState, action),
        contactState: contactsSlice.reducer(state.contactState, action),
        accountsState: accountsSlice.reducer(state.accountsState, action),
        sync: syncSlice.reducer(state.sync, action),
        compose: composeEmailReducer(state.compose, state.accountsState, action),
        calendarState: calendarSlice.reducer(state.calendarState, action),

        itemState: itemContentReducer(state.itemState, state.calendarState, state.folderState, action),

        sysStatus: systemStatusReducer(state.sysStatus, action),

        sysLogs: systemLogSlice.reducer(state.sysLogs, action),

        notesState: notesSlice.reducer(state.notesState, action),

        usersState: usersSlice.reducer(state.usersState, action),

        rulesState: rulesSlice.reducer(state.rulesState, action),

        filesState: filesSlice.reducer(state.filesState, action),

        attachmentsState: attachmentFilesSlice.reducer(state.attachmentsState, action),
        
        photosState: photosSlice.reducer(state.photosState, action),
    }
}

export function configureAppStore_DoNotCall() {
    return configureStore({
        reducer: rootReducer,
        middleware: [asyncMiddleware, ...getDefaultMiddleware({serializableCheck: false, immutableCheck: false})],
    });

    // @ts-ignore
    // if (process.env.NODE_ENV !== 'production' && module.hot) {
    //   // @ts-ignore
    //   module.hot.accept('./reducers/*', 
    //       () => s.replaceReducer(rootReducer))
    // }
    // return s;
}

const appStore = configureAppStore_DoNotCall();

export type AppStore = typeof appStore;

export type AppDispatch = typeof appStore.dispatch;

export function getAppStore_DoNotCall() {
    return appStore;
}
