import {DropDownMenuItem} from "../toolbar/DropDownMenu";
import React, {RefObject, useEffect, useState} from "react";
import {Divider, Menu, MenuItem} from "@mui/material";
import {ContextMenuEvent, MousePosition} from "../../util/TypeHelper";

interface ContextMenuProps {
    menuItems: (DropDownMenuItem | string)[];
    elementRef: RefObject<HTMLElement>;
    onClose?: () => void;
    onOpen?: (event: MouseEvent) => void;
}

export const ContextMenu: React.FC<ContextMenuProps> = ({menuItems, elementRef, onOpen, onClose}) => {
    const [contextPosition, setContextPosition] = useState<MousePosition>({x: null, y: null});

    useEffect(() => {
            const ref = elementRef.current;
            if (ref) {
                ref.oncontextmenu = (event) => {
                    event.preventDefault();

                    if (onOpen) {
                        onOpen(event);
                    }

                    setContextPosition({x: event.clientX, y: event.clientY});
                }
            }
        },
        [elementRef, onOpen]);

    function handleCloseContextMenu() {
        setContextPosition({x: null, y: null});
        if (onClose) onClose();
    }

    function handleContextMenuItemClick(event: ContextMenuEvent<HTMLLIElement>, menuItem: DropDownMenuItem) {
        handleCloseContextMenu();

        menuItem.onClick();
    }

    return (
        <Menu
            className="context-menu"
            keepMounted
            open={contextPosition.x !== null && menuItems.length > 0}
            onClose={handleCloseContextMenu}
            anchorReference="anchorPosition"
            BackdropProps={{className: "menu-backdrop"}}
            transitionDuration={100}
            anchorPosition={
                contextPosition.x !== null && contextPosition.y !== null
                    ? {top: contextPosition.y, left: contextPosition.x}
                    : undefined
            }>
            {
                menuItems
                    .filter(mi => mi && (typeof mi === "string" || !mi.hide))
                    .map(menuItem => (
                        (typeof menuItem === "string")
                            ? (<Divider key={menuItem}/>) :
                            (<MenuItem key={menuItem.name}
                                       onClick={(event) => handleContextMenuItemClick(event, menuItem)}
                                       disabled={menuItem.disabled}>
                                {menuItem.icon}
                                <span className="popup-text">{menuItem.name}</span>
                            </MenuItem>)
                    ))
            }
        </Menu>
    );
};
