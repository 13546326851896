import {Alert, Snackbar} from "@mui/material";
import React from "react";
import {SystemActions} from "../actions/SystemActions";
import {useAppDispatch, useAppSelector} from "../hooks/ReduxHooks";

export const SnackbarView = () => {

    const dispatch = useAppDispatch();

    const onHideError = () => dispatch(SystemActions.hideError());
    const onHideNotification = () => dispatch(SystemActions.hideNotification());
    const onHideWarning = () => dispatch(SystemActions.hideWarning());

    const warning = useAppSelector(s => s.system.warning);
    const error = useAppSelector(s => s.system.error);
    const notification = useAppSelector(s => s.system.notification);

    return <>
        <Snackbar open={error.show} onClose={onHideError}>
            <Alert onClose={onHideError}
                   severity="error">{error.message}</Alert>
        </Snackbar>
        <Snackbar open={notification.show} onClose={onHideNotification} autoHideDuration={50000}>
            <Alert onClose={onHideNotification}
                   severity="success">{notification.message}</Alert>
        </Snackbar>
        <Snackbar open={warning.show} onClose={onHideWarning}>
            <Alert onClose={onHideWarning} 
                   severity="warning">{warning.message}</Alert>
        </Snackbar>
    </>
}