import {AttachmentFilesListView} from "./AttachmentFilesListView";
import {WrapPanel} from "../panels/WrapPanel";
import {StackPanel} from "../panels/StackPanel";
import {ContextMenu} from "../common/ContextMenu";
import {DropDownMenuItem} from "../toolbar/DropDownMenu";
import React, {createRef} from "react";
import GetApp from "@mui/icons-material/GetApp";
import Email from "@mui/icons-material/Email";
import {EmailActions} from "../../actions/EmailActions";
import {useAppStore} from "../../hooks/ReduxHooks";
import {EmailLocation} from "../../locations/EmailLocation";
import {AttachmentInfiniteLoader} from "./AttachmentInfiniteLoader";

export const AttachmentFilesView = () => {

    const menuRef = createRef<HTMLDivElement>();

    const store = useAppStore();

    const menuItems: DropDownMenuItem[] = [
        {
            name: "Download", icon: <GetApp/>, onClick: () => {
                const {selectedAttachment} = store.getState().attachmentsState;
                if (selectedAttachment) {
                    EmailActions.downloadAttachment(selectedAttachment.AttachmentId, selectedAttachment.Name);
                }
            }
        },
        {
            name: "Open Email", icon: <Email/>, onClick: () => {
                const {selectedAttachment} = store.getState().attachmentsState;
                if (selectedAttachment) {
                    EmailLocation.openEmailById(selectedAttachment.EmailId, selectedAttachment.EmailFolderId);
                }
            }
        },
    ];

    return (
        <>
            <StackPanel orientation="vertical" ref={menuRef}>
                <WrapPanel>
                    <AttachmentFilesListView/>
                </WrapPanel>
                <AttachmentInfiniteLoader/>
            </StackPanel>

            <ContextMenu menuItems={menuItems} elementRef={menuRef}/>
        </>
    );
}
