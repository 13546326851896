import {Backdrop, Box, SpeedDial, SpeedDialAction, SpeedDialIcon} from "@mui/material";
import React from "react";
import HttpsIcon from "@mui/icons-material/Https";
import {AccountActionHelper} from "../../actions/AccountActionHelper";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PersonIcon from "@mui/icons-material/Person";
import {ContentLocation} from "../../locations/ContentLocation";
import EmailIcon from "@mui/icons-material/Email";
import {EmailLocation} from "../../locations/EmailLocation";
import {useAppStore} from "../../hooks/ReduxHooks";
import {createDefaultCalendarEntry} from "../../services/calendar/CalendarFactory";
import {DateTime} from "luxon";
import {ItemActions} from "../../actions/ItemActions";

export const CreateItemMobileMenu = () => {
    const store = useAppStore();

    const [createItemOpen, setCreateItemOpen] = React.useState(false);
    const handleOpenCreateItemMenu = () => setCreateItemOpen(true);
    const handleCloseCreateItemMenu = () => setCreateItemOpen(false);

    function onCreateNewCalendarEntry() {
        const entry = createDefaultCalendarEntry(DateTime.local());
        console.log("Creating entry", entry);
        store.dispatch(ItemActions.createCalendarEntry(entry));
        ContentLocation.editCalendar(entry.calendar.Uid, entry.instanceId);
    }

    const createItemActions = [
        {
            icon: <HttpsIcon/>,
            name: 'Account',
            onClick: () => AccountActionHelper.createNewAccount()(store.dispatch, store.getState),
        },
        {
            icon: <CalendarTodayIcon/>,
            name: 'Event',
            onClick: onCreateNewCalendarEntry,
        },
        {
            icon: <PersonIcon/>,
            name: 'Contact',
            onClick: ContentLocation.createContact,
        },
        {
            icon: <EmailIcon/>,
            name: 'Email',
            onClick: EmailLocation.composeNewEmail,
        },
    ];

    return (<Box sx={{position: "absolute", right: 5, bottom: 45}}>
        <Backdrop open={createItemOpen}/>
        <SpeedDial
            ariaLabel=""
            icon={<SpeedDialIcon/>}
            onClose={handleCloseCreateItemMenu}
            onOpen={handleOpenCreateItemMenu}
            open={createItemOpen}
        >
            {createItemActions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    tooltipOpen
                    onClick={() => {
                        action.onClick();
                        handleCloseCreateItemMenu();
                    }}
                />
            ))}
        </SpeedDial>
    </Box>);
}