import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {initialRulesState} from "../state/RulesState";
import {EmailRuleDto} from "../services/rules/dto/EmailRuleDto";

function orderRules(rules: EmailRuleDto[]) {
    rules.sort((a, b) => a.Rank - b.Rank);
}

export const rulesSlice = createSlice({
    name: "rules",
    initialState: initialRulesState,
    reducers: {
        setRulesList(state, {payload: rules}: PayloadAction<EmailRuleDto[]>) {
            orderRules(rules);
            state.rules = rules;
        },
        deleteRule(state, {payload: ruleId}: PayloadAction<number>) {
            state.rules = state.rules.filter(r => r.Id !== ruleId);
        },
        saveRule(state, {payload: rule}: PayloadAction<EmailRuleDto>) {
            const index = state.rules.findIndex(r => r.Id === rule.Id);
            if (index !== undefined) {
                state.rules[index] = rule;
            } else {
                state.rules.push(rule);
            }
            orderRules(state.rules);
        },
    }
});

export namespace RulesActions {
    export const {
        setRulesList,
        deleteRule,
        saveRule,
    } = rulesSlice.actions;

    export const actions = rulesSlice.actions;
}
