import {FileViewModeType} from "../domain/FileViewModeType";
import {FileFolderTypePref, FileViewModePref} from "../util/Preferences";
import {FileFolderType} from "../domain/FileFolderType";
import {blankImageWhitelist, ImageWhitelistDto} from "../redux/PreferenceActions";

export interface PreferenceState {
    aliases: string[];
    imageWhitelist: ImageWhitelistDto;
    
    isSavingAliases: boolean;
    isSavingWhitelist:boolean;

    viewMode: FileViewModeType;
    fileFolderType: FileFolderType;

    showingSearch: boolean;

    fetchingAvailableWords: boolean;
    availableWords: string[];
}

export const initialPreferenceState: PreferenceState = {
    aliases: [],
    imageWhitelist: blankImageWhitelist,
    isSavingAliases: false,
    isSavingWhitelist: false,

    viewMode: FileViewModePref.get(),
    fileFolderType: FileFolderTypePref.get(),

    showingSearch: false,

    fetchingAvailableWords: false,
    availableWords: [],
}
