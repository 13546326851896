import "./AccountItemView.scss";

import React, {FC, useState} from "react";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {Button, Chip, Fade, IconButton, Popper} from '@mui/material';
import {DecryptedGateKeeperAccount} from '../../domain/DecryptedGateKeeperAccount';
import {GateKeeperField} from '../../domain/GateKeeperField';
import {copyTextToClipboard} from '../../util/ClipboardUtil';
import {Notifier, SystemActions} from '../../actions/SystemActions';
import {EditButtons} from '../common/EditButtons';
import {AccountEditItemView} from "./AccountEditItemView";
import {cloneDeep} from 'lodash';
import {AccountActionHelper} from '../../actions/AccountActionHelper';
import {EmailActions} from '../../actions/EmailActions';
import ConfirmDialog from '../common/ConfirmDialog';
import {SmallLoadingIndicator} from '../common/SmallLoadingIndicator';
import {AccountImage} from './AccountImage';
import AccountHistoryView from './AccountHistoryView';
import {formatAccountField} from './AccountItemHelper';
import {HideContentButton} from '../common/HideContentButton';
import {dateTimeFromTicks, dateTimeToLocaleString, ticksFromDateTime} from "../../util/DateHelper";
import {DateTime} from "luxon";
import {AccountsActions} from "../../redux/AccountsSlice";
import {StackPanel} from "../panels/StackPanel";
import ShareIcon from '@mui/icons-material/Share';
import {findPgpKeyByHex} from "../../util/PGPUtil";
import {useAppDispatch, useAppSelector, useAppStore} from "../../hooks/ReduxHooks";
import {extractFriendlyNamesFromEmailAddresses} from "../../util/Formatters";
import classNames from "classnames";

export interface AccountFieldProps {
    field: GateKeeperField;
}

const RenderAccountField: FC<AccountFieldProps> = ({field}) => {
    const [cloaked, setCloaked] = useState<boolean>(true);
    const [value, setValue] = useState<string>("●●●●●●●●●●");

    const [copiedAnchorEl, setCopiedAnchorEl] = useState<HTMLElement>();
    const [showingCopiedPopover, setShowingCopiedPopover] = useState<boolean>(false);
    const [popoverMessage, setPopoverMessage] = useState("");

    const dispatch = useAppDispatch();

    const hideField = field.isProtected && field.type !== "MemorableWord";

    function onShowNotification(message: string) {
        setPopoverMessage(message);
        setShowingCopiedPopover(true);
        window.setTimeout(() => setShowingCopiedPopover(false), 1000);
    }

    const notifier: Notifier = {
        showNotification: onShowNotification,
        showError: error => dispatch(SystemActions.showError(error)),
    };

    if (hideField && cloaked) {
        if (value !== "●●●●●●●●●●") {
            setValue("●●●●●●●●●●");
        }
    } else if (value !== field.value) {
        setValue(field.value);
    }

    function toggleCloaking() {
        setCloaked(!cloaked);
    }

    function onCopyToClipboard(e: React.MouseEvent<HTMLElement>) {
        setCopiedAnchorEl(e.currentTarget);
        copyTextToClipboard(field.value, notifier);
    }

    return (
        <div className="field">
            <div className="field-data">
                <div className="name">{field.name}</div>
                <div className={classNames("value", field.type, {"primary pointer": hideField})}
                     onClick={toggleCloaking}>
                    {hideField && cloaked ? value : formatAccountField(field.type, value)}
                </div>
            </div>

            <IconButton onClick={(e) => onCopyToClipboard(e)}
                        color={"primary"}
                        title="Copy to clipboard"><FileCopyIcon/></IconButton>

            <Popper open={showingCopiedPopover}
                    anchorEl={copiedAnchorEl}
                    placement="right"
                    transition>
                {({TransitionProps}) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <div className="notification-request-banner copy-banner">{popoverMessage}</div>
                    </Fade>
                )}
            </Popper>
        </div>
    );
}

export interface AccountItemViewExtraProps {
    account: DecryptedGateKeeperAccount;
}

export const AccountItemView: FC<AccountItemViewExtraProps> = ({
                                                                   account,
                                                               }) => {

    const editAccount = useAppSelector(s => s.accountsState.editAccount);
    const accountHistory = useAppSelector(s => s.accountsState.accountHistory);

    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState<boolean>(false);

    const pgpKeys = useAppSelector(s => s.accountsState.pgpKeys);

    const dispatch = useAppDispatch();
    const appStore = useAppStore();

    async function onSaveAccount(account: DecryptedGateKeeperAccount) {
        await AccountActionHelper.saveAccount(dispatch, appStore.getState, account);
    }

    async function onShowAccountHistory(account: DecryptedGateKeeperAccount) {
        await AccountActionHelper.showAccountHistory(dispatch, appStore.getState, account);
    }

    async function onDeleteAccount(account: DecryptedGateKeeperAccount) {
        await AccountActionHelper.deleteAccount(dispatch, account);
    }

    function onSetEditing(flag: boolean) {
        if (flag) {
            dispatch(AccountsActions.setEditAccount({
                ...cloneDeep(account),
                id: -1,
                createTime: ticksFromDateTime(DateTime.local())
            }));
        } else {
            dispatch(AccountsActions.setEditAccount(null));
            if (account.fields.length === 0) {
                dispatch(EmailActions.clearItemContent());
            }
        }
    }

    function saveAccount() {
        if (editAccount) {
            onSaveAccount(editAccount);
            onSetEditing(false);
        }
    }

    function beginDeleteAccount() {
        setShowConfirmDeleteDialog(true);
    }

    function confirmDeleteAccount(confirmed: boolean) {
        if (confirmed) {
            onDeleteAccount(account);
        }
        setShowConfirmDeleteDialog(false);
    }

    if (accountHistory) {
        return (
            <div className="account-content-view edit-buttons-container">
                <AccountHistoryView/>
            </div>
        );
    }

    const shareChips = account.publicKeyShares
        .map(id => findPgpKeyByHex(id, pgpKeys)?.key.getUserIds()[0])
        .map(userId => extractFriendlyNamesFromEmailAddresses(userId)[0].Name)
        .map((name, i) => (<Chip key={i} label={name}/>));

    return (
        <div className="account-content-view edit-buttons-container">
            {editAccount
                ? (<AccountEditItemView/>)
                : (<>
                        <div className="account-item">
                            <div className="name">
                                <HideContentButton/>

                                {account.name}
                            </div>

                            {account.category === "Cards"
                                ? (<StackPanel align="middle" className="category-card"><AccountImage
                                    image={account.image}/></StackPanel>)
                                : (<StackPanel className="category">
                                    <span className={`group-glyph glyph-${account.category?.toLowerCase()}`}/>
                                    {account.category}
                                </StackPanel>)
                            }

                            {account.fields
                                .map((field, i) => (<RenderAccountField field={field} key={i}/>))}

                            <div className="field">
                                <div className="field-data">
                                    <div className="name">Modified Time</div>
                                    <div
                                        className="value">{dateTimeToLocaleString(dateTimeFromTicks(account.createTime))}</div>
                                </div>
                            </div>

                            {account.isOwner
                                ? (!!shareChips.length && <div className="field">
                                    <div className="field-data">
                                        <div className="name">Shared With</div>
                                        <StackPanel className="value"
                                                    middle><ShareIcon/>&nbsp;{shareChips}
                                        </StackPanel>
                                    </div>
                                </div>)
                                : <div className="field">
                                    <div>You don't own this account</div>
                                </div>}

                            {!account.decrypted ? <div className="field spinner"><SmallLoadingIndicator/></div> : null}
                        </div>
                    </>
                )}

            {account.isOwner &&
                <EditButtons
                    editing={!!editAccount}
                    onDelete={beginDeleteAccount}
                    onSave={saveAccount}
                    onSetEditing={onSetEditing}
                    disabled={!account.decrypted}
                    canSave={!!editAccount && editAccount.fields.length > 0}
                >
                    {editAccount ? null : <Button onClick={() => onShowAccountHistory(account)} variant="contained"
                                                  color="inherit">History</Button>}
                </EditButtons>
            }

            <ConfirmDialog
                title="Delete Account"
                message={`Are you sure you want to delete the account "${account.name}"?`}
                open={showConfirmDeleteDialog}
                onConfirm={confirmDeleteAccount}
                confirmColour="error"
            />

        </div>
    );
}
