import {useAppSelector} from "../../hooks/ReduxHooks";
import {FC, useEffect, useState} from "react";
import {Accordion, AccordionSummary} from "@mui/material";
import {PgpKeyGridRow} from "./PgpKeyListView";
import {StackPanel} from "../panels/StackPanel";
import {EnrichedPgpKey, EnrichedPgpSignature, EnrichedPgpUser} from "../../domain/pgp/EnrichedPgpKey";
import {enrichPgpKey} from "../../security/PgpKeyEnricher";
import {findPgpKey} from "../../util/PGPUtil";
import {PgpKeysLocation} from "../../locations/PgpKeysLocation";
import {PgpKeyActionsView} from "./PgpKeyActionsView";
import {Centre} from "../Centre";
import {HideContentButton} from "../common/HideContentButton";
import {PgpKey} from "../../domain/PgpKey";
import {ButtonPanel} from "../common/ButtonPanel";

function formatUserId(user: EnrichedPgpUser | null) {
    return <>
        <span>{user?.name || user?.userId || "Unknown User"}</span>
        &nbsp;
        <span>&lt;{user?.email || "?"}&gt;</span>
    </>;
}

interface PgpKeySignatureViewProps {
    enrichedKey: EnrichedPgpKey;
    signature: EnrichedPgpSignature;
}

const PgpKeySignatureView: FC<PgpKeySignatureViewProps> = ({signature, enrichedKey}) => {
    const allKeys = useAppSelector(({accountsState}) => accountsState.pgpKeys);

    const verifyResult = enrichedKey.verificationResults.find(v => v.userID === signature.user.userId?.userid && signature.issuerKeyId.equals(v.keyID));

    const signingKey = findPgpKey(signature.issuerKeyId, allKeys);

    return (
        <StackPanel orientation="horizontal"
                    fill={true}
                    className="signature selectable-2 pointer"
                    onClick={() => PgpKeysLocation.selectPgpKey(signature.issuerKeyId.toHex())}>
            <PgpKeyGridRow pgpKey={signingKey} keyId={signature.issuerKeyId} verification={verifyResult}/>
        </StackPanel>
    );
}

const RenderPgpKeyUser = (enrichedKey: EnrichedPgpKey, user: EnrichedPgpUser, index: number) => (
    <Accordion key={index} className="user">
        <AccordionSummary className="selectable">
            <div>
                <span className="group-glyph glyph-nene">&nbsp;</span>
                {formatUserId(user)}
            </div>
        </AccordionSummary>
        {user.certifications.map((signature, ci) => <PgpKeySignatureView key={ci} signature={signature} enrichedKey={enrichedKey}/>)}
    </Accordion>
);

const RenderTrustedBy: FC<{ trustedBy: string[] }> = ({trustedBy}) => (
    <div className="key-info"><span>Trusted by:</span>
        {trustedBy.length
            ? trustedBy.map((trust, i) => <div key={i} className="success">{trust}</div>)
            : <div className="error">Not trusted by any known keys</div>}
    </div>
);

export const PgpKeyItemView: FC<{ pgpKey: PgpKey }> = ({pgpKey}) => {
    const allKeys = useAppSelector(({accountsState}) => accountsState.pgpKeys);

    const [enrichedKey, setEnrichedKey] = useState<EnrichedPgpKey>();

    useEffect(() => {
            if (pgpKey && !pgpKey?.enrichedKey) {
                enrichPgpKey(pgpKey, allKeys)
                    .then(setEnrichedKey);
            }
        },
        [pgpKey, allKeys]);

    if (!enrichedKey) {
        return <Centre>Please select a key</Centre>;
    }

    const {primaryUser} = enrichedKey;

    const trustedBy = enrichedKey.verificationResults
        .filter(vr => vr.valid)
        .filter(vr => vr.userID === enrichedKey.primaryUser.userId)
        .filter(vr => !vr.keyID.equals(pgpKey.key.getKeyId()))
        .map(vr => findPgpKey(vr.keyID, allKeys)?.key.getUserIds()[0] || "<Unknown Key>");

    return (
        <ButtonPanel buttons={<PgpKeyActionsView pgpKey={pgpKey}/>} className="pgp-item-panel">

            <div className="title">{formatUserId(primaryUser)}</div>

            <HideContentButton/>

            {pgpKey.isPrivate
                ? <div className="key-info success">You have the private key for this key</div>
                : <RenderTrustedBy trustedBy={trustedBy}/>}

            {enrichedKey.verificationError && <div className="key-info error">{enrichedKey.verificationError}</div>}

            <div className="key-info fingerprint"><span>Fingerprint: </span>
                <div>{enrichedKey.fingerprint}</div>
            </div>

            {/* TODO: show sub key information */}
            {/*<div>&nbsp;</div>*/}
            {/*<div>Sub Keys: {enrichedKey.subKeys.length}</div>*/}
            {/*{enrichedKey.subKeys.map((subKey, i) =>*/}
            {/*    (<div key={i}>*/}
            {/*        /!*<span>Sub Key: {subKey.verified ? "verified" : "not verified"}</span>*!/*/}
            {/*        <span>{subKey.verified ? "Signed by: " + subKey.signedBy?.key.getUserIDs()[0] : ""}</span>*/}
            {/*        <span>Count: {findPgpKey(subKey.signatures[0].issuerKeyId, allKeys)?.key.getUserIDs()}</span>*/}
            {/*    </div>))*/}
            {/*}*/}
            {/*<div>&nbsp;</div>*/}

            {enrichedKey.users.map((user, i) => RenderPgpKeyUser(enrichedKey, user, i))}

        </ButtonPanel>
    );
};
