import {EmailHeader} from "../services/messages/EmailHeaderDto";
import RemoteOperationDto, {ManageEmailsRemoteOperationDto} from "../services/messages/RemoteOperationDto";
import {DB} from "../db/DbManager";
import DbDraftEmail from "../domain/DbDraftEmail";
import {
    BeginMoveEmailsAction,
    CLEAR_ITEM_CONTENT,
    ClearItemContentAction,
    DELETE_DRAFT_EMAILS,
    DRAFT_EMAIL_HEADERS_LOADED,
    DraftEmailHeadersLoadedAction,
    MOVE_EMAILS_BEGIN,
    REMOVE_GREYLISTED_EMAILS,
    REMOVE_REMOTE_OPERATIONS,
    RemoveGreylistedEmailsAction,
    RemoveRemoteOperationsAction,
    SELECT_ALL_EMAIL_HEADERS,
    SELECT_EMAIL_HEADERS,
    SELECT_OUTGOING_EMAILS,
    SelectAllEmailHeadersAction,
    SelectEmailHeadersAction,
    SelectOutgoingEmailsAction,
    SET_AUTO_ARCHIVE_DATE,
    SET_AUTO_ARCHIVE_DATE_ON_EMAIL,
    SET_CONTEXT_ATTACHMENT,
    SET_REMIND_ME_LATER_DATE,
    SET_REMIND_ME_LATER_DATE_ON_EMAIL,
    SetAutoArchiveDateAction,
    SetAutoArchiveDateOnEmailAction,
    SetContextAttachmentAction,
    SetRemindMeLaterDateAction,
    SetRemindMeLaterDateOnEmailAction,
    SHOW_ARCHIVE_SETTINGS_DIALOG,
    SHOW_ATTACHMENT_CONTENT,
    SHOW_EMAIL_CATEGORY_DIALOG,
    SHOW_EMAIL_CONTENT,
    SHOW_EMAIL_HEADERS,
    SHOW_IMAGE_ATTACHMENT,
    SHOW_INLINE_ATTACHMENT,
    SHOW_REMIND_ME_LATER_SETTINGS_DIALOG,
    ShowArchiveSettingsDialogAction,
    ShowAttachmentContentAction,
    ShowEmailCategoryDialogAction,
    ShowEmailContentAction,
    ShowEmailHeadersAction,
    ShowImageAttachmentAction,
    ShowInlineAttachmentAction,
    ShowRemindMeLaterSettingsDialogAction,
    STOP_SENDING_EMAILS_ASYNC,
    TOGGLE_EXPAND_CONTENT,
    ToggleExpandContentAction
} from "./EmailActionTypes";
import Folder from "../domain/Folder";
import {AttachmentDto} from "../domain/AttachmentDto";
import {SystemActions} from "./SystemActions";
import {NOOP_ACTION} from "./SystemActionTypes";
import {downloadFile, isImageContentType, isImageFile} from "../util/FileUtil";
import {Dispatch} from 'redux';
import {OutgoingEmailDto} from '../services/messages/OutgoingEmailDto';
import {performFetch, performRawRestCall, performRestCall} from '../util/HttpHelper';
import {StopSendingRequestDto} from '../services/messages/requests/StopSendingRequestDto';
import {StopSendingResponseDto} from '../services/messages/responses/StopSendingResponseDto';
import {BEGIN, ERROR, SUCCESS} from './AsyncHelper';
import {ReleaseGreylistedEmailsRequestDto} from '../services/messages/requests/ReleaseGreylistedEmailsRequestDto';
import {ReleaseGreylistedEmailsResponseDto} from '../services/messages/responses/ReleaseGreylistedEmailsResponseDto';
import {AppState} from '../AppState';
import {isJunkFolder} from "../reducers/FolderReducer";
import {AttachmentContentDto} from "../domain/AttachmentContentDto";
import {AddRemoteOperationPayload, SyncActions} from "../redux/SyncSlice";
import {PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "../AppStore";
import {RelativeDateTimeOption} from "../domain/RelativeDateTimeOption";
import {calculateDateFromRelativeDateOption, calculateDateRangeCategory} from "../util/DateHelper";

const REMOTE_OPERATION_BATCH_SIZE = 10;

let nextOperationId = new Date().getTime();

function nonDrafts(emailHeaders: EmailHeader[]) {
    return emailHeaders.filter(e => !e.IsDraft);
}

function drafts(emailHeaders: EmailHeader[]) {
    return emailHeaders.filter(e => e.IsDraft);
}

export class EmailActions {

    static toggleExpandContent(): ToggleExpandContentAction {
        return {type: TOGGLE_EXPAND_CONTENT};
    }

    static clearItemContent(): ClearItemContentAction {
        // ContentLocation.clearItemContent();
        return {type: CLEAR_ITEM_CONTENT};
    }

    static selectEmailHeaders(emails: EmailHeader[]): SelectEmailHeadersAction {
        return {type: SELECT_EMAIL_HEADERS, emails};
    }

    static selectAllEmails(select: boolean): SelectAllEmailHeadersAction {
        return {type: SELECT_ALL_EMAIL_HEADERS, select};
    }

    static selectOutgoingEmails(outgoingEmails: OutgoingEmailDto[]): SelectOutgoingEmailsAction {
        return {type: SELECT_OUTGOING_EMAILS, outgoingEmails};
    }

    static showEmailHeaders(emailHeaders: EmailHeader[], allEmailHeadersLoaded: boolean = true): ShowEmailHeadersAction {
        return {type: SHOW_EMAIL_HEADERS, emailHeaders, allEmailHeadersLoaded};
    }

    static stopSendingOutgoingEmails(outgoingEmails: OutgoingEmailDto[]) {
        const uids = outgoingEmails.map(o => o.Uid);
        const request: StopSendingRequestDto = {Uids: uids};

        return performRestCall("StopSending", request,
            () => ({type: STOP_SENDING_EMAILS_ASYNC, action: BEGIN, outgoingEmails}),
            (response: StopSendingResponseDto) => ({
                type: STOP_SENDING_EMAILS_ASYNC,
                action: SUCCESS,
                uids: response.Uids
            }),
            error => ({type: STOP_SENDING_EMAILS_ASYNC, action: ERROR, error}));
    }

    static convertDraftToEmailHeader(d: DbDraftEmail): EmailHeader {
        return {
            Id: d.Id,
            FolderId: 0,
            LocalId: d.Uid,
            Read: 0,
            Flagged: 0,
            From: d.From,
            To: d.To,
            CC: d.Cc,
            Subject: d.Subject,
            AttachmentCount: d.Attachments.length,
            LastVerb: null,
            PendingSecureCount: null,
            Category: null,
            Date: d.ModifiedDate || 0,
            DisplayDate: d.ModifiedDate || 0,
            Defaulted: false,
            Importance: "Normal",
            ThreadId: null,
            CertificateId: null,
            SpfMatchStatus: "",
            SmimeStatus: "",
            PgpStatus: "",
            DomainKeyMatchStatus: "",
            SendCount: null,
            MessageClass: "",
            BodySample: "",

            ShowTrustShield: false,

            Reminder: undefined,

            IsDraft: true,
            IsDummyLoadingRow: false,
            DateRangeCategory: calculateDateRangeCategory(d.ModifiedDate),
        };
    }

    static draftEmailsLoaded(draftEmailHeaders: EmailHeader[]): DraftEmailHeadersLoadedAction {
        return {type: DRAFT_EMAIL_HEADERS_LOADED, draftEmailHeaders};
    }

    static async addRemoteOperation(partialOperation: Partial<RemoteOperationDto>, state?: any): Promise<PayloadAction<AddRemoteOperationPayload>> {
        const operation = {
            Id: nextOperationId++,
            ...partialOperation,
        };
        if (DB) {
            if (operation.AddAttachment) {
                const operationCopy = {...operation, AddAttachment: {...operation.AddAttachment}};
                delete operationCopy.AddAttachment.Callback;
                await DB.addOperation(operationCopy);
            } else {
                await DB.addOperation(operation);
            }
        }
        return SyncActions.addRemoteOperation({operation, state});
    }

    static removeRemoteOperation(predicate: (operation: RemoteOperationDto) => boolean) {
        return async (dispatch: Dispatch) => {
            if (DB) {
                const operations = await DB.getAllOperations();
                const operationsToRemove: RemoteOperationDto[] = [];
                for (const operation of operations) {
                    if (predicate(operation)) {
                        await DB.deleteRemoteOperation(operation);
                        operationsToRemove.push(operation);
                    }
                }
                if (operationsToRemove.length > 0) {
                    const action: RemoveRemoteOperationsAction = {type: REMOVE_REMOTE_OPERATIONS, operationsToRemove};
                    dispatch(action);
                }
            }
        };
    }

    static markEmailsAsRead(dispatch: AppDispatch, emails: EmailHeader[], read: boolean) {
        const isRead = read ? 1 : 0;
        const emailIds = nonDrafts(emails).filter(e => e.Read !== isRead).map(e => e.Id);
        return EmailActions.markEmailIdsAsRead(dispatch, emailIds, read);
    }

    static async batchAddRemoteOperations<T>(dispatch: Dispatch, ids: T[], factoryFunc: (idBatch: T[]) => Partial<RemoteOperationDto>) {

        if (ids.length < REMOTE_OPERATION_BATCH_SIZE) {
            dispatch(await EmailActions.addRemoteOperation(factoryFunc(ids)));
            return;
        }

        let batch: T[] = [];
        for (const id of ids) {
            if (batch.length === REMOTE_OPERATION_BATCH_SIZE) {
                dispatch(await EmailActions.addRemoteOperation(factoryFunc(batch)));
                batch = [];
            }
            batch.push(id);
        }

        dispatch(await EmailActions.addRemoteOperation(factoryFunc(batch)));
    }

    static markEmailIdsAsRead(dispatch: AppDispatch, emailIds: number[], read: boolean) {
        return EmailActions.batchAddRemoteOperations(dispatch, emailIds, ids => ({
            ManageEmails: {
                EmailIds: ids,
                MarkAsRead: read,
            }
        }));
    }

    static setEmailFlags(dispatch: AppDispatch, emails: EmailHeader[], flag: boolean) {
        const isFlagged = flag ? 1 : 0;

        const emailIds = nonDrafts(emails).filter(e => e.Flagged !== isFlagged).map(e => e.Id);
        return EmailActions.batchAddRemoteOperations(dispatch, emailIds, ids => ({
            ManageEmails: {
                EmailIds: ids,
                Flag: flag,
            }
        }));
    }

    static setEmailPinned(dispatch: AppDispatch, emails: EmailHeader[], pinned: boolean) {
        const emailIds = nonDrafts(emails).filter(e => e.Pinned !== pinned).map(e => e.Id);
        return EmailActions.batchAddRemoteOperations(dispatch, emailIds, ids => ({
            ManageEmails: {
                EmailIds: ids,
                Pin: pinned,
            }
        }));
    }

    static async deleteSelectedEmails(dispatch: Dispatch, {itemState}: AppState) {
        const emails = itemState.selectedEmailHeaders;
        if (emails.length > 0) {
            await EmailActions.deleteEmails(dispatch, emails);
        }
    }

    static async deleteEmails(dispatch: AppDispatch, emails: EmailHeader[]) {
        const deletedDrafts = drafts(emails).map(e => e.LocalId as string);
        if (deletedDrafts.length > 0) {
            if (DB) await DB.deleteDraftEmails(deletedDrafts);

            dispatch({type: DELETE_DRAFT_EMAILS, uids: deletedDrafts});
            return;
        }

        const emailIds = nonDrafts(emails).map(e => e.Id);
        return EmailActions.batchAddRemoteOperations(dispatch, emailIds, ids => ({
            ManageEmails: {
                EmailIds: ids,
                Delete: true,
            }
        }));
    }

    static beginMoveEmails(emails: EmailHeader[]): BeginMoveEmailsAction {
        return {type: MOVE_EMAILS_BEGIN, emails};
    }

    static completeMoveEmails(emails: EmailHeader[], newParentServerId: string): Promise<PayloadAction<AddRemoteOperationPayload>> {
        const operation: ManageEmailsRemoteOperationDto = {
            EmailIds: nonDrafts(emails).map(e => e.Id),
            MoveToFolderId: newParentServerId
        };
        return EmailActions.addRemoteOperation({ManageEmails: operation});
    };

    static async markEmailsAsJunk(emails: EmailHeader[], currentFolder: Folder | undefined) {
        if (!currentFolder) {
            return {type: NOOP_ACTION};
        }
        const operation: ManageEmailsRemoteOperationDto = {
            EmailIds: nonDrafts(emails).map(e => e.Id),
            MarkAsJunk: !isJunkFolder(currentFolder.icon)
        };
        return await EmailActions.addRemoteOperation({ManageEmails: operation});
    }

    static showEmailContent(): ShowEmailContentAction {
        return {type: SHOW_EMAIL_CONTENT};
    }

    static downloadAttachment(attachmentId: number | undefined, name: string | undefined, emailId?: number, format?: "zip") {
        if (!attachmentId && !emailId) return;

        let url = "/Mail/DownloadAttachment.aspx?";
        if (attachmentId) {
            url += `id=${attachmentId}`;
        } else {
            url += `emailId=${emailId}`;
        }
        if (format) {
            url += `&format=${format}`;
        }
        downloadFile(url, name);
    }

    static async showAttachment(attachment: AttachmentDto, emailId: number, dispatch: AppDispatch): Promise<void> {
        if (isVirusAttachment(attachment)) {
            dispatch(SystemActions.showError("This attachment was a virus.\r\n\r\nIt has been deleted for your protection"));
            return;
        }
        if (isImageFile(attachment.Name) || isImageContentType(attachment.ContentType)) {
            const action: ShowImageAttachmentAction = {type: SHOW_IMAGE_ATTACHMENT, attachment, emailId};
            dispatch(action);
            return;
        }

        const action: ShowInlineAttachmentAction = {type: SHOW_INLINE_ATTACHMENT, attachment, isInline: attachment.IsInline, emailId};
        dispatch(action);

        const url = `/Mail/DownloadAttachment.aspx?id=${attachment.Id}`;

        if (!isImageFile(attachment.Name) && !attachment.IsInline) {
            // TODO: allow improved inline viewing of attachments
            EmailActions.downloadAttachment(attachment.Id, attachment.Name);
        } else {
            try {
                const c = await performRawRestCall<AttachmentContentDto>(url + "&format=json", undefined, "GET");
                c.EmailId = emailId;
                dispatch(EmailActions.showAttachmentContent(c));
            } catch (e) {
                dispatch(SystemActions.showFormattedError("Unable to load attachment content", e));
            }
        }
    }

    static showAttachmentContent(attachmentContent: AttachmentContentDto): ShowAttachmentContentAction {
        return {type: SHOW_ATTACHMENT_CONTENT, attachmentContent};
    }

    static showEmailCategoryDialog(category: string): ShowEmailCategoryDialogAction {
        return {type: SHOW_EMAIL_CATEGORY_DIALOG, show: true, category};
    }

    static hideEmailCategoryDialog(): ShowEmailCategoryDialogAction {
        return {type: SHOW_EMAIL_CATEGORY_DIALOG, show: false};
    }

    static setEmailCategory(emailId: number, category: string): Promise<PayloadAction<AddRemoteOperationPayload>> {
        const operation: ManageEmailsRemoteOperationDto = {
            EmailIds: [emailId],
            Category: category,
        };
        return EmailActions.addRemoteOperation({ManageEmails: operation});
    }

    static async releaseGreylistedEmails(emails: EmailHeader[]) {
        try {
            const emailServerIds = emails.filter(e => e.ServerId).map(e => e.ServerId || "");
            const request: ReleaseGreylistedEmailsRequestDto = {GreylistedEmailIds: emailServerIds};

            const response = await performFetch(`/Mail3/ReleaseGreylistedEmails`, request) as ReleaseGreylistedEmailsResponseDto;

            const action: RemoveGreylistedEmailsAction = {
                type: REMOVE_GREYLISTED_EMAILS,
                emailServerIds: response.GreylistedEmailIds
            };
            return action;
        } catch (error: any) {
            return SystemActions.showError(error.message);
        }
    }

    static showRemindMeLaterSettingsDialog(show: boolean): ShowRemindMeLaterSettingsDialogAction {
        return {type: SHOW_REMIND_ME_LATER_SETTINGS_DIALOG, show};
    }

    static setRemindMeLaterDate(remindMeLaterDate: RelativeDateTimeOption): SetRemindMeLaterDateAction {
        return {type: SET_REMIND_ME_LATER_DATE, remindMeLaterDate};
    }

    static async setRemindMeLaterDateOnEmails(dispatch: AppDispatch, emailIds: number[], remindMeLaterDate: RelativeDateTimeOption) {
        const action: SetRemindMeLaterDateOnEmailAction = {
            type: SET_REMIND_ME_LATER_DATE_ON_EMAIL,
            remindMeLaterDate,
            emailIds,
        };
        dispatch(action);

        try {
            await EmailActions.batchAddRemoteOperations(dispatch, emailIds, ids => ({
                ManageEmails: {
                    EmailIds: ids,
                    RemindMeDate: {Date: calculateDateFromRelativeDateOption(remindMeLaterDate)},
                }
            }));
        } catch (e) {
            dispatch(SystemActions.showError("Unable to set reminder date"));
        }
    }

    static openArchiveSettingsDialog(): ShowArchiveSettingsDialogAction {
        return {type: SHOW_ARCHIVE_SETTINGS_DIALOG, show: true};
    }

    static hideArchiveSettingsDialog(): ShowArchiveSettingsDialogAction {
        return {type: SHOW_ARCHIVE_SETTINGS_DIALOG, show: false};
    }

    static setAutoArchiveDate(archiveDate: number | null): SetAutoArchiveDateAction {
        return {type: SET_AUTO_ARCHIVE_DATE, archiveDate};
    }

    static setAutoArchiveDateOnEmails(emailIds: number[], autoArchiveDate: number | null) {
        return (dispatch: Dispatch) => {
            const action: SetAutoArchiveDateOnEmailAction = {
                type: SET_AUTO_ARCHIVE_DATE_ON_EMAIL,
                autoArchiveDate,
                emailIds,
            };
            dispatch(action);

            EmailActions.batchAddRemoteOperations(dispatch, emailIds, ids => ({
                ManageEmails: {
                    EmailIds: ids,
                    AutoArchiveDate: {Date: autoArchiveDate},
                }
            })).catch(() => dispatch(SystemActions.showError("Unable to set auto-archive date")));
        };
    }

    static setContextAttachment(attachment: AttachmentDto | undefined): SetContextAttachmentAction {
        return {type: SET_CONTEXT_ATTACHMENT, attachment};
    }
}

function isVirusAttachment(attachment: AttachmentDto) {
    return attachment.Status === 2;
}