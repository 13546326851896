import {Key} from "openpgp";
import {EntityDto} from "../services/messages/EntityDto";
import {EnrichedPgpKey} from "./pgp/EnrichedPgpKey";

export const TemporaryKeyId = -1;

export interface PgpKey extends EntityDto {
    key: Key;
    isPrivate: boolean;
    keyHexId: string;
    enrichedKey?: EnrichedPgpKey;
}

export function pgpKeyFromRawKey(key: Key) {
    return {
        key,
        isPrivate: key.isPrivate(),
        keyHexId: key.getKeyId().toHex(),
        Id: TemporaryKeyId,
    };
}

export interface UnknownPgpKey {
    unknownKeyHexId: string;
}