import {ImportanceEnum} from "../../../domain/ImportanceEnum";

export const ASSIGN_CATEGORY_ACTION = "AssignToCategoryAction";
export const AUTO_ARCHIVE_ACTION = "AutoArchiveAction";
export const FLAG_EMAIL_ACTION = "FlagEmailAction";
export const FORWARD_EMAIL_ACTION = "ForwardEmailAction";
export const MARK_AS_READ_ACTION = "MarkAsReadAction";
export const MOVE_TO_FOLDER_ACTION = "MoveToFolderAction";
export const SET_EMAIL_IMPORTANCE_ACTION = "SetEmailImportanceAction";

interface AssignToCategoryAction {
    Type: typeof ASSIGN_CATEGORY_ACTION;
    Category: string;
}

interface AutoArchiveAction {
    Type: typeof AUTO_ARCHIVE_ACTION;
    AutoArchiveDays: number;
}

interface FlagEmailAction {
    Type: typeof FLAG_EMAIL_ACTION;
}

interface ForwardEmailAction {
    Type: typeof FORWARD_EMAIL_ACTION;
    ForwardingAddress: string;
}

interface MarkAsReadAction {
    Type: typeof MARK_AS_READ_ACTION;
}

interface MoveToFolderAction {
    Type: typeof MOVE_TO_FOLDER_ACTION;
    FolderId: number;
}

interface SetEmailImportanceAction {
    Type: typeof SET_EMAIL_IMPORTANCE_ACTION;
    Importance: ImportanceEnum;
}

export type EmailRuleActionTypes =
    AssignToCategoryAction
    | AutoArchiveAction
    | FlagEmailAction
    | ForwardEmailAction
    | MarkAsReadAction
    | MoveToFolderAction
    | SetEmailImportanceAction;

export type EmailRuleActionTypeValues = EmailRuleActionTypes["Type"];
