import {FC, useRef, useState, useEffect, PropsWithChildren} from 'react';
import React from 'react';

import "./ResizeContainer.scss"
import { getPointerId } from '../../util/TypeHelper';

export interface ResizeContainerProps {
    orientation: "vertical" | "horizontal";
}

export const ResizeContainer: FC<ResizeContainerProps & PropsWithChildren> = ({ children, orientation }) => {
    const ref = useRef<HTMLDivElement | null>();

    return (
        <div className={"resize-container " + orientation} ref={newRef => ref.current = newRef}>
            {children}
        </div>
    );
};

export interface ResizeSplitterProps {
    onResize?: (element1: HTMLElement, element2: HTMLElement) => void;
}

export const ResizeSplitter: FC<ResizeSplitterProps> = ({ onResize }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [dragging, setDragging] = useState<boolean>(false);

    useEffect(() => {
        // Ensure that the onResize method is called whenever something here changes
        const splitterElt = ref.current;
        if (splitterElt) {
            const previousElt = splitterElt.previousElementSibling as HTMLElement | null;
            const nextElt = splitterElt.nextElementSibling as HTMLElement | null;
            if (previousElt && nextElt) {
                if (onResize) {
                    onResize(previousElt, nextElt);
                }
            }
        }
    }, [onResize]);

    function startDragging(e: React.MouseEvent) {
        if (e.button !== 0) return;

        setDragging(true);

        const splitterElt = ref.current;
        if (splitterElt) {
            splitterElt.setPointerCapture(getPointerId(e));
        }
    }

    function continueDragging(e: React.MouseEvent) {
        if (!dragging) return;

        const splitterElt = ref.current;
        if (splitterElt) {
            const previousElt = splitterElt.previousElementSibling as HTMLElement | null;
            const nextElt = splitterElt.nextElementSibling as HTMLElement | null;
            if (previousElt && nextElt) {
                previousElt.style.width = e.pageX + "px";
                if (onResize) {
                    onResize(previousElt, nextElt);
                }
            }
        }
    }

    function finishDragging(e: React.MouseEvent) {
        const splitterElt = ref.current;
        if (splitterElt) {
            splitterElt.releasePointerCapture(getPointerId(e));
        }

        if (!dragging) return;

        setDragging(false);
    }

    return (
        <div className="resize-splitter selectable"
            ref={ref}
            onMouseDown={e => startDragging(e)}
            onMouseMove={e => continueDragging(e)}
            onMouseUp={e => finishDragging(e)}
        >
            <div className="selectable"></div>
        </div>
    )
};
