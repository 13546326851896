import {UIMap} from "../util/UIMap";

export type DayOfWeekName = "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday";

export const SundayFlag = 1;
export const MondayFlag = 2;
export const TuesdayFlag = 4;
export const WednesdayFlag = 8;
export const ThursdayFlag = 16;
export const FridayFlag = 32;
export const SaturdayFlag = 64;
export const EOMFlag = 127;

export const DayOfWeekFlagValues = [2, 4, 8, 16, 32, 64, 1, 127];

export type DaysOfWeekFlags = number;

export const DayOfWeekDisplayNames: UIMap<DaysOfWeekFlags>[] = [
    {value: MondayFlag, title: "Monday"},
    {value: TuesdayFlag, title: "Tuesday"},
    {value: WednesdayFlag, title: "Wednesday"},
    {value: ThursdayFlag, title: "Thursday"},
    {value: FridayFlag, title: "Friday"},
    {value: SaturdayFlag, title: "Saturday"},
    {value: SundayFlag, title: "Sunday"},
];
