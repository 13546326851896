import Editor from "../compose/Editor";
import {useAppDispatch, useAppSelector, useAppStore} from "../../hooks/ReduxHooks";
import {shallowEqual} from "react-redux";
import {NotesActions} from "../../redux/NotesSlice";

export const NoteEditPageContent = () => {
    const editingPage = useAppSelector(({notesState}) => notesState.editingPage, shallowEqual);

    const dispatch = useAppDispatch();
    const store = useAppStore();
    
    if (!editingPage) return null;

    async function onChangeNoteContent(html: string) {
        dispatch(NotesActions.setEditNoteContent(html));
    }
    
    async function onSave() {
        const newPage = {...editingPage!, HtmlContent: store.getState().notesState.editPageContent};
        dispatch(await NotesActions.completeEditNote(newPage, true));
    }
    
    return (<div style={{height:"100%"}}>
        <Editor instanceId={"n" + editingPage.ClientId}
                statusbar={false}
                menubar={false} //edit insert format table tools help"
                toolbar="undo redo | bold italic underline formatpainter removeformat | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | link image media | forecolor backcolor emoticons codesample code | searchreplace "
                plugins={["autolink", "image", "paste", "imagetools", "table", "media", "searchreplace", "lists", "emoticons", "code", "codesample", "save"]}
                initialValue={editingPage.HtmlContent}
                onChange={onChangeNoteContent}
                onSave={onSave}/>
        </div>
    );
};
