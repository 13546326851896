import "./CalendarEditView.scss";

import {CalendarContentViewProps, formatRecurrence} from "./CalendarContentView";
import React, {FC, useEffect, useState} from "react";
import {InputAdornment, TextField} from "@mui/material";
import {StackPanel} from "../panels/StackPanel";
import {Calendar, dtoFromCalendar} from "../../domain/CalendarDto";
import {ContentLocation} from "../../locations/ContentLocation";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {SimpleCheckbox} from "../common/SimpleCheckbox";
import {Recurrence} from "../../domain/RecurrenceDto";
import {RecurrenceTypeDisplayNames, RecurrenceTypeEnum} from "../../domain/RecurrenceTypeEnum";
import {SimpleSelect} from "../common/SimpleSelect";
import {calculateWeekOfMonth, getDayOfWeekFromDateTime, plural} from "../../util/DateHelper";
import {DateField} from "../common/DateField";
import {DateTime} from "luxon";
import {SimpleNumberTextField} from "../common/SimpleNumberTextField";
import {EmailActions} from "../../actions/EmailActions";
import {useDispatch} from "react-redux";
import {DEFAULT_MEETING_LENGTH, DefaultRecurrence} from "../../services/calendar/CalendarFactory";
import {getCalendarIcon} from "./CalendarView";
import {SystemActions} from "../../actions/SystemActions";
import {validateCalendar} from "../../services/calendar/CalendarService";
import {CalendarEntryData} from "../../services/calendar/CalendarEntryData";
import {HideContentButton} from "../common/HideContentButton";
import {EditButtons} from "../common/EditButtons";


function updateRecurrenceFromDateTime(recurrence: Recurrence, startDate: DateTime): Recurrence {
    switch (recurrence.Type) {
        case RecurrenceTypeEnum.Daily:
            return recurrence;
        case RecurrenceTypeEnum.Weekly:
            recurrence.DaysOfWeek = getDayOfWeekFromDateTime(startDate);
            break;

        case RecurrenceTypeEnum.Monthly:
            recurrence.DayOfMonth = startDate.day;
            break;

        case RecurrenceTypeEnum.MonthlyOnDay:
            recurrence.WeekOfMonth = calculateWeekOfMonth(startDate);
            recurrence.DaysOfWeek = getDayOfWeekFromDateTime(startDate);
            break;

        case RecurrenceTypeEnum.Yearly:
            recurrence.DayOfMonth = startDate.day;
            recurrence.MonthOfYear = startDate.month;
            break;

        case RecurrenceTypeEnum.YearlyOnDay:
            recurrence.MonthOfYear = startDate.month;
            recurrence.WeekOfMonth = calculateWeekOfMonth(startDate);
            recurrence.DaysOfWeek = getDayOfWeekFromDateTime(startDate);
            break;
    }

    return recurrence;
}

export const CalendarEditView: FC<CalendarContentViewProps> = (props) => {

    console.log("Render edit view");

    const dispatch = useDispatch();

    const [entry1, setEntry] = useState<CalendarEntryData>();
    const [recurrence1, setRecurrence] = useState<Recurrence>();

    useEffect(() => {
            setEntry({...props.calendarEntry});
            setRecurrence({...(props.calendarEntry.recurrence || DefaultRecurrence)});
        },
        [props.calendarEntry]);

    if (!entry1 || !recurrence1) return null;

    const entry = entry1;
    const recurrence = recurrence1;
    const calendar = entry1.calendar;

    const isRepeated = calendar.Recurrences.length > 0;

    async function onSave() {
        const validationResults = validateCalendar(calendar);
        if (validationResults) {
            dispatch(SystemActions.showWarning(validationResults));
        } else {
            dispatch(await EmailActions.addRemoteOperation({ManageCalendar: {Change: dtoFromCalendar(calendar)}}));

            ContentLocation.showCalendar(calendar.Uid, entry.instanceId);
        }
    }

    function onCancel() {
        ContentLocation.showCalendar(calendar.Uid, entry.instanceId);
    }

    function updateCalendar(updatedCalendar: Partial<Calendar>) {
        setEntry({...entry, calendar: {...calendar, ...updatedCalendar}});
    }

    function updateRecurrence(recurrenceUpdate: Partial<Recurrence>) {
        const newRecurrence = updateRecurrenceFromDateTime({...recurrence, ...recurrenceUpdate}, calendar.StartDateTime ?? DateTime.local());
        updateCalendar({Recurrences: [newRecurrence]});
        setRecurrence(newRecurrence);
    }

    function onToggleRepeat() {
        if (isRepeated) {
            updateCalendar({Recurrences: []});
        } else {
            updateRecurrence({});
        }
    }

    function onToggleAllDayEvent(AllDayEvent: boolean) {
        const now = DateTime.local();
        const start = calendar.StartDateTime ?? now;
        const end = calendar.EndDateTime ?? now;

        if (AllDayEvent) {
            const StartDateTime = start.startOf("day");
            const EndDateTime = end.endOf("day");
            updateCalendar({AllDayEvent, StartDateTime, EndDateTime});
        } else {
            const StartDateTime = start.set({hour: now.hour});
            const EndDateTime =
                start.day === end.day
                    ? start.plus(DEFAULT_MEETING_LENGTH)
                    : end.set({hour: now.hour});

            updateCalendar({AllDayEvent, StartDateTime, EndDateTime});
        }
    }

    function renderStartingUntilOptions() {
        function handleStartDateChange(startDate: DateTime | null) {
            if (!startDate) return;

            const newRecurrence = updateRecurrenceFromDateTime({...recurrence}, startDate);
            updateCalendar({StartDateTime: startDate, Recurrences: [newRecurrence]});
            setRecurrence(newRecurrence);
        }

        return (
            <div className="sentence">
                <DateField label="Starting"
                           value={calendar.StartDateTime}
                           onChange={startDate => handleStartDateChange(startDate)}/>

                <span className="right-space"/>

                <DateField label="Until"
                           value={recurrence.Until}
                           onChange={Until => updateRecurrence({Until})}/>
            </div>
        );
    }

    function getRecurrenceIntervalType() {
        switch (recurrence.Type) {
            case RecurrenceTypeEnum.Daily:
                return "day";
            case RecurrenceTypeEnum.Weekly:
                return "week";
            case RecurrenceTypeEnum.Monthly:
            case RecurrenceTypeEnum.MonthlyOnDay:
                return "month";
            case RecurrenceTypeEnum.Yearly:
            case RecurrenceTypeEnum.YearlyOnDay:
                return "year";
        }
        return "???";
    }

    function renderRepeatOptions() {
        return (
            <>
                <div className="sentence hide-underline">
                    <SimpleSelect className="right-space bottom-space"
                                  options={RecurrenceTypeDisplayNames}
                                  value={recurrence.Type}
                                  onChange={Type => updateRecurrence({Type})}/>

                    <span className="right-space">every</span>

                    <SimpleNumberTextField className="narrow bottom-space"
                                           value={recurrence.Interval}
                                           onChange={Interval => updateRecurrence({Interval})}/>

                    <span className="right-space"/>

                    <span className="right-space">{plural(recurrence.Interval, getRecurrenceIntervalType())}</span>

                    {renderStartingUntilOptions()}
                </div>
            </>
        );
    }

    function renderSingleInstanceOptions() {
        return (
            <>
                <div className="sentence">
                    <DateField label="Start"
                               value={calendar.StartDateTime}
                               showTime={!calendar.AllDayEvent}
                               onChange={StartDateTime => updateCalendar({StartDateTime})}/>

                    <span className="right-space"/>

                    <DateField label="End"
                               value={calendar.EndDateTime}
                               showTime={!calendar.AllDayEvent}
                               onChange={EndDateTime => updateCalendar({EndDateTime})}/>
                </div>
                <div className="timezone">{calendar.TimezoneName}</div>
            </>
        );
    }

    const repeatedText = isRepeated ? formatRecurrence(recurrence) : "Repeated";

    return (
        <>
            <StackPanel orientation="vertical" className="calendar-content-view edit-calendar" align="start">
                <HideContentButton/>

                <TextField label="Title"
                           value={calendar.Subject || ""} fullWidth
                           onChange={e => updateCalendar({Subject: e.target.value})}
                           InputProps={{
                               startAdornment: <InputAdornment position="start">{getCalendarIcon(calendar)}</InputAdornment>
                           }}
                />

                <TextField label="Category"
                           value={calendar.Category || ""}
                           onChange={e => updateCalendar({Category: e.target.value})}/>

                <SimpleNumberTextField label="Reminder"
                                       className="reminder hide-underline"
                                       value={calendar.ReminderMins || 0}
                                       InputProps={{
                                           startAdornment: <InputAdornment position="start"><NotificationsIcon/></InputAdornment>,
                                           endAdornment: <InputAdornment position="end">mins</InputAdornment>,
                                       }}
                                       onChange={ReminderMins => updateCalendar({ReminderMins})}/>

                <SimpleCheckbox label="All day event" checked={calendar.AllDayEvent} onChange={onToggleAllDayEvent}/>

                {renderSingleInstanceOptions()}

                <SimpleCheckbox label={repeatedText} checked={isRepeated} onChange={onToggleRepeat}/>

                {isRepeated ? renderRepeatOptions() : null}

                <TextField label="Details"
                           value={calendar.Body || ""}
                           multiline fullWidth
                           onChange={e => updateCalendar({Body: e.target.value})}/>

            </StackPanel>

            <EditButtons onSetEditing={onCancel}
                         onSave={onSave}/>
        </>
    );
}
