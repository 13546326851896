import {AppStore} from '../AppStore';
import {debounceTime} from 'rxjs/operators';
import {performFetch} from '../util/HttpHelper';
import {FolderActions} from '../actions/FolderActions';
import SearchRequestDto from '../services/messages/SearchRequestDto';
import {convertEmailHeader} from '../services/messages/EmailHeaderDto';
import {EmailDataSource, EmailDataSourceResult} from '../domain/EmailDataSource';
import {FolderLocation} from '../locations/FolderLocation';
import {FolderTypeEnum} from '../domain/Folder';
import {RxSubjects} from "../state/RxSubjectsState";

const SEARCH_DEBOUNCE_TIME = 500;

let nextId = 0;

export function registerSearchTextSubjectHandler(store: AppStore) {
    RxSubjects.searchText$
        .pipe(debounceTime(SEARCH_DEBOUNCE_TIME))
        .subscribe({
            next: newSearchText => {

                //store.getState().;
                // TODO: Search based on the folder type

                nextId++;

                async function getNextResultsPage(offset: number, pageSize: number): Promise<void> {
                    const state = store.getState();

                    const currentSearchText = state.folderState.searchText;

                    // TODO: add sorting and filtering support

                    if (currentSearchText.length === 0) {
                        store.dispatch(FolderActions.searchResultsLoaded());
                        return;
                    }
                    try {
                        const request: SearchRequestDto = {
                            RequestId: nextId,
                            Text: "" + currentSearchText,
                            Offset: offset,
                            PageSize: pageSize,
                            Options: state.folderState.searchOptions,
                        };

                        console.debug("Loading next search page: ", request);

                        const newResults = await performFetch("/Mail3/Search", request);

                        if (newResults.RequestId === nextId && newResults.Emails) {
                            const pageResult: EmailDataSourceResult = {
                                emails: newResults.Emails.map(convertEmailHeader),
                                lastEmail: newResults.Emails.length < pageSize,
                                offset,
                                pageSize,
                            };
                            store.dispatch(FolderActions.searchResultsLoaded(pageResult));
                        } else {
                            console.debug("Discarding stale search results: ", newResults.RequestId, nextId);
                            getNextResultsPage(offset, pageSize);
                        }
                    } catch (e) {
                        console.error("Failed to load the next set of rows", e);
                        store.dispatch(FolderActions.performSearchAsync(undefined, undefined, new Error("A problem occurred while fetching the next set of your search results")));
                    }
                }

                try {
                    if (newSearchText.length === 0) {
                        console.debug("Empty search");
                        const inboxFolder = store.getState().folderState.folderList.find(f => f.type === FolderTypeEnum.Inbox);
                        FolderLocation.selectFolder(inboxFolder!);
                    } else {
                        console.debug("New search: ", newSearchText);
                        const dataSource: EmailDataSource = {getNextResultsPage};
                        store.dispatch(FolderActions.performSearchAsync(dataSource, newSearchText));
                        getNextResultsPage(0, 30)
                            .catch(() => store.dispatch(FolderActions.performSearchAsync(undefined, undefined, new Error("A problem occurred while performing your search"))));
                    }
                } catch (e) {
                    console.error(e);
                    store.dispatch(FolderActions.performSearchAsync(undefined, undefined, new Error("A problem occurred while performing your search")));
                }
            }
        });
}
