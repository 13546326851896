import quotedPrintable from "quoted-printable";
import {Base64} from "js-base64";

const BoundaryRegex = /.*[ ;]+boundary *= *("[^;]+"|[^;]+).*$/i;

interface BestScore {
    score: number;
}

function findPartScore(part: MimePart, type: string): number {
    let score = 1;
    if (part.contentType === type) {
        score += 100;
    }
    if (part.contentType.startsWith(type)) {
        score += 100;
    }
    if (!part.contentDisposition) {
        score += 15;
    }
    if (part.contentType.startsWith("text/")) {
        score += 10;
    }
    if (part.contentType.includes("text/html")) {
        score += 5;
    }
    return score;
}

export class MimeParser {
    private readonly lines: string[];
    private lineIndex: number = 0;

    private readonly rootPart: MimePart;

    private currentPart: MimePartWrapper;

    public subject?: string;

    constructor(content: string) {
        this.lines = content.split(/\r?\n/);

        this.rootPart = {contentType: "", contentLines: [], subParts: [], contentStartIndex: 0, contentEndIndex: 0};
        this.currentPart = {mimePart: this.rootPart, parentPart: undefined};
    }

    parse(): MimePart {
        let parseHeaders = true;
        do {
            if (parseHeaders) this.parseMimeHeader();

            parseHeaders = this.parseMimeContent();

        } while (this.lineIndex < this.lines.length);

        return this.rootPart;
    }

    findBestPart(type: string): MimePart {
        return this.findBestSubPart(this.rootPart, this.rootPart, type, {score: 0});
    }

    findExactPart(type: string): MimePart | undefined {
        return this.findExactSubPart(this.rootPart, type);
    }

    getRootPart() {
        return this.rootPart;
    }

    getMimePartLines(mimePart: MimePart) {
        return this.lines.slice(mimePart.contentStartIndex, mimePart.contentEndIndex);
    }

    private findBestSubPart(part: MimePart, bestPart: MimePart, type: string, bestScore: BestScore): MimePart {
        const partScore = findPartScore(part, type);

        if (partScore > bestScore.score) {
            bestPart = part;
            bestScore.score = partScore;
        }
        for (const subPart of part.subParts) {
            bestPart = this.findBestSubPart(subPart, bestPart, type, bestScore);
        }
        return bestPart;
    }

    private findExactSubPart(part: MimePart, type: string): MimePart | undefined {
        if (part.contentType === type) {
            return part;
        }
        for (const subPart of part.subParts) {
            const found = this.findExactSubPart(subPart, type);
            if (found) {
                return found;
            }
        }
        return undefined;
    }

    private parseMimeHeader() {
        let currentHeaderName = "";
        let currentValue = "";
        do {
            const line = this.lines[this.lineIndex++];
            if (!line || line.length === 0) {
                break;
            }
            if (!line.startsWith(' ') && !line.startsWith('\t')) {
                const headerNameEnd = line.indexOf(":");
                if (headerNameEnd > 0) {
                    currentHeaderName = line.substring(0, headerNameEnd).toLowerCase();
                    currentValue = line.substring(headerNameEnd + 1);
                } else {
                    currentHeaderName = "";
                }
            } else {
                currentValue = line.trim();
            }

            const {mimePart} = this.currentPart;
            switch (currentHeaderName) {
                case "content-type":
                    mimePart.contentType += currentValue.trim();
                    break;
                case "content-disposition":
                    mimePart.contentDisposition = (mimePart.contentDisposition || "") + currentValue.trim();
                    break;
                case "content-transfer-encoding":
                    mimePart.contentTransferEncoding = (mimePart.contentTransferEncoding || "") + currentValue.trim().toLowerCase();
                    break;
                case "subject":
                    this.subject = currentValue.trim();
                    break;
            }

        } while (true);

        this.processContentTypeHeader();
    }

    private processContentTypeHeader() {
        const {mimePart} = this.currentPart;

        const sections = mimePart.contentType.split(";");

        const result = BoundaryRegex.exec(mimePart.contentType);
        if (result && result.length > 1) {
            const boundary = result[1];
            if (boundary.startsWith('"')) {
                mimePart.boundary = "--" + boundary.substring(1, boundary.length - 1);
            } else {
                mimePart.boundary = "--" + boundary;
            }
        }

        mimePart.contentType = sections[0].trim().toLowerCase();

        // TODO: add support for "charset"
    }

    private parseMimeContent() {
        let part: MimePartWrapper = this.currentPart;
        const boundaryEnd = (part.mimePart.boundary || part.parentPart?.mimePart.boundary) + "--";
        const content: string[] = [];
        let result = true;
        do {
            const line = this.lines[this.lineIndex++];
            if (line === undefined) {
                break;
            }

            if (line === part.mimePart.boundary) {
                // Add this new part to the current
                this.createSubPart(this.currentPart);
                break;
            }
            if (part.parentPart && line === part.parentPart.mimePart.boundary) {
                // Add this new part to the parent
                this.createSubPart(part.parentPart);
                break;
            }
            if (part.parentPart && line === part.parentPart.mimePart.boundary + "--") {
                // Drop down to the parent
                this.currentPart = part.parentPart;
                this.currentPart.mimePart.contentEndIndex = this.lineIndex - 1;
                result = false;
                break;
            }
            if (boundaryEnd.length > 2 && line === boundaryEnd) {
                break;
            }

            content.push(line);

        } while (true);

        part.mimePart.contentLines.push(...content);
        part.mimePart.contentEndIndex = this.lineIndex - 1;
        return result;
    }

    private createSubPart(parentPart: MimePartWrapper) {
        this.currentPart = {
            mimePart: {
                contentLines: [],
                contentType: "",
                subParts: [],
                contentStartIndex: this.lineIndex,
                contentEndIndex: this.lineIndex
            },
            parentPart,
        };
        parentPart.mimePart.subParts.push(this.currentPart.mimePart);
    }

    public getAttachmentParts() {
        return [...this.findMatchingParts(this.rootPart, p => !!p.contentDisposition?.includes("attachment"))];
    }

    public* findMatchingParts(part: MimePart, predicate: (part: MimePart) => boolean): Generator<MimePart> {
        if (predicate(part)) {
            yield part;
        }
        for (const subPart of part.subParts) {
            yield* this.findMatchingParts(subPart, predicate);
        }
    }

    static getAttachmentName(attachment: Partial<MimePart>) {

        const matches = attachment.contentDisposition?.match(/filename="(.+)"/);

        if (!matches) return "attachment.txt";

        return matches[1] ?? "attachment.txt";
    }
}

export interface MimePart {
    contentType: string;
    contentLines: string[];
    contentStartIndex: number;
    contentEndIndex: number;
    contentTransferEncoding?: string;
    contentDisposition?: string;
    boundary?: string;

    subParts: MimePart[];
}

interface MimePartWrapper {
    mimePart: MimePart;
    parentPart: MimePartWrapper | undefined;
}

export function decodeMimePartContent(mimePart: MimePart) {
    // TODO: add support for more content transfer encodings

    switch (mimePart.contentTransferEncoding) {
        case "quoted-printable":
            return quotedPrintable.decode(mimePart.contentLines.join("\r\n"));
        case "base64":
            return Base64.decode(mimePart.contentLines.join("\r\n"));
        default:
            return mimePart.contentLines.join("\r\n");
    }
}

export function downloadAttachmentFileFromMemory(attachment: MimePart, fileName: string) {
    // TODO: use the correct content type
    const element = document.createElement('a');

    const content = attachment.contentLines.join("\r\n");
    switch (attachment.contentTransferEncoding) {
        case "quoted-printable":
            element.setAttribute('href', `data:text/plain;charset=utf-8,` + encodeURIComponent(quotedPrintable.decode(content)));
            break;
        case "base64":
            element.setAttribute('href', `data:application/octet-stream;base64,` + content);
            break;
        default:
            element.setAttribute('href', `data:text/plain;charset=utf-8,` + encodeURIComponent(content));
            break;
    }

    element.setAttribute('download', fileName);

    element.style.display = 'none';
    document.body.appendChild(element);

    try {
        element.click();
    } finally {
        document.body.removeChild(element);
    }
}

