import React, {FC, PropsWithChildren, useState} from 'react';
import classNames from 'classnames';

import "./Expander.scss";
import {Collapse} from '@mui/material';

export interface ExpanderProps {
    className?: string;
}

const arrowElements = <svg className="expander-button" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"></path></svg>;

export const Expander: FC<ExpanderProps & PropsWithChildren> = ({ className, children }) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    return (
        <Collapse in={expanded} collapsedSize={30} className={classNames("expander-panel", className, { "expanded": expanded } )}>
            <div className="expander-title selectable" onClick={() => setExpanded(!expanded)}>
                {arrowElements}
            </div>
            {children}
        </Collapse>
    );
};
