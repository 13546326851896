import React, {FC} from "react";
import {NotificationMessage} from "../../domain/NotificationMessage";
import {useAppDispatch} from "../../hooks/ReduxHooks";
import {SystemActions} from "../../actions/SystemActions";
import {StackPanel} from "../panels/StackPanel";
import classNames from "classnames";
import CloseIcon from "@mui/icons-material/Close";

export const NotificationItemView: FC<{ notification: NotificationMessage }> = ({notification}) => {

    const dispatch = useAppDispatch();

    function onHideNotification(id: string) {
        dispatch(SystemActions.hideNotificationMessage(id));
    }

    return (
        <StackPanel className={classNames("notification-message", notification.type, {"today": notification.today})} align="middle">
            <span className="spacer"/>
            <span className="icon">{notification.icon}</span>
            <span className="message">
                    {notification.message}
                </span>
            <span className="spacer"/>
            <span className="close" title="Hide notification" onClick={() => onHideNotification(notification.id)}>
                    <CloseIcon/>
                </span>
        </StackPanel>
    );
};