import {ChangeAliasesResponseDto} from "../services/messages/responses/ChangeAliasesResponseDto";

export type ImageWhitelistType = "Includes" | "StartsWith" | "Regex";

export interface ImageWhitelistDto {
    includes: string[];
    startsWith: string[];
    regex: string[];
}

export const blankImageWhitelist: ImageWhitelistDto ={
    startsWith: [],
    regex: [],
    includes: [],
};

export const ADD_ALIAS = "ADD_ALIAS";
export const REMOVE_ALIAS = "REMOVE_ALIAS";

export interface AliasAsyncPayload {
    data: string;
    error?: any;
    response?: ChangeAliasesResponseDto;
}
