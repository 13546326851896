import {ToolbarButton} from "./ToolbarTypes";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import {ContentLocation} from "../../locations/ContentLocation";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

export const EmailRuleToolbar = () => (
    <>
        <ToolbarButton title="Add New Rule" onClick={() => ContentLocation.showEmailRule(-1)}
                       icon={<><AccountTreeIcon/><AddIcon className="add-icon2"/><AddIcon className="add-icon"/></>}/>

    </>
);
