import React, {FC, useEffect, useState} from "react";
import {StackPanel} from "../panels/StackPanel";
import {QuickSearchText} from "../common/QuickFilterOption";
import {SimpleGrid} from "../common/grid/SimpleGrid";
import {useAppSelector} from "../../hooks/ReduxHooks";
import {AppState} from "../../AppState";
import {PgpKeysLocation} from "../../locations/PgpKeysLocation";
import {DateTime} from "luxon";
import {formatDateTimeAsFriendlyString} from "../../util/DateHelper";
import {EnrichedVerificationResult} from "../../domain/pgp/EnrichedPgpKey";
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {KeyID} from "../../util/PGPUtil";
import {PgpKeyListControlsView} from "./PgpKeyListControlsView";
import {PgpKey} from "../../domain/PgpKey";
import {isPgpKeyContent} from "../../domain/ItemContent";

interface PgpKeyGridRowProps {
    pgpKey?: PgpKey;
    keyId?: KeyID;
    verification?: EnrichedVerificationResult;
}

function expiryDateToString(date: number | Date | null): string {
    if (date !== null && typeof (date) === "object") {
        return formatDateTimeAsFriendlyString(DateTime.fromJSDate(date as Date));
    } else {
        return "Never";
    }
}

export const PgpKeyGridRow: FC<PgpKeyGridRowProps> = ({pgpKey, keyId, verification}) => {
    const [expiryDate, setExpiryDate] = useState<string>();

    useEffect(() => {
        let isMounted = true;
        pgpKey?.key.getExpirationTime()
            .then(date => isMounted && setExpiryDate(expiryDateToString(date)))
            .catch(e => console.error(e));
        return () => {
            isMounted = false;
        }
    }, [pgpKey?.key]);

    const icon = verification &&
        (<span className={verification.valid ? "success" : "error"}>{verification.valid ? <CheckIcon/> : <ErrorOutlineIcon/>}</span>);

    return (<>
        {icon && <div className="grid-icon">{icon}</div>}

        <div className="grid-user">{pgpKey?.key.getUserIds()[0] || keyId?.toHex()}</div>

        <div className="grid-key-id">{keyId?.toHex() || pgpKey?.key.getKeyId().toHex()}</div>

        <div className="grid-expiry">{pgpKey ? expiryDate : "Unknown Key"}</div>
    </>);
};

export const PgpKeyListView = () => {
    const [filterText, setFilterText] = useState<string>("");

    const allPublicKeys = useAppSelector(({accountsState}: AppState) => accountsState.pgpKeys);
    const allSecretKeys = useAppSelector(({accountsState}: AppState) => accountsState.secretKeys);

    const allKeys = [...allSecretKeys, ...allPublicKeys.filter(pk => !allSecretKeys.some(sk => sk.keyHexId === pk.keyHexId))];

    const lowerFilterText = filterText.toLowerCase();

    const filteredKeys = (filterText.length === 0
        ? [...allKeys]
        : allKeys.filter(k => k.key.getUserIds().some(u => u.toLowerCase().includes(lowerFilterText))))
        .sort((a, b) => a.key.getUserIds()[0].localeCompare(b.key.getUserIds()[0]));

    const selectedKeySelector = ({itemState}: AppState) => isPgpKeyContent(itemState.itemContent) ? [itemState.itemContent] : [];

    function updateGridSelection(newSelectedKeys: PgpKey[]) {
        PgpKeysLocation.selectPgpKey(newSelectedKeys[newSelectedKeys.length - 1]?.key.getKeyId().toHex());
    }

    return (
        <>
            <StackPanel orientation="horizontal" className="panel top-panel" align={"middle"} fill={true}>
                <QuickSearchText onQuickSearchTextChange={text => setFilterText(text)}
                                 searchPrompt={"Filter Keys"}/>
                <PgpKeyListControlsView/>
            </StackPanel>

            <div className="pgp-grid">
                <SimpleGrid items={filteredKeys}
                            onUpdateSelection={updateGridSelection}
                            rowIdExtractor={k => k.key.getKeyId().bytes}
                            selectedItemSelector={selectedKeySelector}
                            rowRenderer={pgpKey => <PgpKeyGridRow pgpKey={pgpKey}/>}
                            rowClassRules={pgpKey => ({"secret-key-row": pgpKey.isPrivate})}
                >
                    <div className="grid-user">User</div>
                    <div className="grid-key-id">Id</div>
                    <div className="grid-expiry">Expiry</div>
                </SimpleGrid>
            </div>
        </>
    );
}
