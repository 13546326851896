import {DateTime} from "luxon";
import {Store} from "redux";
import {AppState} from "../AppState";
import {SystemActions} from "../actions/SystemActions";
import {NotificationMessage} from "../domain/NotificationMessage";
import {getContactShortDisplayName} from "../util/Formatters";
import {ContactDto} from "./messages/ContactDto";

export class NotificationService {
    private readonly store: Store<AppState>;

    private lastUpdate: DateTime = DateTime.utc(1);

    constructor(store: Store<AppState>) {
        this.store = store;
    }

    start() {
        this.handleTimeChange();
        setInterval(() => this.handleTimeChange(), 1000);// * 60 * 60);    // Once an hour
    }

    private handleTimeChange() {
        const now = DateTime.utc();

        if (now.day === this.lastUpdate.day && now.year === this.lastUpdate.year) {
            return;
        }
        this.lastUpdate = now;

        const today = now.toFormat("dd/MM");
        const tomorrow = now.plus({days: 1}).toFormat("dd/MM");

        console.log("Looking for birthdays today...");

        const {contacts} = this.store.getState().contactState;

        const messages: NotificationMessage[] = [];

        for (const contact of contacts) {
            const birthday = contact.Birthday?.substring(0, 5);
            const anniversary = contact.Anniversary?.substring(0, 5);

            if (birthday === today) messages.push(createBirthdayNotification(contact, "today"));
            if (birthday === tomorrow) messages.push(createBirthdayNotification(contact, "tomorrow"));

            if (anniversary === today) messages.push(createAnniversaryNotification(contact, "today"));
            if (anniversary === tomorrow) messages.push(createAnniversaryNotification(contact, "tomorrow"));
        }

        this.store.dispatch(SystemActions.setNotificationMessages(messages));
    }
}

function createBirthdayNotification(contactDto: ContactDto, todayTomorrow: string): NotificationMessage {
    return {
        id: contactDto.Id + "-birthday",
        type: "birthday",
        today: todayTomorrow === "today",
        icon: "🎂",
        message: `${getContactShortDisplayName(contactDto)}'s birthday is ${todayTomorrow}`,
        visible: false,
    };
}

function createAnniversaryNotification(contactDto: ContactDto, todayTomorrow: string): NotificationMessage {
    return {
        id: contactDto.Id + "-anniversary",
        type: "anniversary",
        today: todayTomorrow === "today",
        icon: "🎈",
        message: `${getContactShortDisplayName(contactDto)}'s anniversary is ${todayTomorrow}`,
        visible: false,
    };
}
