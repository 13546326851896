import Folder from "../domain/Folder";
import {ExpandedFolderIdPref} from "../util/Preferences";
import {Dispatch} from "redux";
import {AppState} from "../AppState";
import {
    BeginCreateSubFolderAction,
    BeginMoveFolderAction,
    BeginRenameFolderAction,
    CancelMoveFolderAction,
    CREATE_SUB_FOLDER_BEGIN,
    EXPAND_FOLDER,
    FOLDERS_LOADED,
    FoldersLoadedAction,
    HIDE_EDIT_FOLDER_DIALOG,
    HideEditFolderDialogAction,
    MOVE_FOLDER_BEGIN,
    MOVE_FOLDER_CANCEL,
    PERFORM_SEARCH_ASYNC,
    PerformSearchAction,
    RENAME_FOLDER_BEGIN,
    SEARCH_RESULTS_LOADED,
    SearchResultsLoadedAction,
    SET_CONTEXT_FOLDER,
    SetContextFolderAction,
    SHOW_ONLY_SYNCED_FOLDERS,
    ShowOnlySyncedFoldersAction,
} from "./FolderActionTypes";
import {EmailDataSource, EmailDataSourceResult} from '../domain/EmailDataSource';
import {EmailActions} from './EmailActions';
import {ManageFolderRemoteOperationDto} from '../services/messages/operations/ManageFolderRemoteOperationDto';
import {PayloadAction} from "@reduxjs/toolkit";
import {AddRemoteOperationPayload} from "../redux/SyncSlice";

export class FolderActions {
    static foldersLoaded(folders: Folder[]): FoldersLoadedAction {
        return { type: FOLDERS_LOADED, folders};
    }

    static expandFolder(folderId: number, expand: boolean) {
        return (dispatch: Dispatch, getState: () => AppState) => {
            dispatch({ type: EXPAND_FOLDER, folderId, expand });

            ExpandedFolderIdPref.set(getState().folderState.expandedFolderIds);
        }
    }

    static setContextFolder(folder: Folder | undefined): SetContextFolderAction {
        return { type: SET_CONTEXT_FOLDER, folder };
    }

    static showOnlySyncedFolders(showOnlySynced: boolean): ShowOnlySyncedFoldersAction {
        return { type: SHOW_ONLY_SYNCED_FOLDERS, showOnlySynced };
    }

    static performSearchAsync(emailDataSource?: EmailDataSource, currentSearchText?: string, error?: Error): PerformSearchAction {
        return { type: PERFORM_SEARCH_ASYNC, emailDataSource, currentSearchText, error };
    }

    static searchResultsLoaded(results?: EmailDataSourceResult): SearchResultsLoadedAction {
        return { type: SEARCH_RESULTS_LOADED, results };
    }

    static beginRenameFolder(folder: Folder): BeginRenameFolderAction {
        return { type: RENAME_FOLDER_BEGIN, folder };
    }

    static beginCreateSubFolder(parentFolder: Folder): BeginCreateSubFolderAction {
        return { type: CREATE_SUB_FOLDER_BEGIN, parentFolder };
    }

    static beginMoveFolder(folder: Folder): BeginMoveFolderAction {
        return { type: MOVE_FOLDER_BEGIN, folder };
    }

    static cancelMoveFolder(): CancelMoveFolderAction {
        return { type: MOVE_FOLDER_CANCEL };
    }

    static hideEditFolderDialog(): HideEditFolderDialogAction {
        return { type: HIDE_EDIT_FOLDER_DIALOG };
    }

    static async deleteFolder(folder: Folder): Promise<PayloadAction<AddRemoteOperationPayload>> {
        const ManageFolder: ManageFolderRemoteOperationDto = { DeleteFolder: { ServerId: folder.serverId } };
        return EmailActions.addRemoteOperation({ ManageFolder });
    }

    static async completeMoveFolder(folder: Folder, newParentServerId: string): Promise<PayloadAction<AddRemoteOperationPayload>> {
        const ManageFolder: ManageFolderRemoteOperationDto = { MoveFolder: { ServerId: folder.serverId, NewParentServerId: newParentServerId } };
        return EmailActions.addRemoteOperation({ ManageFolder });
    }

    static async completeEditFolder(folder: Folder, newName: string): Promise<PayloadAction<AddRemoteOperationPayload>> {
        const isNewFolder = folder.folderId === -1;

        if (isNewFolder) {
            const ManageFolder: ManageFolderRemoteOperationDto = {
                CreateSubFolder: {
                    ParentServerId: folder.parentId,
                    Name: newName,
                    ServerId: folder.serverId
                }
            };
            return EmailActions.addRemoteOperation({ManageFolder});
        } else {
            const ManageFolder: ManageFolderRemoteOperationDto = {RenameFolder: {ServerId: folder.serverId, Name: newName}};
            return EmailActions.addRemoteOperation({ManageFolder});
        }
    }
}
