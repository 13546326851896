import {EnrichedPgpKey, EnrichedPgpSignature, EnrichedPgpSubKey, EnrichedPgpUser, EnrichedVerificationResult} from "../domain/pgp/EnrichedPgpKey";
import {config, SignaturePacket, User} from "openpgp";
import {PgpKey} from "../domain/PgpKey";

function enrichPgpUser(user: User): EnrichedPgpUser {
    const certifications = [];

    for (const certification of user.selfCertifications) {
        certifications.push(enrichPgpSignature(certification, user));
    }

    for (const certification of user.otherCertifications) {
        certifications.push(enrichPgpSignature(certification, user));
    }

    return {
        user,
        userId: user.userId?.userid,
        email: user.userId?.email,
        name: user.userId?.name,
        certifications,
    };
}

export interface PgpVerificationResult {
    signingKey: PgpKey;
    result: string;
}

function enrichPgpSignature(signature: SignaturePacket, user: User): EnrichedPgpSignature {
    return {
        signature,
        user,
        verified: signature.verified,
        issuerKeyId: signature.issuerKeyId,
    };
}

export async function enrichPgpKey({key}: PgpKey, allKeys: PgpKey[]): Promise<EnrichedPgpKey> {
    let verificationError = "";
    let verificationResults: EnrichedVerificationResult[] = [];
    try {
        // @ts-ignore - this is there, honest!
        const rawResults: any[] = await key.verifyAllUsers(
            allKeys.map(k => k.key), undefined, {...config, ignoreUnsupportedPackets: true});

        verificationResults = rawResults.map((vr: any) => ({userID: vr.userid, keyID: vr.keyid, valid: vr.valid}));

        await key.verifyPrimaryKey();
    } catch (e: any) {
        verificationError = "" + e.message;
    }
    const subKeys: EnrichedPgpSubKey[] = [];

    // for (const subKey of key.subKeys) {
    //     try {
    //         const signature = await subKey.verify(key.primaryKey);
    //         console.log(subKey);
    //         console.log(signature);
    //         subKeys.push({
    //             verified: signature.verified,
    //             signedBy: findPgpKey(signature.issuerKeyId, allKeys),
    //             signatures: subKey.bindingSignatures.map(enrichPgpSignature),
    //         });
    //     } catch (e: any) {
    //         console.log(e.message);
    //         subKeys.push({
    //             verified: false,
    //             signedBy: undefined,
    //             signatures: subKey.bindingSignatures.map(enrichPgpSignature),
    //         });
    //     }
    // }

    return {
        primaryUser: enrichPgpUser(key.users[0]),
        fingerprint: key.getFingerprint(),
        users: key.users.map(enrichPgpUser),
        verificationError,
        subKeys,
        verificationResults
    };
}
