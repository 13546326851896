import {PhotosQuickSearch} from "./PhotosQuickSearch";
import React from "react";
import {PhotoBreadcrumbs} from "./PhotoBreadcrumbs";

export const PhotosControls = () => {
    return (
        <>
            <PhotosQuickSearch/>
            <PhotoBreadcrumbs/>
        </>
    );
}
