import {Dispatch} from 'redux';
import {
    convertGateKeeperFieldsToEncryptedXml,
    convertGateKeeperFieldsToXml,
    findGatekeeperSecretKey,
    parseGateKeeperEncryptedXml,
    parseGateKeeperXml
} from '../util/GateKeeperXmlParser';
import {DecryptedGateKeeperAccount} from '../domain/DecryptedGateKeeperAccount';
import {EmailActions} from './EmailActions';
import {DbGateKeeperAccount} from '../db/DbSchema';
import {AppState} from '../AppState';
import {uuid} from '../util/Uuid';
import {isChangeAccountRemoteOperationDto} from '../services/messages/RemoteOperationDto';
import {ticksFromDateTime} from "../util/DateHelper";
import {DateTime} from "luxon";
import {DB} from "../db/DbManager";
import {AccountsActions} from "../redux/AccountsSlice";
import {ItemActions} from "./ItemActions";
import {
    findDecryptionKeys,
    findPgpKeyByHex,
    getKeyDescriptions,
    pgpReadArmoredMessage,
    SecretKey
} from "../util/PGPUtil";
import {SystemActions} from "./SystemActions";
import {AppDispatch} from "../AppStore";

export function convertAccountFromXml(record: DbGateKeeperAccount): DecryptedGateKeeperAccount {
    const fields = parseGateKeeperXml(record.DataXml);
    return {
        clientId: record.ClientId,
        id: record.Id,
        isOwner: typeof record.IsOwner === 'undefined' || record.IsOwner,
        entityId: record.EntityId,
        category: record.Category,
        name: record.Name,
        image: record.Image,
        createTime: record.CreateTime,
        decrypted: false,
        fields,
        secretKey: null,
        publicKeyShares: record.PublicKeyShares ?? [],
    };
}

export function convertDecryptedAccountToRecordWithoutFields(account: DecryptedGateKeeperAccount): DbGateKeeperAccount {
    return {
        Id: -1,
        ClientId: account.clientId,
        IsOwner: account.isOwner,
        IsArchived: 0,
        EntityId: account.entityId,
        Category: account.category,
        Name: account.name,
        CreateTime: account.createTime,
        Image: account.image,
        DataXml: "",
        EncryptedDataXml: "",
        PublicKeyShares: account.publicKeyShares,
    };
}

export class AccountActionHelper {
    static createNewAccount() {
        return (dispatch: Dispatch, getState: () => AppState) => {
            try {
                const {accountsState, system} = getState();
                const secretKey = findGatekeeperSecretKey(accountsState.secretKeys, [system.defaultAddress])[0];
                const account: DecryptedGateKeeperAccount = {
                    clientId: uuid(),
                    id: -1,
                    entityId: -1,
                    isOwner: true,
                    category: "",
                    name: "",
                    image: "",
                    createTime: ticksFromDateTime(DateTime.local()),
                    fields: [],
                    decrypted: true,
                    secretKey,
                    publicKeyShares: [],
                };
                dispatch(ItemActions.selectAccount(account));
                dispatch(AccountsActions.setEditAccount(account));
            } catch (e: any) {
                dispatch(SystemActions.showFormattedError("Unable to add new account", e));
            }
        };
    }

    static async selectAccount(record: DbGateKeeperAccount, dispatch: Dispatch, state: AppState) {
        const {accountsState} = state;
        const {secretKeys, password} = accountsState;

        const binaryMessage = await pgpReadArmoredMessage(record.EncryptedDataXml);
        const secretKey = findDecryptionKeys(binaryMessage, secretKeys)[0];
        try {
            dispatch(ItemActions.beginSelectAccount());

            const account = convertAccountFromXml(record);
            dispatch(ItemActions.selectAccount(account));

            if (!secretKey) {
                dispatch(AccountsActions.itemNeedsPassword({
                    record,
                    errorMessage: "You don't have the required private key to decrypt this information",
                    keyName: null,
                }));
                return;
            }

            const encryptedFields = await parseGateKeeperEncryptedXml(binaryMessage, secretKey, password);

            dispatch(ItemActions.selectAccount({
                ...account,
                fields: [...account.fields, ...encryptedFields],
                decrypted: true,
                secretKey
            }));
        } catch (e: any) {
            console.warn(e);
            dispatch(AccountsActions.itemNeedsPassword({
                record,
                errorMessage: password.length > 0 ? e.message : "",
                keyName: getKeyDescriptions([secretKey]),
            }));
        }
    }

    static async showAccountHistory(dispatch: Dispatch, getState: () => AppState, latestAccount: DecryptedGateKeeperAccount) {
        const {accountsState} = getState();
        const {secretKeys, password} = accountsState;

        const record: DbGateKeeperAccount = convertDecryptedAccountToRecordWithoutFields(latestAccount);

        const encryptedHistory = await DB.getGateKeeperAccountHistory(record.EntityId);
        let secretKey: SecretKey | null = null;

        try {
            encryptedHistory.sort((a, b) => b.CreateTime - a.CreateTime);
            const accountHistory = encryptedHistory
                .map(version => convertAccountFromXml(version));

            dispatch(AccountsActions.showAccountHistory(accountHistory));

            for (let index = 0; index < encryptedHistory.length; index++) {
                const encryptedVersion = encryptedHistory[index];
                const decryptedVersion = accountHistory[index];

                const binaryMessage = await pgpReadArmoredMessage(encryptedVersion.EncryptedDataXml);
                secretKey = findDecryptionKeys(binaryMessage, secretKeys)[0];
                if (!secretKey) {
                    dispatch(AccountsActions.itemNeedsPassword({
                        record,
                        errorMessage: "You don't have the required private key to decrypt this information",
                        keyName: null,
                    }));
                    return;
                }

                const enrichedDecryptedVersion = {...decryptedVersion, secretKey};

                const encryptedFields = await parseGateKeeperEncryptedXml(binaryMessage, secretKey, password);

                const updatedVersion = {
                    ...enrichedDecryptedVersion,
                    fields: [...enrichedDecryptedVersion.fields, ...encryptedFields],
                    decrypted: true
                };

                dispatch(AccountsActions.updateAccountHistoryVersion({updatedVersion, index}));
            }

        } catch (e: any) {
            console.warn(e);
            dispatch(AccountsActions.itemNeedsPassword({
                record,
                errorMessage: password.length > 0 ? e.message : "",
                keyName: getKeyDescriptions(secretKey ? [secretKey] : []),
            }));
        }
    }

    static hideAccountHistory() {
        return AccountsActions.showAccountHistory(null);
    }

    static async saveAccount(dispatch: Dispatch, getState: () => AppState, account: DecryptedGateKeeperAccount) {
        try {
            const fields = account.fields.filter(f => !f.isProtected);
            const protectedFields = account.fields.filter(f => f.isProtected);

            const {accountsState} = getState();
            const publicKeys = (account.publicKeyShares ?? [])
                .map(keyId => findPgpKeyByHex(keyId, accountsState.pgpKeys))
                .filter(k => !!k)
                .map(pgpKey => pgpKey!.key);

            const dbAccount: DbGateKeeperAccount = {
                ...convertDecryptedAccountToRecordWithoutFields(account),

                DataXml: convertGateKeeperFieldsToXml(fields),
                EncryptedDataXml: await convertGateKeeperFieldsToEncryptedXml(protectedFields, account.secretKey!, publicKeys),
            };
            dispatch(await EmailActions.addRemoteOperation({ManageAccount: {Change: dbAccount}}));
            dispatch(EmailActions.clearItemContent());
        } catch (e: any) {
            dispatch(SystemActions.showFormattedError("Unable to save account", e));
        }
    }

    static async deleteAccount(dispatch: AppDispatch, account: DecryptedGateKeeperAccount) {
        dispatch(EmailActions.clearItemContent());

        if (account.entityId === -1) {
            await EmailActions.removeRemoteOperation(op => (isChangeAccountRemoteOperationDto(op.ManageAccount) && op.ManageAccount.Change.ClientId === account.clientId))(dispatch);
        } else {
            dispatch(await EmailActions.addRemoteOperation({ManageAccount: {DeleteId: account.entityId}}));
        }
    }
}
