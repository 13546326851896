import {convertNameAndAddressToString} from "../../util/Formatters";
import SimpleAutocomplete from "../common/SimpleAutocomplete";
import React, {FC} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import DbDraftEmail from "../../domain/DbDraftEmail";
import {ComposeEmailActions} from "../../actions/ComposeEmailActions";

interface SenderSelectControlProps {
    senderAddress: string;
}

export const SenderSelectControl: FC<SenderSelectControlProps> = ({senderAddress}) => {
    const dispatch = useAppDispatch();

    const availableAddresses = useAppSelector(({system}) => system.availableAddresses);
    const displayName = useAppSelector(({system}) => system.displayName);

    function onChangeDraftEmail(changes: Partial<DbDraftEmail>) {
        dispatch(ComposeEmailActions.changeDraftEmail(changes));
    }

    return <SimpleAutocomplete
        label="Send From"
        inputType={"email"}
        options={availableAddresses.map(address => ({value: address, title: address}))}
        value={senderAddress}
        setValue={newValue => onChangeDraftEmail({From: convertNameAndAddressToString(displayName, newValue)})}
    />
}
