import React, {FC} from "react";
import {DateTime} from "luxon";

export interface TimeControlProps {
    title: string;
    time: DateTime | undefined | null;
}

export const TimeControl: FC<TimeControlProps> = ({title, time}) => {
    return <span>{title} {time ? time.toFormat("HH:mm:ss") : "never"}</span>;
};
