import {GateKeeperRecordDto} from "../services/messages/GateKeeperRecordDto";
import {DecryptedGateKeeperAccount} from '../domain/DecryptedGateKeeperAccount';
import {EmailThreadContentDto} from "../domain/EmailContentDto";
import {PgpKey} from "../domain/PgpKey";
import {FileDto} from "../services/protected/dto/FileDto";

export type AccountRecordItem = GateKeeperRecordDto | EmailThreadContentDto | FileDto;

export interface AccountsState {
    accounts: GateKeeperRecordDto[];

    secretKeys: PgpKey[];
    secretKeysLoaded: boolean;

    pgpKeys: PgpKey[];
    pgpKeysLoaded: boolean;
    pgpKeyAddresses: string[];

    password: string;
    passwordRequired: boolean;
    passwordError: string;
    passwordRequiredForItem: AccountRecordItem;
    keyDescription: string | null;

    editAccount: DecryptedGateKeeperAccount | null;
    accountHistory: DecryptedGateKeeperAccount[] | null;
}

export const BlankGateKeeperAccount: GateKeeperRecordDto = {
    Id: -1,
    IsOwner: true,
    ClientId: "", EntityId: -1, Category: "", CreateTime: 0, DataXml: "", EncryptedDataXml: "", Image: "", IsArchived: 0, Name: "", 
    PublicKeyShares: [],
};

export const initialAccountsState:AccountsState = {
    accounts: [],
    
    secretKeys: [],
    secretKeysLoaded: false,
    
    pgpKeys: [],
    pgpKeysLoaded: false,
    pgpKeyAddresses: [],
    
    passwordRequired: false,
    password: window.localStorage.getItem("pw") || "",
    passwordError: "",
    passwordRequiredForItem: BlankGateKeeperAccount,
    keyDescription: null,
    editAccount: null,
    accountHistory: null,
};
