import React, {FC} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./ToolbarView.scss";
import {AppState} from "../../AppState";
import {MobileToolbar} from "./MobileToolbar";
import {DesktopToolbar} from "./DesktopToolbar";
import {SystemActions} from "../../actions/SystemActions";
import {isTearOffWindow} from "../../App";
import {useAppSelector} from "../../hooks/ReduxHooks";
import classNames from "classnames";

export const ToolbarView: FC = () => {

    const isMobile = useSelector(({system}: AppState) => system.isMobile);
    const isOffline = useAppSelector(s => s.system.isOffline && s.system.userName);

    const dispatch = useDispatch();

    if (isTearOffWindow) {
        return null;
    }

    const onHideToolbarMenu = () => dispatch(SystemActions.showToolbarMenu(false));
    
    return (
        <div className={classNames("main-toolbar", {"offline-toolbar": isOffline})}
             key="toolbar"
             onClick={onHideToolbarMenu}>
            {isMobile ? <MobileToolbar/> : <DesktopToolbar/>}
        </div>
    );
};
