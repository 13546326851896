import React, {FC, useEffect, useRef, useState} from "react";
import classNames from "classnames";
import {AttachmentDto} from "../../domain/AttachmentDto";
import {EmailActions} from "../../actions/EmailActions";
import MessageIcon from '@mui/icons-material/Message';
import "./AttachmentList.scss";
import {ContextMenu} from "../common/ContextMenu";
import {DropDownMenuItem} from "../toolbar/DropDownMenu";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {EmailContentDto} from "../../domain/EmailContentDto";

type SelectAttachmentActionType = (attachment: AttachmentDto | null, showInline?: boolean) => void;

export interface RenderAttachmentListProps {
    attachments: AttachmentDto[] | undefined;
    emailId: number;
    onSelectAttachment?: SelectAttachmentActionType;
    contextMenuItems: (DropDownMenuItem | string)[];
    emailContent?: EmailContentDto;
}

export const FileTypesPath = "/Static/FileTypes/";

function isCustomImage(attachment: AttachmentDto): boolean {
    return attachment.ContentType === "Id";
}

function getAttachmentImageSource(attachment: AttachmentDto): string {
    if (attachment.ContentType === "Thumbnail") {
        return attachment.Image;
    }
    if (isCustomImage(attachment)) {
        return `/Mail/DownloadAttachment.aspx?id=${attachment.Image}&preview=1&large=0`;
    }
    return FileTypesPath + attachment.Image;
}

const AttachmentItem: FC<{
    attachment: AttachmentDto,
    selectedAttachmentId: string | undefined,
    onSelectAttachment: SelectAttachmentActionType
}> = ({
          attachment,
          selectedAttachmentId,
          onSelectAttachment,
      }) => {
    const dispatch = useAppDispatch();

    const contextAttachmentId = useAppSelector(({itemState}) => itemState.contextAttachment?.Id);
    const contextAttachmentUid = useAppSelector(({itemState}) => itemState.contextAttachment?.Uid);

    return (
        <span className={classNames("selectable", {
            "selected": attachment.Uid === selectedAttachmentId,
            "context": attachment.Id === contextAttachmentId || attachment.Uid === contextAttachmentUid,
            "pending-upload": attachment.IsPendingUpload
        })}
              onClick={() => onSelectAttachment(attachment)}
              onMouseOver={() => dispatch(EmailActions.setContextAttachment(attachment))}
              title={attachment.Name}>

            <img src={getAttachmentImageSource(attachment)}
                 alt={attachment.Name}/>

            {isCustomImage(attachment) || attachment.IsInline ? null : (<span>{attachment.Name}</span>)}

        </span>
    );
}

export const AttachmentList: FC<RenderAttachmentListProps> = ({
                                                                  attachments,
                                                                  emailId,
                                                                  onSelectAttachment,
                                                                  contextMenuItems,
                                                                  emailContent,
                                                              }) => {

    const dispatch = useAppDispatch();
    const menuRef = useRef<HTMLDivElement>(null);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const selectedAttachmentId = useAppSelector(({itemState}) => itemState.selectedAttachmentId);

    useEffect(() => {
        if (attachments?.length === 1 && attachments[0].IsInline && emailContent?.Content.length === 0) {
            EmailActions.showAttachment(attachments[0], emailId, dispatch).then();
        }
    }, [dispatch, attachments, emailContent, emailId]);

    if (!attachments || attachments.length === 0) {
        return null;
    }

    const showMessageButton = attachments.some(isCustomImage) || attachments.some(a => a.IsInline);
    const smallList = !attachments.length;

    const onSelectAttachmentInternal = onSelectAttachment
        || ((attachment: AttachmentDto | null) => {
            if (attachment) {
                EmailActions.showAttachment(attachment, emailId, dispatch).then();
            } else {
                dispatch(EmailActions.showEmailContent());
            }
        });

    const viewEmailSpan = showMessageButton
        ? (
            <span className={classNames("selectable", {"selected": !selectedAttachmentId})}
                  onClick={() => onSelectAttachmentInternal(null)}
                  title="View Email">
                    <MessageIcon style={{fontSize: "32pt"}}/>
                </span>
        ) : null;

    return (
        <div ref={menuRef}
             className={classNames("attachment-list", {"small": smallList}, {"context-menu-open": menuOpen})}>
            {viewEmailSpan}

            {attachments.map((attachment, i) => <AttachmentItem key={i}
                                                                attachment={attachment}
                                                                selectedAttachmentId={selectedAttachmentId}
                                                                onSelectAttachment={onSelectAttachmentInternal}/>)}

            <ContextMenu menuItems={contextMenuItems}
                         elementRef={menuRef}
                         onOpen={() => setMenuOpen(true)}
                         onClose={() => setMenuOpen(false)}/>
        </div>
    );
};