import {DbGateKeeperAccount} from '../../db/DbSchema';
import {ManageFolderRemoteOperationDto} from './operations/ManageFolderRemoteOperationDto';
import DbDraftEmail from '../../domain/DbDraftEmail';
import {DateDto} from '../../domain/DateDto';
import {ContactDto} from "./ContactDto";
import {CalendarDto} from "../../domain/CalendarDto";
import {EditorFileCallback} from "../../components/compose/Editor";
import {NoteSectionDto} from "../../domain/NoteSectionDto";
import {NotePageDto} from "../../domain/NotePageDto";

export function isChangeAccountRemoteOperationDto(value: ChangeAccountRemoteOperationDto | DeleteAccountRemoteOperationDto | undefined): value is ChangeAccountRemoteOperationDto {
    return !!value && (value as ChangeAccountRemoteOperationDto).Change !== undefined;
}

export default interface RemoteOperationDto {
    Id: number,

    ManageEmails?: ManageEmailsRemoteOperationDto;

    SaveDraftEmail?: SaveDraftEmailRemoteOperationDto;

    AddAttachment?: AddAttachmentRemoteOperationDto;

    RemoveAttachment?: RemoveAttachmentRemoteOperationDto;

    ManageAccount?: ChangeAccountRemoteOperationDto | DeleteAccountRemoteOperationDto;

    ManageFolder?: ManageFolderRemoteOperationDto;

    ManageContact?: ManageContactRemoteOperationDto;

    ManageCalendar?: ManageCalendarRemoteOperationDto;

    ManageNoteSection?: ManageNoteSectionRemoteOperationDto;
    ManageNotePage?: ManageNotePageRemoteOperationDto;
}

export interface ManageEmailsRemoteOperationDto {
    EmailIds: number[];

    MarkAsRead?: boolean;

    MarkAsJunk?: boolean;

    Flag?: boolean;

    Pin?: boolean;

    Category?: string;

    NewSubject?: string;

    Delete?: boolean;

    MoveToFolderId?: string;

    AutoArchiveDate?: DateDto;

    RemindMeDate?: DateDto;
}

export interface SaveDraftEmailRemoteOperationDto {
    Email: DbDraftEmail;
}

export interface AddAttachmentRemoteOperationDto {
    EmailUid: string;
    AttachmentUid: string;
    Name: string;
    ContentId: string | null;
    File: File;
    Callback: EditorFileCallback | undefined;
}

export interface RemoveAttachmentRemoteOperationDto {
    EmailUid: string;
    AttachmentUid: string;
}

export interface ChangeAccountRemoteOperationDto {
    Change: DbGateKeeperAccount;
}
export interface DeleteAccountRemoteOperationDto {
    DeleteId: number;
}

export interface ManageClientItemRemoteOperationDto<TDto> {
    Change?: TDto;
    DeleteId?: number;
    DeleteClientId?: string | null;
}

export type ManageContactRemoteOperationDto = ManageClientItemRemoteOperationDto<ContactDto>;

export type ManageCalendarRemoteOperationDto = ManageClientItemRemoteOperationDto<CalendarDto>;

export type ManageNoteSectionRemoteOperationDto = ManageClientItemRemoteOperationDto<NoteSectionDto>;

export type ManageNotePageRemoteOperationDto = ManageClientItemRemoteOperationDto<NotePageDto>;
