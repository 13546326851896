import {DecryptedGateKeeperAccount} from "../domain/DecryptedGateKeeperAccount";
import {CREATE_CALENDAR_ENTRY, CreateCalendarEntryAction, SHOW_CALENDAR_ENTRY, ShowCalendarEntryAction} from "./CalendarActionTypes";
import {CalendarEntryData} from "../services/calendar/CalendarEntryData";
import {LocationItemType, SELECT_CATEGORY_FILTERS, SelectCategoryFiltersAction} from "./EmailActionTypes";
import {ItemContent} from "../domain/ItemContent";
import {EmailViewOptions} from "../domain/EmailViewOptions";

export const CANCEL_PASSWORD_ENTRY = "CANCEL_PASSWORD_ENTRY";

export interface CancelPasswordEntryAction {
    type: typeof CANCEL_PASSWORD_ENTRY;
}

export const SELECT_ACCOUNT = "SELECT_ACCOUNT";

export interface SelectAccountAction {
    type: typeof SELECT_ACCOUNT;
    account: DecryptedGateKeeperAccount;
}

export const BEGIN_SELECT_ACCOUNT = "BEGIN_SELECT_ACCOUNT";


export interface BeginSelectAccountAction {
    type: typeof BEGIN_SELECT_ACCOUNT;
}

export const SHOW_ITEM_CONTENT = "SHOW_ITEM_CONTENT";

export interface ShowItemContentAction {
    type: typeof SHOW_ITEM_CONTENT;
    itemContent: ItemContent;
}

export const SET_ITEM_CONTENT = "SET_ITEM_CONTENT";

export interface SetItemContentAction {
    type: typeof SET_ITEM_CONTENT;
    itemId: number | undefined;
    itemIdStr: string | undefined;
    itemType: LocationItemType | undefined;
}

export const SET_EMAIL_VIEW_OPTIONS = "SET_EMAIL_VIEW_OPTIONS";

export interface SetEmailViewOptionsAction {
    type: typeof SET_EMAIL_VIEW_OPTIONS;
    emailViewOptions: EmailViewOptions;
}

export namespace ItemActions {
    export function beginSelectAccount(): BeginSelectAccountAction {
        return {type: BEGIN_SELECT_ACCOUNT};
    }

    export function selectAccount(account: DecryptedGateKeeperAccount): SelectAccountAction {
        return {type: SELECT_ACCOUNT, account};
    }

    export function setItemContent(itemId: number | undefined, itemIdStr: string | undefined, itemType: LocationItemType): SetItemContentAction {
        return {type: SET_ITEM_CONTENT, itemId, itemIdStr, itemType};
    }

    export function showItemContent(itemContent: ItemContent): ShowItemContentAction {
        return {type: SHOW_ITEM_CONTENT, itemContent};
    }

    export function cancelPasswordEntry(): CancelPasswordEntryAction {
        return {type: CANCEL_PASSWORD_ENTRY};
    }

    export function showCalendarEntry(calendarUid: string | undefined, instanceId: number | undefined): ShowCalendarEntryAction {
        return {type: SHOW_CALENDAR_ENTRY, calendarUid, instanceId};
    }

    export function createCalendarEntry(calendar: CalendarEntryData): CreateCalendarEntryAction {
        return {type: CREATE_CALENDAR_ENTRY, calendar};
    }

    export function setSelectedCategoryFilters(filters: string[]): SelectCategoryFiltersAction {
        return {type: SELECT_CATEGORY_FILTERS, filters};
    }

    export function setEmailViewOptions(emailViewOptions: EmailViewOptions): SetEmailViewOptionsAction {
        return {type: SET_EMAIL_VIEW_OPTIONS, emailViewOptions};
    }
}
