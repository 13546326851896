import {AllActionTypes} from "../actions/AllActionTypes";
import {DateTime} from "luxon";
import {initialSystemStatusState, SystemStatusState} from "../state/SystemStatusState";
import {SET_SYSTEM_STATUS_VIEW, UPDATE_SYSTEM_STATUS, UpdateSystemStatusAction} from "../actions/SystemStatusActionTypes";

export function systemStatusReducer(state: SystemStatusState = initialSystemStatusState, action: AllActionTypes): SystemStatusState {
    switch (action.type) {
        case SET_SYSTEM_STATUS_VIEW:
            return {...state, view: action.view};

        case UPDATE_SYSTEM_STATUS:
            return handleSystemStatusUpdate(state, action);

    }
    return state;
}

function handleSystemStatusUpdate(state: SystemStatusState, action: UpdateSystemStatusAction) {
    for (const s of action.newStatus.Services) {
        if (s.LastSeen && (typeof s.LastSeen === "string")) {
            s.LastSeen = DateTime.fromISO(s.LastSeen);
        }
    }
    return {
        ...state,
        lastUpdateTime: DateTime.local(),
        status: action.newStatus,
    };
}
