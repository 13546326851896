import "./PgpKeysView.scss";
import {StackPanel} from "../panels/StackPanel";
import React from "react";
import {PgpKeyListView} from "./PgpKeyListView";
import {AppContainer} from "../common/AppContainer";
import {ContentViewContainer} from "../ContentView";
import {useAppSelector} from "../../hooks/ReduxHooks";
import ItemView from "../item/ItemView";
import {hasItemContent} from "../../domain/ItemContent";

export const PgpKeysView = () => {

    const hasContent = useAppSelector(({itemState}) => hasItemContent(itemState.itemContent));

    return (
        <AppContainer contentClassName="main-content pgp-keys-view">

            <ContentViewContainer hasContent={hasContent}>
                <StackPanel orientation="vertical"
                            align="stretch"
                            fill={true}
                            className="left-panel">
                    <PgpKeyListView/>
                </StackPanel>

                <StackPanel orientation="vertical"
                            fill={true}
                            className="right-panel">
                    <ItemView/>
                </StackPanel>
            </ContentViewContainer>

        </AppContainer>
    );
}
