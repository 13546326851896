import React, {FC} from "react";

import {useSelector} from "react-redux";
import {AppState} from "../../AppState";
import {SystemStatusView} from "./SystemStatusView";

import "./SystemStatus.scss";
import {SystemLogView} from "./SystemLogView";
import {AppContainer} from "../common/AppContainer";

export const SystemStatusControllerView: FC = () => {
    const view = useSelector(({sysStatus}: AppState) => sysStatus.view);

    const getViewElt = () => {
        switch (view) {
            case "status":
                return <SystemStatusView/>;
            case "logs":
                return <SystemLogView/>;
                
            // TODO: add additional system status views here
            default:
                return <div>TODO</div>;
        }
    };

    return <AppContainer view={getViewElt()}/>;
};
