import "./AccountEditItemView.scss";

import React, {ChangeEvent, FC, useState} from "react";
import {Button, FormLabel, IconButton, MenuItem, TextField} from '@mui/material';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import NoEncryptionIcon from '@mui/icons-material/NoEncryption';
import {GateKeeperField, GateKeeperFieldType} from '../../domain/GateKeeperField';

import "./AccountItemView.scss";
import CasinoIcon from '@mui/icons-material/Casino';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import ShareIcon from '@mui/icons-material/Share';

import {min} from 'lodash';
import {FieldTypeOptions, getAccountFieldType, isSensitiveFieldType, validateAccountField} from './AccountItemHelper';
import GeneratePasswordDialog from '../passwords/GeneratePasswordDialog';
import {AccountsActions} from "../../redux/AccountsSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {DecryptedGateKeeperAccount} from "../../domain/DecryptedGateKeeperAccount";
import {StackPanel} from "../panels/StackPanel";
import SimpleAutocomplete from "../common/SimpleAutocomplete";
import {shallowEqual} from "react-redux";
import {SimpleMultiSelect} from "../common/SimpleSelect";
import {extractAddressDomain, extractEmailAddresses, extractFriendlyNamesFromEmailAddresses} from "../../util/Formatters";

export interface AccountEditFieldProps {
    field: GateKeeperField;
    index: number;
    onRemoveField: () => void;
}

const RenderAccountEditField: FC<AccountEditFieldProps> = ({field, index, onRemoveField}) => {
    const [name, setName] = useState<string>(field.name);
    const dispatch = useAppDispatch();

    const [editingName, setEditingName] = useState<boolean>(false);

    const [passwordDialogOpen, setPasswordDialogOpen] = useState<boolean>(false);

    function handleFieldChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setNewFieldValue(e.target.value);
    }

    function updateField(update: Partial<GateKeeperField>) {
        dispatch(AccountsActions.updateAccountField({index, updatedField: {...field, ...update}}));
    }

    function handleFieldNameChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (editingName) {
            setName(e.target.value);
        }
    }

    function handleGenerateRandomPassword() {
        setPasswordDialogOpen(true);
    }

    function setNewFieldValue(newValue: string | null) {
        if (newValue !== null) {
            updateField({value: newValue});
        }
        setPasswordDialogOpen(false);
    }

    function setNewProtected(newProtected: boolean) {
        updateField({isProtected: newProtected});
    }

    function acceptFieldNameChange() {
        setEditingName(false);
        updateField({name});
    }

    function cancelFieldNameChange() {
        setEditingName(false);
        setName(field.name);
    }

    const nameEditButtons = (
        <>
            <IconButton onClick={acceptFieldNameChange} title="Accept Name Change">
                <DoneIcon/>
            </IconButton>
            <IconButton onClick={cancelFieldNameChange} title="Cancel">
                <ClearIcon/>
            </IconButton>
        </>
    );

    const fieldEditButtons = (
        <>
            <IconButton onClick={() => setEditingName(true)} title="Edit field name"><EditIcon/></IconButton>
            {field.isProtected
                ? <IconButton onClick={() => setNewProtected(false)} title="Field is protected"
                              className="protected"><EnhancedEncryptionIcon/></IconButton>
                : <IconButton onClick={() => setNewProtected(true)} title="Field is unprotected"
                              className="unprotected"><NoEncryptionIcon/></IconButton>}
            <IconButton onClick={() => onRemoveField()} title="Remove field"><DeleteIcon/></IconButton>
        </>
    );

    function handleNameChangeKeyDown(e: React.KeyboardEvent) {
        if (e.key === "Enter") {
            acceptFieldNameChange();
        } else if (e.key === "Escape") {
            cancelFieldNameChange();
        }
    }

    return (
        <div className="field">
            {editingName
                ? (<TextField label="New field name"
                              autoFocus
                              value={name}
                              onChange={e => handleFieldNameChange(e)}
                              onKeyDown={e => handleNameChangeKeyDown(e)}/>)
                : <TextField
                    {...validateAccountField(field)}
                    type={getAccountFieldType(field.type)}
                    label={name}
                    value={field.value}
                    onChange={e => handleFieldChange(e)}
                />}

            {editingName ? nameEditButtons : fieldEditButtons}

            {field.type === "Password"
                && (<>
                    <IconButton onClick={() => handleGenerateRandomPassword()} color="primary"
                                title="Generate a random password"><CasinoIcon/></IconButton>
                    <GeneratePasswordDialog open={passwordDialogOpen} onGenerated={newPassword => setNewFieldValue(newPassword)} variant="password"/>
                </>)}

            {field.type === "PIN"
                && (<>
                    <IconButton onClick={() => handleGenerateRandomPassword()} color="primary"
                                title="Generate a random PIN"><CasinoIcon/></IconButton>
                    <GeneratePasswordDialog open={passwordDialogOpen} onGenerated={newPassword => setNewFieldValue(newPassword)} variant="PIN"/>
                </>)}
        </div>
    );
}

export const AccountEditItemView = () => {

    const account = useAppSelector(({accountsState}) => accountsState.editAccount!);

    const allCategories = useAppSelector(s => s.accountsState.accounts.map(a => a.Category), shallowEqual);
    const pgpKeys = useAppSelector(s => s.accountsState.pgpKeys);
    const domains = ["jrlh.co.uk", "redgatesoftware.co.uk"];    // TODO: don't hardcode this!
    const dispatch = useAppDispatch();

    function onSetEditAccount(account: DecryptedGateKeeperAccount) {
        dispatch(AccountsActions.setEditAccount(account));
    }

    function onRemoveField(index: number) {
        dispatch(AccountsActions.removeEditAccountField(index));
    }

    const fields = account.fields
        .map((field, i) => (<RenderAccountEditField field={field} key={field.id} onRemoveField={() => onRemoveField(i)} index={i}/>));

    function addAccountField(type: GateKeeperFieldType) {
        const isProtected = isSensitiveFieldType(type);
        const ids = account.fields.map(f => f.id);
        const nextId = (min(ids) || 0) - 2;

        const name = FieldTypeOptions.find(o => o.type === type)?.name ?? type;
        onSetEditAccount({...account, fields: [...account.fields, {id: nextId, isProtected, name, type: type, value: ""}]});
    }

    function addDefaultCardFields() {
        onSetEditAccount({
            ...account, fields: [
                {id: 1, isProtected: false, type: "Text", name: "Name on Card", value: ""},
                {id: 2, isProtected: true, type: "CardNumber", name: "Card Number", value: ""},
                {id: 3, isProtected: true, type: "CVV", name: "Card CVV", value: ""},
                {id: 4, isProtected: true, type: "CardExpiry", name: "Card Expiry", value: ""},
                {id: 5, isProtected: true, type: "Number", name: "Card PIN", value: ""},
            ]
        });
    }

    function addDefaultAccountFields() {
        onSetEditAccount({
            ...account, fields: [
                {id: 1, isProtected: false, type: "Text", name: "Username", value: ""},
                {id: 2, isProtected: true, type: "Password", name: "Password", value: ""},
            ]
        });
    }

    if (fields.length === 0) {
        return (
            <div className="account-item">
                <div className="new-account">
                    <FormLabel component="legend">What type of account do you want to create?</FormLabel>
                    <div>&nbsp;</div>
                    <Button onClick={() => addDefaultCardFields()} variant="contained" color="primary">Credit/Debit Card</Button>
                    <div>&nbsp;</div>
                    <Button onClick={() => addDefaultAccountFields()} variant="contained" color="primary">Generic</Button>
                </div>
            </div>
        );
    }

    const availableCategories = [...new Set(allCategories)].sort();

    const validSharePgpKeyOptions = pgpKeys
        .filter(key => key.key.getUserIds()
            .flatMap(id => extractEmailAddresses(id))
            .some(a => domains.includes(extractAddressDomain(a)?.toLowerCase() ?? "")))
        .map(key => ({value: key.keyHexId, title: extractFriendlyNamesFromEmailAddresses(key.key.getUserIds()[0])[0].Name!}))
        .sort((a, b) => a.title.localeCompare(b.title));

    const image = account.image.length > 0 ? <span className={`glyph card-glyph ${account.image}`}/> : null;
    return (
        <div className="account-item">
            {image}
            <TextField className="name" placeholder="Account Name" value={account.name}
                       onChange={e => onSetEditAccount({...account, name: e.target.value})}
                       autoFocus/>
            <br/>
            <StackPanel orientation="horizontal" middle={true}>
                <SimpleAutocomplete
                    label="Category"
                    className="category"
                    freeSolo
                    disableClearable
                    options={availableCategories.map(value => ({value, title: value}))}
                    value={account.category}
                    setValue={newValue => onSetEditAccount({...account, category: newValue})}
                />
                <span className={`group-glyph glyph-${account.category?.toLowerCase()}`}/>
            </StackPanel>

            {fields}

            <br/>
            <div className="field">
                <TextField
                    select
                    label="Add a new field"
                    value={"Unknown"}
                    onChange={(e) => addAccountField(e.target.value as GateKeeperFieldType)}
                >
                    {FieldTypeOptions.map((option) => (
                        <MenuItem key={option.type} value={option.type} disabled={option.type === "Unknown"}>
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>
            </div>

            <br/>

            <StackPanel className="field">
                <SimpleMultiSelect
                    label="Shared With"
                    startAdornment={<ShareIcon/>}
                    onChange={publicKeyShares => onSetEditAccount({...account, publicKeyShares})}
                    options={validSharePgpKeyOptions}
                    value={account.publicKeyShares}
                />  
            </StackPanel>
        </div>
    );
}
