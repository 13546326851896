import DbDraftEmail from "../domain/DbDraftEmail";
import {DbAttachment} from "../db/DbSchema";
import ComposeOptions from "../domain/ComposeOptions";
import {ComposeOptionsPref} from "../util/Preferences";

export interface ComposeEmailState {
    isComposingEmail: boolean;
    hasModifications: boolean;
    draftEmail?: DbDraftEmail;
    draftAttachments?: DbAttachment[];

    settingsDialogOpen: boolean;
    composeOptions: ComposeOptions;
}

export const initialComposeEmailState: ComposeEmailState = {
    isComposingEmail: false,
    hasModifications: false,

    settingsDialogOpen: false,
    composeOptions: ComposeOptionsPref.get(),
};
