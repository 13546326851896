import {FC, useEffect, useState} from "react";
import {EmailRuleDto} from "../../services/rules/dto/EmailRuleDto";
import {ButtonPanel} from "../common/ButtonPanel";
import {Button, TextField} from "@mui/material";
import {ContentLocation} from "../../locations/ContentLocation";
import {EmailRuleConditionChange, RuleItemConditionView} from "./RuleItemConditionView";
import {EmailRuleActionChange, RuleItemActionView} from "./RuleItemActionView";
import {SimpleSelect} from "../common/SimpleSelect";
import {UIMap} from "../../util/UIMap";
import {
    ASSIGN_CATEGORY_ACTION,
    AUTO_ARCHIVE_ACTION,
    EmailRuleActionTypes,
    EmailRuleActionTypeValues,
    FLAG_EMAIL_ACTION,
    FORWARD_EMAIL_ACTION,
    MARK_AS_READ_ACTION,
    MOVE_TO_FOLDER_ACTION,
    SET_EMAIL_IMPORTANCE_ACTION
} from "../../services/rules/dto/EmailRuleActionDto";
import {
    CATEGORY_CONDITION,
    EMAIL_BODY_CONDITION,
    EMAIL_FROM_CONDITION,
    EMAIL_FROM_DOMAIN_CONDITION,
    EMAIL_SUBJECT_CONDITION,
    EMAIL_TO_CONDITION,
    EmailRuleConditionTypes,
    EmailRuleConditionTypeValues
} from "../../services/rules/dto/EmailRuleConditionDto";
import DeleteIcon from "@mui/icons-material/Delete";
import {RulesService} from "../../services/rules/RulesService";
import {useAppDispatch} from "../../hooks/ReduxHooks";
import ConfirmDialog from "../common/ConfirmDialog";

export const availableActionOptions: UIMap<EmailRuleActionTypeValues | "none">[] = [
    {value: "none", title: "Add Action"},
    {value: AUTO_ARCHIVE_ACTION, title: "Auto Archive"},
    {value: ASSIGN_CATEGORY_ACTION, title: "Assign Category"},
    {value: FLAG_EMAIL_ACTION, title: "Flag Email"},
    {value: FORWARD_EMAIL_ACTION, title: "Forward Email"},
    {value: MARK_AS_READ_ACTION, title: "Mark as Read"},
    {value: MOVE_TO_FOLDER_ACTION, title: "Move to Folder"},
    {value: SET_EMAIL_IMPORTANCE_ACTION, title: "Set Importance"},
];

export const availableConditionOptions: UIMap<EmailRuleConditionTypeValues | "none">[] = [
    {value: "none", title: "Add Condition"},
    {value: CATEGORY_CONDITION, title: "Category"},
    {value: EMAIL_BODY_CONDITION, title: "Content"},
    {value: EMAIL_FROM_CONDITION, title: "From"},
    {value: EMAIL_FROM_DOMAIN_CONDITION, title: "From Domain"},
    {value: EMAIL_SUBJECT_CONDITION, title: "Subject"},
    {value: EMAIL_TO_CONDITION, title: "To"},
];

function createRuleCondition(Type: EmailRuleConditionTypeValues): EmailRuleConditionTypes {
    switch (Type) {
        case EMAIL_BODY_CONDITION:
            return {Type, Text: "content", MatchCase: false, MatchPartial: true, IsRegex: false};
        case CATEGORY_CONDITION:
            return {Type, Text: "category", MatchPartial: false, MatchCase: false};
        case EMAIL_TO_CONDITION:
            return {Type, Address: "address", IncludeCC: false};
        case EMAIL_FROM_CONDITION:
            return {Type, Address: "address"};
        case EMAIL_SUBJECT_CONDITION:
            return {Type, Text: "subject", MatchCase: false, MatchPartial: true, IsRegex: false};
        case EMAIL_FROM_DOMAIN_CONDITION:
            return {Type, Address: "domain"};
    }
}

function createRuleAction(Type: EmailRuleActionTypeValues): EmailRuleActionTypes {
    switch (Type) {
        case AUTO_ARCHIVE_ACTION:
            return {Type, AutoArchiveDays: 1};
        case ASSIGN_CATEGORY_ACTION:
            return {Type, Category: "category"};
        case FLAG_EMAIL_ACTION:
            return {Type};
        case MARK_AS_READ_ACTION:
            return {Type};
        case FORWARD_EMAIL_ACTION:
            return {Type, ForwardingAddress: "address"};
        case SET_EMAIL_IMPORTANCE_ACTION:
            return {Type, Importance: "Medium"};
        case MOVE_TO_FOLDER_ACTION:
            return {Type, FolderId: -1};
    }
}

export const RuleItemView: FC<{ rule: EmailRuleDto }> = ({rule: initialRule}) => {

    const dispatch = useAppDispatch();

    const [rule, setRule] = useState<EmailRuleDto>();
    const [dirty, setDirty] = useState(false);
    const [showConfirmDeleteRule, setShowConfirmDeleteRule] = useState(false);

    useEffect(() => setRule(initialRule), [initialRule]);

    if (!rule) return null;

    function updateRule(rule: EmailRuleDto) {
        setRule(rule);
        setDirty(true);
    }

    function onChangeCondition(index: number, change: EmailRuleConditionChange) {
        if (!rule) return;
        const conditions = [...rule.Conditions];
        conditions[index] = {...rule.Conditions[index], ...change};
        updateRule({...rule, Conditions: conditions});
    }

    function onChangeAction(index: number, change: EmailRuleActionChange) {
        if (!rule) return;
        const actions = [...rule.Actions];
        actions[index] = {...rule.Actions[index], ...change};
        updateRule({...rule, Actions: actions});
    }

    function onRemoveCondition(index: number) {
        if (!rule) return;
        const conditions = [...rule.Conditions]
        conditions.splice(index, 1);
        updateRule({...rule, Conditions: conditions});
    }

    function onRemoveAction(index: number) {
        if (!rule) return;
        const actions = [...rule.Actions];
        actions.splice(index, 1);
        updateRule({...rule, Actions: actions});
    }

    function onAddCondition(type: EmailRuleConditionTypeValues | "none") {
        if (!type || type === "none" || !rule) return;

        updateRule({...rule, Conditions: [...rule.Conditions, createRuleCondition(type)]});
    }

    function onAddAction(type: EmailRuleActionTypeValues | "none") {
        if (!type || type === "none" || !rule) return;

        updateRule({...rule, Actions: [...rule.Actions, createRuleAction(type)]});
    }

    function onBeginDeleteRule() {
        setShowConfirmDeleteRule(true);
    }

    async function onDeleteRule(confirmed: boolean) {
        setShowConfirmDeleteRule(false);
        if (confirmed) {
            if (rule && await RulesService.deleteRule(rule.Id, dispatch)) {
                ContentLocation.clearItemContent();
            }
        }
    }

    async function onSaveRule() {
        if (rule && await RulesService.saveRule(rule, dispatch) && rule.Id !== -1) {
            setDirty(false);
        }
    }

    const buttons = <>
        <Button variant="contained" color="primary" disabled={!dirty} onClick={onSaveRule}>Save</Button>
        <Button variant="contained" color="inherit" onClick={() => ContentLocation.clearItemContent()}>Cancel</Button>
        <Button variant="contained" color="secondary" className="right" onClick={onBeginDeleteRule}>Delete</Button>
    </>;

    return (
        <ButtonPanel className="rule-item-view" buttons={buttons}>
            <TextField className="title field"
                       label="Rule Name"
                       fullWidth
                       value={rule.Name}
                       onChange={(e) => updateRule({...rule, Name: e.target.value})}/>

            <div className="field">
                <Button className="link" onClick={() => updateRule({...rule, StopProcessing: !rule?.StopProcessing})}>
                    {rule.StopProcessing ? "Stop" : "Continue"}
                </Button>
                &nbsp; processing rules if this rule is triggered
            </div>

            <div className="field title">When</div>

            {rule.Conditions.map((condition, i) => (
                <div key={i} className="condition">
                    {i ? <Button className="link" onClick={() => updateRule({...rule, OrConditions: !rule?.OrConditions})}>
                        {rule.OrConditions ? "or" : "and"}
                    </Button> : null}
                    <div>
                        <Button className="delete selectable" onClick={() => onRemoveCondition(i)}><DeleteIcon/></Button>
                        <RuleItemConditionView key={i} condition={condition}
                                               onChange={change => onChangeCondition(i, change)}/>
                    </div>
                </div>
            ))}

            <div className="field">
                <SimpleSelect options={availableConditionOptions}
                              value={"none"}
                              className="add-item"
                              disableUnderline
                              onChange={onAddCondition}/>
            </div>

            <div className="field"/>

            <div className="field title">Then</div>

            {rule.Actions.map((action, i) => (
                <div key={i} className="action">
                    <div>
                        <Button className="delete selectable" onClick={() => onRemoveAction(i)}><DeleteIcon/></Button>
                        <RuleItemActionView key={i} action={action}
                                            onChange={change => onChangeAction(i, change)}/>
                    </div>
                </div>
            ))}

            <div className="field">
                <SimpleSelect options={availableActionOptions}
                              value={"none"}
                              className="add-item"
                              disableUnderline
                              onChange={onAddAction}/>
            </div>

            <ConfirmDialog title="Delete Rule"
                           message="Are you sure you want to delete this email rule?"
                           open={showConfirmDeleteRule}
                           onConfirm={onDeleteRule}
                           confirmColour="error"/>
        </ButtonPanel>
    );
} 
