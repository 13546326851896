import React from "react";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import HistoryIcon from '@mui/icons-material/History';
import SearchOptions from "../../domain/SearchOptions";
import OutboxIcon from '@mui/icons-material/Outbox';
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {SET_SEARCH_OPTIONS, SetSearchOptionsAction} from "../../actions/FolderActionTypes";
import styled from "@emotion/styled";
import {RxSubjects} from "../../state/RxSubjectsState";
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';

type ValidSearchOption = keyof SearchOptions;

const SearchOptionsViewDiv = styled.div`
    &.quick-options {
        padding: 0 5px;
        display: flex;
        justify-content: center;

        .MuiToggleButton-root {
            border-radius: 0;
            border-top: 0;
            margin-left: 0;
        }
    }
`;

export const SearchOptionsView = () => {
    const dispatch = useAppDispatch();

    const searchOptions = useAppSelector(s => s.folderState.searchOptions);
    const searchText = useAppSelector(s => s.folderState.searchText);
    const isAdmin = useAppSelector(s=>s.system.roles.includes("admin"));

    const values: string[] = Object.entries(searchOptions).filter(([, v]) => v).map(([k]) => k);

    function onChangeOptions(newValues: ValidSearchOption[]) {
        const newOptions: SearchOptions = {
            IncludeSentItems: false,
            IncludeArchive: false,
            IncludeAllMailboxes: false,
            IncludeDeleted: false,
            IncludeSpam: false,
        };
        for (const value of newValues) {
            newOptions[value] = true;
        }
        const action: SetSearchOptionsAction = {type: SET_SEARCH_OPTIONS, searchOptions: newOptions};
        dispatch(action);
        setTimeout(() => RxSubjects.searchText$.next(searchText), 0);
    }

    return (
        <SearchOptionsViewDiv className="quick-options">
            <ToggleButtonGroup
                value={values}
                onChange={(_, newValues) => onChangeOptions(newValues)}
            >
                {isAdmin && <ToggleButton value="IncludeAllMailboxes" title="Include All Mailboxes"><DynamicFeedIcon/></ToggleButton>}
                <ToggleButton value="IncludeSentItems" title="Include Sent Items"><OutboxIcon/></ToggleButton>
                <ToggleButton value="IncludeArchive" title="Include Archived Items"><HistoryIcon/></ToggleButton>
                <ToggleButton value="IncludeSpam" title="Include Spam Items"><DeleteOutlineIcon/></ToggleButton>
                <ToggleButton value="IncludeDeleted" title="Include Deleted Items"><DeleteIcon/></ToggleButton>
            </ToggleButtonGroup>
        </SearchOptionsViewDiv>
    );
}
