import React, {FocusEventHandler, KeyboardEventHandler} from "react";
import {AutocompleteRenderInputParams, Paper, TextField} from "@mui/material";
import classNames from "classnames";
import styled from "@emotion/styled";
import Autocomplete from "@mui/material/Autocomplete";

const AutoCompleteOptionSpan = styled.span`
  font-size: 10pt;
  display: block;

  span.name {
    display: block;
    font-weight: bold;
  }
`;

interface AutocompleteOption {
    title: string;
    value: string;
}

interface EmailAutoCompleteProps {
    label?: string;
    options: AutocompleteOption[];
    value: string;
    autoFocus?: boolean;
    freeSolo?: boolean;
    className?: string;
    disableClearable?: boolean;
    setValue: (newValue: string) => void;
    onFocus?: FocusEventHandler<HTMLInputElement>;
    onKeyPress?: KeyboardEventHandler;
    fullWidth?: boolean;
    inputType?: string;
}

function getAutoCompleteLabel(option: AutocompleteOption | string): string {
    return isAutocompleteOption(option) ? option.title : option;
}

function isAutocompleteOption(value: AutocompleteOption | string | null): value is AutocompleteOption {
    return (value as AutocompleteOption).title !== undefined;
}

const RenderSimpleAutocomplete = (props: EmailAutoCompleteProps) => {
    const [open, setOpen] = React.useState(false);

    function handleChange(event: React.ChangeEvent<{}>, newValue: AutocompleteOption | string | null) {
        if (newValue) {
            const newStringValue = isAutocompleteOption(newValue) ? newValue.value : newValue;
            props.setValue(newStringValue);
        }
    }

    function renderOption(props: any, option: AutocompleteOption | string) {
        return (
            <AutoCompleteOptionSpan {...props}>
                <span className="name">{(typeof option === "string") ? option: option.title}</span>
            </AutoCompleteOptionSpan>
        );
    }

    function renderInput(params: AutocompleteRenderInputParams) {
        return (
            <TextField
                {...params}
                label={props.label}
                margin="dense"
                fullWidth
                autoFocus={props.autoFocus}
                size="small"
                InputProps={{...params.InputProps, type: props.inputType}}
            />);
    }

    return (
        <Autocomplete
            className={classNames("auto-complete", props.className)}
            autoFocus={props.autoFocus}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onChange={handleChange}
            options={props.options}
            freeSolo={props.freeSolo}
            filterOptions={props.freeSolo ? (options, params) => {
                const value = params.inputValue;
                const lowerValue = params.inputValue.toLowerCase();
                const filteredOptions = options.filter(o => o.title.toLowerCase().includes(lowerValue));
                if (value && filteredOptions[0]?.title.toLowerCase() !== lowerValue) {
                    filteredOptions.push({value, title: `Add '${value}'`});
                }
                return filteredOptions;
            } : undefined}
            disableClearable={props.disableClearable}
            value={{title: props.value, value: props.value}}
            getOptionLabel={getAutoCompleteLabel}
            PaperComponent={params => (<Paper {...params} elevation={8}/>)}
            renderOption={renderOption}
            renderInput={renderInput}
            onFocus={props.onFocus}
            onKeyPress={props.onKeyPress}
            fullWidth={props.fullWidth}
        />);
};

export default React.memo(RenderSimpleAutocomplete);
