import {AppState} from "../AppState";

export interface AppConfig {
    title: string;
    showMobileSearch: boolean;
    showFolderMenuFn: (state: AppState) => boolean;
    showAppControlsInMobileToolbar: boolean;
}

const defaultAppConfig: AppConfig = {
    title: "",
    showMobileSearch: false,
    showFolderMenuFn: _ => false,
    showAppControlsInMobileToolbar: true,
}

const EmailApp: AppConfig = {
    ...defaultAppConfig,
    title: "Redgate Mail",
    showMobileSearch: true,
    showFolderMenuFn: _ => true,
    showAppControlsInMobileToolbar: false,
};


const SystemAppDefinitions = {
    email: EmailApp,
    system: {...defaultAppConfig, title: "System Dashboard"},
    prefs: {...defaultAppConfig, title: "Preferences"},
    pgpkeys: {...defaultAppConfig, title: "PGP Keys"},
    users: {...defaultAppConfig, title: "User Management"},
    "login-audit": {...defaultAppConfig, title: "Login Audit"},
    rules: {...defaultAppConfig, title: "Email Rules"},
}

type SystemAppDefinitionsType = typeof SystemAppDefinitions;
export type LocationAppType = keyof SystemAppDefinitionsType;

export function getAppConfig(app: LocationAppType): AppConfig {
    return SystemAppDefinitions[app];
}
