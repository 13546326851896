import {BaseCalendarEntry, BaseCalendarEntryDto} from './BaseCalendarEntryDto';
import {DateTime, Zone} from 'luxon';
import {dateTimeFromNullableTicks, ticksFromNullableDateTime} from '../util/DateHelper';

export interface RecurrenceExceptionDto extends BaseCalendarEntryDto {
    ExceptionStartTime: number | null;
}

export interface RecurrenceException extends BaseCalendarEntry {
    ExceptionStartTime: DateTime | null;
}

export function recurrenceExceptionFromDto(dto: RecurrenceExceptionDto, zone: Zone): RecurrenceException {
    return {
        ...dto,

        CreateDateTime: dateTimeFromNullableTicks(dto.CreateDateTime, zone),
        StartDateTime: dateTimeFromNullableTicks(dto.StartDateTime, zone),
        EndDateTime: dateTimeFromNullableTicks(dto.EndDateTime, zone),
        AppointmentReplyDateTime: dateTimeFromNullableTicks(dto.AppointmentReplyDateTime, zone),

        ExceptionStartTime: dateTimeFromNullableTicks(dto.ExceptionStartTime, zone)
    };
}

export function dtoFromRecurrenceException(entity: RecurrenceException, zone: Zone): RecurrenceExceptionDto {
    return {
        ...entity,

        CreateDateTime: ticksFromNullableDateTime(entity.CreateDateTime, zone),
        StartDateTime: ticksFromNullableDateTime(entity.StartDateTime, zone),
        EndDateTime: ticksFromNullableDateTime(entity.EndDateTime, zone),
        AppointmentReplyDateTime: ticksFromNullableDateTime(entity.AppointmentReplyDateTime, zone),

        ExceptionStartTime: ticksFromNullableDateTime(entity.ExceptionStartTime, zone)
    };
}
