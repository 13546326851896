import {AppStore} from "../AppStore";
import {RxSubjects} from "../state/RxSubjectsState";
import {debounceTime} from "rxjs/operators";
import {PhotoBrowserService} from "../services/photos/PhotoBrowserService";

const SEARCH_DEBOUNCE_TIME = 50;

export function registerPhotosSearchTextSubjectHandler(store: AppStore) {
    const service = new PhotoBrowserService(store.dispatch);

    function handleNextLoad(searching: boolean) {
        const {photosState} = store.getState();

        service.browsePhotos(photosState.breadcrumbs, searching ? photosState.searchText : undefined)
            .catch(console.error);
    }

    RxSubjects.photosSearchText$
        .pipe(
            debounceTime(SEARCH_DEBOUNCE_TIME),
        )
        .subscribe(searching => handleNextLoad(searching));
}
