import React, {useRef, useState} from "react";
import {Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {useAppSelector} from "../../hooks/ReduxHooks";

export interface DropDownMenuProps {
    className?: string;
    menuItems: DropDownMenuItem[];
    hidden?: boolean;
    disabled?: boolean;
}

export interface DropDownMenuItem {
    icon: any;
    name: string;
    hide?: boolean;
    disabled?: boolean;
    onClick: () => void;
}

export const DropDownMenu = ({className, menuItems, hidden, disabled}: DropDownMenuProps) => {
    const menuRef = useRef<HTMLDivElement>(null);
    const [isMenuOpen, setMenuOpen] = useState(false);

    const isMobile = useAppSelector(({system}) => system.isMobile);

    const mainItem = menuItems[0];

    if (hidden) {
        return null;
    }
    if (isMobile) {
        return (
            <ButtonGroup variant="text" ref={menuRef} className={className}>
                {menuItems.map(menuItem => (
                    <Button
                        disabled={disabled}
                        key={menuItem.name}
                        style={{border: "none"}}
                        title={mainItem.name}
                        onClick={_event => {
                            setMenuOpen(false);
                            menuItem.onClick();
                        }}>
                        {menuItem.icon}
                        <span className="label">{menuItem.name}</span>
                    </Button>
                ))}
            </ButtonGroup>
        );
    }
    
    return (<>
        <ButtonGroup variant="text" ref={menuRef} className={className}>
            <Button style={{border: "none"}} title={mainItem.name}
                    disabled={disabled}
                    onClick={() => mainItem.onClick()}>{mainItem.icon}<span
                className="label">{mainItem.name}</span></Button>

            <Button className="drop-down-arrow" onClick={() => setMenuOpen(!isMenuOpen)}><ArrowDropDownIcon/></Button>
        </ButtonGroup>

        <Popper open={isMenuOpen} 
                anchorEl={menuRef.current} 
                role={undefined} 
                transition
                disablePortal
                placement="bottom-start">
            {({TransitionProps}) => (
                <Grow {...TransitionProps} style={{transformOrigin: 'left top'}} timeout={100}>
                    <Paper>
                        <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                            <MenuList id="split-button-menu" dense>
                                {menuItems.map(menuItem => (
                                    <MenuItem
                                        key={menuItem.name}
                                        onClick={_event => {
                                            setMenuOpen(false);
                                            menuItem.onClick();
                                        }}>
                                        {menuItem.icon}
                                        <span className="popup-text">{menuItem.name}</span>
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </>);
}
