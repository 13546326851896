import {SystemStatusViewType} from "../locations/SystemStatusLocation";
import {SystemStatusResponseDto} from "../services/system/SystemStatusResponseDto";

export const SET_SYSTEM_STATUS_VIEW = "SET_SYSTEM_STATUS_VIEW";

export interface SetSystemStatusViewAction {
    type: typeof SET_SYSTEM_STATUS_VIEW;
    view: SystemStatusViewType;
}

export const UPDATE_SYSTEM_STATUS = "UPDATE_SYSTEM_STATUS";

export interface UpdateSystemStatusAction {
    type: typeof UPDATE_SYSTEM_STATUS;
    newStatus: SystemStatusResponseDto;
}

export type SystemStatusActionTypes =
    SetSystemStatusViewAction
    | UpdateSystemStatusAction
    ;
