import React, {useEffect, useRef} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {AttachmentFilesActions} from "../../redux/AttachmentFilesSlice";
import {RxSubjects} from "../../state/RxSubjectsState";
import {SmallLoadingIndicator} from "../common/SmallLoadingIndicator";

export const AttachmentInfiniteLoader = () => {
    const needScrollRef = useRef<HTMLDivElement>(null);

    const dispatch = useAppDispatch();

    useEffect(() => {
            if (!needScrollRef.current) return;

            const observer = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting) {
                    dispatch(AttachmentFilesActions.setShouldLoadMore(true));

                    RxSubjects.attachmentSearchText$.next();
                } else {
                    dispatch(AttachmentFilesActions.setShouldLoadMore(false));
                }
            }, {threshold: [0],});

            observer.observe(needScrollRef.current);

            return () => {
                observer.disconnect();
                dispatch(AttachmentFilesActions.setShouldLoadMore(false));
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [needScrollRef]);

    return (
        <div ref={needScrollRef}>
            <AttachmentListLoadingIndicator/>
        </div>
    );
}

const AttachmentListLoadingIndicator = () => {
    const hasMoreItems = useAppSelector(s => s.attachmentsState.moreAttachmentsAvailable);

    return hasMoreItems ? <div style={{height:"50px"}}><SmallLoadingIndicator/></div> : <div>&nbsp;</div>;
}
