import React from 'react';
import './App.scss';
import "redux";
import MainView from './components/MainView';
import {Provider} from 'react-redux';
import {getStore_DoNotCall} from './BootStrap';

export const isTearOffWindow = window.opener?.location.host === window.location.host;

const App: React.FC = () => {

    return (
        <Provider store={getStore_DoNotCall()}>
            {/* <CssBaseline /> */}
            <MainView/>
        </Provider>
    );
}

export default App;
