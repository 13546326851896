import React, {FC} from "react";
import {useSelector} from "react-redux";
import {AppState} from "../../../AppState";
import {TimeControl} from "../../common/TimeControl";

export const SystemStatusUpdateTime: FC = () => {

    const lastUpdateTime = useSelector(({sysStatus}:AppState) => sysStatus.lastUpdateTime);

    return (
        <div className="bottom-status-bar"><TimeControl title="Last Updated:" time={lastUpdateTime}/></div>
    );
};
