import DbDraftEmail from "../domain/DbDraftEmail";
import { DbAttachment } from "../db/DbSchema";
import { MarkDraftEmailAttachmentUploadedAction } from './AttachmentActionTypes';
import ComposeOptions from "../domain/ComposeOptions";

export const COMPOSE_EMAIL = "COMPOSE_EMAIL";
export interface ComposeEmailAction {
    type: typeof COMPOSE_EMAIL;
    show: boolean;
    email?: DbDraftEmail;
    attachments?: DbAttachment[];
}

export const CHANGE_DRAFT_EMAIL = "CHANGE_DRAFT_EMAIL";
export interface ChangeDraftEmailAction {
    type: typeof CHANGE_DRAFT_EMAIL;
    changes: Partial<DbDraftEmail>;
}

export const AMEND_DRAFT_EMAIL = "AMEND_DRAFT_EMAIL";
export interface AmendDraftEmailAction {
    type: typeof AMEND_DRAFT_EMAIL;
    email: DbDraftEmail;
}

export const SET_COMPOSE_SETTINGS_OPEN = "SET_COMPOSE_SETTINGS_OPEN";
export interface SetComposeSettingsOpenAction {
    type: typeof SET_COMPOSE_SETTINGS_OPEN;
    open: boolean;
}

export const SET_COMPOSE_OPTIONS = "SET_COMPOSE_OPTIONS";
export interface SetComposeOptionsAction {
    type: typeof SET_COMPOSE_OPTIONS;
    composeOptions: ComposeOptions;
}

export type ComposeEmailActionTypes =
    ComposeEmailAction |
    ChangeDraftEmailAction |
    MarkDraftEmailAttachmentUploadedAction |
    AmendDraftEmailAction |
    SetComposeSettingsOpenAction |
    SetComposeOptionsAction;
