import {performMail3RestCall} from "../../util/HttpHelper";
import {SystemActions} from "../../actions/SystemActions";
import {PgpKey} from "../../domain/PgpKey";
import {AppDispatch} from "../../AppStore";
import {EmailActions} from "../../actions/EmailActions";

export class PgpKeyService {
    static async importKey(pgpKey: PgpKey, dispatch: AppDispatch) {
        try {
            await performMail3RestCall("PgpKey/Import", {key: pgpKey.key.armor(), secret: pgpKey.isPrivate});

            dispatch(EmailActions.clearItemContent());
            dispatch(SystemActions.showNotification("Key imported"));
        } catch (e: any) {
            dispatch(SystemActions.showFormattedError("Unable to upload key", e));
        }
    }

    static async deleteKey(keyId: number, dispatch: AppDispatch) {
        try {
            await performMail3RestCall("PgpKey/Delete", {keyId});

            dispatch(EmailActions.clearItemContent());
            dispatch(SystemActions.showNotification("Key deleted"));
        } catch (e: any) {
            dispatch(SystemActions.showFormattedError("Unable to delete key", e));
        }
    }
}
