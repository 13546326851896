import React, {ReactNode, useEffect} from "react";
import './MainView.scss';
import {useDispatch} from "react-redux";
import LoginView from "./login/LoginView";
import {ThemeProvider} from "@mui/material";
import createAppTheme from "../theme/Themes";
import {FolderTreeSizePref} from '../util/Preferences';
import {HotKeys, KeyMap} from "react-hotkeys";
import {NotificationRequestBanner} from "./banners/NotificationRequestBanner";
import {DebugView} from "./DebugView";
import {attachPermissionChangeHook} from "../NotificationManager";
import {getAppView} from "../apps/AppViews";
import {EncryptionPasswordDialog} from "./security/EncryptionPasswordDialog";
import classNames from "classnames";
import {LoggedOutBanner} from "./banners/LoggedOutBanner";
import {useAppSelector} from "../hooks/ReduxHooks";
import {SnackbarView} from "./SnackbarView";
import {getUsernameCookie} from "../util/CommonCookies";

const keyMap: KeyMap = {
    DELETE: "del",
    UP: "up",
    DOWN: "down",
};

export const initialTreeSize = FolderTreeSizePref.get() + "px";

const MainView = () => {

    const app = useAppSelector(s => s.system.app);
    const theme = useAppSelector(s => s.system.theme);
    const userName = useAppSelector(s => s.system.userName);
    const notificationPermission = useAppSelector(s => s.system.notificationPermission);

    const loggedInUserName =  getUsernameCookie();
    
    const dispatch = useDispatch();

    useEffect(() => attachPermissionChangeHook(dispatch));

    const content: ReactNode = userName.length === 0 && (!loggedInUserName || app !== "email")
        ? <LoginView/>
        : getAppView(app);

    useEffect(() => {
        document.getElementsByTagName("body")[0].className = theme
    }, [theme]);

    const isDevMode = window.location.hostname === "localhost";
    if (isDevMode) console.log("Development mode");
    
    return (
        <HotKeys className={classNames("main-view", {"dev-mode": isDevMode})} keyMap={keyMap}>
            <ThemeProvider theme={createAppTheme(theme)}>
                {notificationPermission === "default" || !notificationPermission ?
                    <NotificationRequestBanner/> : null}

                {userName.length === 0 && loggedInUserName ? <LoggedOutBanner/> : null}

                <DebugView/>

                {content}

                <SnackbarView/>
                
                <EncryptionPasswordDialog/>
            </ThemeProvider>
        </HotKeys>
    );
};

export default MainView;
