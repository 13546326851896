import {EmailThreadContentDto} from "./EmailContentDto";
import {DecryptedGateKeeperAccount} from "./DecryptedGateKeeperAccount";
import {EmailAnalysisDto} from '../services/messages/responses/EmailAnalysisDto';
import {EmailSourceDto} from './EmailSourceDto';
import {ContactDto} from "../services/messages/ContactDto";
import {CalendarEntryData} from "../services/calendar/CalendarEntryData";
import {GateKeeperRecordDto} from "../services/messages/GateKeeperRecordDto";
import {PgpKey, UnknownPgpKey} from "./PgpKey";
import {EmailRuleDto} from "../services/rules/dto/EmailRuleDto";
import {FileDto} from "../services/protected/dto/FileDto";

export interface DummyItemContent {
    dummyType: "loading" | "none";
}

export interface LoginRequiredContent {
    loginRequired: true;
}

export type ItemContent =
    EmailThreadContentDto
    | DecryptedGateKeeperAccount
    | DummyItemContent
    | EmailAnalysisDto
    | EmailSourceDto
    | ContactDto
    | CalendarEntryData
    | PgpKey
    | UnknownPgpKey
    | EmailRuleDto
    | FileDto
    | LoginRequiredContent;

export function hasItemContent(content: ItemContent | undefined): boolean {
    return !!content && (!isDummyContent(content) || content?.dummyType === "loading");
}

export function isDummyContent(content: ItemContent | undefined): content is DummyItemContent {
    if (!content) {
        return false;
    }
    return (content as DummyItemContent).dummyType !== undefined;
}

export function isEmailContent(content: ItemContent | GateKeeperRecordDto | undefined): content is EmailThreadContentDto {
    if (!content) {
        return false;
    }
    const asEmail = content as EmailThreadContentDto;
    return asEmail.Emails !== undefined
        && asEmail.Id !== undefined
        && asEmail.Emails[0]
        && asEmail.Emails[0].Content !== undefined
        && asEmail.Emails[0].FolderId !== undefined;
}

export function isEmailAnalysisContent(content: ItemContent | undefined): content is EmailAnalysisDto {
    if (!content) {
        return false;
    }
    return (content as EmailAnalysisDto).SpamFilterResults !== undefined;
}

export function isEmailSourceContent(content: ItemContent | undefined): content is EmailSourceDto {
    if (!content) {
        return false;
    }
    return (content as EmailSourceDto).Decoded !== undefined;
}

export function isAccountContent(content: ItemContent | undefined): content is DecryptedGateKeeperAccount {
    if (!content) {
        return false;
    }
    return (content as DecryptedGateKeeperAccount).fields !== undefined;
}

export function isContactContent(content: ItemContent | undefined): content is ContactDto {
    if (!content) return false;

    return (content as ContactDto).Addresses !== undefined;
}

export function isCalendarEntryContent(content: ItemContent | undefined): content is CalendarEntryData {
    if (!content) return false;

    return (content as CalendarEntryData).calendar !== undefined;
}

export function isPgpKeyContent(content: ItemContent | undefined): content is PgpKey {
    if (!content) return false;

    return content && (content as PgpKey).keyHexId !== undefined;
}

export function isUnknownPgpKeyContent(content: ItemContent | undefined): content is UnknownPgpKey {
    if (!content) return false;

    return content && (content as UnknownPgpKey).unknownKeyHexId !== undefined;
}

export function isEmailRuleContent(content: ItemContent | undefined): content is EmailRuleDto {
    if (!content) return false;

    return content && (content as EmailRuleDto).Conditions !== undefined;
}

export function isLoginRequiredContent(content: ItemContent | undefined): content is LoginRequiredContent {
    if (!content) return false;

    return content && (content as LoginRequiredContent).loginRequired;
}
