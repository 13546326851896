import {DisplayableMessage} from '../actions/DisplayableMessage';
import {HandshakePref} from '../util/Preferences';
import {AllowedThemes, ConnectionStatus} from '../actions/SystemActionTypes';
import {ThemePref} from '../theme/Themes';
import {parseLocation} from "../util/LocationParser";
import {AppConfig, getAppConfig, LocationAppType} from "../apps/AppConfig";
import {NotificationMessage} from "../domain/NotificationMessage";
import {PermissionRole} from "../domain/PermissionRoles";
import {getUsernameCookie} from "../util/CommonCookies";

const handshake = HandshakePref.get();

const emptyMessage: DisplayableMessage = {show: false, message: ""};

export const initialLocation = parseLocation(window.location.hash);

export interface SystemState {
    app: LocationAppType;
    appConfig: AppConfig;

    theme: AllowedThemes;
    connectionStatus: ConnectionStatus;
    isOffline: boolean;
    userName: string;
    roles: PermissionRole[];
    loginInProgress: boolean;

    error: DisplayableMessage;
    notification: DisplayableMessage;
    warning: DisplayableMessage;

    systemStateType: number;
    systemMessages: string[];

    notificationMessages: NotificationMessage[];

    isMobile: boolean;
    showFolderMenu: boolean;
    folderTreeWidth: number;
    showToolbarMenu: boolean;

    availableAddresses: string[];
    defaultAddress: string;
    displayName: string;

    notificationPermission: NotificationPermission;
}

export const initialSystemState: SystemState = {
    app: initialLocation.app || "email",
    appConfig: getAppConfig(initialLocation.app || "email"),

    theme: ThemePref.get(),
    connectionStatus: ConnectionStatus.Disconnected,
    isOffline: true,
    userName: getUsernameCookie(),
    roles: handshake.Roles || [],
    loginInProgress: false,

    error: emptyMessage,
    notification: emptyMessage, //{show: true, message: "Testing..."},
    warning: emptyMessage,

    systemStateType: 0,
    systemMessages: [],
    notificationMessages: [],

    isMobile: false,
    showFolderMenu: false,
    showToolbarMenu: false,
    folderTreeWidth: 350,
    availableAddresses: handshake.AvailableAddresses,
    defaultAddress: handshake.DefaultAddress,
    displayName: handshake.DisplayName,

    notificationPermission: typeof Notification === "undefined" ? "denied" : Notification.permission,
}
