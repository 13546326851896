import { Notifier } from '../actions/SystemActions';

export function copyTextToClipboard(text: string, notifier: Notifier) {
    var textArea = document.createElement("textarea");
    textArea.style.position = 'fixed';
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.background = 'transparent';

    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        if (notifier.showNotification) notifier.showNotification(successful ? "Copied" : "Unable to copy to the clipboard");
    } catch (err) {
        if (notifier.showError) notifier.showError("Unable to copy to the clipboard");
    }
    document.body.removeChild(textArea);
}
