import {ServiceWorkerMessage, ServiceWorkerMessageType} from "../domain/ServiceWorkerMessaging";


export type ServiceWorkerMessageListener = (data: ServiceWorkerMessage) => void;

const swMessageListenerMap: Map<ServiceWorkerMessageType, ServiceWorkerMessageListener> = new Map<ServiceWorkerMessageType, ServiceWorkerMessageListener>();

export class ServiceWorkerMessageHandler {

    static addServiceWorkerMessageListener(type: ServiceWorkerMessageType, listener: ServiceWorkerMessageListener) {
        swMessageListenerMap.set(type, listener);
    }
    
    static listenForServiceWorkerMessages() {

        try {
            // Handle messages from the service worker
            if (navigator.serviceWorker) {
                navigator.serviceWorker.addEventListener('message', (event: MessageEvent<ServiceWorkerMessage>) => {
                    const data = event.data;

                    console.log("Received SW message: ", data);
                    
                    const listener = swMessageListenerMap.get(data.type);
                    if (listener) {
                        listener(data);
                    } else {
                        console.warn("No listener registered for event type: " + data.type);
                    }
                });
            }
        } catch (e) {
            console.error(e);
        }
    }

}
