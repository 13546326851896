import "./ButtonPanel.scss";

import {FC, PropsWithChildren, ReactNode} from "react";
import {AlignmentType, StackPanel} from "../panels/StackPanel";
import classNames from "classnames";

export interface ButtonPanelProps {
    buttons: ReactNode;

    className?: string;
    buttonsClassName?: string;
    fill?: boolean;
    fillContent? : boolean;
    
    buttonLocation?: "top" | "bottom";

    align?: AlignmentType;
}

export const ButtonPanel: FC<ButtonPanelProps & PropsWithChildren> = ({
                                                      buttons,
                                                      className,
                                                      buttonsClassName,
                                                      fill,
                                                      fillContent,
                                                      align,
                                                      buttonLocation,
                                                      children,
                                                  }) => {
    return (
        <StackPanel orientation="vertical" className={classNames("button-panel", buttonLocation || "bottom")} fill={fill}>
            <div className={classNames("content", className)}>{children}</div>

            <StackPanel orientation="horizontal" align={align ?? "middle"} fill={fillContent} className={classNames("buttons", buttonsClassName)}>
                {buttons}
            </StackPanel>
        </StackPanel>
    );
};
