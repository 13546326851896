import React, {FC} from "react";
import {formatDateForTextField, parseTextFieldDate} from "../../util/Formatters";
import {InputLabelProps, TextField} from "@mui/material";

interface ConditionalTextFieldProps {
    label: string;
    value: string | null;
    type: string;
    onChange: (newValue: string) => void;
    fullWidth?: boolean;
}

export const ConditionalTextField: FC<ConditionalTextFieldProps> = (props) => {
    let value = props.value;
    if (value === null || value === undefined) return null;

    if (props.type === "date") {
        value = formatDateForTextField(value);
    }

    function handleValueChange(newValue: string) {
        if (props.type === "date" && newValue) {
            props.onChange(parseTextFieldDate(newValue));
        } else {
            props.onChange(newValue);
        }
    }

    const inputLabelProps: InputLabelProps = props.type === "date" ? {shrink: true} : {};
    
    return <TextField
        label={props.label}
        value={value || ""}
        type={props.type}
        fullWidth={props.fullWidth}
        onChange={(e) => handleValueChange(e.target.value)}
        InputLabelProps={inputLabelProps}
    />;
}