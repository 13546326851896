import {SystemActions} from "../actions/SystemActions";
import {UsersActions} from "../redux/UsersSlice";
import {AppDispatch} from "../AppStore";
import {UserDto} from "../domain/UserDto";
import {performMail3RestCall} from "../util/HttpHelper";
import {UserListResponseDto} from "./messages/responses/UserListResponseDto";

export class UserManagementService {

    static async updateUserList(dispatch: AppDispatch) {
        const response = await performMail3RestCall<UserListResponseDto>("Users/List", {});
        dispatch(UsersActions.setUsers(response.Users));
    }

    static async saveUser(user: UserDto, dispatch: AppDispatch): Promise<boolean> {
        try {
            await performMail3RestCall<UserListResponseDto>("Users/Save", {User: user});

            dispatch(SystemActions.showNotification(user.WebId === -1 ? "User created" : "User saved"));

            await UserManagementService.updateUserList(dispatch);

            return true;
        } catch (e: any) {
            dispatch(SystemActions.showFormattedError("Unable to save user", e));
            return false;
        }
    }

    static async deleteUserTokens(webUserId: number, dispatch: AppDispatch): Promise<boolean> {
        try {
            await performMail3RestCall<UserListResponseDto>(`Users/DeleteUserTokens/${webUserId}`, {});

            dispatch(SystemActions.showNotification("User tokens deleted"));

            await UserManagementService.updateUserList(dispatch);

            return true;
        } catch (e: any) {
            dispatch(SystemActions.showFormattedError("Unable to save user", e));
            return false;
        }
    }
}
