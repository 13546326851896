import {DateTime, Zone} from 'luxon';

export function shiftFromUtcToZoneMillis(dateTime: DateTime) {
    return dateTime
        .plus({minutes: dateTime.offset})
        .toUTC();
}

export function createDateTimeInZone(dateTime: DateTime, zone: Zone): DateTime {
    const asLocal = dateTime.setZone(zone);
    return asLocal.minus({minutes: asLocal.offset});
}

export function createDateTimeFromZone(dateTime: DateTime, zone: Zone): DateTime {
    const asLocal = dateTime.setZone(zone);
    return asLocal.plus({minutes: asLocal.offset});
}