import React, {FC, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';

interface PasswordDialogProps {
    title: string;
    message: string;
    details?: string;
    errorMessage: string;
    open: boolean;
    onCancel: () => void;
    onEnterPassword: (password: string) => void;
}

const PasswordDialog: FC<PasswordDialogProps> = ({ title, message, details, errorMessage, open, onCancel, onEnterPassword }) => {
    const [password, setPassword] = useState<string>("");

    return (
        <Dialog open={open} onClose={onCancel}>

            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

            <DialogContent>
                <div id="alert-dialog-description">{message}</div>
                {details ? <div id="alert-dialog-description"><br/>{details}</div> : null}

                {errorMessage.length > 0 ? <div id="alert-dialog-description" className="error"><br/>{errorMessage}<br/>&nbsp;</div> : null}

                <Grid container spacing={1} alignItems="flex-end">
                    <Grid item>
                        <LockIcon />
                    </Grid>
                    <Grid item xs>
                        <TextField label="Password" type="password" autoFocus
                                   onChange={e => setPassword(e.target.value)}
                                   onKeyPress={e => { if (e.key === "Enter") { onEnterPassword(password); } }} fullWidth />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => onEnterPassword(password)} color="primary" variant="contained">OK</Button>
                <Button onClick={onCancel} color="inherit">Cancel</Button>
            </DialogActions>

        </Dialog>
    );
}

export default PasswordDialog;
