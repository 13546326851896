import "./Users.scss";

import React, {FC, useEffect, useState} from "react";
import {StackPanel} from "../panels/StackPanel";
import {QuickSearchText} from "../common/QuickFilterOption";
import {SimpleGrid} from "../common/grid/SimpleGrid";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {AppState} from "../../AppState";
import {UserDto} from "../../domain/UserDto";
import {UsersLocation} from "../../locations/UsersLocation";
import {UserControlsView} from "./UserControlsView";
import {SystemActions} from "../../actions/SystemActions";
import {UserManagementService} from "../../services/UserManagementService";

interface UserGridRowProps {
    user: UserDto;
}

export const UserGridRow: FC<UserGridRowProps> = ({user}) => {
    return (<>

        <div className="grid-web-id">{user.WebId}</div>

        <div className="grid-mail-id">{user.MailId}</div>

        <div className="grid-web-un">{user.WebUsername}</div>

        <div className="grid-mail-un">{user.MailUsername}</div>

        <div className="grid-dn">{user.DisplayName}</div>
    </>);
};

export const UserListView = () => {

    const dispatch = useAppDispatch();

    const [filterText, setFilterText] = useState<string>("");

    const allUsers = useAppSelector(({usersState}) => usersState.users);

    const filteredUsers = (filterText.length === 0
        ? [...allUsers]
        : allUsers.filter(k => k.MailUsername?.toLowerCase().includes(filterText) || k.WebUsername?.toLowerCase().includes(filterText)))
        .sort((a, b) => a.WebUsername.localeCompare(b.WebUsername));

    const selectedUserSelector = ({usersState}: AppState) => usersState.selectedUser ? [usersState.selectedUser] : [];

    function updateGridSelection(newSelectedUsers: UserDto[]) {
        UsersLocation.selectUser(newSelectedUsers[newSelectedUsers.length - 1]?.WebId);
    }

    useEffect(() => {
        if (allUsers.length === 0) {
            UserManagementService.updateUserList(dispatch)
                .catch(error => dispatch(SystemActions.showFormattedError("Unable to load users", error)));
        }
    }, [allUsers, dispatch]);

    return (
        <StackPanel orientation="vertical" className="users-list-panel">
            <StackPanel orientation="horizontal" className="panel top-panel" align="middle" fill={true}>
                <QuickSearchText onQuickSearchTextChange={text => setFilterText(text.toLowerCase())}
                                 searchPrompt={"Filter Users"}/>
                <UserControlsView/>
            </StackPanel>

            <div className="user-grid">
                <SimpleGrid items={filteredUsers}
                            onUpdateSelection={updateGridSelection}
                            rowIdExtractor={u => u.WebId}
                            selectedItemSelector={selectedUserSelector}
                            rowRenderer={user => <UserGridRow user={user}/>}
                            rowClassRules={user => ({"mail-user-row": !!user.MailId})}
                >
                    <div className="grid-web-id">Web Id</div>
                    <div className="grid-mail-id">Mail Id</div>
                    <div className="grid-web-un">Web Username</div>
                    <div className="grid-mail-un">Mail Username</div>
                    <div className="grid-dn">Display Name</div>
                </SimpleGrid>
            </div>
        </StackPanel>
    );
};