import EmailHeaderDto, {EmailHeader} from '../services/messages/EmailHeaderDto';
import {ItemContent} from '../domain/ItemContent';
import {OutgoingEmailDto} from '../services/messages/OutgoingEmailDto';
import {AttachmentContentDto} from "../domain/AttachmentContentDto";
import {LocationItemType} from "../actions/EmailActionTypes";
import {AttachmentDto} from "../domain/AttachmentDto";
import {RelativeDateTimeOption} from "../domain/RelativeDateTimeOption";
import {ImageContentDto} from "../domain/ImageContentDto";
import {EmailViewOptions} from "../domain/EmailViewOptions";
import {EmailViewOptionsPref} from "../util/Preferences";

export const BlankEmailHeaderDto: EmailHeaderDto = {
    AttachmentCount: 0,
    BodySample: "",
    CC: "",
    Category: "",
    CertificateId: null,
    Date: 0,
    DisplayDate: 0,
    Defaulted: false,
    DomainKeyMatchStatus: "",
    Flagged: 0,
    FolderId: 0,
    From: "",
    Id: 0,
    Importance: "",
    LastVerb: "",
    MessageClass: "",
    PendingSecureCount: 0,
    PgpStatus: "",
    Read: 0,
    SendCount: 0,
    SmimeStatus: "",
    SpfMatchStatus: "",
    Subject: "",
    ThreadId: "",
    To: "",
    ShowTrustShield: false,
    Reminder: undefined,
};

export const BlankEmailHeader: EmailHeader = {
    ...BlankEmailHeaderDto, 
    IsDummyLoadingRow: false,
    DateRangeCategory: "",
};

export interface ItemState {
    emailHeaders: EmailHeader[];
    allEmailHeadersLoaded: boolean;
    itemLoadingInProgress: boolean;
    draftEmailHeaders: EmailHeader[];
    selectedGreylistedEmailId?: string;
    selectedEmailHeaders: EmailHeader[];
    selectedOutgoingEmails: OutgoingEmailDto[];

    availableCategories: string[];
    selectedCategoriesFilter: string[];

    itemId?: number;
    itemIdStr?: string;
    itemType?: LocationItemType;
    itemContent: ItemContent;
    
    emailViewOptions: EmailViewOptions;

    contentExpanded: boolean;
    isLoadingContent: boolean;
    selectedAttachmentId?: string;
    contextAttachment?: AttachmentDto;
    imageContent?: ImageContentDto;
    inlineContent?: AttachmentContentDto;

    editEmailCategory: string;
    showEmailCategoryDialog: boolean;

    showArchiveSettingsDialog: boolean;
    autoArchiveDate: number | null;

    showRemindMeLaterSettingsDialog: boolean;
    remindMeLaterDate: RelativeDateTimeOption;
}

export const NoItemContent: ItemContent = { dummyType: "none" };
export const LoadingItemContent: ItemContent = { dummyType: "loading" };

export const initialItemState: ItemState = {
    emailHeaders: [],
    allEmailHeadersLoaded: false,
    itemLoadingInProgress: false,
    draftEmailHeaders: [],

    selectedEmailHeaders: [],
    selectedOutgoingEmails: [],

    availableCategories: [],
    selectedCategoriesFilter: [],

    contentExpanded: false,
    isLoadingContent: false,
    itemContent: NoItemContent,

    emailViewOptions: EmailViewOptionsPref.get(),

    showEmailCategoryDialog: false,
    editEmailCategory: "",

    showArchiveSettingsDialog: false,
    autoArchiveDate: null,

    showRemindMeLaterSettingsDialog: false,
    remindMeLaterDate: undefined,
};
