import React, {FC} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import {Button} from '@mui/material';

import "./HideContentButton.scss";
import {hasItemContent} from '../../domain/ItemContent';
import {ContentLocation} from "../../locations/ContentLocation";
import {useAppSelector} from "../../hooks/ReduxHooks";
import {isTearOffWindow} from "../../App";

export function popOutContent() {
    window.open(window.location.href,
        "_blank",
        //currentLocation.itemId + "_" + currentLocation.itemType + "_" + currentLocation.itemIdStr + "_"+currentLocation.composeId,
        "popup=true");
}

export const HideContentButton: FC = () => {
    const hasContent = useAppSelector(({itemState}) => hasItemContent(itemState.itemContent));

    if (!hasContent) {
        return null;
    }

    function onClearItemContent() {
        ContentLocation.clearItemContent();
    }

    return (
        <>
            <Button onClick={onClearItemContent} size="small" className="hide-content-button" title="Close">
                <CloseIcon/>
            </Button>
            {!isTearOffWindow && <Button onClick={popOutContent} size="small" className="expand-content-button" title="Pop-out">
                <AspectRatioIcon/>
            </Button>}
        </>
    );
};
