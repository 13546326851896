import React, {FC, ReactNode, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {AppState} from "../../AppState";
import {GateKeeperRecordDto} from "../../services/messages/GateKeeperRecordDto";
import {Accordion, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import "./AccountsListView.scss";
import {QuickSearchText} from "../common/QuickFilterOption";
import classNames from 'classnames';
import {isAccountContent} from '../../domain/ItemContent';
import {AccountImage} from './AccountImage';
import {ContentLocation} from "../../locations/ContentLocation";
import {useAppSelector} from "../../hooks/ReduxHooks";
import ShareIcon from "@mui/icons-material/Share";

function groupBy<K, V>(list: V[], keyGetter: (value: V) => K) {
    const map = new Map<K, V[]>();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

const RenderAccountsListView: FC<AccountsListViewProps> = ({
                                                               accounts, selectedAccount,
                                                           }) => {

    const isMobile = useAppSelector(({system}) => system.isMobile);

    const [expanded, setExpanded] = useState<string | false>(false);
    const [accountFilterText, setAccountFilterText] = useState<string>("");

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    function onSelectAccount(account: GateKeeperRecordDto) {
        ContentLocation.showAccount(account.Id, account.ClientId);
    }

    function renderAccount(account: GateKeeperRecordDto): ReactNode {
        return (
            <div key={account.Id}
                 className={classNames("account-summary", "selectable", {"selected": selectedAccount?.clientId === account.ClientId})}
                 onClick={() => onSelectAccount(account)}
            >
                {typeof account.IsOwner !== 'undefined' && !account.IsOwner && <ShareIcon className="share-icon"/>}
                <AccountImage image={account.Image} showDefault={true}/>
                <span className="name">{account.Name}</span>
            </div>
        );
    }

    function isAccountMatch(account: GateKeeperRecordDto) {
        return account.Category.toLowerCase().indexOf(accountFilterText) >= 0
            || account.Name.toLowerCase().indexOf(accountFilterText) >= 0;
    }

    function renderCategory(accounts: GateKeeperRecordDto[]): ReactNode | null {
        const category = accounts[0].Category;
        accounts.sort((a, b) => a.Name.localeCompare(b.Name));
        return (
            <Accordion key={category} expanded={expanded === category} onChange={handleChange(category)} TransitionProps={{unmountOnExit: true, timeout: isMobile ? 0 : 100}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>} className="category selectable-2">
                    <span className={`group-glyph glyph-${category.toLowerCase()}`}/>
                    <span>{category}</span>
                </AccordionSummary>

                {accounts.map(account => renderAccount(account))}

            </Accordion>
        );
    }

    const filteredAccounts = (accountFilterText.length === 0
        ? accounts
        : accounts.filter(isAccountMatch))
        .filter(a => a.IsArchived === 0);

    const categories = groupBy(filteredAccounts, a => a.Category);

    if (categories.size === 1 && filteredAccounts.length > 0 && expanded !== filteredAccounts[0].Category) {
        // Auto-expand if there's only one category being displayed
        setExpanded(filteredAccounts[0].Category);
    }

    const sortedCategories: GateKeeperRecordDto[][] = [];
    categories.forEach(accounts => sortedCategories.push(accounts));

    const categoryElts = sortedCategories
        .sort((a, b) => a[0].Category.localeCompare(b[0].Category))
        .map(category => renderCategory(category));

    return (
        <div className="content-item-list accounts-view">
            <div className="quick-options">
                <QuickSearchText onQuickSearchTextChange={text => setAccountFilterText(text.toLowerCase())} searchPrompt="Filter Accounts" autoFocus/>
            </div>
            <div className="accounts-list">
                {categoryElts}
            </div>
        </div>
    )
}

const mapState = ({accountsState, itemState}: AppState) => ({
    accounts: accountsState.accounts,
    selectedAccount: isAccountContent(itemState.itemContent) ? itemState.itemContent : undefined,
});

const connector = connect(mapState);

type AccountsListViewProps = ConnectedProps<typeof connector>;

const AccountsListView = connector(RenderAccountsListView);

export default AccountsListView;
