import Folder from "../domain/Folder";
import {EmailDataSource, EmailDataSourceResult} from '../domain/EmailDataSource';
import {GetGreylistedEmailsRequestDto} from '../services/messages/requests/GetGreylistedEmailsRequestDto';
import {GreylistedEmailResponseDto} from '../services/messages/responses/GreylistedEmailResponseDto';
import SearchOptions from "../domain/SearchOptions";

export const SELECT_FOLDER = "SELECT_FOLDER";
export interface SelectFolderAction {
    type: typeof SELECT_FOLDER;
    folderId: number;
    virtualDataSource?: EmailDataSource;
}

export const FOLDERS_LOADED = "FOLDERS_LOADED";
export interface FoldersLoadedAction {
    type: typeof FOLDERS_LOADED;
    folders: Folder[];
}

export const EXPAND_FOLDER = "EXPAND_FOLDER";
export interface ExpandFolderAction {
    type: typeof EXPAND_FOLDER;
    folderId: number;
    expand: boolean;
}

export const SHOW_ONLY_SYNCED_FOLDERS = "SHOW_ONLY_SYNCED_FOLDERS";
export interface ShowOnlySyncedFoldersAction {
    type: typeof SHOW_ONLY_SYNCED_FOLDERS;
    showOnlySynced: boolean;
}

export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export interface SetSearchTextAction {
    type: typeof SET_SEARCH_TEXT;
    searchText: string;
}

export const SET_SEARCH_OPTIONS = "SET_SEARCH_OPTIONS";
export interface SetSearchOptionsAction {
    type: typeof SET_SEARCH_OPTIONS;
    searchOptions: SearchOptions;
}

export const PERFORM_SEARCH_ASYNC = "PERFORM_SEARCH_ASYNC";
export interface PerformSearchAction {
    type: typeof PERFORM_SEARCH_ASYNC;
    emailDataSource?: EmailDataSource;
    currentSearchText?: string;
    error?: Error;
}

export const SEARCH_RESULTS_LOADED = "SEARCH_RESULTS_LOADED";
export interface SearchResultsLoadedAction {
    type: typeof SEARCH_RESULTS_LOADED;
    results?: EmailDataSourceResult;
}

export const SET_CONTEXT_FOLDER = "SET_CONTEXT_FOLDER";
export interface SetContextFolderAction {
    type: typeof SET_CONTEXT_FOLDER;
    folder: Folder | undefined;
}

export const RENAME_FOLDER_BEGIN = "RENAME_FOLDER_BEGIN";
export interface BeginRenameFolderAction {
    type: typeof RENAME_FOLDER_BEGIN;
    folder: Folder;
}

export const CREATE_SUB_FOLDER_BEGIN = "CREATE_SUB_FOLDER_BEGIN";
export interface BeginCreateSubFolderAction {
    type: typeof CREATE_SUB_FOLDER_BEGIN;
    parentFolder: Folder;
}

export const MOVE_FOLDER_BEGIN = "MOVE_FOLDER_BEGIN";
export interface BeginMoveFolderAction {
    type: typeof MOVE_FOLDER_BEGIN;
    folder: Folder;
}

export const MOVE_FOLDER_CANCEL = "MOVE_FOLDER_CANCEL";
export interface CancelMoveFolderAction {
    type: typeof MOVE_FOLDER_CANCEL;
}

export const HIDE_EDIT_FOLDER_DIALOG = "HIDE_EDIT_FOLDER_DIALOG";
export interface HideEditFolderDialogAction {
    type: typeof HIDE_EDIT_FOLDER_DIALOG;
}

export const GET_GREYLISTED_EMAILS_ASYNC = "GET_GREYLISTED_EMAILS_ASYNC";
export interface GetGreylistedEmailsAsyncAction {
    type: typeof GET_GREYLISTED_EMAILS_ASYNC;
    request?: GetGreylistedEmailsRequestDto;
    response?: GreylistedEmailResponseDto;
    error?: Error;
}

export type FolderActionTypes =
    SelectFolderAction
    | ExpandFolderAction
    | FoldersLoadedAction
    | ShowOnlySyncedFoldersAction
    | SetSearchTextAction | SetSearchOptionsAction
    | PerformSearchAction
    | SearchResultsLoadedAction
    | SetContextFolderAction
    | BeginRenameFolderAction
    | BeginCreateSubFolderAction
    | HideEditFolderDialogAction
    | BeginMoveFolderAction
    | CancelMoveFolderAction
    | GetGreylistedEmailsAsyncAction;
